import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(
      _ctx.b('', {
        success: _ctx.isSuccess,
        confirmed: _ctx.isConfirmed,
        expected: _ctx.isExpected,
      })
    ),
    disabled: _ctx.isDisabled
  }, [
    (_ctx.isIconShow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedIcon
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isRemoveShow)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass(_ctx.b('remove')),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleRemove && _ctx.handleRemove(...args)), ["stop"]))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedRemoveIcon
          }, null, 10, _hoisted_3)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isTitleShow)
      ? (_openBlock(), _createElementBlock("h6", {
          key: 2,
          class: _normalizeClass(_ctx.b('title'))
        }, [
          _renderSlot(_ctx.$slots, "title")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isLabelShow)
      ? (_openBlock(), _createElementBlock("p", {
          key: 3,
          class: _normalizeClass(_ctx.b('label'))
        }, [
          _renderSlot(_ctx.$slots, "label")
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}