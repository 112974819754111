import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedWindow,
  SharedDate,
  SharedCurrency,
  SharedMultiSelect,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { SharedMultiSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedDate,
    SharedCurrency,
    SharedMultiSelect,
    UIButton,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
    date: {
      type: <PropType<string[]>>Array,
      default: () => [],
    },
    volumeFrom: {
      type: String,
      default: "",
    },
    volumeTo: {
      type: String,
      default: "",
    },
    status: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
    type: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
  },
  emits: {
    "update:isFilter": null,
    "update:currency": null,
    "update:date": null,
    "update:volumeFrom": null,
    "update:volumeTo": null,
    "update:status": null,
    "update:type": null,
  },
  computed: {
    displayedCurrencyOptions(): SharedMultiSelectOption[] {
      return [
        {
          id: 0,
          label: "USD",
          value: "USD",
          icon: "usd",
          isActive: false,
        },
      ];
    },

    displayedStatusOptions(): SharedMultiSelectOption[] {
      return [
        {
          id: 0,
          label: "EUR",
          value: "EUR",
          icon: "eur",
          isActive: false,
        },
      ];
    },

    displayedTypeOptions(): SharedMultiSelectOption[] {
      return [
        {
          id: 0,
          label: "RUB",
          value: "RUB",
          icon: "rub",
          isActive: false,
        },
      ];
    },
  },
  methods: {
    changeCurrency(currency: SharedMultiSelectOption[]): void {
      this.$emit("update:currency", currency);
    },

    changeDate(date: string[]): void {
      console.log(date);
      // this.$emit("update:date", date);
    },

    changeVolumeFrom(from: string): void {
      this.$emit("update:volumeFrom", from);
    },

    changeVolumeTo(to: string): void {
      this.$emit("update:volumeTo", to);
    },

    changeStatus(status: SharedMultiSelectOption[]): void {
      this.$emit("update:status", status);
    },

    changeType(type: SharedMultiSelectOption[]): void {
      this.$emit("update:type", type);
    },

    handleClose(): void {
      this.$emit("update:isFilter", !this.isFilter);
    },

    handleApply(): void {
      console.log("Handle Apply");
    },
  },
});
