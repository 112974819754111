import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  emits: {
    google: null,
  },
  computed: {
    displayedIcon(): string {
      return SVG.google;
    },
  },
  methods: {
    handleGoogle(): void {
      this.$emit("google");
    },
  },
});
