import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Детали операции",
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('list'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Дата и время")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "07.05.2024 15:02:10", 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, "№ " + _toDisplayString(_ctx.$t("сделки")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "24356", 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Тип")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.$t("Пополнение")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item-operation'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Операция")), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-group'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('item-icon')),
                innerHTML: _ctx.displayedChevronIcon
              }, null, 10, _hoisted_1),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('item-info'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('item-label'))
                }, "120,000.99 RUB", 2),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('item-label'))
                }, "1,000.12345678 USDT", 2)
              ], 2)
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Цена исполнения")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "98.928347 RUB", 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Комиссия")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "0.6894 USDT", 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Статус")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.$t("Выполнено")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item-stage'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Этап")), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-steps'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('item-step'))
              }, "1. " + _toDisplayString(_ctx.$t("Средства Ушли")), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('item-step'))
              }, "2. " + _toDisplayString(_ctx.$t("Выставили ордер")), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('item-step', { active: true }))
              }, " 3. " + _toDisplayString(_ctx.$t("Средства поступили, но в другом объеме")), 3)
            ], 2)
          ], 2)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Закрыть",
          view: "main",
          onClick: _ctx.handleClose
        }, null, 8, ["onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}