import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-search";

export const SVG: SvgAttribute = {
  search: `<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.972" fill-rule="evenodd" clip-rule="evenodd" d="M7.33827 0.0179528C10.1654 -0.147683 12.4985 0.83282 14.3377 2.95945C16.2182 5.42981 16.6386 8.13317 15.5988 11.0696C15.3014 11.8137 14.902 12.493 14.4007 13.1076C15.5287 14.2352 16.6568 15.3628 17.7848 16.4904C18.1146 16.9855 18.0656 17.4408 17.6377 17.8561C17.2123 18.0728 16.8059 18.0448 16.4186 17.772C15.3046 16.6584 14.1905 15.5449 13.0765 14.4313C10.959 16.0509 8.59787 16.5482 5.99304 15.9231C3.12219 15.0593 1.22347 13.2034 0.296831 10.3552C-0.499428 7.10151 0.306306 4.32107 2.71404 2.01397C4.05011 0.87706 5.59153 0.211722 7.33827 0.0179528ZM7.80069 1.82487C10.2906 1.8314 12.1894 2.88192 13.4969 4.97648C14.5583 6.99275 14.5864 9.02378 13.581 11.0696C12.2079 13.3494 10.1831 14.435 7.50642 14.3262C4.76674 13.9546 2.95206 12.4628 2.06245 9.85097C1.41206 7.16517 2.13371 4.93106 4.22743 3.14855C5.28902 2.35365 6.48014 1.91242 7.80069 1.82487Z"/>
  </svg>`,
  clear: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_437_34640)">
    <circle cx="6.99967" cy="7.00016" r="4.66667" fill="white"/>
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M6.49388 0.560466C9.10843 0.475284 11.1182 1.52346 12.5232 3.705C13.5544 5.55398 13.6911 7.46804 12.9333 9.44718C12.0828 11.3697 10.6473 12.6229 8.62669 13.207C6.09786 13.7675 3.94227 13.1158 2.15989 11.2519C0.693961 9.48289 0.238232 7.47767 0.792704 5.23625C1.39031 3.26233 2.63445 1.86324 4.52513 1.03898C5.16204 0.78402 5.81829 0.624516 6.49388 0.560466ZM4.88059 4.3339C5.03049 4.34891 5.16721 4.39906 5.29075 4.48429C5.85168 5.04979 6.41677 5.61034 6.98606 6.16593C7.57434 5.5822 8.16679 5.00341 8.76341 4.42961C9.13875 4.26841 9.41673 4.37325 9.59739 4.74406C9.63865 4.9435 9.6022 5.12578 9.48802 5.29093C8.92252 5.85186 8.36197 6.41695 7.80638 6.98625C8.39011 7.57452 8.96889 8.16698 9.5427 8.76359C9.70551 9.24011 9.54145 9.53179 9.05052 9.63859C8.91678 9.61527 8.79373 9.56515 8.68138 9.4882C8.12045 8.9227 7.55536 8.36216 6.98606 7.80656C6.41677 8.36216 5.85168 8.9227 5.29075 9.4882C4.91702 9.69899 4.6208 9.6215 4.40208 9.25578C4.32713 9.03506 4.36358 8.83452 4.51145 8.65422C5.06782 8.10242 5.61926 7.54641 6.16575 6.98625C5.61015 6.41695 5.04961 5.85186 4.48411 5.29093C4.29413 4.9863 4.32604 4.70829 4.57981 4.45695C4.67697 4.39895 4.77724 4.35794 4.88059 4.3339Z" fill="#9E9FA8"/>
    </g>
    <defs>
    <clipPath id="clip0_437_34640">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
