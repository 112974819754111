<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b('', { active: isSearch })">
    <button :class="b('action', { active: isSearch })" @click="handleSearch">
      <span :class="b('icon')" v-html="displayedIcons.search"></span>
    </button>

    <input
      :class="b('field')"
      type="text"
      :value="search"
      @input="handleChangeSearch"
    />

    <span
      :class="b('clear')"
      v-if="isClearShow"
      v-html="displayedIcons.clear"
    ></span>
  </div>
</template>
