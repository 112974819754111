import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedCheckbox, SharedSwitch } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCheckbox,
    SharedSwitch,
  },
  data() {
    return {
      twoFactor: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handlePassword(): void {
      console.log("Handle Password");
    },

    handleEmail(): void {
      console.log("Handle Email");
    },
  },
});
