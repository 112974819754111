import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  FileImageParams,
  FileType,
  KybDocumentsFileName,
} from "@/shared/constants/constants";
import {
  KybUserFileRequestFactory,
  KybUserFileResponseFactory,
} from "@/shared/factory/kyb";
import {
  KybUserFileRequest,
  KybUserFileResponse,
} from "@/shared/repository/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      documentRegistrationCompany: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-registration-company"],
        type: FileType.document,
      }),
      documentRegulation: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-regulation"],
        type: FileType.document,
      }),
      documentListOfShareholders: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-list-of-shareholders"],
        type: FileType.document,
      }),
      documentLicense: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-license"],
        type: FileType.document,
      }),
      documentCheckAddresses: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-check-addresses"],
        type: FileType.document,
      }),
      documentSow: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-sow"],
        type: FileType.document,
      }),
      documentSof: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-sof"],
        type: FileType.document,
      }),
      documentInn: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-inn"],
        type: FileType.document,
      }),
      documentRecordSheet: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-record-sheet"],
        type: FileType.document,
      }),
      documentCreate: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-create"],
        type: FileType.document,
      }),
      documentUpdate: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-update"],
        type: FileType.document,
      }),
      documentPurpose: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-purpose"],
        type: FileType.document,
      }),
      documentFinance: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-finance"],
        type: FileType.document,
      }),
      documentExample: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-example"],
        type: FileType.document,
      }),
      documentKyb: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-document"],
        type: FileType.document,
      }),
      documentBeneficiary: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-beneficiary"],
        type: FileType.document,
      }),
      documentBroker: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-broker"],
        type: FileType.document,
      }),
      documentDeclaration: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-declaration"],
        type: FileType.document,
      }),
      documentDirector: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-director"],
        type: FileType.document,
      }),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .getKybUserFiles(this.displayedUserId)
        .then((userFiles) => {
          userFiles.forEach((userFile) => {
            this.handleCurrentFileResponse(userFile);
          });
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },

    isContinueDisabled(): boolean {
      return (
        !this.documentRegistrationCompany.id ||
        !this.documentRegulation.id ||
        !this.documentListOfShareholders.id ||
        !this.documentLicense.id ||
        !this.documentCheckAddresses.id ||
        !this.documentSow.id ||
        !this.documentSof.id ||
        !this.documentInn.id ||
        !this.documentRecordSheet.id ||
        !this.documentCreate.id ||
        !this.documentUpdate.id ||
        !this.documentPurpose.id ||
        !this.documentFinance.id ||
        !this.documentExample.id ||
        !this.documentKyb.id ||
        !this.documentBeneficiary.id ||
        !this.documentBroker.id ||
        !this.documentDeclaration.id ||
        !this.documentDirector.id ||
        this.loading
      );
    },
  },
  methods: {
    async handleUploadFile(
      params: FileImageParams,
      name: string
    ): Promise<void> {
      try {
        this.loading = true;

        this.handleCurrentFileUpdate(name, params);

        await this.$projectServices.projectRepository
          .kybUserFile(this.handleCurrentFileRequest(name))
          .then((uploadFile) => {
            this.handleCurrentFileResponse(uploadFile);
          });
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveFile(name: string): Promise<void> {
      try {
        this.loading = true;

        const { id } = this.handleCurrentFile(name);

        if (typeof id !== "number") {
          return;
        }

        await this.$projectServices.projectRepository
          .kybUserFileDelete(id)
          .then(() => {
            this.handleCurrentFileClear(name);
          });
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    handleCurrentFile(name: string): KybUserFileResponse {
      switch (name) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          return this.documentRegistrationCompany;
        case KybDocumentsFileName["kyb-documents-regulation"]:
          return this.documentRegulation;
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          return this.documentListOfShareholders;
        case KybDocumentsFileName["kyb-documents-license"]:
          return this.documentLicense;
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          return this.documentCheckAddresses;
        case KybDocumentsFileName["kyb-documents-sow"]:
          return this.documentSow;
        case KybDocumentsFileName["kyb-documents-sof"]:
          return this.documentSof;
        case KybDocumentsFileName["kyb-documents-inn"]:
          return this.documentInn;
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          return this.documentRecordSheet;
        case KybDocumentsFileName["kyb-documents-create"]:
          return this.documentCreate;
        case KybDocumentsFileName["kyb-documents-update"]:
          return this.documentUpdate;
        case KybDocumentsFileName["kyb-documents-purpose"]:
          return this.documentPurpose;
        case KybDocumentsFileName["kyb-documents-finance"]:
          return this.documentFinance;
        case KybDocumentsFileName["kyb-documents-example"]:
          return this.documentExample;
        case KybDocumentsFileName["kyb-documents-document"]:
          return this.documentKyb;
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          return this.documentBeneficiary;
        case KybDocumentsFileName["kyb-documents-broker"]:
          return this.documentBroker;
        case KybDocumentsFileName["kyb-documents-declaration"]:
          return this.documentDeclaration;
        case KybDocumentsFileName["kyb-documents-director"]:
          return this.documentDirector;
        default:
          return KybUserFileResponseFactory();
      }
    },

    handleCurrentFileUpdate(
      name: string,
      { file, link }: FileImageParams
    ): void {
      switch (name) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          this.documentRegistrationCompany.file = file;
          this.documentRegistrationCompany.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-regulation"]:
          this.documentRegulation.file = file;
          this.documentRegulation.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          this.documentListOfShareholders.file = file;
          this.documentListOfShareholders.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-license"]:
          this.documentLicense.file = file;
          this.documentLicense.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          this.documentCheckAddresses.file = file;
          this.documentCheckAddresses.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-sow"]:
          this.documentSow.file = file;
          this.documentSow.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-sof"]:
          this.documentSof.file = file;
          this.documentSof.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-inn"]:
          this.documentInn.file = file;
          this.documentInn.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          this.documentRecordSheet.file = file;
          this.documentRecordSheet.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-create"]:
          this.documentCreate.file = file;
          this.documentCreate.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-update"]:
          this.documentUpdate.file = file;
          this.documentUpdate.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-purpose"]:
          this.documentPurpose.file = file;
          this.documentPurpose.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-finance"]:
          this.documentFinance.file = file;
          this.documentFinance.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-example"]:
          this.documentExample.file = file;
          this.documentExample.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-document"]:
          this.documentKyb.file = file;
          this.documentKyb.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          this.documentBeneficiary.file = file;
          this.documentBeneficiary.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-broker"]:
          this.documentBroker.file = file;
          this.documentBroker.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-declaration"]:
          this.documentDeclaration.file = file;
          this.documentDeclaration.link = link;

          break;
        case KybDocumentsFileName["kyb-documents-director"]:
          this.documentDirector.file = file;
          this.documentDirector.link = link;

          break;
        default:
          break;
      }
    },

    handleCurrentFileClear(name: string): void {
      switch (name) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          this.documentRegistrationCompany = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-registration-company"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-regulation"]:
          this.documentRegulation = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-regulation"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          this.documentListOfShareholders = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-list-of-shareholders"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-license"]:
          this.documentLicense = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-license"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          this.documentCheckAddresses = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-check-addresses"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-sow"]:
          this.documentSow = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-sow"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-sof"]:
          this.documentSof = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-sof"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-inn"]:
          this.documentInn = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-inn"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          this.documentRecordSheet = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-record-sheet"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-create"]:
          this.documentCreate = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-create"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-update"]:
          this.documentUpdate = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-update"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-purpose"]:
          this.documentPurpose = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-purpose"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-finance"]:
          this.documentFinance = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-finance"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-example"]:
          this.documentExample = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-example"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-document"]:
          this.documentKyb = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-document"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          this.documentBeneficiary = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-beneficiary"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-broker"]:
          this.documentBroker = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-broker"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-declaration"]:
          this.documentDeclaration = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-declaration"],
            type: FileType.image,
          });

          break;
        case KybDocumentsFileName["kyb-documents-director"]:
          this.documentDirector = KybUserFileResponseFactory({
            name: KybDocumentsFileName["kyb-documents-director"],
            type: FileType.image,
          });

          break;
        default:
          break;
      }
    },

    handleCurrentFileRequest(name: string): KybUserFileRequest {
      switch (name) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          return KybUserFileRequestFactory(this.documentRegistrationCompany);
        case KybDocumentsFileName["kyb-documents-regulation"]:
          return KybUserFileRequestFactory(this.documentRegulation);
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          return KybUserFileRequestFactory(this.documentListOfShareholders);
        case KybDocumentsFileName["kyb-documents-license"]:
          return KybUserFileRequestFactory(this.documentLicense);
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          return KybUserFileRequestFactory(this.documentCheckAddresses);
        case KybDocumentsFileName["kyb-documents-sow"]:
          return KybUserFileRequestFactory(this.documentSow);
        case KybDocumentsFileName["kyb-documents-sof"]:
          return KybUserFileRequestFactory(this.documentSof);
        case KybDocumentsFileName["kyb-documents-inn"]:
          return KybUserFileRequestFactory(this.documentInn);
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          return KybUserFileRequestFactory(this.documentRecordSheet);
        case KybDocumentsFileName["kyb-documents-create"]:
          return KybUserFileRequestFactory(this.documentCreate);
        case KybDocumentsFileName["kyb-documents-update"]:
          return KybUserFileRequestFactory(this.documentUpdate);
        case KybDocumentsFileName["kyb-documents-purpose"]:
          return KybUserFileRequestFactory(this.documentPurpose);
        case KybDocumentsFileName["kyb-documents-finance"]:
          return KybUserFileRequestFactory(this.documentFinance);
        case KybDocumentsFileName["kyb-documents-example"]:
          return KybUserFileRequestFactory(this.documentExample);
        case KybDocumentsFileName["kyb-documents-document"]:
          return KybUserFileRequestFactory(this.documentKyb);
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          return KybUserFileRequestFactory(this.documentBeneficiary);
        case KybDocumentsFileName["kyb-documents-broker"]:
          return KybUserFileRequestFactory(this.documentBroker);
        case KybDocumentsFileName["kyb-documents-declaration"]:
          return KybUserFileRequestFactory(this.documentDeclaration);
        case KybDocumentsFileName["kyb-documents-director"]:
          return KybUserFileRequestFactory(this.documentDirector);
        default:
          return KybUserFileRequestFactory();
      }
    },

    handleCurrentFileResponse(params: KybUserFileResponse): void {
      switch (extractFileName(params.name)) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          this.documentRegistrationCompany = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-regulation"]:
          this.documentRegulation = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          this.documentListOfShareholders = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-license"]:
          this.documentLicense = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          this.documentCheckAddresses = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-sow"]:
          this.documentSow = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-sof"]:
          this.documentSof = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-inn"]:
          this.documentInn = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          this.documentRecordSheet = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-create"]:
          this.documentCreate = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-update"]:
          this.documentUpdate = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-purpose"]:
          this.documentPurpose = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-finance"]:
          this.documentFinance = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-example"]:
          this.documentExample = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-document"]:
          this.documentKyb = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          this.documentBeneficiary = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-broker"]:
          this.documentBroker = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-declaration"]:
          this.documentDeclaration = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-director"]:
          this.documentDirector = KybUserFileResponseFactory(params);

          break;
        default:
          break;
      }
    },

    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    handleContinue(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },
  },
});
