/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { UserInfoResponse } from "@/shared/repository/repo";
import { UserInfoFactory } from "@/shared/factory/user";

enum UserMutation {
  UPDATE_USER_INFO = "UPDATE_USER_INFO",
}

interface State {
  userInfo?: UserInfoResponse;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "user",
})
export default class UserModuleState extends VuexModule<State> {
  userInfo = UserInfoFactory();

  @Mutation
  [UserMutation.UPDATE_USER_INFO](userInfo: UserInfoResponse): void {
    this.userInfo = UserInfoFactory(userInfo);
  }
}
