import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "wallet-main";

export const SVG: SvgAttribute = {
  conclusion: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.365 3.37346C17.3299 3.36964 17.2942 3.36768 17.2581 3.36768L8.79169 3.36768C8.2525 3.36768 7.8154 3.80478 7.8154 4.34397C7.8154 4.88316 8.2525 5.32026 8.79169 5.32026L14.9695 5.32026L6.29314 13.9966C5.90261 14.3871 5.90261 15.0203 6.29314 15.4108C6.68366 15.8013 7.31683 15.8013 7.70736 15.4108L16.3949 6.72328L16.3949 12.9215C16.3949 13.4607 16.832 13.8978 17.3712 13.8978C17.9104 13.8978 18.3475 13.4607 18.3475 12.9215L18.3475 4.34974C18.3475 3.81055 17.9104 3.37345 17.3712 3.37345C17.3692 3.37345 17.3671 3.37345 17.365 3.37346Z"/>
    <rect x="2" y="20.0451" width="20" height="2" rx="1"/>
  </svg>`,
  input: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.97416 15.1972C7.01184 15.2016 7.05017 15.2039 7.08903 15.2039L15.5554 15.2039C16.0946 15.2039 16.5317 14.7668 16.5317 14.2276C16.5317 13.6884 16.0946 13.2513 15.5554 13.2513L9.37795 13.2513L18.0544 4.57493C18.4449 4.18441 18.4449 3.55125 18.0544 3.16072C17.6638 2.7702 17.0307 2.7702 16.6401 3.16072L7.95254 11.8483L7.95254 5.64913C7.95254 5.10994 7.51544 4.67284 6.97625 4.67284C6.43706 4.67284 5.99996 5.10994 5.99996 5.64913L5.99995 14.2209C5.99995 14.7594 6.43593 15.196 6.97416 15.1972Z"/>
    <rect x="2" y="19.5449" width="20" height="2" rx="1"/>
  </svg>`,
  sort: `<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61023 4.38179C6.03012 4.38179 6.26301 3.89553 5.99981 3.56837L3.44516 0.392943C3.24502 0.144165 2.86615 0.144166 2.66601 0.392944L0.111364 3.56837C-0.151837 3.89553 0.0810508 4.38179 0.500942 4.38179H5.61023ZM0.500965 6.61827C0.0810745 6.61827 -0.151812 7.10452 0.111389 7.43168L2.66603 10.6071C2.86618 10.8559 3.24505 10.8559 3.44519 10.6071L5.99984 7.43168C6.26304 7.10452 6.03015 6.61827 5.61026 6.61827H0.500965Z" fill="#222327"/>
  </svg>`,
  usd: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_278_13621)">
    <path d="M23.9469 13.1223H0.0541992V13.1226H23.9469V13.1223Z" fill="#C42126"/>
    <path d="M22.7015 6.57147H1.29858V6.57183H22.7015V6.57147Z" fill="#C42126"/>
    <path d="M23.5517 8.75482H0.447388V8.75518H23.5517V8.75482Z" fill="#C42126"/>
    <path d="M5.15774 21.856H18.8428L18.8435 21.8553H5.15698L5.15774 21.856Z" fill="#C42126"/>
    <path d="M11.9997 0C11.764 0 11.5302 0.00851213 11.2981 0.021787H12.7015C12.4692 0.00851213 12.2354 0 11.9997 0Z" fill="#C42126"/>
    <path d="M23.9499 10.9388H0.0495605V10.9391H23.9499V10.9388Z" fill="#C42126"/>
    <path d="M15.5467 4.38803H21.2767C20.5961 3.55947 19.8051 2.82601 18.9288 2.20508H14.626C14.982 2.83148 15.2882 3.57062 15.5467 4.38803Z" fill="#F3F4F5"/>
    <path d="M14.626 2.20549H18.9288C17.15 0.944931 15.0145 0.155532 12.7017 0.0219727H12.3323C13.4208 0.158977 13.9208 0.96459 14.626 2.20549Z" fill="#E73625"/>
    <path d="M23.5526 8.75504C23.3392 7.99411 23.0531 7.26405 22.7015 6.57208H16.1093C16.2428 7.27297 16.3482 8.00506 16.4262 8.75504H23.5526Z" fill="#F3F4F5"/>
    <path d="M15.7385 6.57156H22.7014C22.3046 5.79112 21.8279 5.05878 21.2769 4.38824H15.1946C15.4091 5.06714 15.5912 5.79949 15.7385 6.57156Z" fill="#E73625"/>
    <path d="M16.6785 13.1221H23.9457C23.9803 12.7528 23.9998 12.3786 23.9998 12.0004C23.9998 11.6425 23.981 11.2891 23.9503 10.939H16.6802C16.7039 11.665 16.7034 12.3964 16.6785 13.1221Z" fill="#F3F4F5"/>
    <path d="M23.9507 10.9385C23.885 10.1887 23.7499 9.45927 23.5526 8.75555H16.1201C16.1948 9.47042 16.2441 10.2018 16.2681 10.9385H23.9507Z" fill="#E73625"/>
    <path d="M15.9839 17.4886H22.6701C23.0267 16.7974 23.3173 16.0668 23.5352 15.3053H16.3071C16.2275 16.0564 16.1195 16.7883 15.9839 17.4886Z" fill="#F3F4F5"/>
    <path d="M23.5352 15.3051C23.7366 14.6016 23.8765 13.8721 23.946 13.1221H16.4521C16.4265 13.8594 16.3757 14.5906 16.2996 15.3051H23.5352Z" fill="#E73625"/>
    <path d="M18.8433 21.8553C19.7323 21.2368 20.5345 20.5026 21.2259 19.6723H15.5957C15.3325 20.4931 15.0201 21.2311 14.6576 21.8553H18.8433Z" fill="#F3F4F5"/>
    <path d="M15.3838 19.672H21.2258C21.7833 19.0022 22.2672 18.2699 22.6698 17.4887H15.9346C15.7851 18.2613 15.6012 18.994 15.3838 19.672Z" fill="#E73625"/>
    <path d="M11.5651 23.9915C11.5408 23.9907 11.5166 23.9896 11.4923 23.9888C11.5166 23.9895 11.5408 23.9907 11.5651 23.9915Z" fill="#DC3027"/>
    <path d="M11.9999 23.9998C14.5439 23.9998 16.9012 23.2057 18.8426 21.8557H14.4996C13.7288 23.1837 13.2203 23.9998 11.9999 23.9998Z" fill="#E73625"/>
    <path d="M12 24.0001C11.8543 24.0001 11.7095 23.9967 11.5652 23.9916C11.7111 23.9965 11.856 24.0001 12 24.0001Z" fill="#C42126"/>
    <path d="M15.0233 2.20508H5.07133C4.19483 2.82601 3.40396 3.55942 2.72314 4.38803H15.9437C15.6853 3.57062 15.3793 2.83148 15.0233 2.20508Z" fill="#F3F4F5"/>
    <path d="M5.07153 2.20549H15.0235C14.3181 0.96459 13.4209 0.158977 12.3324 0.0219727H11.2984C8.98569 0.155532 6.85011 0.944931 5.07153 2.20549Z" fill="#E73625"/>
    <path d="M0.447632 8.75504H16.8046C16.7265 8.00491 16.6212 7.27297 16.4876 6.57208H1.29874C0.947061 7.2641 0.660992 7.99411 0.447632 8.75504Z" fill="#F3F4F5"/>
    <path d="M2.72309 4.38806C2.17249 5.0586 1.6954 5.79089 1.29883 6.57138H16.4875C16.3403 5.79946 16.1582 5.06716 15.9437 4.38806C15.9437 4.38806 2.72309 4.38806 2.72309 4.38806Z" fill="#E73625"/>
    <path d="M0.00012207 11.9999C0.00012207 12.3781 0.0198317 12.7523 0.0541336 13.1216H16.9513C16.9763 12.3958 16.9767 11.6647 16.953 10.9386H0.0495735C0.018869 11.2886 0.00012207 11.642 0.00012207 11.9999Z" fill="#F3F4F5"/>
    <path d="M0.0495605 10.9385H16.9528C16.9287 10.2017 16.8793 9.47047 16.8048 8.75555H0.447452C0.250204 9.45943 0.115124 10.1887 0.0495605 10.9385Z" fill="#E73625"/>
    <path d="M16.7988 15.3051H0.464966C0.682886 16.0667 0.973718 16.7972 1.33011 17.4884H16.4756C16.6113 16.7882 16.719 16.0563 16.7988 15.3051Z" fill="#F3F4F5"/>
    <path d="M0.46496 15.3051H16.7988C16.8748 14.5906 16.9257 13.8592 16.9513 13.1221H0.0541992C0.123715 13.8723 0.26376 14.6016 0.46496 15.3051Z" fill="#E73625"/>
    <path d="M5.15693 21.8553H14.9869C15.3493 21.2309 15.662 20.4931 15.925 19.6723H2.77429C3.4657 20.5026 4.26771 21.2368 5.15693 21.8553Z" fill="#F3F4F5"/>
    <path d="M1.33032 17.4886C1.73298 18.2698 2.21685 19.0022 2.77429 19.672H15.925C16.1421 18.994 16.3261 18.2613 16.4756 17.4886H1.33032Z" fill="#E73625"/>
    <path d="M11.4924 23.9886C11.5167 23.9894 11.5409 23.9905 11.5652 23.9912C11.7096 23.9963 11.8543 23.9997 12 23.9997C13.2204 23.9997 14.2155 23.1837 14.9865 21.8557H5.15771C6.96892 23.1153 9.14296 23.8907 11.4924 23.9886Z" fill="#E73625"/>
    <path d="M11.7519 0.00622559C5.60258 0.131324 0.590553 4.87932 0.0511475 10.9188H11.7521V0.00622559H11.7519Z" fill="#283991"/>
    <path d="M2.40102 6.79919L2.58383 7.36211H3.17613L2.69697 7.7102L2.87998 8.27352L2.40102 7.92523L1.92221 8.27352L2.10486 7.7102L1.62585 7.36211H2.21816L2.40102 6.79919Z" fill="#EFEFEF"/>
    <path d="M2.40102 8.78796L2.58383 9.35123H3.17613L2.69697 9.69932L2.87998 10.2626L2.40102 9.91435L1.92221 10.2626L2.10486 9.69932L1.62585 9.35123H2.21816L2.40102 8.78796Z" fill="#EFEFEF"/>
    <path d="M4.39011 3.81567L4.57297 4.37879H5.16507L4.68606 4.72708L4.86892 5.28999L4.39011 4.94211L3.9111 5.28999L4.09396 4.72708L3.61475 4.37879H4.20705L4.39011 3.81567Z" fill="#EFEFEF"/>
    <path d="M4.39011 5.80487L4.57297 6.36799H5.16507L4.68606 6.71587L4.86892 7.27919L4.39011 6.93131L3.9111 7.27919L4.09396 6.71587L3.61475 6.36799H4.20705L4.39011 5.80487Z" fill="#EFEFEF"/>
    <path d="M4.39011 7.79352L4.57297 8.35684H5.16507L4.68606 8.70452L4.86892 9.26784L4.39011 8.9196L3.9111 9.26784L4.09396 8.70452L3.61475 8.35684H4.20705L4.39011 7.79352Z" fill="#EFEFEF"/>
    <path d="M0.890925 9.26797L0.707915 8.70465L1.18692 8.35676H0.594977L0.581702 8.31567C0.516696 8.51672 0.457972 8.7202 0.403809 8.92596L0.412169 8.91973L0.890925 9.26797Z" fill="#EFEFEF"/>
    <path d="M1.92226 6.28478L2.40107 5.93654L2.88008 6.28478L2.69707 5.72145L3.17608 5.37322H2.58378L2.40092 4.8103L2.21806 5.37322H1.99978C1.95925 5.43463 1.92003 5.49639 1.88062 5.55871L2.10497 5.72145L1.92226 6.28478Z" fill="#EFEFEF"/>
    <path d="M4.3903 2.95274L4.86911 3.30062L4.68625 2.7377L5.16506 2.38947H4.82305C4.53258 2.60663 4.25234 2.83645 3.98309 3.07895L3.91089 3.30062L4.3903 2.95274Z" fill="#EFEFEF"/>
    <path d="M5.90027 2.30689L6.37928 1.95865L6.85808 2.30689L6.67522 1.74357L7.15403 1.39568H6.56193L6.5371 1.31989C6.33646 1.42239 6.1379 1.52904 5.94384 1.64254L6.08292 1.74352L5.90027 2.30689Z" fill="#EFEFEF"/>
    <path d="M6.37932 2.82129L6.56218 3.38405H7.15428L6.67527 3.73229L6.85813 4.29561L6.37932 3.94732L5.90031 4.29561L6.08317 3.73229L5.604 3.38405H6.19626L6.37932 2.82129Z" fill="#EFEFEF"/>
    <path d="M6.37932 4.81049L6.56218 5.37325H7.15428L6.67527 5.72149L6.85813 6.28481L6.37932 5.93652L5.90031 6.28481L6.08317 5.72149L5.604 5.37325H6.19626L6.37932 4.81049Z" fill="#EFEFEF"/>
    <path d="M6.37932 6.79919L6.56218 7.36211H7.15428L6.67527 7.7102L6.85813 8.27352L6.37932 7.92523L5.90031 8.27352L6.08317 7.7102L5.604 7.36211H6.19626L6.37932 6.79919Z" fill="#EFEFEF"/>
    <path d="M6.37932 8.78796L6.56218 9.35123H7.15428L6.67527 9.69932L6.85813 10.2626L6.37932 9.91435L5.90031 10.2626L6.08317 9.69932L5.604 9.35123H6.19626L6.37932 8.78796Z" fill="#EFEFEF"/>
    <path d="M8.36833 1.82642L8.55119 2.38953H9.14329L8.66448 2.73782L8.84714 3.30074L8.36833 2.95285L7.88917 3.30074L8.07218 2.73782L7.59302 2.38953H8.18527L8.36833 1.82642Z" fill="#EFEFEF"/>
    <path d="M8.36833 3.81567L8.55119 4.37879H9.14329L8.66448 4.72708L8.84714 5.28999L8.36833 4.94211L7.88917 5.28999L8.07218 4.72708L7.59302 4.37879H8.18527L8.36833 3.81567Z" fill="#EFEFEF"/>
    <path d="M8.36833 5.80487L8.55119 6.36799H9.14329L8.66448 6.71587L8.84714 7.27919L8.36833 6.93131L7.88917 7.27919L8.07218 6.71587L7.59302 6.36799H8.18527L8.36833 5.80487Z" fill="#EFEFEF"/>
    <path d="M8.36833 7.79352L8.55119 8.35684H9.14329L8.66448 8.70452L8.84714 9.26784L8.36833 8.9196L7.88917 9.26784L8.07218 8.70452L7.59302 8.35684H8.18527L8.36833 7.79352Z" fill="#EFEFEF"/>
    <path d="M10.3575 0.832092L10.5403 1.39521H11.1324L10.6534 1.74309L10.8363 2.30641L10.3575 1.95812L9.8783 2.30641L10.0613 1.74309L9.58215 1.39521H10.1743L10.3575 0.832092Z" fill="#EFEFEF"/>
    <path d="M10.3575 2.82129L10.5403 3.38405H11.1324L10.6534 3.73229L10.8363 4.29561L10.3575 3.94732L9.8783 4.29561L10.0613 3.73229L9.58215 3.38405H10.1743L10.3575 2.82129Z" fill="#EFEFEF"/>
    <path d="M10.3575 4.81049L10.5403 5.37325H11.1324L10.6534 5.72149L10.8363 6.28481L10.3575 5.93652L9.8783 6.28481L10.0613 5.72149L9.58215 5.37325H10.1743L10.3575 4.81049Z" fill="#EFEFEF"/>
    <path d="M10.3575 6.79919L10.5403 7.36211H11.1324L10.6534 7.7102L10.8363 8.27352L10.3575 7.92523L9.8783 8.27352L10.0613 7.7102L9.58215 7.36211H10.1743L10.3575 6.79919Z" fill="#EFEFEF"/>
    <path d="M10.3575 8.78796L10.5403 9.35123H11.1324L10.6534 9.69932L10.8363 10.2626L10.3575 9.91435L9.8783 10.2626L10.0613 9.69932L9.58215 9.35123H10.1743L10.3575 8.78796Z" fill="#EFEFEF"/>
    </g>
    <defs>
    <clipPath id="clip0_278_13621">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  eur: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_278_13566)">
    <path d="M11.9999 0V24C18.6275 24 24.0001 18.6274 24.0001 12.0002C24.0001 5.37237 18.6275 0 11.9999 0Z" fill="#2B479D"/>
    <path d="M11.9998 0C5.37257 0 0 5.37237 0 12.0002C0 18.6274 5.37257 24 11.9998 24C18.6274 24 18.6274 0 11.9998 0Z" fill="#2B479D"/>
    <path d="M11.9793 2.0816L12.272 2.98237H13.2194L12.453 3.53905L12.7457 4.43982L11.9793 3.88278L11.213 4.43982L11.5058 3.53905L10.7391 2.98237H11.6865L11.9793 2.0816Z" fill="#F8D12E"/>
    <path d="M11.9793 19.086L12.272 19.9872H13.2194L12.453 20.5439L12.7457 21.4446L11.9793 20.888L11.213 21.4446L11.5058 20.5439L10.7391 19.9872H11.6865L11.9793 19.086Z" fill="#F8D12E"/>
    <path d="M16.6461 3.58865L16.9386 4.48962H17.886L17.1196 5.04666L17.4123 5.94743L16.6461 5.39039L15.8797 5.94743L16.1724 5.04666L15.4061 4.48962H16.3535L16.6461 3.58865Z" fill="#F8D12E"/>
    <path d="M19.8302 6.73016L20.1228 7.63093H21.0702L20.3037 8.18781L20.5965 9.08858L19.8302 8.5319L19.0638 9.08858L19.3565 8.18781L18.5901 7.63093H19.5373L19.8302 6.73016Z" fill="#F8D12E"/>
    <path d="M19.906 14.6671L20.1986 15.5682H21.1459L20.3795 16.1249L20.6724 17.0254L19.906 16.4689L19.1396 17.0254L19.4323 16.1249L18.6659 15.5682H19.6133L19.906 14.6671Z" fill="#F8D12E"/>
    <path d="M4.24347 6.73016L4.53603 7.63093H5.48321L4.71696 8.18781L5.00952 9.08858L4.24347 8.5319L3.47708 9.08858L3.76978 8.18781L3.00354 7.63093H3.95092L4.24347 6.73016Z" fill="#F8D12E"/>
    <path d="M20.5683 10.5155L20.8609 11.4162H21.8083L21.0419 11.9729L21.3346 12.8739L20.5683 12.3174L19.8019 12.8739L20.0948 11.9729L19.3282 11.4162H20.2754L20.5683 10.5155Z" fill="#F8D12E"/>
    <path d="M3.51975 10.5155L3.8123 11.4162H4.75948L3.99309 11.9729L4.28599 12.8739L3.51975 12.3174L2.7533 12.8739L3.04606 11.9729L2.27966 11.4162H3.22699L3.51975 10.5155Z" fill="#F8D12E"/>
    <path d="M4.14096 14.6671L4.43352 15.5682H5.3807L4.6145 16.1249L4.90701 17.0254L4.14096 16.4689L3.37457 17.0254L3.66727 16.1249L2.90088 15.5682H3.84826L4.14096 14.6671Z" fill="#F8D12E"/>
    <path d="M16.7834 17.9427L17.076 18.8435H18.0234L17.2568 19.4006L17.5497 20.3013L16.7834 19.7446L16.0168 20.3013L16.3098 19.4006L15.5433 18.8435H16.4907L16.7834 17.9427Z" fill="#F8D12E"/>
    <path d="M7.46357 3.58865L7.75607 4.48962H8.70345L7.93705 5.04666L8.22996 5.94743L7.46357 5.39039L6.69732 5.94743L6.99003 5.04666L6.22363 4.48962H7.17081L7.46357 3.58865Z" fill="#F8D12E"/>
    <path d="M7.11786 17.885L7.41057 18.7862H8.35795L7.59155 19.3427L7.88426 20.2434L7.11786 19.6871L6.35162 20.2434L6.64433 19.3427L5.87793 18.7862H6.82511L7.11786 17.885Z" fill="#F8D12E"/>
    </g>
    <defs>
    <clipPath id="clip0_278_13566">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  rub: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_278_13546)">
    <path d="M12.0006 23.9995C18.6277 23.9995 24.0001 18.6272 24.0001 12C24.0001 5.37291 18.6277 0.000549316 12.0006 0.000549316C5.37346 0.000549316 0.00109863 5.37291 0.00109863 12C0.00109863 18.6272 5.37346 23.9995 12.0006 23.9995Z" fill="white"/>
    <path d="M23.3143 7.99893H15.9207C16.2418 10.5623 16.2437 13.3868 15.9269 15.9534H23.33C23.7622 14.7152 23.9998 13.3855 23.9998 12.0001C24 10.5964 23.7569 9.25041 23.3143 7.99893Z" fill="#3757A6"/>
    <path d="M15.9202 7.99861H23.3137C23.3126 7.99481 23.3114 7.99162 23.3099 7.98817C21.7742 3.65781 17.8236 0.471808 13.0771 0.0488892C14.9141 1.05575 15.4507 4.24575 15.9202 7.99861Z" fill="#EFEFEF"/>
    <path d="M13.0776 23.9501C17.8326 23.5264 21.7887 20.3294 23.3185 15.9875C23.3225 15.9759 23.3259 15.9645 23.33 15.9534H15.9267C15.4608 19.7265 14.922 22.939 13.0776 23.9501Z" fill="#E73B36"/>
    <path d="M0 12C0 13.3857 0.237983 14.7152 0.669769 15.9534H16.5444C16.8616 13.3866 16.8593 10.5623 16.5386 7.99887H0.685729C0.243101 9.2504 0 10.5964 0 12Z" fill="#3757A6"/>
    <path d="M0.689347 7.98804C0.688232 7.99144 0.687067 7.99468 0.685547 7.99848H16.5384C16.0685 4.24557 14.9142 1.05562 13.0775 0.0487602C12.7222 0.0172959 12.363 -0.000488281 11.9996 -0.000488281C6.77943 -0.000538948 2.34023 3.33341 0.689347 7.98804Z" fill="#EFEFEF"/>
    <path d="M16.5446 15.9533H0.669922C0.673925 15.9645 0.677674 15.9759 0.681474 15.9874C2.32577 20.654 6.77136 23.9997 11.9999 23.9997C12.3633 23.9997 12.7226 23.9819 13.0778 23.95C14.9223 22.939 16.0787 19.7265 16.5446 15.9533Z" fill="#E73B36"/>
    </g>
    <defs>
    <clipPath id="clip0_278_13546">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  aed: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.996">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9219 0.976562C11.6406 0.976562 12.3594 0.976562 13.0781 0.976562C17.2803 1.48698 20.4443 3.5651 22.5703 7.21094C22.8472 7.70631 23.0738 8.22194 23.25 8.75781C17.8438 8.75781 12.4375 8.75781 7.03125 8.75781C7.04681 6.5155 7.0312 4.28111 6.98438 2.05469C8.23739 1.47583 9.54989 1.11645 10.9219 0.976562Z" fill="#00722E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.98438 2.05469C7.0312 4.28111 7.04681 6.5155 7.03125 8.75781C7.03125 11.6328 7.03125 14.5078 7.03125 17.3828C7.04681 19.5626 7.0312 21.7345 6.98438 23.8984C4.01879 22.4875 1.93285 20.2531 0.726562 17.1953C0.357108 16.1711 0.114921 15.1242 0 14.0547C0 13.336 0 12.6172 0 11.8984C0.459594 7.94894 2.35022 4.89423 5.67188 2.73438C6.08981 2.47072 6.5273 2.24416 6.98438 2.05469Z" fill="#FE0100"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03125 8.75781C12.4375 8.75781 17.8438 8.75781 23.25 8.75781C23.6313 9.77406 23.8814 10.821 24 11.8984C24 12.6016 24 13.3047 24 14.0078C23.8785 15.1614 23.6129 16.2864 23.2031 17.3828C17.8125 17.3828 12.4219 17.3828 7.03125 17.3828C7.03125 14.5078 7.03125 11.6328 7.03125 8.75781Z" fill="#F3F4F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03125 17.3828C12.4219 17.3828 17.8125 17.3828 23.2031 17.3828C21.5996 21.1848 18.8027 23.6145 14.8125 24.6719C14.2165 24.7998 13.6227 24.9014 13.0312 24.9766C12.3281 24.9766 11.625 24.9766 10.9219 24.9766C9.54975 24.8366 8.23725 24.4772 6.98438 23.8984C7.0312 21.7345 7.04681 19.5626 7.03125 17.3828Z" fill="#272727"/>
    </g>
  </svg>`,
  usdt: `<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 24C20.6274 24 26 18.6274 26 12C26 5.37258 20.6274 0 14 0C7.37258 0 2 5.37258 2 12C2 18.6274 7.37258 24 14 24Z" fill="white"/>
    <g filter="url(#filter0_d_278_13531)">
    <path d="M21.5 12L14 19.5L6.5 12L14 4.5L21.5 12Z" fill="url(#paint0_linear_278_13531)"/>
    </g>
    <path d="M14 0C7.3725 0 2 5.3725 2 12C2 18.627 7.3725 24 14 24C20.6275 24 26 18.627 26 12C26 5.3725 20.6275 0 14 0Z" fill="#4EAF94"/>
    <path d="M18.8135 6V8.7115H15.255V10.014C18.105 10.1715 20.25 10.854 20.25 11.671C20.25 12.489 18.105 13.171 15.255 13.3285V18H12.641V13.322C9.843 13.154 7.75 12.479 7.75 11.671C7.75 10.8635 9.843 10.1885 12.641 10.0205V8.7115H9.083V6H18.8135ZM15.255 10.31V11.8965C14.3844 11.9416 13.5119 11.9396 12.6415 11.8905V10.3155C10.239 10.4355 8.461 10.866 8.461 11.3795C8.461 11.9855 10.941 12.477 14 12.477C17.059 12.477 19.539 11.9855 19.539 11.3795C19.539 10.8585 17.709 10.423 15.255 10.3105V10.31Z" fill="white"/>
    <defs>
    <filter id="filter0_d_278_13531" x="0.5" y="1.5" width="27" height="27" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_278_13531"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_278_13531" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_278_13531" x1="21.5" y1="19.5" x2="21.5" y2="4.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0055FF"/>
    <stop offset="1" stop-color="#0055FF" stop-opacity="0.8"/>
    </linearGradient>
    </defs>
  </svg>`,
  btc: `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.95372 26.7134C15.3183 30.6049 22.8218 29.4109 26.7134 24.0463C30.605 18.6818 29.4109 11.1782 24.0464 7.28665C18.6818 3.39507 11.1783 4.58914 7.28669 9.95368C3.39511 15.3182 4.58918 22.8218 9.95372 26.7134Z" fill="#F7931A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.581 12.6106C21.0786 13.149 22.1633 13.948 21.9068 15.4197C21.7209 16.4967 21.1174 17.0148 20.3304 17.1925C21.3895 17.7672 21.9129 18.6409 21.3518 20.1455C20.656 22.0142 19.1164 22.1619 17.0849 21.7565L16.5356 23.7674L15.3464 23.4567L15.8883 21.4727C15.58 21.3924 15.2652 21.3071 14.9405 21.2151L14.3967 23.2084L13.2088 22.8977L13.7578 20.8829C13.6557 20.8554 13.553 20.8274 13.4497 20.7992L13.4492 20.799C13.2715 20.7506 13.0919 20.7015 12.9102 20.6538L11.3627 20.2493L11.9938 18.8633C11.9938 18.8633 12.8702 19.1071 12.8586 19.0895C13.1953 19.1769 13.3517 18.9536 13.4151 18.8046L14.9042 13.3479C14.9257 13.09 14.8469 12.764 14.3652 12.6379C14.3843 12.6251 13.5016 12.4126 13.5016 12.4126L13.8547 11.1177L15.4946 11.5469L15.4932 11.5531C15.74 11.6175 15.9941 11.6785 16.253 11.7405L16.797 9.74893L17.9853 10.0597L17.4526 12.012C17.7721 12.0884 18.0931 12.1656 18.406 12.2474L18.9354 10.3079L20.1244 10.6187L19.581 12.6106ZM19.0677 19.2926C19.4531 17.8797 17.3937 17.3946 16.3895 17.158L16.3895 17.158C16.2775 17.1316 16.1787 17.1083 16.0978 17.0872L15.3691 19.7561C15.4356 19.7735 15.5136 19.795 15.6008 19.8192L15.6009 19.8192C16.5711 20.0877 18.6909 20.6742 19.0677 19.2926ZM17.3375 13.5035C18.1759 13.6986 19.8922 14.0981 19.5418 15.3821C19.199 16.6378 17.435 16.1447 16.6263 15.9186C16.5528 15.8981 16.4872 15.8798 16.4313 15.8651L17.0919 13.4444C17.1598 13.4621 17.2431 13.4815 17.3375 13.5035Z" fill="white"/>
  </svg>`,
  conclusionGray: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3647 3.37346C17.3296 3.36964 17.2939 3.36768 17.2578 3.36768L8.79145 3.36768C8.25225 3.36768 7.81515 3.80478 7.81515 4.34397C7.81515 4.88316 8.25225 5.32026 8.79145 5.32026L14.9693 5.32026L6.29289 13.9966C5.90237 14.3871 5.90237 15.0203 6.29289 15.4108C6.68342 15.8013 7.31659 15.8013 7.70711 15.4108L16.3947 6.72328L16.3947 12.9215C16.3947 13.4607 16.8318 13.8978 17.371 13.8978C17.9102 13.8978 18.3473 13.4607 18.3473 12.9215L18.3473 4.34974C18.3473 3.81055 17.9102 3.37345 17.371 3.37345C17.3689 3.37345 17.3668 3.37345 17.3647 3.37346Z"/>
    <rect x="2" y="20.0449" width="20" height="2" rx="1"/>
  </svg>`,
  inputGray: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.97457 15.1969C7.01225 15.2013 7.05058 15.2036 7.08944 15.2036L15.5558 15.2036C16.095 15.2036 16.5321 14.7665 16.5321 14.2273C16.5321 13.6881 16.095 13.251 15.5558 13.251L9.37836 13.251L18.0548 4.57469C18.4453 4.18416 18.4453 3.551 18.0548 3.16048C17.6642 2.76996 17.0311 2.76996 16.6405 3.16048L7.95295 11.848L7.95295 5.64889C7.95295 5.1097 7.51585 4.67259 6.97666 4.67259C6.43747 4.67259 6.00037 5.1097 6.00037 5.64889L6.00037 14.2206C6.00037 14.7591 6.43634 15.1958 6.97457 15.1969Z"/>
    <rect x="2" y="19.5449" width="20" height="2" rx="1"/>
  </svg>`,
  swapGray: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M18.6211 10.1257C18.6211 10.2719 18.6211 10.418 18.6211 10.5642C18.5252 10.9482 18.2845 11.1932 17.8988 11.2994C13.9866 11.3166 10.0744 11.3166 6.16223 11.2994C5.77348 11.2072 5.52412 10.9707 5.41418 10.59C5.41418 10.4352 5.41418 10.2805 5.41418 10.1257C5.54419 9.6907 5.83652 9.44565 6.2912 9.39055C9.29198 9.38196 12.2928 9.37335 15.2936 9.36476C14.8765 8.94773 14.4595 8.53073 14.0425 8.11371C13.7846 7.70992 13.7846 7.3058 14.0425 6.90136C14.4498 6.49147 14.9012 6.43558 15.3967 6.73369C16.3992 7.7447 17.4095 8.7464 18.4276 9.73878C18.507 9.86321 18.5715 9.99219 18.6211 10.1257Z"/>
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M18.6211 13.3761C18.6211 13.5308 18.6211 13.6856 18.6211 13.8404C18.4911 14.2754 18.1988 14.5204 17.7441 14.5755C14.7433 14.5841 11.7425 14.5927 8.7417 14.6013C9.16732 15.0269 9.59293 15.4525 10.0185 15.8782C10.2493 16.2796 10.2407 16.6751 9.99275 17.0647C9.58545 17.4746 9.13404 17.5305 8.63852 17.2324C7.63612 16.2214 6.62581 15.2197 5.60764 14.2273C5.52822 14.1029 5.46373 13.9739 5.41418 13.8404C5.41418 13.6942 5.41418 13.548 5.41418 13.4019C5.51006 13.0179 5.75083 12.7729 6.13643 12.6667C10.0486 12.6495 13.9608 12.6495 17.873 12.6667C18.2618 12.7589 18.5111 12.9953 18.6211 13.3761Z"/>
    <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M23.997 8.59063C24.005 10.8625 23.997 13.1343 23.973 15.406C23.9636 16.5524 23.8516 17.6883 23.637 18.8137C23.1947 20.696 22.1308 22.1118 20.4453 23.0613C19.2105 23.6123 17.9147 23.9003 16.5577 23.9252C13.8864 24.0001 11.2147 24.0161 8.54242 23.9732C7.15818 23.9772 5.79828 23.8012 4.4628 23.4453C2.728 22.7824 1.4721 21.6065 0.695146 19.9176C0.311374 18.831 0.103408 17.7111 0.0712044 16.5579C-0.00338043 13.9026 -0.0193639 11.2469 0.0232082 8.59063C0.0406796 7.70727 0.104706 6.82737 0.215191 5.95087C0.505421 3.72615 1.61733 2.05431 3.55088 0.935337C4.22085 0.607792 4.9248 0.383815 5.66269 0.263399C6.61732 0.122278 7.57723 0.0422853 8.54242 0.0234216C11.2147 -0.0194594 13.8864 -0.00346094 16.5577 0.0714176C17.6218 0.107447 18.6617 0.283433 19.6774 0.599369C21.1124 1.17014 22.2242 2.12206 23.0131 3.45511C23.3713 4.15159 23.6112 4.88753 23.733 5.6629C23.8692 6.64187 23.9571 7.61776 23.997 8.59063ZM22.1252 9.59854C22.137 11.8068 22.113 14.0146 22.0532 16.2219C22.0376 17.2587 21.8616 18.2666 21.5252 19.2456C20.858 20.6231 19.7781 21.479 18.2855 21.8134C17.5875 21.944 16.8836 22.024 16.1737 22.0534C13.1491 22.1603 10.1254 22.1442 7.10256 22.0054C5.17457 22.0217 3.69468 21.2298 2.66296 19.6296C2.44593 19.2025 2.28591 18.7545 2.18301 18.2857C2.05241 17.5877 1.9724 16.8838 1.94303 16.1739C1.84704 13.3902 1.84704 10.6064 1.94303 7.8227C1.96852 6.97875 2.08054 6.14679 2.279 5.32693C2.92243 3.42478 4.24231 2.34488 6.23864 2.08723C7.38645 1.95442 8.53834 1.89043 9.69432 1.89525C11.8545 1.86026 14.0143 1.87625 16.1737 1.94324C17.1744 1.9583 18.1503 2.11828 19.1015 2.4232C20.7526 3.16327 21.6885 4.43516 21.9092 6.23885C22.0341 7.36286 22.1061 8.48279 22.1252 9.59854Z"/>
  </svg>`,
  historyGray: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.639684 0.0324461C0.858961 0.0324461 1.07823 0.0324461 1.29751 0.0324461C1.57851 0.149053 1.77429 0.352636 1.88486 0.643194C1.9311 1.0807 1.97026 1.51918 2.00233 1.95865C2.0638 2.80173 2.00233 3.41212 2.98907 3.67344C3.61557 3.6891 4.24208 3.70476 4.86857 3.72042C5.52438 3.94344 5.75152 4.38976 5.54989 5.05937C5.42495 5.34088 5.21351 5.52095 4.91556 5.59965C3.52159 5.63098 2.12763 5.63098 0.733659 5.59965C0.400127 5.50103 0.173017 5.28962 0.0523383 4.96541C0.0210116 3.54033 0.0210116 2.11525 0.0523383 0.690174C0.14286 0.37281 0.338643 0.153568 0.639684 0.0324461Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6706 5.51845C12.2495 5.40461 12.6646 5.60818 12.9158 6.12919C12.9315 7.94579 12.9472 9.76234 12.9628 11.5789C14.1701 12.7704 15.3605 13.9763 16.5339 15.1964C16.789 15.7936 16.6401 16.2556 16.0875 16.5824C15.7176 16.7069 15.373 16.6599 15.0537 16.4414C13.7929 15.1808 12.5321 13.9201 11.2712 12.6595C11.1967 12.5575 11.134 12.4479 11.0833 12.3306C11.052 10.2635 11.052 8.19634 11.0833 6.12919C11.1939 5.83862 11.3897 5.63505 11.6706 5.51845Z"/>
    <path d="M16.2115 0.0324478C13.4039 0.00360604 10.5962 -0.00600982 7.78846 0.00360604C3.89226 0.0844425 1.46278 1.63229 0.500013 4.64713H2.50357C2.96039 3.72339 3.72202 2.99273 4.78846 2.45516C5.49698 2.1981 6.22777 2.04428 6.98076 1.99369C8.20113 1.93737 9.41267 1.88931 10.6154 1.84948C12.481 1.85989 14.3464 1.88873 16.2115 1.93601C17.2402 1.95379 18.2403 2.12684 19.2115 2.45516C20.7291 3.19161 21.6233 4.39333 21.8942 6.06038C21.9857 6.65314 22.0434 7.24919 22.0673 7.84858C22.0774 9.23546 22.1063 10.6199 22.1538 12.0018C22.1063 13.3837 22.0774 14.7681 22.0673 16.155C22.0519 17.1083 21.9077 18.0409 21.6346 18.9527C20.9674 20.6386 19.7367 21.6193 17.9423 21.8945C17.3684 21.9818 16.7915 22.0395 16.2115 22.0676C14.8076 22.0917 13.4038 22.1205 12 22.1541C10.5962 22.1205 9.1924 22.0917 7.78846 22.0676C6.85442 22.0468 5.94092 21.9026 5.04807 21.635C3.6015 21.0539 2.65919 20.0155 2.22115 18.52C2.11198 18.0251 2.03505 17.5252 1.99038 17.0203C1.93012 15.3571 1.88224 13.6938 1.84677 12.0306H0C0.000130814 13.4247 0.00974622 14.8187 0.0288462 16.2127C0.115327 21.2984 2.70187 23.8846 7.78846 23.9711C10.5962 24.0096 13.4038 24.0096 16.2115 23.9711C21.2981 23.8846 23.8847 21.2984 23.9711 16.2127C24.0096 13.4054 24.0096 10.5982 23.9711 7.79089C23.8847 2.70518 21.2981 0.119031 16.2115 0.0324478Z"/>
    <path d="M0.0288448 12.0306C0.0288448 11.5209 0.44212 11.1077 0.951921 11.1077C1.46172 11.1077 1.875 11.5209 1.875 12.0306V13.8765C1.875 14.3862 1.46172 14.7994 0.951921 14.7994C0.44212 14.7994 0.0288448 14.3862 0.0288448 13.8765V12.0306Z"/>
  </svg>`,
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5"/>
    <circle cx="8" cy="8" r="1.5"/>
    <circle cx="8" cy="14" r="1.5"/>
  </svg>`,
};
