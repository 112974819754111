import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label')),
            innerHTML: _ctx.$t(column.label)
          }, null, 10, _hoisted_1),
          (column.sort)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(_ctx.b('column-sort', { active: column.sort.isActive })),
                onClick: ($event: any) => (_ctx.handleColumnSort(column))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('column-icon')),
                  innerHTML: _ctx.displayedIcons.sort
                }, null, 10, _hoisted_3)
              ], 10, _hoisted_2))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('row')),
          key: user.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-id'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('row-status'))
            }, null, 2),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(user.id), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-email'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(user.email), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-kyc'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(_ctx.handleDisplayedRowStatus(user).label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-fio'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(_ctx.handleDisplayedRowFio(user)), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-balance'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label', { divider: true }))
            }, null, 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-applications'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, null, 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-action'))
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.b('row-toggle', { active: user.id === _ctx.id })),
              onClick: ($event: any) => (_ctx.handleRowToggle(user))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-icon')),
                innerHTML: _ctx.displayedIcons.dots
              }, null, 10, _hoisted_5)
            ], 10, _hoisted_4),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-options', { active: user.id === _ctx.id }))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
                return (_openBlock(), _createElementBlock("button", {
                  class: _normalizeClass(_ctx.b('row-option')),
                  key: option.id,
                  disabled: _ctx.handleIsRowOptionDisabled(option, user),
                  onClick: ($event: any) => (_ctx.handleRowOption(option, user))
                }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_6))
              }), 128))
            ], 2)
          ], 2)
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}