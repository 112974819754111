<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.id"
      >
        <p :class="b('column-label')" v-html="$t(column.label)"></p>

        <button
          v-if="column.sort"
          :class="b('column-sort', { active: column.sort.isActive })"
          @click="handleColumnSort(column)"
        >
          <span :class="b('column-icon')" v-html="displayedIcons.sort"></span>
        </button>
      </div>
    </div>

    <div :class="b('rows')">
      <div :class="b('row')" v-for="user in users" :key="user.id">
        <div :class="b('row-id')">
          <span :class="b('row-status')"></span>

          <p :class="b('row-label')">
            {{ user.id }}
          </p>
        </div>

        <div :class="b('row-email')">
          <p :class="b('row-label')">
            {{ user.email }}
          </p>
        </div>

        <div :class="b('row-kyc')">
          <p :class="b('row-label')">
            {{ handleDisplayedRowStatus(user).label }}
          </p>
        </div>

        <div :class="b('row-fio')">
          <p :class="b('row-label')">
            {{ handleDisplayedRowFio(user) }}
          </p>
        </div>

        <div :class="b('row-balance')">
          <p :class="b('row-label', { divider: true })"></p>
        </div>

        <div :class="b('row-applications')">
          <p :class="b('row-label')"></p>
        </div>

        <div :class="b('row-action')">
          <button
            :class="b('row-toggle', { active: user.id === id })"
            @click="handleRowToggle(user)"
          >
            <span :class="b('row-icon')" v-html="displayedIcons.dots"></span>
          </button>

          <div :class="b('row-options', { active: user.id === id })">
            <button
              :class="b('row-option')"
              v-for="option in displayedOptions"
              :key="option.id"
              :disabled="handleIsRowOptionDisabled(option, user)"
              @click="handleRowOption(option, user)"
            >
              {{ $t(option.label) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
