<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="
      b('', {
        success: isSuccess,
        center: isCenter,
        link: displayedIsLink,
        view,
      })
    "
    :style="{ backgroundImage: displayedBackgroundImage }"
    :disabled="isDisabled"
    @click="handleUpload"
  >
    <span v-if="isIconShow" :class="b('icon')" v-html="displayedIcon"></span>

    <p v-if="isLabelShow" :class="b('label', { success: isSuccess })">
      <slot :class="b('label')" name="label"></slot>
    </p>

    <button
      v-if="isRemoveShow"
      :class="b('remove')"
      :disabled="isDisabledRemove"
      @click.stop="handleRemove"
    >
      <span :class="b('icon')" v-html="displayedRemoveIcon"></span>
    </button>

    <input
      :class="b('file')"
      ref="file"
      type="file"
      :accept="displayedAccept"
      @change="changeFile"
    />
  </button>
</template>
