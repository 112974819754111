import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    file: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
  },
  computed: {
    displayedIcon(): string {
      return SVG.download;
    },

    isDisabled(): boolean {
      return !this.file || !this.name;
    },
  },
  methods: {
    handleDownload(): void {
      if (this.file && this.name) {
        const downloadLink = document.createElement("a");

        downloadLink.href = this.file;
        downloadLink.download = this.name;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(downloadLink.href);
      }
    },
  },
});
