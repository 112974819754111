import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  SharedPasswordValidationOption,
  SharedPasswordValidationOptionLabel,
} from "./model";

import { ValidationPassword } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    validation: {
      type: <PropType<ValidationPassword>>Object,
      required: true,
    },
  },
  computed: {
    displayedOptions(): SharedPasswordValidationOption[] {
      const {
        containNumbers,
        containSpecialCharacters,
        charactersLong,
        match,
      } = this.validation;

      return [
        {
          id: 0,
          label: SharedPasswordValidationOptionLabel.containNumbers,
          isValidate: containNumbers,
        },
        {
          id: 1,
          label: SharedPasswordValidationOptionLabel.containSpecialCharacters,
          isValidate: containSpecialCharacters,
        },
        {
          id: 2,
          label: SharedPasswordValidationOptionLabel.charactersLong,
          isValidate: charactersLong,
        },
        {
          id: 3,
          label: SharedPasswordValidationOptionLabel.match,
          isValidate: match,
        },
      ];
    },
  },
  methods: {
    displayedIcon({ isValidate }: SharedPasswordValidationOption): string {
      return isValidate ? SVG.success : SVG.error;
    },
  },
});
