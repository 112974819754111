<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.id"
      >
        <p :class="b('column-label')" v-html="$t(column.label)"></p>

        <button :class="b('column-sort')" @click="handleColumnSort">
          <span :class="b('column-icon')" v-html="displayedIcons.sort"></span>
        </button>
      </div>
    </div>

    <div :class="b('rows')">
      <div :class="b('row')" v-for="row in displayedRows" :key="row.id">
        <div :class="b('row-date')">
          <p :class="b('row-label')">{{ row.date }}</p>
        </div>

        <div :class="b('row-id')">
          <p :class="b('row-label')">{{ row.id }}</p>
        </div>

        <div :class="b('row-email')">
          <p :class="b('row-label')">{{ row.email }}</p>
        </div>

        <div :class="b('row-type')">
          <p :class="b('row-label')">{{ row.type }}</p>
        </div>

        <div :class="b('row-amount')">
          <p :class="b('row-label', { success: true })">{{ row.amount }}</p>
        </div>

        <div :class="b('row-currency')">
          <p :class="b('row-label')">{{ row.currency }}</p>
        </div>

        <div :class="b('row-price')">
          <p :class="b('row-label')">{{ row.price }}</p>
        </div>

        <div :class="b('row-commission')">
          <p :class="b('row-label')">{{ row.commission }}</p>
        </div>

        <div :class="b('row-number')">
          <p :class="b('row-label')">{{ row.number }}</p>
        </div>

        <div :class="b('row-status')">
          <span :class="b('row-icon')" v-html="displayedIcons.create"></span>

          <p :class="b('row-label')">{{ row.status.label }}</p>
        </div>

        <div :class="b('row-stage')">
          <p :class="b('row-label')">{{ row.stage.label }}</p>

          <button :class="b('row-action')">
            <span :class="b('row-icon')" v-html="displayedIcons.remove"></span>
          </button>
        </div>

        <div :class="b('row-action')">
          <button :class="b('row-toggle')">
            <span :class="b('row-icon')" v-html="displayedIcons.dots"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
