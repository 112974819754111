import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Внести изменения")), 3),
        _createVNode(_component_SharedSelect, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Поиск по ID")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          placeholder: "Введите ID пользователя"
        })
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('divider'))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Комиссия MEX")), 3),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Комиссия MOEX")), 3),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, "SWIFT", 2),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Комиссия Крипто биржи")), 3),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Блокчейн перевод")), 3),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Волатильность")), 3),
        _createVNode(_component_SharedInputDouble, { view: "light" })
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      class: _normalizeClass(_ctx.b('save')),
      label: "Cохранить",
      view: "main",
      disabled: _ctx.isSaveDisabled,
      onClick: _ctx.handleSave
    }, null, 8, ["class", "disabled", "onClick"])
  ], 2))
}