import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";

import { LoginForm } from "@/components/login";
import { SharedAuthCard } from "@/components/shared";
import { UILink } from "@/components/ui";

import { LoginFactory } from "@/shared/factory/login";
import { RoutesName } from "@/shared/constants/constants";
import { Error } from "@/shared/repository/repo";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    LoginForm,
    SharedAuthCard,
    UILink,
  },
  data() {
    return {
      user: LoginFactory(),
      isRemember: false,
      loading: false,
      message: "",
    };
  },
  methods: {
    async handleLogin(): Promise<void> {
      try {
        this.loading = true;
        this.message = "";

        await this.$projectServices.projectRepository
          .login(this.user)
          .then(() => {
            this.$router.push({ name: RoutesName.wallet });
          });
      } catch (e) {
        const { message } = <Error>e;

        this.message = message;
      } finally {
        this.loading = false;
      }
    },

    handleGoogle(): void {
      console.log("Google");
    },
  },
});
