/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { Modal } from "@/shared/constants/constants";

enum ModalMutation {
  UPDATE_MODAL = "UPDATE_MODAL",
  CLEAR_MODAL = "CLEAR_MODAL",
}

interface State {
  modal?: Modal | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "modal",
})
export default class ModalModuleState extends VuexModule<State> {
  modal: Modal | null = null;

  @Mutation
  [ModalMutation.UPDATE_MODAL](modal: Modal): void {
    this.modal = modal;
  }

  @Mutation
  [ModalMutation.CLEAR_MODAL](): void {
    this.modal?.callback();

    this.modal = null;
  }
}
