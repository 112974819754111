import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import {
  WalletRequisitesRadio,
  WalletRequisitesRadioLabel,
  WalletRequisitesRadioValue,
} from "./model";

import { SharedRadio } from "@/components/shared";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedRadio,
  },
  props: {
    requisites: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:requisites": null,
  },
  computed: {
    displayedRadios(): WalletRequisitesRadio[] {
      return [
        {
          id: 0,
          label: WalletRequisitesRadioLabel.pattern,
          value: WalletRequisitesRadioValue.pattern,
        },
        {
          id: 1,
          label: WalletRequisitesRadioLabel.new,
          value: WalletRequisitesRadioValue.new,
        },
      ];
    },
  },
  methods: {
    handleRadio({ value }: WalletRequisitesRadio): void {
      this.$emit("update:requisites", value);
    },
  },
});
