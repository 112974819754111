import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  DefaultNavbarLink,
  DefaultNavbarLinkIcon,
  DefaultNavbarLinkLabel,
} from "./model";

import { RoutesTo } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  computed: {
    displayedLinks(): DefaultNavbarLink[] {
      return [
        {
          id: 0,
          label: DefaultNavbarLinkLabel.wallet,
          to: RoutesTo.wallet,
          icon: SVG[DefaultNavbarLinkIcon.wallet],
        },
        {
          id: 1,
          label: DefaultNavbarLinkLabel.application,
          to: RoutesTo.application,
          icon: SVG[DefaultNavbarLinkIcon.application],
        },
        {
          id: 2,
          label: DefaultNavbarLinkLabel.history,
          to: RoutesTo.history,
          icon: SVG[DefaultNavbarLinkIcon.history],
        },
        {
          id: 3,
          label: DefaultNavbarLinkLabel.settings,
          to: RoutesTo.settings,
          icon: SVG[DefaultNavbarLinkIcon.settings],
        },
      ];
    },
  },
});
