<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <p :class="b('label')">Password must:</p>

    <div
      :class="b('option')"
      v-for="option in displayedOptions"
      :key="option.id"
    >
      <span :class="b('option-icon')" v-html="displayedIcon(option)"></span>

      <p
        :class="
          b('option-label', {
            green: option.isValidate,
            red: !option.isValidate,
          })
        "
      >
        {{ $t(option.label) }}
      </p>
    </div>
  </div>
</template>
