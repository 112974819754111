import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "default-header";

export const SVG: SvgAttribute = {
  wallet: `<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.968" fill-rule="evenodd" clip-rule="evenodd" d="M7.03126 2.71875C9.90632 2.70833 12.7813 2.71875 15.6563 2.75C17.9073 2.91038 19.5428 3.9833 20.5625 5.96875C20.7258 6.351 20.8403 6.74681 20.9063 7.15625C23.4713 7.08963 25.4401 8.13125 26.8125 10.2813C27.5136 11.5681 27.7949 12.9431 27.6563 14.4063C29.1001 14.3826 29.9855 15.0701 30.3125 16.4688C30.3542 17.5938 30.3542 18.7188 30.3125 19.8438C29.9997 21.2713 29.1143 21.9588 27.6563 21.9063C27.8685 24.5538 26.9101 26.6475 24.7813 28.1875C23.8293 28.7965 22.7876 29.1298 21.6563 29.1875C16.9688 29.2292 12.2813 29.2292 7.59376 29.1875C5.0627 28.9386 3.23979 27.6782 2.12501 25.4063C1.85037 24.7452 1.68371 24.0577 1.62501 23.3438C1.56388 18.1131 1.58472 12.8839 1.68751 7.65625C2.34238 4.78235 4.12364 3.13651 7.03126 2.71875ZM7.09376 4.78125C10.0539 4.75217 13.0123 4.78342 15.9688 4.875C17.3256 5.09773 18.2631 5.83731 18.7813 7.09375C14.9065 7.14581 11.0314 7.17706 7.15626 7.1875C5.82777 7.35713 4.67152 7.88838 3.68751 8.78125C3.73828 6.96006 4.60286 5.69968 6.28126 5C6.55745 4.91548 6.82826 4.84256 7.09376 4.78125ZM7.34376 9.21875C12.3252 9.18838 17.3044 9.21963 22.2813 9.3125C24.7005 10.1407 25.8046 11.8386 25.5938 14.4063C23.0947 14.4262 21.6989 15.6762 21.4063 18.1563C21.7014 20.6278 23.0972 21.8778 25.5938 21.9063C25.8216 24.0863 24.9883 25.7009 23.0938 26.75C22.7725 26.8918 22.4392 26.9959 22.0938 27.0625C17.2828 27.1652 12.4703 27.186 7.65626 27.125C5.82896 26.9197 4.57896 25.9614 3.90626 24.25C3.79871 23.9136 3.72579 23.5698 3.68751 23.2188C3.64584 19.8438 3.64584 16.4688 3.68751 13.0938C4.04308 10.978 5.26183 9.68631 7.34376 9.21875ZM24.8438 16.4688C25.9538 16.4392 27.058 16.4704 28.1563 16.5625C28.1954 16.6094 28.2266 16.6615 28.25 16.7188C28.2917 17.6979 28.2917 18.6771 28.25 19.6563C28.198 19.7083 28.1458 19.7604 28.0938 19.8125C27.0313 19.8542 25.9688 19.8542 24.9063 19.8125C23.5235 19.2884 23.1798 18.3822 23.875 17.0938C24.1583 16.8122 24.4813 16.6039 24.8438 16.4688Z"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M9.15621 13.7813C11.9272 13.7708 14.698 13.7813 17.4687 13.8125C18.2747 14.0839 18.5143 14.6151 18.1875 15.4063C18.046 15.5896 17.8688 15.725 17.6562 15.8125C14.7604 15.8542 11.8645 15.8542 8.96871 15.8125C8.24309 15.4268 8.08684 14.8747 8.49996 14.1563C8.69177 13.9721 8.91052 13.8471 9.15621 13.7813Z"/>
  </svg>`,
  logout: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_11379)">
    <path opacity="0.976" fill-rule="evenodd" clip-rule="evenodd" d="M9.08203 -0.0195312C9.66797 -0.0195312 10.2539 -0.0195312 10.8398 -0.0195312C14.1982 0.372988 16.7828 1.98757 18.5938 4.82422C18.7945 5.1607 18.9638 5.51227 19.1016 5.87891C19.152 6.58387 18.8264 6.98754 18.125 7.08984C17.7271 7.06676 17.4276 6.88449 17.2266 6.54297C15.8766 3.89734 13.7217 2.38042 10.7617 1.99219C7.48188 1.81245 4.93633 3.08198 3.125 5.80078C1.83515 8.02816 1.6138 10.3589 2.46094 12.793C3.61199 15.573 5.66277 17.2722 8.61328 17.8906C11.8829 18.3158 14.5196 17.2286 16.5234 14.6289C16.8441 14.1374 17.1501 13.6361 17.4414 13.125C17.9941 12.7482 18.4954 12.8068 18.9453 13.3008C19.0966 13.5414 19.1487 13.8018 19.1016 14.082C18.469 15.5096 17.5511 16.7271 16.3477 17.7344C14.7341 19.0227 12.8982 19.7714 10.8398 19.9805C10.2539 19.9805 9.66797 19.9805 9.08203 19.9805C5.61926 19.5551 2.99558 17.8493 1.21094 14.8633C0.535453 13.6119 0.125297 12.2838 -0.0195312 10.8789C-0.0195312 10.28 -0.0195312 9.68098 -0.0195312 9.08203C0.482285 5.1418 2.53958 2.34883 6.15234 0.703125C7.10234 0.332147 8.07891 0.0912613 9.08203 -0.0195312Z"/>
    <path opacity="0.979" fill-rule="evenodd" clip-rule="evenodd" d="M10.8045 6.36963C11.073 6.3452 11.3225 6.39711 11.5529 6.52537C12.4197 7.32713 13.2864 8.12885 14.1532 8.9306C14.6628 9.62743 14.6753 10.3311 14.1907 11.0417C13.3114 11.855 12.4321 12.6682 11.5529 13.4815C11.0317 13.7466 10.5764 13.6716 10.1872 13.2566C10.0023 12.9039 10.0147 12.5578 10.2246 12.2183C10.6923 11.7857 11.16 11.3531 11.6277 10.9205C9.84419 10.909 8.06074 10.8975 6.27725 10.8859C5.7422 10.8005 5.46783 10.5064 5.4541 10.0034C5.47251 9.56524 5.70946 9.2826 6.165 9.15555C7.99837 9.14403 9.83173 9.13247 11.6651 9.12095C11.1724 8.66527 10.6798 8.20962 10.1872 7.75394C9.87158 7.08902 10.0774 6.62757 10.8045 6.36963Z"/>
    </g>
    <defs>
    <clipPath id="clip0_1_11379">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
