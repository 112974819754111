import { FileImageParams } from "@/shared/constants/constants";

export function convertFile(file: File): Promise<FileImageParams> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve({
        file: <string>reader.result,
        link: URL.createObjectURL(file),
      });
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}
