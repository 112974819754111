<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedLanguage :class="b('language')" view="light" />

    <div :class="b('profile')">
      <p :class="b('profile-label')">{{ displayedEmail }}</p>

      <button
        :class="b('profile-logout')"
        :disabled="isLogoutDisabled"
        @click="handleLogout"
      >
        <span :class="b('profile-icon')" v-html="displayedIcons.logout"></span>
      </button>
    </div>
  </div>
</template>
