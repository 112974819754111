import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "application-confirm-result";

export const SVG: SvgAttribute = {
  copy: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.976" fill-rule="evenodd" clip-rule="evenodd" d="M6.83758 1.45898C9.2751 1.45312 11.7126 1.45898 14.1501 1.47656C15.4919 1.69336 16.2712 2.47266 16.488 3.81445C16.5224 6.59271 16.5107 9.37005 16.4528 12.1465C16.1539 13.3594 15.3863 14.0567 14.1501 14.2383C13.916 14.2559 13.6816 14.2617 13.447 14.2559C13.2879 15.2241 12.7606 15.9096 11.8649 16.3125C11.6279 16.4098 11.3818 16.4684 11.1266 16.4883C8.68915 16.5117 6.25163 16.5117 3.81414 16.4883C2.47221 16.2713 1.69291 15.4921 1.47625 14.1504C1.44189 11.6182 1.4536 9.08694 1.51141 6.55664C1.78196 5.41891 2.49094 4.73337 3.63836 4.5C3.91936 4.48242 4.20061 4.47659 4.48211 4.48242C4.37154 3.06733 4.9692 2.10053 6.27508 1.58203C6.4651 1.52943 6.65262 1.48842 6.83758 1.45898ZM6.87274 3.00586C9.28681 3 11.7009 3.00586 14.1149 3.02344C14.5544 3.13477 14.8298 3.41016 14.9411 3.84961C14.9645 6.52148 14.9645 9.19336 14.9411 11.8652C14.8298 12.3047 14.5544 12.5801 14.1149 12.6914C13.9043 12.7089 13.6934 12.7148 13.4821 12.709C13.488 10.6816 13.4821 8.65427 13.4645 6.62695C13.2009 5.47267 12.4919 4.76367 11.3376 4.5C9.55635 4.48242 7.77513 4.47655 5.99383 4.48242C5.89409 3.74291 6.18705 3.25072 6.87274 3.00586ZM3.91961 5.99414C6.28682 5.98827 8.654 5.99414 11.0212 6.01172C11.5426 6.11132 11.8532 6.42189 11.9528 6.94336C11.9763 9.31054 11.9763 11.6777 11.9528 14.0449C11.8441 14.5169 11.557 14.8157 11.0915 14.9414C8.67741 14.9649 6.26337 14.9649 3.8493 14.9414C3.40985 14.8301 3.13446 14.5547 3.02313 14.1152C2.99969 11.7012 2.99969 9.28712 3.02313 6.87305C3.15513 6.40705 3.45396 6.11406 3.91961 5.99414Z" fill="#45B2FC"/>
  </svg>`,
  chevron: `<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33333 0.444445C8.64016 0.13762 9.13762 0.13762 9.44444 0.444445C9.75127 0.75127 9.75127 1.24873 9.44444 1.55556L5.55556 5.44445C5.24873 5.75127 4.75127 5.75127 4.44444 5.44445C4.13762 5.13762 4.13762 4.64016 4.44444 4.33333L8.33333 0.444445Z" fill="#9E9FA8"/>
    <path d="M5.55556 4.33333C5.86238 4.64016 5.86238 5.13762 5.55556 5.44445C5.24873 5.75127 4.75127 5.75127 4.44444 5.44445L0.555555 1.55556C0.248731 1.24873 0.248731 0.751269 0.555556 0.444444C0.862381 0.137619 1.35984 0.13762 1.66667 0.444445L5.55556 4.33333Z" fill="#9E9FA8"/>
  </svg>`,
};
