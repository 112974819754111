import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput } from "@/components/ui";
import { SharedDate, SharedRadio } from "@/components/shared";

import { KycPersonalInfoResponseFactory } from "@/shared/factory/kyc";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    SharedDate,
    SharedRadio,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      kycPersonalInfo: KycPersonalInfoResponseFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      await this.$projectServices.projectRepository
        .getKycPersonalInfo(this.id)
        .then((kycPersonalInfo) => {
          this.kycPersonalInfo =
            KycPersonalInfoResponseFactory(kycPersonalInfo);
        });
    } catch (e) {
      console.log(e);
    }
  },
});
