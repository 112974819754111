import { HistoryFilter } from "../constants/constants";

export const FilterFactory = (
  params: Partial<HistoryFilter> = {}
): HistoryFilter => {
  return {
    currency: params.currency ? params.currency : [],
    volume: {
      from: params.volume ? params.volume.from : "",
      to: params.volume ? params.volume.to : "",
    },
    date: params.date ? params.date : [],
    status: params.status ? params.status : [],
    type: params.type ? params.type : [],
  };
};
