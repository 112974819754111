import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["value", "onInput", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h5", {
          key: 0,
          class: _normalizeClass(_ctx.b('title'))
        }, _toDisplayString(_ctx.$t(_ctx.title)), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedFields, (field) => {
        return (_openBlock(), _createElementBlock("input", {
          class: _normalizeClass(_ctx.b('field', { error: _ctx.isError })),
          type: "number",
          key: field.id,
          value: field.value,
          ref_for: true,
          ref: field.key,
          onInput: ($event: any) => (_ctx.changeField($event, field)),
          onKeydown: ($event: any) => (_ctx.handleBackspace($event, field))
        }, null, 42, _hoisted_1))
      }), 128))
    ], 2),
    (_ctx.isLabel)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.$t("Введите 6 цифр из сообщения на email")), 3))
      : _createCommentVNode("", true)
  ], 2))
}