import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:value": null,
  },
  methods: {
    handleSwitch(): void {
      this.$emit("update:value", !this.value);
    },
  },
});
