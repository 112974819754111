import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-crypto-select";

export const SVG: SvgAttribute = {
  chevron: `<svg width="13" height="14" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8794 4.2929C11.2699 3.90238 11.903 3.90238 12.2936 4.2929C12.6841 4.68343 12.6841 5.31659 12.2936 5.70712L7.34383 10.6569C6.9533 11.0474 6.32014 11.0474 5.92961 10.6569C5.53909 10.2663 5.53909 9.63317 5.92961 9.24265L10.8794 4.2929Z"/>
    <path d="M7.34383 9.24265C7.73435 9.63317 7.73435 10.2663 7.34383 10.6569C6.9533 11.0474 6.32014 11.0474 5.92961 10.6569L0.979865 5.70712C0.589341 5.31659 0.589341 4.68343 0.979865 4.2929C1.37039 3.90238 2.00355 3.90238 2.39408 4.2929L7.34383 9.24265Z"/>
  </svg>`,
  btc: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.30216 18.4756C10.8785 21.07 15.8809 20.2739 18.4753 16.6976C21.0697 13.1212 20.2736 8.11884 16.6973 5.52445C13.1209 2.93007 8.11852 3.72612 5.52413 7.30248C2.92975 10.8788 3.7258 15.8812 7.30216 18.4756Z" fill="#F7931A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7201 9.07376C14.7186 9.4327 15.4417 9.96538 15.2707 10.9465C15.1468 11.6645 14.7445 12.0099 14.2198 12.1284C14.9258 12.5115 15.2748 13.0939 14.9007 14.097C14.4368 15.3428 13.4104 15.4413 12.0561 15.171L11.6899 16.5117L10.8971 16.3045L11.2584 14.9819C11.0528 14.9283 10.843 14.8714 10.6265 14.8101L10.264 16.139L9.47206 15.9318L9.83802 14.5886C9.77 14.5703 9.70154 14.5516 9.63262 14.5328L9.63224 14.5327C9.51383 14.5004 9.39409 14.4677 9.27295 14.4359L8.2413 14.1662L8.66207 13.2422C8.66207 13.2422 9.24628 13.4047 9.23861 13.393C9.46301 13.4513 9.56733 13.3024 9.60959 13.2031L10.6023 9.56528C10.6166 9.39336 10.5641 9.17599 10.243 9.09193C10.2557 9.0834 9.66722 8.94178 9.66722 8.94178L9.90266 8.07847L10.9959 8.36465L10.995 8.36874C11.1595 8.41167 11.3289 8.45238 11.5015 8.49372L11.8642 7.16597L12.6564 7.37314L12.3013 8.67469C12.5142 8.72564 12.7282 8.7771 12.9369 8.8316L13.2898 7.53864L14.0825 7.74581L13.7201 9.07376ZM13.3779 13.5284C13.6349 12.5865 12.262 12.2631 11.5925 12.1054C11.5179 12.0878 11.452 12.0722 11.3981 12.0582L10.9123 13.8375C10.9566 13.849 11.0086 13.8634 11.0668 13.8795C11.7136 14.0585 13.1268 14.4495 13.3779 13.5284ZM12.2245 9.66902C12.7834 9.79911 13.9277 10.0654 13.694 10.9215C13.4655 11.7585 12.2895 11.4298 11.7504 11.2791C11.7014 11.2654 11.6577 11.2532 11.6204 11.2435L12.0608 9.62961C12.1061 9.64145 12.1616 9.65437 12.2245 9.66902Z" fill="white"/>
  </svg>`,
  usdt: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 16C15.4183 16 19 12.4183 19 8C19 3.58172 15.4183 0 11 0C6.58172 0 3 3.58172 3 8C3 12.4183 6.58172 16 11 16Z" fill="white"/>
    <g filter="url(#filter0_d_567_42639)">
    <path d="M16 8L11 13L6 8L11 3L16 8Z" fill="url(#paint0_linear_567_42639)"/>
    </g>
    <path d="M11 0C6.58167 0 3 3.58167 3 8C3 12.418 6.58167 16 11 16C15.4183 16 19 12.418 19 8C19 3.58167 15.4183 0 11 0Z" fill="#4EAF94"/>
    <path d="M14.2087 4V5.80767H11.8363V6.676C13.7363 6.781 15.1663 7.236 15.1663 7.78067C15.1663 8.326 13.7363 8.78067 11.8363 8.88567V12H10.0937V8.88133C8.22834 8.76933 6.83301 8.31933 6.83301 7.78067C6.83301 7.24233 8.22834 6.79233 10.0937 6.68033V5.80767H7.72167V4H14.2087ZM11.8363 6.87333V7.931C11.2559 7.96105 10.6743 7.95972 10.094 7.927V6.877C8.49234 6.957 7.30701 7.244 7.30701 7.58633C7.30701 7.99033 8.96034 8.318 10.9997 8.318C13.039 8.318 14.6923 7.99033 14.6923 7.58633C14.6923 7.239 13.4723 6.94867 11.8363 6.87367V6.87333Z" fill="white"/>
    <defs>
    <filter id="filter0_d_567_42639" x="0" y="0" width="22" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_567_42639"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_567_42639" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_567_42639" x1="16" y1="13" x2="16" y2="3" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0055FF"/>
    <stop offset="1" stop-color="#0055FF" stop-opacity="0.8"/>
    </linearGradient>
    </defs>
  </svg>`,
};
