<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <button :class="b('back')" @click="handleBack">
      <span :class="b('icon')" v-html="displayedIcons.chevron"></span>
    </button>

    <h2 :class="b('title')">
      {{ $t(title) }}
    </h2>
  </div>
</template>
