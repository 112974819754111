<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack
      title="Основная информация об организации"
      @back="handleBack"
    />

    <div :class="b('fields')">
      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Страна регистрации организации") }}
        </h4>

        <UIInput view="light" v-model="kybBasicInfo.country" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Название организации") }}
        </h4>

        <UIInput view="light" v-model="kybBasicInfo.organisation" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Регистрационный номер") }}
        </h4>

        <UIInput view="light" v-model="kybBasicInfo.regional_number" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Дата регистрации") }}
        </h4>

        <SharedDate
          format="yyyy-MM-dd"
          v-model:date="kybBasicInfo.registration_date"
        />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Номер Налогоплательщика") }}
        </h4>

        <UIInput view="light" v-model="kybBasicInfo.inn" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Деятельность лицензируется?") }}
        </h4>

        <div :class="b('field-radios')">
          <div :class="b('field-radio')">
            <SharedRadio
              view="light"
              :checked-value="true"
              v-model:value="kybBasicInfo.licensed"
            />

            <p :class="b('field-radio-label')">
              {{ $t("Да") }}
            </p>
          </div>

          <div :class="b('field-radio')">
            <SharedRadio
              view="light"
              :checked-value="false"
              v-model:value="kybBasicInfo.licensed"
            />

            <p :class="b('field-radio-label')">
              {{ $t("Нет") }}
            </p>
          </div>
        </div>
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Телеграм для связи") }}
        </h4>

        <UIInput
          view="light"
          :is-telegram="true"
          v-model="kybBasicInfo.telegram"
        />
      </div>
    </div>
  </div>
</template>
