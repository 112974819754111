<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <h3 :class="b('title')">
      {{ $t("Верификация информации о компании") }}
    </h3>

    <div :class="b('content')">
      <div :class="b('list')">
        <button
          :class="b('item', { padding: item.isPadding })"
          v-for="item in displayedList"
          :key="item.id"
          @click="handleContinue(item)"
        >
          <span :class="b('item-icon')" v-html="item.icon"></span>

          <div :class="b('item-info')">
            <h4 :class="b('item-title')">
              {{ $t(item.title) }}
            </h4>

            <p :class="b('item-label')">
              {{ $t(item.label) }}
            </p>
          </div>

          <span :class="b('item-chevron')" v-html="displayedChevron"></span>
        </button>
      </div>

      <UIButton
        label="Отправить на проверку"
        view="main"
        :disabled="isVerificationDisabled"
        @click="handleVerification"
      />
    </div>
  </SharedCard>
</template>
