import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-language";

export const SVG: SvgAttribute = {
  chevron: `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8794 3.79308C11.2699 3.40256 11.903 3.40256 12.2936 3.79308C12.6841 4.18361 12.6841 4.81677 12.2936 5.2073L7.34383 10.157C6.9533 10.5476 6.32014 10.5476 5.92961 10.157C5.53909 9.76652 5.53909 9.13336 5.92961 8.74283L10.8794 3.79308Z" fill="#9E9FA8"/>
    <path d="M7.34383 8.74283C7.73435 9.13336 7.73435 9.76652 7.34383 10.157C6.9533 10.5476 6.32014 10.5476 5.92961 10.157L0.979865 5.2073C0.589341 4.81677 0.589341 4.18361 0.979865 3.79308C1.37039 3.40256 2.00355 3.40256 2.39408 3.79308L7.34383 8.74283Z" fill="#9E9FA8"/>
  </svg>`,
};
