import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-users-table";

export const SVG: SvgAttribute = {
  sort: `<svg width="7" height="11" viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61023 4.38179C6.03012 4.38179 6.26301 3.89553 5.99981 3.56837L3.44516 0.392943C3.24502 0.144165 2.86615 0.144166 2.66601 0.392944L0.111364 3.56837C-0.151837 3.89553 0.0810508 4.38179 0.500942 4.38179H5.61023ZM0.500965 6.61827C0.0810745 6.61827 -0.151812 7.10452 0.111389 7.43168L2.66603 10.6071C2.86618 10.8559 3.24505 10.8559 3.44519 10.6071L5.99984 7.43168C6.26304 7.10452 6.03015 6.61827 5.61026 6.61827H0.500965Z" />
  </svg>`,
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5" />
    <circle cx="8" cy="8" r="1.5" />
    <circle cx="8" cy="14" r="1.5" />
  </svg>`,
};
