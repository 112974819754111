<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.id"
      >
        <p :class="b('column-label')">
          {{ $t(column.label) }}
        </p>

        <button :class="b('column-sort')">
          <span :class="b('column-icon')" v-html="displayedSortIcon"></span>
        </button>
      </div>

      <div
        :class="b('column', { small: true })"
        v-for="column in displayedSmallColumns"
        :key="column.id"
      >
        <p :class="b('column-label')">
          {{ $t(column.label) }}
        </p>

        <button :class="b('column-sort')">
          <span :class="b('column-icon')" v-html="displayedSortIcon"></span>
        </button>
      </div>
    </div>

    <div :class="b('rows')">
      <div :class="b('row')" v-for="row in displayedRows" :key="row.id">
        <div :class="b('row-date-time')">
          <p :class="b('row-label', { dateTime: true })">
            {{ row.dateTime }}
          </p>
        </div>

        <div :class="b('row-type')">
          <p :class="b('row-label')">
            {{ row.type }}
          </p>
        </div>

        <div :class="b('row-amount')">
          <p
            :class="
              b('row-label', {
                refill: row.amount.type.refill,
                conclusion: row.amount.type.conclusion,
              })
            "
          >
            {{ row.amount.label }}
          </p>
        </div>

        <div :class="b('row-currency')">
          <p :class="b('row-label', { currency: true })">
            {{ row.currency }}
          </p>
        </div>

        <div :class="b('row-commission')">
          <p :class="b('row-label')">
            {{ row.commission }}
          </p>
        </div>

        <div :class="b('row-status')">
          <div :class="b('row-group')">
            <span :class="b('row-icon')" v-html="row.status.icon"></span>

            <p :class="b('row-label', { status: true })">
              {{ row.status.label }}
            </p>
          </div>

          <div :class="b('row-actions')">
            <UIButton
              label="Подтвердить"
              view="main-small"
              @click="handleConfirm(row)"
            />

            <button :class="b('row-action')" @click="handleRemove(row)">
              <span :class="b('row-icon')" v-html="displayedRemoveIcon"></span>
            </button>
          </div>
        </div>

        <div :class="b('row-block')">
          <div :class="b('row-block-group')">
            <div :class="b('row-block-type')">
              <p :class="b('row-label')">
                {{ row.type }}
              </p>

              <span
                :class="
                  b('row-label', {
                    refill: row.amount.type.refill,
                    conclusion: row.amount.type.conclusion,
                    block: true,
                  })
                "
              >
                {{ row.amount.label }}

                <p :class="b('row-label', { currency: true })">
                  {{ row.currency }}
                </p>
              </span>
            </div>

            <div :class="b('row-block-date')">
              <p :class="b('row-label', { dateTime: true })">
                {{ row.dateTime }}
              </p>

              <span :class="b('row-icon')" v-html="row.status.icon"></span>
            </div>
          </div>

          <div :class="b('row-block-group')">
            <div :class="b('row-block-commission')">
              <p :class="b('row-label')">
                {{ $t("Комиссия") }}
              </p>

              <p :class="b('row-label')">
                {{ row.commission }}
              </p>
            </div>

            <div :class="b('row-block-other')">
              <div :class="b('row-block-number')">
                <p :class="b('row-label')">№ {{ $t("Transaction ") }}</p>

                <p :class="b('row-label')">24355</p>
              </div>

              <div :class="b('row-block-actions')">
                <button
                  :class="b('row-block-dots', { active: row.isActions })"
                  @click="handleRowActions(row)"
                >
                  <span
                    :class="b('row-icon')"
                    v-html="displayedDotsIcon"
                  ></span>
                </button>

                <div :class="b('row-actions', { visible: row.isActions })">
                  <UIButton
                    label="Подтвердить"
                    view="main-small"
                    @click="handleConfirm(row)"
                  />

                  <button :class="b('row-action')" @click="handleRemove(row)">
                    <span
                      :class="b('row-icon')"
                      v-html="displayedRemoveIcon"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
