import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('field', { view: _ctx.view })),
      type: "number",
      step: "any",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChangeValue && _ctx.handleChangeValue(...args)))
    }, null, 42, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('icon')),
      innerHTML: _ctx.displayedIcons.percent
    }, null, 10, _hoisted_2)
  ], 2))
}