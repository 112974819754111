import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedLanguage } from "@/components/shared";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  computed: {
    displayedLogo(): string {
      return SVG.logo;
    },
  },
});
