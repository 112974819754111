import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-label'))
      }, _toDisplayString(_ctx.$t("Изменить статус")), 3),
      _createVNode(_component_SharedSelect, {
        view: "light",
        options: _ctx.displayedOptions,
        value: _ctx.userApp.status,
        "onUpdate:value": _ctx.handleChangeStatus
      }, null, 8, ["options", "value", "onUpdate:value"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-label'))
      }, _toDisplayString(_ctx.$t("Комментарий для пользователя")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        placeholder: "Добавьте комментарий при необходимости",
        modelValue: _ctx.userApp.message,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userApp.message) = $event))
      }, null, 8, ["modelValue"])
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Cохранить",
      view: "main",
      disabled: _ctx.isSaveDisabled,
      onClick: _ctx.handleSave
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}