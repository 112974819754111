import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-remove";

export const SVG: SvgAttribute = {
  close: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0046 5.70518C20.3337 6.03423 20.3337 6.56773 20.0046 6.89678L14.0466 12.8548L20.0046 18.8128C20.3337 19.1419 20.3337 19.6754 20.0046 20.0044C19.6756 20.3335 19.1421 20.3335 18.813 20.0044L12.855 14.0464L6.89695 20.0044C6.5679 20.3335 6.0344 20.3335 5.70534 20.0044C5.37629 19.6754 5.37629 19.1419 5.70534 18.8128L11.6634 12.8548L5.70535 6.89678C5.37629 6.56773 5.37629 6.03423 5.70535 5.70518C6.0344 5.37612 6.5679 5.37612 6.89695 5.70518L12.855 11.6632L18.813 5.70518C19.1421 5.37612 19.6756 5.37613 20.0046 5.70518Z" fill="#9E9FA8"/>
  </svg>`,
  remove: `<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_563_38973)">
    <path opacity="0.989" fill-rule="evenodd" clip-rule="evenodd" d="M27.7461 -0.0585938C29.5429 -0.0585938 31.3399 -0.0585938 33.1367 -0.0585938C38.0062 0.431704 42.4984 1.9942 46.6133 4.62891C36.7695 4.62891 26.9258 4.62891 17.082 4.62891C16.1445 4.62891 15.207 4.62891 14.2695 4.62891C17.2108 2.70312 20.4139 1.35546 23.8789 0.585938C25.1799 0.341866 26.469 0.127023 27.7461 -0.0585938Z" fill="#FCC1B7"/>
    <path opacity="0.999" fill-rule="evenodd" clip-rule="evenodd" d="M17.082 4.62888C26.9258 4.62888 36.7695 4.62888 46.6133 4.62888C53.6836 3.65232 56.7304 6.6992 55.7539 13.7695C55.7539 22.168 55.7539 30.5663 55.7539 38.9648C55.7539 40.996 55.7539 43.0274 55.7539 45.0586C50.793 45.0586 45.832 45.0586 40.8711 45.0586C42.6834 43.1877 44.5194 41.3321 46.3789 39.4922C43.1912 36.324 40.0271 33.1405 36.8867 29.9414C40.0271 26.7423 43.1912 23.5588 46.3789 20.3906C44.25 18.2617 42.1211 16.1328 39.9922 14.0039C36.824 17.1916 33.6405 20.3557 30.4414 23.4961C27.2423 20.3557 24.0588 17.1916 20.8906 14.0039C19.6365 15.2776 18.367 16.5275 17.082 17.7539C17.082 13.3789 17.082 9.00388 17.082 4.62888Z" fill="#E5554E"/>
    <path opacity="0.998" fill-rule="evenodd" clip-rule="evenodd" d="M14.2695 4.62891C15.207 4.62891 16.1445 4.62891 17.082 4.62891C17.082 9.0039 17.082 13.3789 17.082 17.7539C16.2464 18.6483 15.387 19.5272 14.5039 20.3906C17.6916 23.5588 20.8557 26.7423 23.9961 29.9414C20.8752 33.1209 17.7307 36.285 14.5625 39.4336C14.4843 39.5118 14.4843 39.5898 14.5625 39.668C16.6345 41.7594 18.7243 43.8298 20.832 45.8789C24.0376 42.7124 27.2408 39.5483 30.4414 36.3867C33.6405 39.5271 36.824 42.6912 39.9922 45.8789C40.2699 45.5814 40.5629 45.308 40.8711 45.0586C45.832 45.0586 50.793 45.0586 55.7539 45.0586C55.7539 45.4102 55.7539 45.7617 55.7539 46.1133C53.3711 49.8243 50.3243 52.8711 46.6133 55.2539C35.832 55.2539 25.0508 55.2539 14.2695 55.2539C10.5586 52.8711 7.51171 49.8243 5.12891 46.1133C5.12891 35.332 5.12891 24.5508 5.12891 13.7695C7.2328 10.5127 9.84998 7.71976 12.9805 5.39062C13.3989 5.10696 13.8287 4.85305 14.2695 4.62891Z" fill="#E20B62"/>
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M5.12891 13.7695C5.12891 24.5508 5.12891 35.332 5.12891 46.1133C2.49424 41.9985 0.931739 37.5063 0.441406 32.6367C0.441406 30.8399 0.441406 29.0429 0.441406 27.2461C0.931704 22.3766 2.4942 17.8845 5.12891 13.7695Z" fill="#EF99A0"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M55.7539 13.7695C58.3886 17.8845 59.9511 22.3766 60.4414 27.2461C60.4414 29.0429 60.4414 30.8399 60.4414 32.6367C60.293 33.9402 60.0587 35.2293 59.7383 36.5039C59.7459 36.4526 59.7264 36.4134 59.6797 36.3867C58.4546 37.3796 57.1461 38.239 55.7539 38.9648C55.7539 30.5664 55.7539 22.168 55.7539 13.7695Z" fill="#FCC0B7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4414 23.4961C32.5508 25.6836 34.6992 27.832 36.8867 29.9414C40.0271 33.1405 43.1912 36.324 46.3789 39.4922C44.5194 41.3321 42.6834 43.1877 40.8711 45.0586C40.5629 45.308 40.2699 45.5814 39.9922 45.8789C36.824 42.6912 33.6405 39.5271 30.4414 36.3867C28.332 34.1992 26.1836 32.0508 23.9961 29.9414C20.8557 26.7423 17.6916 23.5588 14.5039 20.3906C15.387 19.5272 16.2464 18.6483 17.082 17.7539C18.367 16.5275 19.6365 15.2776 20.8906 14.0039C24.0588 17.1916 27.2423 20.3557 30.4414 23.4961Z" fill="#FEFEFE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8867 29.9414C34.6992 27.832 32.5508 25.6836 30.4414 23.4961C33.6405 20.3557 36.824 17.1916 39.9922 14.0039C42.1211 16.1329 44.25 18.2617 46.3789 20.3906C43.1912 23.5588 40.0271 26.7423 36.8867 29.9414Z" fill="#E6F0FE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9961 29.9414C26.1837 32.0508 28.3321 34.1992 30.4414 36.3867C27.2408 39.5483 24.0376 42.7124 20.8321 45.8789C18.7243 43.8298 16.6345 41.7594 14.5625 39.668C14.4844 39.5898 14.4844 39.5118 14.5625 39.4336C17.7307 36.285 20.8752 33.1209 23.9961 29.9414Z" fill="#E6F0FE"/>
    <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M59.7383 36.5039C59.0245 39.9766 57.6963 43.1797 55.7539 46.1133C55.7539 45.7617 55.7539 45.4102 55.7539 45.0586C55.7539 43.0274 55.7539 40.9961 55.7539 38.9648C57.1461 38.239 58.4546 37.3796 59.6797 36.3867C59.7264 36.4134 59.7459 36.4526 59.7383 36.5039Z" fill="#EF9AA0"/>
    <path opacity="0.988" fill-rule="evenodd" clip-rule="evenodd" d="M14.2695 55.2539C25.0508 55.2539 35.832 55.2539 46.6133 55.2539C42.4643 57.913 37.933 59.4755 33.0195 59.9414C31.3007 59.9414 29.5821 59.9414 27.8633 59.9414C22.9496 59.4755 18.4184 57.913 14.2695 55.2539Z" fill="#EF99A0"/>
    </g>
    <defs>
    <clipPath id="clip0_563_38973">
    <rect width="60" height="60" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
  </svg>`,
};
