import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { AdminUsersLabel, AdminUsersOption } from "./model";

import { SvgAttribute } from "@/shared/constants/constants";

import { AdminUsersSearchType } from "@/views/admin-users/model";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    search: {
      type: String,
      default: "",
    },
    searchType: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:search": null,
    "update:searchType": null,
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    displayedOptions(): AdminUsersOption[] {
      return [
        {
          id: 0,
          label: AdminUsersLabel.all,
          value: AdminUsersSearchType.all,
          isActive: this.searchType === AdminUsersSearchType.all,
        },
        {
          id: 1,
          label: AdminUsersLabel.id,
          value: AdminUsersSearchType.id,
          isActive: this.searchType === AdminUsersSearchType.id,
        },
        {
          id: 2,
          label: AdminUsersLabel.email,
          value: AdminUsersSearchType.email,
          isActive: this.searchType === AdminUsersSearchType.email,
        },
        {
          id: 3,
          label: AdminUsersLabel.fio,
          value: AdminUsersSearchType.fio,
          isActive: this.searchType === AdminUsersSearchType.fio,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isSearchClearShow(): boolean {
      return !!this.search;
    },
  },
  methods: {
    handleSearch(event: Event): void {
      this.$emit("update:search", (<HTMLInputElement>event.target).value);
    },

    handleSearchClear(): void {
      this.$emit("update:search", "");
    },

    handleToggle(): void {
      this.isToggle = !this.isToggle;
    },

    handleHide(): void {
      this.isToggle = false;
    },

    handleOption({ value }: AdminUsersOption): void {
      this.$emit("update:searchType", value);
    },
  },
});
