import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('icon')),
      innerHTML: _ctx.displayedIcon
    }, null, 10, _hoisted_1)
  ], 2))
}