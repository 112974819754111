import { Language } from "@/shared/constants/constants";

export interface SharedLanguageOption {
  id: number;
  label: string;
  value: Language;
  isActive: boolean;
}

export enum SharedLanguageOptionLabel {
  "ru-RU" = "RU",
  "en-EN" = "EN",
}
