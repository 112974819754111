export interface WalletApplicationsColumn {
  id: number;
  label: WalletApplicationsColumnLabel;
}

export enum WalletApplicationsColumnLabel {
  dateTime = "Дата и время",
  operation = "Операция",
}

export interface WalletApplicationsRow {
  id: number;
  dateTime: string;
  operation: {
    from: {
      amount: string;
      currency: string;
    };
    to: {
      amount: string;
      currency: string;
    };
    icon: string;
  };
}
