import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedInputPassword = _resolveComponent("SharedInputPassword")!
  const _component_SharedPasswordValidation = _resolveComponent("SharedPasswordValidation")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_SharedInputPassword, {
          placeholder: "Новый пароль*",
          view: "light",
          modelValue: _ctx.password,
          "onUpdate:modelValue": _ctx.changePassword
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        (_ctx.isValidationPasswordShow)
          ? (_openBlock(), _createBlock(_component_SharedPasswordValidation, {
              key: 0,
              validation: _ctx.displayedValidationPassword
            }, null, 8, ["validation"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_SharedInputPassword, {
        placeholder: "Подтвердите пароль*",
        view: "light",
        modelValue: _ctx.password_confirmation,
        "onUpdate:modelValue": _ctx.changeConfirmPassword
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Изменить пароль",
      view: "main",
      disabled: _ctx.isForgotDisabled,
      onClick: _ctx.handleForgot
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}