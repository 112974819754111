import { RoutesTo } from "@/shared/constants/constants";

export interface DefaultNavbarLink {
  id: number;
  label: DefaultNavbarLinkLabel;
  to: RoutesTo;
  icon: string;
}

export enum DefaultNavbarLinkLabel {
  verification = "Реквизиты",
  wallet = "Кошелек",
  application = "Создание заявки",
  history = "История",
  settings = "Настройки",
}

export enum DefaultNavbarLinkIcon {
  verification = "verification",
  wallet = "wallet",
  application = "application",
  history = "history",
  settings = "settings",
}
