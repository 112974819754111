/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { DEFAILT_SECONDS, Notification } from "@/shared/constants/constants";

enum NotificationMutation {
  UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION",
  CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION",
}

interface State {
  notifcation?: Notification | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "notification",
})
export default class NotificationModuleState extends VuexModule<State> {
  notification: Notification | null = null;

  @Mutation
  [NotificationMutation.UPDATE_NOTIFICATION](notification: Notification): void {
    this.notification = notification;
  }

  @Mutation
  [NotificationMutation.CLEAR_NOTIFICATION](): void {
    setTimeout(() => {
      this.notification = null;
    }, DEFAILT_SECONDS);
  }

  @Action
  updateNotification(notification: Notification): void {
    this.UPDATE_NOTIFICATION(notification);

    this.CLEAR_NOTIFICATION();
  }
}
