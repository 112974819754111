import {
  AdminUserAppResponse,
  AdminUserAppStatusRequest,
  AdminUserResponse,
} from "@/shared/repository/repo";

export const AdminUserResponseFactory = (
  params: Partial<AdminUserResponse> = {}
): AdminUserResponse => {
  return {
    id: params.id ?? 0,
    name: params.name ?? "",
    email: params.email ?? "",
    email_verified_at: params.email_verified_at ?? "",
    two_factor_secre: params.two_factor_secre ?? null,
    two_factor_recovery_codes: params.two_factor_recovery_codes ?? null,
    creatd_at: params.creatd_at ?? "",
    updated_at: params.updated_at ?? "",
    user_info: {
      id: params.user_info && params.user_info.id ? params.user_info.id : 0,
      user_id:
        params.user_info && params.user_info.user_id
          ? params.user_info.user_id
          : 0,
      lang:
        params.user_info && params.user_info.lang ? params.user_info.lang : "",
      application_type:
        params.user_info && params.user_info.application_type
          ? params.user_info.application_type
          : "",
      creatd_at:
        params.user_info && params.user_info.creatd_at
          ? params.user_info.creatd_at
          : "",
      updated_at:
        params.user_info && params.user_info.updated_at
          ? params.user_info.updated_at
          : "",
    },
  };
};

export const AdminUserAppResponseFactory = (
  params: Partial<AdminUserAppResponse> = {}
): AdminUserAppResponse => {
  return {
    id: params.id ?? 0,
    user_id: params.user_id ?? 0,
    country: params.country ?? "",
    organisation: params.organisation ?? "",
    regional_number: params.regional_number ?? "",
    registration_date: params.registration_date ?? "",
    inn: params.inn ?? "",
    licensed: params.licensed ?? "",
    telegram: params.telegram ?? "",
    status: params.status ?? "reject",
    type: params.type ?? "kyc",
    creatd_at: params.creatd_at ?? "",
    updated_at: params.updated_at ?? "",
  };
};

export const AdminUserAppStatusRequestFactory = (
  params: Partial<AdminUserAppStatusRequest> = {}
): AdminUserAppStatusRequest => {
  return {
    id: params.id ? params.id : 0,
    status: params.status ? params.status : "reject",
    message: params.message ? params.message : "",
  };
};
