import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

const AT = "@";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
    placeholderView: {
      type: String,
      default: "",
    },
    maska: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isTelegram: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:modelValue": null,
  },
  methods: {
    changeModelValue(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      if (this.isTelegram) {
        this.$emit(
          "update:modelValue",
          value[0] === AT ? value : `${AT}${value}`
        );
      } else {
        this.$emit("update:modelValue", value);
      }
    },
  },
});
