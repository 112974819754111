import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { SettingsTab, SettingsTabLabel } from "./model";

import { SettingsTabValue } from "@/views/settings/model";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    tab: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:tab": null,
  },
  computed: {
    displayedTabs(): SettingsTab[] {
      return [
        {
          id: 0,
          label: SettingsTabLabel.main,
          value: SettingsTabValue.main,
          isActive: this.tab === SettingsTabValue.main,
        },
        {
          id: 1,
          label: SettingsTabLabel["kyc-kyb"],
          value: SettingsTabValue["kyc-kyb"],
          isActive: this.tab === SettingsTabValue["kyc-kyb"],
        },
        {
          id: 2,
          label: SettingsTabLabel.requisites,
          value: SettingsTabValue.requisites,
          isActive: this.tab === SettingsTabValue.requisites,
        },
      ];
    },
  },
  methods: {
    handleTab({ value }: SettingsTab): void {
      this.$emit("update:tab", value);
    },
  },
});
