<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button :class="b('toggle', { active: isVisible })" @click="handleToggle">
      <span :class="b('toggle-placeholder')">
        {{ displayedPlaceholder }}
      </span>

      <span :class="b('toggle-group')">
        <span :class="b('toggle-currency')" v-if="isCurrencyShow">
          <span :class="b('toggle-icon')" v-html="value.currency.icon"></span>

          {{ value.currency.coin }}
        </span>

        <span
          :class="b('toggle-icon', { active: isVisible })"
          v-html="displayedChevronIcon"
        ></span>
      </span>
    </button>

    <div :class="b('options', { visible: isVisible })">
      <button
        :class="b('option', { active: option.isActive })"
        v-for="option in displayedOptions"
        :key="option.id"
        @click="handleOption(option)"
      >
        {{ option.label }}

        <span :class="b('option-currency')">
          <span :class="b('option-icon')" v-html="option.currency.icon"></span>

          {{ option.currency.coin }}
        </span>
      </button>

      <button :class="b('option', { create: true })" @click="handleCreate">
        Создать новый
      </button>
    </div>
  </div>
</template>
