<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <UIInput
      placeholder="Адрес электронной почты*"
      view="light"
      :modelValue="email"
      @update:modelValue="changeEmail"
    />

    <UIButton
      label="ПРОДОЛЖИТЬ"
      view="main"
      :disabled="isNextDisabled"
      @click="handleNext"
    />
  </div>
</template>
