import { VerificationKybComponent } from "@/views/verification/model";

export interface KybCompanyItem {
  id: number;
  title: KybCompanyItemTitle;
  label: KybCompanyItemLabel;
  icon: string;
  isPadding: boolean;
  component: VerificationKybComponent;
}

export enum KybCompanyItemTitle {
  basicInformation = "Основная информация",
  particpants = "Участники организации",
  documents = "Загрузка документов",
}

export enum KybCompanyItemLabel {
  basicInformation = "Информация о счете, адрес компании и декларация о происхождении средств",
  particpants = "Основная информация, идентификационные данные и страна проживания связанных сторон",
  documents = "Предоставьте необходимые документы для подтверждения вашей компании",
}
