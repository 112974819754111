import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedVerification } from "@/components/shared";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { decline } from "@/shared/utils/decline-helpers";

const MAX_SECONDS = 60;
const DEFAULT_SECONDS = 1000;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedVerification,
  },
  data() {
    return {
      code: "",
      seconds: MAX_SECONDS,
      timer: <null | number>null,
    };
  },
  created(): void {
    this.handleStartTimer();
  },
  computed: {
    isCreateDisabled(): boolean {
      return this.code.length !== CODE_MAX_LENGTH;
    },

    isRepeatDisabled(): boolean {
      return !!this.seconds;
    },

    displayedRepeatLabel(): string {
      return `через ${this.seconds} ${decline(this.seconds, "секунд", [
        "у",
        "ы",
        "",
      ])}`;
    },
  },
  methods: {
    handleStartTimer(): void {
      this.seconds = MAX_SECONDS;

      this.timer = setInterval(() => {
        this.seconds--;

        if (!this.seconds && this.timer !== null) {
          clearInterval(this.timer);
        }
      }, DEFAULT_SECONDS);
    },

    handleRepeat(): void {
      this.handleStartTimer();
    },

    handleCreate(): void {
      console.log("Handle Create!");
    },
  },
});
