import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_SharedCheckbox = _resolveComponent("SharedCheckbox")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-group'))
        }, [
          _createVNode(_component_SharedBack, { onClick: _ctx.handleBack }, null, 8, ["onClick"]),
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t("Участник")), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-checkboxes'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('header-checkbox'))
          }, [
            _createVNode(_component_SharedCheckbox, {
              view: "22",
              checked: _ctx.kybParticipant.is_beneficiary,
              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybParticipant.is_beneficiary) = $event))
            }, null, 8, ["checked"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('header-checkbox-label'))
            }, _toDisplayString(_ctx.$t("Является Бенефициаром")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('header-checkbox'))
          }, [
            _createVNode(_component_SharedCheckbox, {
              view: "22",
              checked: _ctx.kybParticipant.is_director,
              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybParticipant.is_director) = $event))
            }, null, 8, ["checked"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('header-checkbox-label'))
            }, _toDisplayString(_ctx.$t("Является директором")), 3)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-fields'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Фамилия*")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              modelValue: _ctx.kybParticipant.sur_name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybParticipant.sur_name) = $event))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Имя*")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              modelValue: _ctx.kybParticipant.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybParticipant.name) = $event))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Отчество")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              modelValue: _ctx.kybParticipant.patronymic,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybParticipant.patronymic) = $event))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Гражданство*")), 3),
            _createVNode(_component_SharedCountry, {
              placeholder: "Выберите страну",
              value: _ctx.kybParticipant.citizenship,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybParticipant.citizenship) = $event))
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Дата рождения*")), 3),
            _createVNode(_component_SharedDate, {
              format: "yyyy-MM-dd",
              date: _ctx.kybParticipant.birth_date,
              "onUpdate:date": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybParticipant.birth_date) = $event))
            }, null, 8, ["date"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Укажите пол*")), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-radios'))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-radio'))
              }, [
                _createVNode(_component_SharedRadio, {
                  view: "light",
                  checkedValue: "male",
                  value: _ctx.kybParticipant.gender,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
                }, null, 8, ["value"]),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('form-radio-label'))
                }, _toDisplayString(_ctx.$t("Мужчина")), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-radio'))
              }, [
                _createVNode(_component_SharedRadio, {
                  view: "light",
                  checkedValue: "female",
                  value: _ctx.kybParticipant.gender,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
                }, null, 8, ["value"]),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('form-radio-label'))
                }, _toDisplayString(_ctx.$t("Женщина")), 3)
              ], 2)
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-address'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Адрес проживания*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-address-fields'))
          }, [
            _createVNode(_component_SharedCountry, {
              class: _normalizeClass(_ctx.b('form-address-country')),
              placeholder: "Cтрана",
              value: _ctx.kybParticipant.address.country,
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybParticipant.address.country) = $event))
            }, null, 8, ["class", "value"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-address-city')),
              placeholder: "Город",
              view: "light",
              modelValue: _ctx.kybParticipant.address.city,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybParticipant.address.city) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-address-state')),
              placeholder: "Штат / Область",
              view: "light",
              modelValue: _ctx.kybParticipant.address.state,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybParticipant.address.state) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-address-street')),
              placeholder: "Улица",
              view: "light",
              modelValue: _ctx.kybParticipant.address.street,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybParticipant.address.street) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-address-office')),
              placeholder: "Номер Офиса",
              view: "light",
              modelValue: _ctx.kybParticipant.address.office,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybParticipant.address.office) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-address-index')),
              placeholder: "Индекс",
              view: "light",
              modelValue: _ctx.kybParticipant.address.zip,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybParticipant.address.zip) = $event))
            }, null, 8, ["class", "modelValue"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-fields'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Какой долей он владеет в обществе?*")), 3),
            _createVNode(_component_SharedInputDouble, {
              view: "light",
              modelValue: _ctx.kybParticipant.share_in_org,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kybParticipant.share_in_org) = $event))
            }, null, 8, ["modelValue"])
          ], 2)
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}