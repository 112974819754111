<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Создание заявки") }}
    </h1>

    <div :class="b('wrapper')">
      <component
        :is="displayedComponent"
        v-model:from="application.from"
        v-model:to="application.to"
        v-model:amount="application.amount"
        v-model:coin="application.crypto.coin"
        v-model:network="application.crypto.network"
        v-model:address="application.crypto.address"
        @next="handleNext"
        @confirm="handleConfirm"
        @back="handleBack"
      />
    </div>

    <transition name="opacity">
      <ApplicationConfirm
        v-if="isConfirm"
        :class="b('modal')"
        @close="handleCloseConfirm"
      />
    </transition>
  </div>
</template>
