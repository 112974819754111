import { Modal, ModalActionLabel } from "@/shared/constants/constants";

export const ModalFactory = (params: Partial<Modal> = {}): Modal => {
  return {
    title: params.title ? params.title : "",
    label: params.label ? params.label : "",
    icon: params.icon ? params.icon : "",
    actionLabel: params.actionLabel ? params.actionLabel : ModalActionLabel.ok,
    callback: params.callback ? params.callback : () => {},
  };
};
