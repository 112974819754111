import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminToolbar, AdminContent } from "@/components/admin";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminToolbar,
    AdminContent,
  },
});
