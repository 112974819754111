import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton, UILink } from "@/components/ui";
import {
  SharedInputPassword,
  SharedCheckbox,
  SharedOr,
  SharedGoogle,
  SharedError,
} from "@/components/shared";

import {
  validationEmail,
  validationPassword,
} from "@/shared/utils/validation-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    UILink,
    SharedInputPassword,
    SharedCheckbox,
    SharedOr,
    SharedGoogle,
    SharedError,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    isRemember: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:email": null,
    "update:password": null,
    "update:isRemember": null,
    login: null,
    google: null,
  },
  computed: {
    isLoginDisabled(): boolean {
      return (
        !validationEmail(this.email) ||
        !validationPassword(this.password, this.password).isValidation ||
        this.loading
      );
    },

    isPasswordError(): boolean {
      return !!this.message;
    },
  },
  methods: {
    changeEmail(email: string): void {
      this.$emit("update:email", email);
    },

    changePassword(password: string): void {
      this.$emit("update:password", password);
    },

    changeIsRemember(isRemember: boolean): void {
      this.$emit("update:isRemember", isRemember);
    },

    handleLogin(): void {
      this.$emit("login");
    },

    handleGoogle(): void {
      this.$emit("google");
    },
  },
});
