import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect, SharedInputDouble } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    SharedInputDouble,
    UIInput,
    UIButton,
  },
  computed: {
    isSaveDisabled(): boolean {
      return true;
    },
  },
  methods: {
    handleSave(): void {
      console.log("Handle Save");
    },
  },
});
