import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { SharedSelect } from "@/components/shared";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedSelect,
  },
});
