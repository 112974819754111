import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  emits: {
    back: null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("back");
    },
  },
});
