import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Персональная информация")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Фамилия*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kycPersonalInfo.sur_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kycPersonalInfo.sur_name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Имя*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kycPersonalInfo.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kycPersonalInfo.name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Отчество")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kycPersonalInfo.patronymic,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kycPersonalInfo.patronymic) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Укажите ваш пол*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-radios'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                "checked-value": "male",
                value: _ctx.kycPersonalInfo.gender,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kycPersonalInfo.gender) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('form-radio-label'))
              }, _toDisplayString(_ctx.$t("Мужчина")), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                "checked-value": "female",
                value: _ctx.kycPersonalInfo.gender,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kycPersonalInfo.gender) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('form-radio-label'))
              }, _toDisplayString(_ctx.$t("Женщина")), 3)
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Дата рождения*")), 3),
          _createVNode(_component_SharedDate, {
            date: _ctx.kycPersonalInfo.birth_date,
            "onUpdate:date": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kycPersonalInfo.birth_date) = $event)),
            format: "yyyy-MM-dd"
          }, null, 8, ["date"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Гражданство*")), 3),
          _createVNode(_component_SharedCountry, {
            placeholder: "Выберите страну",
            value: _ctx.kycPersonalInfo.citizenship,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kycPersonalInfo.citizenship) = $event))
          }, null, 8, ["value"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Серия и номер паспорта*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            maska: "##########",
            modelValue: _ctx.kycPersonalInfo.passport,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kycPersonalInfo.passport) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Дата выдачи паспорта*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            maska: "####-##-##",
            modelValue: _ctx.kycPersonalInfo.passport_date,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kycPersonalInfo.passport_date) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Наименование органа выдавшего документ*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kycPersonalInfo.passport_issued_org,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kycPersonalInfo.passport_issued_org) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form', { address: true }))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Адрес организации*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-block', { address: true }))
          }, [
            _createVNode(_component_SharedCountry, {
              class: _normalizeClass(_ctx.b('form-country')),
              placeholder: "Cтрана",
              value: _ctx.kycPersonalInfo.address.country,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kycPersonalInfo.address.country) = $event))
            }, null, 8, ["class", "value"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-city')),
              placeholder: "Город",
              view: "light",
              modelValue: _ctx.kycPersonalInfo.address.city,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kycPersonalInfo.address.city) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-state')),
              placeholder: "Штат / Область",
              view: "light",
              modelValue: _ctx.kycPersonalInfo.address.state,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kycPersonalInfo.address.state) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-street')),
              placeholder: "Улица",
              view: "light",
              modelValue: _ctx.kycPersonalInfo.address.street,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kycPersonalInfo.address.street) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-office')),
              placeholder: "Номер Офиса",
              view: "light",
              modelValue: _ctx.kycPersonalInfo.address.office,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kycPersonalInfo.address.office) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-index')),
              placeholder: "Индекс",
              view: "light",
              modelValue: _ctx.kycPersonalInfo.address.zip,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kycPersonalInfo.address.zip) = $event))
            }, null, 8, ["class", "modelValue"])
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form', { last: true }))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Номер телефона*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kycPersonalInfo.phone,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.kycPersonalInfo.phone) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Телеграм для связи*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-telegram": true,
            modelValue: _ctx.kycPersonalInfo.telegram,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.kycPersonalInfo.telegram) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}