<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Фильтр" @close="handleClose">
    <div :class="b('wrapper')">
      <div :class="b('form')">
        <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Валюта") }}
          </p>

          <SharedMultiSelect
            :placeholder="$t('Все')"
            :options="displayedCurrencyOptions"
            :values="currency"
            @update:values="changeCurrency"
          />
        </div>

        <div :class="b('block')">
          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("Объем от") }}
            </p>

            <SharedCurrency
              label="USD"
              :value="volumeFrom"
              @update:value="changeVolumeFrom"
            />
          </div>

          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("до") }}
            </p>

            <SharedCurrency
              label="USD"
              :value="volumeTo"
              @update:value="changeVolumeTo"
            />
          </div>
        </div>

        <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Дата/Период") }}
          </p>

          <SharedDate
            placeholder="За все время"
            :range="true"
            :multi-calendars="true"
            @update:date="changeDate"
          />
        </div>

        <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Статус") }}
          </p>

          <SharedMultiSelect
            :placeholder="$t('Все')"
            :options="displayedStatusOptions"
            :values="status"
            @update:values="changeStatus"
          />
        </div>

        <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Тип") }}
          </p>

          <SharedMultiSelect
            :placeholder="$t('Все')"
            :options="displayedTypeOptions"
            :values="type"
            @update:values="changeType"
          />
        </div>
      </div>

      <UIButton label="Применить фильтр" view="main" @click="handleApply" />
    </div>
  </SharedWindow>
</template>
