<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedVerification
      title="Email Verification"
      :code="code"
      @update:code="changeCode"
    />

    <UIButton
      label="Create Account"
      view="main"
      :disabled="isVerificationDisabled"
      @click="handleVerification"
    />
  </div>
</template>
