import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import {
  LoginPage,
  RegistrationPage,
  ForgotPage,
  ApplicationPage,
  HistoryPage,
  SettingsPage,
  VerificationPage,
  RequisitesPage,
  AdminUsersPage,
  AdminCommissionPage,
  AdminKycPage,
  AdminApplicationPage,
  WalletPage,
} from "@/views";

import { Layout, RoutesName, RoutesTo } from "@/shared/constants/constants";
import { $axios } from "@/shared/repository/initialize-axios";
import { UserResponse } from "@/shared/repository/repo";
import { ApiRoutes } from "@/shared/repository/url-generator";

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutesTo.login,
    name: RoutesName.login,
    component: LoginPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.registration,
    name: RoutesName.registration,
    component: RegistrationPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.forgot,
    name: RoutesName.forgot,
    component: ForgotPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.wallet,
    name: RoutesName.wallet,
    component: WalletPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.application,
    name: RoutesName.application,
    component: ApplicationPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.history,
    name: RoutesName.history,
    component: HistoryPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.settings,
    name: RoutesName.settings,
    component: SettingsPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.verification,
    name: RoutesName.verification,
    component: VerificationPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.requisites,
    name: RoutesName.requisites,
    component: RequisitesPage,
    meta: {
      layout: Layout.default,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.adminUsers,
    name: RoutesName.adminUsers,
    component: AdminUsersPage,
    meta: {
      layout: Layout.admin,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.adminKyc,
    name: RoutesName.adminKyc,
    component: AdminKycPage,
    meta: {
      layout: Layout.admin,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.adminApplication,
    name: RoutesName.adminApplication,
    component: AdminApplicationPage,
    meta: {
      layout: Layout.admin,
      requiresAuth: true,
    },
  },
  {
    path: RoutesTo.adminCommission,
    name: RoutesName.adminCommission,
    component: AdminCommissionPage,
    meta: {
      layout: Layout.admin,
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  try {
    await $axios.get<UserResponse>(ApiRoutes.user).then(({ data }) => {
      if (data.email_verified_at) {
        if (requiresAuth) {
          next();
        } else {
          next({ name: RoutesName.wallet });
        }
      } else {
        if (requiresAuth) {
          next();
        } else {
          next();
        }
      }
    });
  } catch (e) {
    if (requiresAuth) {
      next({ name: RoutesName.login });
    } else {
      next();
    }
  }
});

export default router;
