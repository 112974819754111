import { AdminKycComponent } from "@/views/admin-kyc/model";

export interface AdminKycKybCompanyCard {
  id: number;
  title: AdminKycKybCompanyCardTitle;
  label: AdminKycKybCompanyCardLabel;
  component: AdminKycComponent;
}

export enum AdminKycKybCompanyCardTitle {
  basic = "Основная информация",
  participants = "Участники организации",
  documents = "Загрузка документов",
}

export enum AdminKycKybCompanyCardLabel {
  basic = "Информация о счете, адрес компании и декларация о происхождении средств",
  participants = "Основная информация, идентификационные данные и страна проживания связанных сторон",
  documents = "Предоставьте необходимые документы для подтверждения вашей компании",
}
