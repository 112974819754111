import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthHeader = _resolveComponent("AuthHeader")!
  const _component_AuthIntroduction = _resolveComponent("AuthIntroduction")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('container'))
    }, [
      _createVNode(_component_AuthHeader),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _createVNode(_component_AuthIntroduction, {
          class: _normalizeClass(_ctx.b('introduction'))
        }, null, 8, ["class"]),
        _createVNode(_component_router_view)
      ], 2)
    ], 2)
  ], 2))
}