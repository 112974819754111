import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  KybCompanyItem,
  KybCompanyItemLabel,
  KybCompanyItemTitle,
} from "./model";

import { UIButton } from "@/components/ui";
import { SharedCard } from "@/components/shared";

import { VerificationKybComponent } from "@/views/verification/model";
import { PromiseStatus } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCard,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isBasicInformation: false,
      isParticipants: false,
      isDocuments: false,
      loading: false,
    };
  },
  async created(): Promise<void> {
    await Promise.allSettled([
      this.$projectServices.projectRepository.getKybBasicInfo(
        this.displayedUserId
      ),
      this.$projectServices.projectRepository.getKybRegistrationInfo(
        this.displayedUserId
      ),
      this.$projectServices.projectRepository.getParticipants(
        this.displayedUserId
      ),
      this.$projectServices.projectRepository.getKybUserFiles(
        this.displayedUserId
      ),
    ]).then(
      ([kybBasiInfo, kybRegistrationInfo, kybParticipants, kybUserFiles]) => {
        if (
          kybBasiInfo.status === PromiseStatus.fulfilled &&
          kybRegistrationInfo.status === PromiseStatus.fulfilled
        ) {
          this.isBasicInformation = true;
        }

        if (kybParticipants.status === PromiseStatus.fulfilled) {
          this.isParticipants = !!kybParticipants.value.length;
        }

        if (kybUserFiles.status === PromiseStatus.fulfilled) {
          this.isDocuments = !!kybUserFiles.value.length;
        }
      }
    );
  },
  computed: {
    displayedList(): KybCompanyItem[] {
      return [
        {
          id: 0,
          title: KybCompanyItemTitle.basicInformation,
          label: KybCompanyItemLabel.basicInformation,
          icon: this.isBasicInformation ? SVG.check : SVG.time,
          isPadding: false,
          component: VerificationKybComponent.kybBasicInformation,
        },
        {
          id: 1,
          title: KybCompanyItemTitle.particpants,
          label: KybCompanyItemLabel.particpants,
          icon: this.isParticipants ? SVG.check : SVG.time,
          isPadding: true,
          component: VerificationKybComponent.kybParticipants,
        },
        {
          id: 2,
          title: KybCompanyItemTitle.documents,
          label: KybCompanyItemLabel.documents,
          icon: this.isDocuments ? SVG.check : SVG.time,
          isPadding: false,
          component: VerificationKybComponent.kybDocuments,
        },
      ];
    },

    displayedChevron(): string {
      return SVG.chevron;
    },

    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },

    isVerificationDisabled(): boolean {
      return (
        !this.isBasicInformation ||
        !this.isParticipants ||
        !this.isDocuments ||
        this.loading
      );
    },
  },
  methods: {
    handleContinue({ component }: KybCompanyItem): void {
      this.$emit("update:component", component);
    },

    async handleVerification(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository.kybVerification();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
