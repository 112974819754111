import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedLanguage } from "@/components/shared";

import { RoutesName, SvgAttribute } from "@/shared/constants/constants";
import { UserInfoResponse } from "@/shared/repository/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedUserInfo(): UserInfoResponse {
      return this.$projectServices.userRepo.userInfo;
    },

    isLogoutDisabled(): boolean {
      return this.loading;
    },
  },
  methods: {
    async handleLogout(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository.logout();

        this.$router.push({ name: RoutesName.login });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
