import { App } from "vue";
import { getModule } from "vuex-module-decorators";

import { HttpRepo } from "./http-repo";
import { ProjectServices } from "./repo";
import { UrlGenerator } from "./url-generator";

import store from "@/store";

import ProfileModuleState from "@/store/modules/profile";
import LanguageModuleState from "@/store/modules/language";
import NotificationModuleState from "@/store/modules/notification";
import ModalModuleState from "@/store/modules/modal";
import UserModuleState from "@/store/modules/user";

export default function createProjectServices(context: App): ProjectServices {
  const { $axios } = context.config.globalProperties;
  const $store = store();

  const projectUrlGenerator = new UrlGenerator();

  const projectRepository = new HttpRepo($axios, projectUrlGenerator);

  const profileRepo = getModule(ProfileModuleState, $store);
  const languageRepo = getModule(LanguageModuleState, $store);
  const notificationRepo = getModule(NotificationModuleState, $store);
  const modalRepo = getModule(ModalModuleState, $store);
  const userRepo = getModule(UserModuleState, $store);

  return {
    projectUrlGenerator,
    projectRepository,
    profileRepo,
    languageRepo,
    notificationRepo,
    modalRepo,
    userRepo,
  };
}
