import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('header-title'))
      }, _toDisplayString(_ctx.$t(_ctx.title)), 3),
      _createVNode(_component_router_link, {
        class: _normalizeClass(_ctx.b('header-link')),
        to: "/"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('header-link-label', { big: true }))
          }, _toDisplayString(_ctx.$t("Смотреть все")), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('header-link-label', { small: true }))
          }, _toDisplayString(_ctx.$t("Все")), 3)
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}