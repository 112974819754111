<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Детали операции" @close="handleClose">
    <template #default>
      <div :class="b('wrapper')">
        <div :class="b('list')">
          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Дата и время") }}
            </h4>

            <p :class="b('item-label')">07.05.2024 15:02:10</p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">№ {{ $t("сделки") }}</h4>

            <p :class="b('item-label')">24356</p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Тип") }}
            </h4>

            <p :class="b('item-label')">
              {{ $t("Пополнение") }}
            </p>
          </div>

          <div :class="b('item-operation')">
            <h4 :class="b('item-title')">
              {{ $t("Операция") }}
            </h4>

            <div :class="b('item-group')">
              <span
                :class="b('item-icon')"
                v-html="displayedChevronIcon"
              ></span>

              <div :class="b('item-info')">
                <p :class="b('item-label')">120,000.99 RUB</p>

                <p :class="b('item-label')">1,000.12345678 USDT</p>
              </div>
            </div>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Цена исполнения") }}
            </h4>

            <p :class="b('item-label')">98.928347 RUB</p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Комиссия") }}
            </h4>

            <p :class="b('item-label')">0.6894 USDT</p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Статус") }}
            </h4>

            <p :class="b('item-label')">
              {{ $t("Выполнено") }}
            </p>
          </div>

          <div :class="b('item-stage')">
            <h4 :class="b('item-title')">
              {{ $t("Этап") }}
            </h4>

            <div :class="b('item-steps')">
              <p :class="b('item-step')">1. {{ $t("Средства Ушли") }}</p>

              <p :class="b('item-step')">2. {{ $t("Выставили ордер") }}</p>

              <p :class="b('item-step', { active: true })">
                3. {{ $t("Средства поступили, но в другом объеме") }}
              </p>
            </div>
          </div>
        </div>

        <UIButton label="Закрыть" view="main" @click="handleClose" />
      </div>
    </template>
  </SharedWindow>
</template>
