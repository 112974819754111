import {
  AdminUserAppStatusRequest,
  ProjectUrlGenerator,
} from "@/shared/repository/repo";

export enum ApiRoutes {
  csfr = "/sanctum/csrf-cookie",
  login = "/api/v1/login",
  registration = "/api/v1/register",
  emailCode = "/api/v1/email-code",
  codeVerification = "/api/v1/code-verification",
  forgot = "/api/v1/forgot-password",
  logout = "/api/v1/logout",

  // User

  user = "/api/v1/user",
  userInfo = "/api/v1/user-info",

  // kyc

  kycPersonalInfo = "/api/v1/kyc",
  kycUploadFile = "/api/v1/kyc/upload/file",
  kycDeleteFile = "/api/v1/kyc/delete/file/",
  kycUserFiles = "/api/v1/kyc/user-files",
  kycVerification = "/api/v1/kyc/verification",

  // Kyb

  kybBasicInfo = "/api/v1/kyb/basic-info",
  kybRegistrationInfo = "/api/v1/kyb/registration-info",
  kybParticipants = "/api/v1/kyb/participants",
  kybParticipant = "/api/v1/kyb/participant",
  kybParticipantById = "/api/v1/kyb/participant/",
  kybParticipantUploadFile = "/api/v1/kyb/participant/upload/file",
  kybParticipantDeleteFile = "/api/v1/kyb/participant-file/delete/",
  kybParticipantFiles = "/api/v1/kyb/participant-files",
  kybUserFiles = "/api/v1/kyb/user-files",
  kybUserFile = "/api/v1/kyb/upload/file",
  kybUserFileDelete = "/api/v1/kyb/delete/file/",
  kybVerification = "/api/v1/kyb/verification",

  // Requisites

  requisiteBank = "/api/v1/requisite/bank/store",
  requisiteBankShow = "/api/v1/requisite/bank/show",
  requisiteCrypto = "/api/v1/requisite/crypto/store",
  requisiteCryptoShow = "/api/v1/requisite/crypto/show",
  requisiteVerification = "/api/v1/requisite/verification",

  // Admin

  adminUsers = "/api/v1/admin/users",
  adminUserApp = "/api/v1/admin/user-app",
  adminUserAppStatus = "/api/v1/admin/user-app/status",
}

export class UrlGenerator implements ProjectUrlGenerator {
  // Login, Registration, Verification, Forgot, Logout

  csfr(): string {
    return ApiRoutes.csfr;
  }

  login(): string {
    return ApiRoutes.login;
  }

  registration(): string {
    return ApiRoutes.registration;
  }

  emailCode(): string {
    return ApiRoutes.emailCode;
  }

  codeVerification(): string {
    return ApiRoutes.codeVerification;
  }

  forgot(): string {
    return ApiRoutes.forgot;
  }

  logout(): string {
    return ApiRoutes.logout;
  }

  // User

  user(): string {
    return ApiRoutes.user;
  }

  userInfo(): string {
    return ApiRoutes.userInfo;
  }

  // Kyc

  kycPersonalInfo(): string {
    return ApiRoutes.kycPersonalInfo;
  }

  kycUploadFile(): string {
    return ApiRoutes.kycUploadFile;
  }

  kycDeleteFile(): string {
    return ApiRoutes.kycDeleteFile;
  }

  kycUserFiles(): string {
    return ApiRoutes.kycUserFiles;
  }

  kycVerification(): string {
    return ApiRoutes.kycVerification;
  }

  // Kyb

  kybBasicInfo(): string {
    return ApiRoutes.kybBasicInfo;
  }

  kybRegistrationInfo(): string {
    return ApiRoutes.kybRegistrationInfo;
  }

  kybParticipants(): string {
    return ApiRoutes.kybParticipants;
  }

  kybParticipant(): string {
    return ApiRoutes.kybParticipant;
  }

  kybParticipantById(id: number): string {
    return `${ApiRoutes.kybParticipantById}${id}?id=${id}`;
  }

  kybParticipantUploadFile(): string {
    return ApiRoutes.kybParticipantUploadFile;
  }

  kybParticipantDeleteFile(): string {
    return ApiRoutes.kybParticipantDeleteFile;
  }

  kybParticipantFiles(): string {
    return ApiRoutes.kybParticipantFiles;
  }

  kybUserFiles(): string {
    return ApiRoutes.kybUserFiles;
  }

  kybUserFile(): string {
    return ApiRoutes.kybUserFile;
  }

  kybUserFileDelete(): string {
    return ApiRoutes.kybUserFileDelete;
  }

  kybVerification(): string {
    return ApiRoutes.kybVerification;
  }

  // Requisites

  requisiteBank(): string {
    return ApiRoutes.requisiteBank;
  }

  requisiteBankShow(): string {
    return ApiRoutes.requisiteBankShow;
  }

  requisiteCrypto(): string {
    return ApiRoutes.requisiteCrypto;
  }

  requisiteCryptoShow(): string {
    return ApiRoutes.requisiteCryptoShow;
  }

  requisiteVerification(): string {
    return ApiRoutes.requisiteVerification;
  }

  // Admin

  adminUsers(): string {
    return ApiRoutes.adminUsers;
  }

  adminUserApp(): string {
    return ApiRoutes.adminUserApp;
  }

  adminUserAppStatus({
    id,
    status,
    message,
  }: AdminUserAppStatusRequest): string {
    return `${ApiRoutes.adminUserAppStatus}?id=${id}&status=${status}&message=${message}`;
  }
}
