<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Верификация информации о компании") }}
    </h2>

    <div :class="b('cards')">
      <button
        :class="b('card')"
        v-for="card in displayedCards"
        :key="card.id"
        @click="handleCard(card)"
      >
        <span :class="b('card-icon')" v-html="displayedIcons.check"></span>

        <div :class="b('card-info')">
          <p :class="b('card-title')">
            {{ $t(card.title) }}
          </p>

          <p :class="b('card-label')">
            {{ $t(card.label) }}
          </p>
        </div>

        <span :class="b('card-icon')" v-html="displayedIcons.chevron"></span>
      </button>
    </div>
  </div>
</template>
