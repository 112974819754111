<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <router-link :class="b('wallet')" to="/">
      <span
        :class="b('icon', { wallet: true })"
        v-html="displayedIcons.wallet"
      ></span>

      <div :class="b('wallet-group')">
        <p :class="b('label')">9,999.12</p>

        <p :class="b('wallet-currency')">USD</p>
      </div>

      <div :class="b('wallet-divider')"></div>

      <div :class="b('wallet-group')">
        <p :class="b('label')">2,456.12345678</p>

        <p :class="b('wallet-currency')">USDT</p>
      </div>
    </router-link>

    <div :class="b('group')">
      <SharedLanguage :class="b('language')" view="light" />

      <div :class="b('profile')">
        <p :class="b('label')">{{ displayedUserInfo.email }}</p>

        <button
          :class="b('profile-logout')"
          :disabled="isLogoutDisabled"
          @click="handleLogout"
        >
          <span :class="b('icon')" v-html="displayedIcons.logout"></span>
        </button>
      </div>
    </div>
  </div>
</template>
