import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Номер расчетного счета")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Номер корреспондентского счета")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Банковский идентификационный код (БИК)")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Полное наименование банка")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Идентиф. номер налогоплательщика (ИНН)")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Код причины постановки на учет (КПП)")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('footer'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('footer-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Валюта")), 3),
          _createVNode(_component_SharedSelect, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Сумма")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        label: "Создать заявку",
        view: "main"
      })
    ], 2)
  ], 2))
}