<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" @close="handleClose">
    <div :class="b('wrapper')">
      <h2 :class="b('title')">
        {{ $t("Изменение статуса") }} <br />
        {{ $t("пользователя") }} {{ userId }}
      </h2>

      <div :class="b('form')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Статус") }}
          </p>

          <SharedSelect
            view="light"
            :options="displayedOptions"
            v-model:value="status.status"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Комментарий для пользователя") }}
          </p>

          <UIInput
            placeholder="Добавьте комментарий при необходимости"
            view="light"
            placeholder-view="light"
            v-model="status.message"
          />
        </div>
      </div>

      <UIButton
        :class="b('update')"
        label="Изменить статус пользователя"
        view="main"
        :disabled="isUpdateDisabled"
        @click="handleChangeStatus"
      />
    </div>
  </SharedWindow>
</template>
