import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-kyc-kyb-company";

export const SVG: SvgAttribute = {
  check: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.9296" cy="10.9503" rx="8.71572" ry="8.71888" fill="white"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M11.0741 0.00897091C15.9572 -0.150176 19.7107 1.80816 22.3348 5.884C24.2607 9.33851 24.5161 12.9146 23.1008 16.6123C21.5123 20.2041 18.8312 22.5456 15.0575 23.6368C10.3345 24.6841 6.30859 23.4665 2.97973 19.9841C0.241875 16.679 -0.609268 12.9326 0.426294 8.74488C1.54242 5.05695 3.86604 2.44299 7.39717 0.902997C8.58671 0.426644 9.81236 0.128638 11.0741 0.00897091ZM16.1299 8.23401C16.8835 8.20473 17.309 8.56235 17.4066 9.30684C17.3933 9.50863 17.3337 9.69597 17.2279 9.8688C15.3298 11.8357 13.3977 13.7684 11.4316 15.6672C11.0474 15.8496 10.6729 15.8326 10.3081 15.6161C9.12498 14.4326 7.94192 13.2491 6.75881 12.0655C6.46164 11.5191 6.53824 11.0337 6.98862 10.6096C7.34176 10.3521 7.7163 10.318 8.11213 10.5074C9.05547 11.3999 9.98318 12.311 10.8954 13.2406C12.47 11.6654 14.0446 10.0902 15.6192 8.51499C15.7861 8.40617 15.9563 8.31253 16.1299 8.23401Z" fill="#51BC40"/>
  </svg>`,
  chevron: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M7.47642 19.8043C7.44867 19.5416 7.48772 19.2916 7.59361 19.0543C9.93801 16.7177 12.2739 14.374 14.6014 12.0231C12.2739 9.67214 9.93801 7.32839 7.59361 4.99182C7.43737 4.6793 7.43737 4.36683 7.59361 4.05432C7.91915 3.74639 8.28633 3.69169 8.69517 3.89025C11.2499 6.44494 13.8045 8.99963 16.3592 11.5543C16.5155 11.8668 16.5155 12.1793 16.3592 12.4918C13.8045 15.0465 11.2499 17.6012 8.69517 20.1559C8.17364 20.4054 7.76737 20.2882 7.47642 19.8043Z" fill="#9E9FA8"/>
  </svg>`,
};
