<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <UIButton
      :class="b('action')"
      label="Продолжить"
      view="main"
      @click="handleNext"
    />
  </div>
</template>
