import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-table";

export const SVG: SvgAttribute = {
  sort: `<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61023 4.38179C6.03012 4.38179 6.26301 3.89553 5.99981 3.56837L3.44516 0.392943C3.24502 0.144165 2.86615 0.144166 2.66601 0.392944L0.111364 3.56837C-0.151837 3.89553 0.0810508 4.38179 0.500942 4.38179H5.61023ZM0.500965 6.61827C0.0810745 6.61827 -0.151812 7.10452 0.111389 7.43168L2.66603 10.6071C2.86618 10.8559 3.24505 10.8559 3.44519 10.6071L5.99984 7.43168C6.26304 7.10452 6.03015 6.61827 5.61026 6.61827H0.500965Z" fill="#222327"/>
  </svg>`,
  created: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.77421 1.64926C9.46433 1.6425 11.1544 1.64926 12.8444 1.66954C12.9437 1.69894 13.0384 1.7395 13.1283 1.79123C15.4741 4.13704 17.82 6.48287 20.1658 8.82867C20.2175 8.91859 20.2581 9.01326 20.2875 9.1126C20.3145 10.8027 20.3145 12.4927 20.2875 14.1828C20.2827 15.4728 19.9852 16.6897 19.3951 17.8333C18.6983 18.9231 17.718 19.633 16.4544 19.9628C15.9213 20.1013 15.3805 20.1959 14.8319 20.2468C13.5481 20.2913 12.2637 20.3116 10.9786 20.3076C9.69351 20.3116 8.40908 20.2913 7.12523 20.2468C6.06284 20.1772 5.06231 19.8933 4.12367 19.395C2.88344 18.5868 2.13305 17.4443 1.8725 15.9675C1.75678 15.3781 1.68918 14.7832 1.6697 14.1828C1.64265 12.0465 1.64265 9.91032 1.6697 7.77406C1.68936 6.70953 1.89216 5.68198 2.27812 4.69138C2.91897 3.28036 3.99385 2.38125 5.50277 1.99404C6.25518 1.80838 7.01235 1.69345 7.77421 1.64926ZM7.73365 2.9878C9.16682 2.9878 10.6 2.9878 12.0332 2.9878C12.0169 4.25961 12.0372 5.53052 12.094 6.80059C12.198 8.43185 13.043 9.42561 14.6291 9.78186C14.9644 9.84522 15.3024 9.88578 15.6432 9.90355C16.7518 9.92383 17.8605 9.9306 18.9692 9.92383C18.976 11.3436 18.9692 12.7632 18.9489 14.1828C18.9508 15.2976 18.6804 16.3387 18.1377 17.306C17.5552 18.0623 16.7913 18.5423 15.846 18.746C15.4971 18.8176 15.1456 18.8717 14.7914 18.9082C12.4394 18.9734 10.0868 18.987 7.73365 18.9488C6.63322 18.9441 5.60563 18.6737 4.65097 18.1375C3.96236 17.6338 3.50942 16.9645 3.29216 16.1298C3.11835 15.4925 3.02371 14.8435 3.00823 14.1828C2.98119 12.0465 2.98119 9.91032 3.00823 7.77406C3.00867 6.74485 3.23851 5.77138 3.69778 4.85363C4.19823 4.05681 4.90805 3.53626 5.82726 3.29201C6.45876 3.12813 7.09424 3.02672 7.73365 2.9878ZM13.3717 3.96127C14.9184 5.48769 16.4597 7.02225 17.9957 8.56501C17.0619 8.59986 16.129 8.58635 15.197 8.52445C14.227 8.46008 13.6524 7.95306 13.4731 7.00339C13.4327 6.76161 13.4057 6.51824 13.392 6.27328C13.3717 5.50274 13.3649 4.73207 13.3717 3.96127Z" fill="#9E9FA8"/>
    <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M9.92374 11.1813C10.123 11.1636 10.3122 11.1974 10.4916 11.2827C11.1338 11.9249 11.7761 12.5672 12.4183 13.2094C12.5613 13.4739 12.5478 13.7307 12.3777 13.9801C11.749 14.6088 11.1203 15.2375 10.4916 15.8662C9.90861 16.1021 9.55711 15.9128 9.437 15.2983C9.45181 15.1457 9.49914 15.0037 9.57897 14.8724C9.78855 14.6629 9.99809 14.4533 10.2077 14.2437C8.95026 14.2302 7.69286 14.2167 6.43545 14.2032C5.99393 13.9888 5.87902 13.6575 6.09067 13.2094C6.16226 13.0888 6.26367 13.0009 6.39488 12.9458C7.6658 12.9323 8.93676 12.9187 10.2077 12.9052C9.99809 12.6956 9.78855 12.4861 9.57897 12.2765C9.33284 11.7914 9.44779 11.4263 9.92374 11.1813Z" fill="#9E9FA8"/>
  </svg>`,
  expectation: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.84685 1.85758C9.93584 1.85097 12.0248 1.85758 14.1137 1.87741C15.375 1.88205 16.5649 2.17292 17.6834 2.75001C18.7491 3.43143 19.4432 4.38996 19.7657 5.62561C19.901 6.14683 19.9936 6.67566 20.0434 7.21215C20.0869 8.46757 20.1068 9.72356 20.1029 10.9802C20.1068 12.2368 20.0869 13.4928 20.0434 14.7482C19.9754 15.7869 19.6978 16.7653 19.2104 17.6833C18.529 18.749 17.5705 19.4431 16.3348 19.7656C15.8135 19.901 15.2847 19.9935 14.7483 20.0433C13.4929 20.0869 12.2369 20.1067 10.9803 20.1028C9.72364 20.1067 8.46766 20.0869 7.21223 20.0433C6.17337 19.9753 5.19499 19.6976 4.27714 19.2103C3.06436 18.4201 2.33059 17.3029 2.07582 15.8588C1.96265 15.2824 1.89655 14.7007 1.8775 14.1136C1.85106 12.0246 1.85106 9.93572 1.8775 7.84676C1.89672 6.8058 2.09504 5.801 2.47245 4.83234C3.09911 3.45256 4.15019 2.57336 5.62569 2.19472C6.36157 2.01315 7.10193 1.90078 7.84685 1.85758ZM7.80718 3.16648C9.92259 3.15986 12.038 3.16648 14.1533 3.18631C15.1468 3.18927 16.0855 3.41403 16.9694 3.86059C17.7486 4.34996 18.2576 5.04406 18.4965 5.94292C18.6143 6.37215 18.6936 6.80845 18.7345 7.25181C18.798 9.53849 18.8113 11.8258 18.7741 14.1136C18.7569 14.9307 18.6115 15.724 18.3378 16.4934C17.8851 17.5275 17.1117 18.1952 16.0175 18.4964C15.5885 18.6142 15.1522 18.6935 14.7086 18.7344C12.4087 18.7982 10.1083 18.8114 7.80718 18.774C7.00368 18.7525 6.22362 18.6071 5.46704 18.3377C4.43296 17.8851 3.76529 17.1116 3.46404 16.0174C3.29407 15.3943 3.20153 14.7597 3.18639 14.1136C3.15995 12.0246 3.15995 9.93572 3.18639 7.84676C3.18456 6.75665 3.44898 5.73861 3.97966 4.79268C4.47229 4.11931 5.12673 3.6764 5.943 3.46395C6.56052 3.3037 7.18193 3.20454 7.80718 3.16648Z" fill="#A1A2AB"/>
    <path d="M10.1539 5.79584C10.1539 5.39878 10.4583 5.0769 10.8338 5.0769C11.2093 5.0769 11.5137 5.39878 11.5137 5.79584V10.9556C11.5137 11.3526 11.2093 11.6745 10.8338 11.6745C10.4583 11.6745 10.1539 11.3526 10.1539 10.9556V5.79584Z" fill="#A1A2AB"/>
    <path d="M14.8907 13.0429C15.2159 13.2415 15.3274 13.6811 15.1396 14.025C14.9518 14.3689 14.536 14.4867 14.2108 14.2882L10.7427 11.8414C10.1536 11.4231 10.1594 11.0513 10.1536 10.5769C10.3413 10.233 11.0974 10.3976 11.4226 10.5961L14.8907 13.0429Z" fill="#A1A2AB"/>
  </svg>`,
  waitingForConfirmation: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M6.93935 2.04102C9.63212 2.03385 12.3248 2.04102 15.0175 2.0625C15.8338 2.0809 16.6358 2.19548 17.4237 2.40625C18.9703 2.95029 19.9945 4.00303 20.496 5.56445C20.6283 6.04313 20.7142 6.53009 20.7538 7.02539C20.8965 9.66084 20.8965 12.2962 20.7538 14.9316C20.659 16.9785 19.7065 18.4609 17.8964 19.3789C16.9671 19.7128 16.0075 19.8847 15.0175 19.8945C13.2844 19.9232 11.5513 19.9232 9.81826 19.8945C9.36322 19.661 9.24144 19.31 9.45302 18.8418C9.55129 18.7145 9.67302 18.6214 9.81826 18.5625C11.5514 18.5402 13.2845 18.5115 15.0175 18.4766C17.8294 18.5147 19.2832 17.1326 19.3788 14.3301C19.4435 12.4829 19.4579 10.6352 19.4218 8.78711C19.4214 8.00933 19.3641 7.2359 19.2499 6.4668C18.2115 7.50522 17.1731 8.54361 16.1347 9.58203C15.4869 10.2013 14.8137 10.7886 14.1151 11.3438C12.024 12.9193 9.93285 12.9193 7.84169 11.3438C7.14311 10.7886 6.46992 10.2013 5.82216 9.58203C4.78374 8.54361 3.74534 7.50522 2.70693 6.4668C2.6463 6.85158 2.60333 7.2383 2.57802 7.62695C2.53455 8.81538 2.5059 10.0042 2.49208 11.1934C2.32091 11.6119 2.01296 11.7623 1.56826 11.6445C1.34235 11.5476 1.19196 11.3829 1.11708 11.1504C1.08028 9.77393 1.10892 8.39893 1.20302 7.02539C1.29786 4.97857 2.25033 3.49617 4.06044 2.57813C4.99772 2.25014 5.95734 2.0711 6.93935 2.04102ZM9.04482 3.41602C11.036 3.41013 13.0269 3.43161 15.0175 3.48047C15.7497 3.49913 16.4659 3.61371 17.1659 3.82422C17.7821 4.08237 18.2834 4.4834 18.6698 5.02734C17.1635 6.56236 15.6309 8.06627 14.0722 9.53906C13.48 10.0573 12.8354 10.5013 12.1386 10.8711C11.2237 11.2307 10.3357 11.1734 9.47451 10.6992C8.92047 10.3457 8.39053 9.959 7.88466 9.53906C6.32588 8.06627 4.79336 6.56236 3.28701 5.02734C3.72725 4.3988 4.31447 3.96912 5.04872 3.73828C5.47235 3.6307 5.90204 3.55909 6.33779 3.52344C7.24671 3.48149 8.14905 3.44568 9.04482 3.41602Z" fill="#FCCA13"/>
    <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M0.666098 13.9434C1.72609 13.9362 2.78599 13.9434 3.84579 13.9648C4.22502 14.1143 4.38973 14.3935 4.33993 14.8027C4.27547 15.0963 4.09644 15.2754 3.80282 15.3399C2.78589 15.3685 1.76896 15.3685 0.752036 15.3399C0.216588 15.1378 0.066197 14.7725 0.300864 14.2441C0.405758 14.1172 0.527503 14.017 0.666098 13.9434Z" fill="#FCCA13"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M0.666098 16.6934C2.64271 16.6862 4.61927 16.6934 6.59579 16.7148C6.92291 16.8238 7.09478 17.053 7.11141 17.4023C7.07725 17.7581 6.89107 17.9872 6.55282 18.0898C4.61922 18.1185 2.68563 18.1185 0.752036 18.0898C0.216588 17.8878 0.066197 17.5225 0.300864 16.9941C0.405758 16.8672 0.527503 16.767 0.666098 16.6934Z" fill="#FCCA13"/>
  </svg>`,
  error: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M5.77928 2.04102C9.24544 2.03385 12.7116 2.04102 16.1777 2.0625C17.7753 2.32823 18.9139 3.19477 19.5937 4.66211C19.7377 5.02253 19.838 5.39494 19.8945 5.7793C19.9232 9.24546 19.9232 12.7116 19.8945 16.1777C19.4863 18.2474 18.2474 19.4863 16.1777 19.8945C12.7116 19.9232 9.24544 19.9232 5.77928 19.8945C4.18126 19.6282 3.04258 18.7617 2.36327 17.2949C2.21908 16.934 2.11882 16.5617 2.06248 16.1777C2.02023 12.6243 2.03455 9.07225 2.10545 5.52149C2.60142 3.58593 3.82602 2.42578 5.77928 2.04102ZM6.33787 3.41602C9.43167 3.40885 12.5254 3.41602 15.6191 3.4375C16.9351 3.52583 17.8446 4.17752 18.3476 5.39258C18.4547 5.71317 18.512 6.04261 18.5195 6.38086C18.5482 9.44595 18.5482 12.5111 18.5195 15.5762C18.4087 17.0375 17.6567 17.99 16.2637 18.4336C16.0366 18.4777 15.8074 18.5063 15.5762 18.5195C12.5111 18.5482 9.44593 18.5482 6.38084 18.5195C4.99016 18.4318 4.05201 17.7372 3.56639 16.4355C3.49003 16.1542 3.44706 15.8678 3.43748 15.5762C3.39599 12.3527 3.41032 9.13009 3.48045 5.9082C3.84196 4.39338 4.79444 3.56264 6.33787 3.41602Z" fill="#F86E6E"/>
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M7.84173 7.36913C8.06847 7.34468 8.28332 7.38052 8.48626 7.47655C9.3104 8.30787 10.1412 9.13145 10.9784 9.94726C11.8157 9.13145 12.6465 8.30787 13.4706 7.47655C13.8179 7.30885 14.1402 7.34468 14.4374 7.58398C14.6176 7.87603 14.6319 8.17682 14.4804 8.48632C13.6491 9.31046 12.8255 10.1412 12.0097 10.9785C12.8255 11.8158 13.6491 12.6466 14.4804 13.4707C14.6481 13.818 14.6123 14.1403 14.373 14.4375C14.0809 14.6177 13.7801 14.632 13.4706 14.4805C12.6465 13.6491 11.8157 12.8256 10.9784 12.0098C10.1412 12.8256 9.3104 13.6491 8.48626 14.4805C8.13894 14.6482 7.81668 14.6123 7.51946 14.373C7.33929 14.081 7.32498 13.7802 7.47649 13.4707C8.30781 12.6466 9.13139 11.8158 9.9472 10.9785C9.13139 10.1412 8.30781 9.31046 7.47649 8.48632C7.24437 7.98668 7.36615 7.61427 7.84173 7.36913Z" fill="#F86E6E"/>
  </svg>`,
  atWork: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M6.42383 2.25586C6.9844 2.29221 7.51433 2.4426 8.01367 2.70703C11.1934 4.49741 14.373 6.28775 17.5527 8.07812C18.9672 9.24748 19.3611 10.7013 18.7344 12.4395C18.4957 12.9788 18.1448 13.4299 17.6816 13.793C14.5233 15.5942 11.3579 17.3846 8.18555 19.1641C6.52369 20.0386 5.04127 19.8023 3.73828 18.4551C3.31628 17.9259 3.05847 17.3244 2.96484 16.6504C2.9362 12.8691 2.9362 9.08789 2.96484 5.30664C3.3503 3.40967 4.5033 2.39274 6.42383 2.25586ZM5.99414 3.7168C6.55471 3.64926 7.07034 3.77101 7.54102 4.08203C10.5775 5.78647 13.6139 7.49087 16.6504 9.19531C17.4438 9.78476 17.7374 10.5654 17.5313 11.5371C17.3727 12.111 17.0361 12.5479 16.5215 12.8477C13.333 14.6352 10.139 16.4112 6.93945 18.1758C6.00527 18.3896 5.25332 18.1103 4.68359 17.3379C4.53226 17.0844 4.43201 16.8123 4.38281 16.5215C4.35415 12.8262 4.35415 9.13086 4.38281 5.43555C4.57583 4.53372 5.11294 3.96079 5.99414 3.7168Z" fill="#36A1EA"/>
  </svg>`,
  done: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.84685 1.85758C9.93584 1.85097 12.0248 1.85758 14.1137 1.87741C15.375 1.88205 16.5649 2.17292 17.6834 2.75001C18.7491 3.43143 19.4432 4.38996 19.7657 5.62561C19.901 6.14683 19.9936 6.67566 20.0434 7.21215C20.0869 8.46757 20.1068 9.72356 20.1029 10.9802C20.1068 12.2368 20.0869 13.4928 20.0434 14.7482C19.9754 15.7869 19.6978 16.7653 19.2104 17.6833C18.529 18.749 17.5705 19.4431 16.3348 19.7656C15.8135 19.901 15.2847 19.9935 14.7483 20.0433C13.4929 20.0869 12.2369 20.1067 10.9803 20.1028C9.72364 20.1067 8.46766 20.0869 7.21223 20.0433C6.17337 19.9753 5.19499 19.6976 4.27714 19.2103C3.06436 18.4201 2.33059 17.3029 2.07582 15.8588C1.96265 15.2824 1.89655 14.7007 1.8775 14.1136C1.85106 12.0246 1.85106 9.93572 1.8775 7.84676C1.89672 6.8058 2.09504 5.801 2.47245 4.83234C3.09911 3.45256 4.15019 2.57336 5.62569 2.19472C6.36157 2.01315 7.10193 1.90078 7.84685 1.85758ZM7.80718 3.16648C9.92259 3.15986 12.038 3.16648 14.1533 3.18631C15.1468 3.18927 16.0855 3.41403 16.9694 3.86059C17.7486 4.34996 18.2576 5.04406 18.4965 5.94292C18.6143 6.37215 18.6936 6.80845 18.7345 7.25181C18.798 9.53849 18.8113 11.8258 18.7741 14.1136C18.7569 14.9307 18.6115 15.724 18.3378 16.4934C17.8851 17.5275 17.1117 18.1952 16.0175 18.4964C15.5885 18.6142 15.1522 18.6935 14.7086 18.7344C12.4087 18.7982 10.1083 18.8114 7.80718 18.774C7.00368 18.7525 6.22362 18.6071 5.46704 18.3377C4.43296 17.8851 3.76529 17.1116 3.46404 16.0174C3.29407 15.3943 3.20153 14.7597 3.18639 14.1136C3.15995 12.0246 3.15995 9.93572 3.18639 7.84676C3.18456 6.75665 3.44898 5.73861 3.97966 4.79268C4.47229 4.11931 5.12673 3.6764 5.943 3.46395C6.56052 3.3037 7.18193 3.20454 7.80718 3.16648Z" fill="#73D264"/>
    <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M14.3913 7.92609C15.1074 7.93481 15.3519 8.27858 15.125 8.95734C13.4515 10.6441 11.7658 12.3166 10.0679 13.9748C9.81706 14.0674 9.57908 14.0409 9.35399 13.8954C8.52768 13.0691 7.70134 12.2428 6.87503 11.4165C6.67564 11.0906 6.69547 10.7799 6.93452 10.4844C7.20411 10.3181 7.48176 10.3049 7.76745 10.4447C8.43511 11.1124 9.1028 11.7801 9.77046 12.4477C11.2314 10.9868 12.6923 9.52583 14.1533 8.06491C14.2299 8.00704 14.3092 7.96079 14.3913 7.92609Z" fill="#73D264"/>
  </svg>`,
  remove: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.58399 1.0957C9.84706 1.08854 12.1101 1.0957 14.373 1.11719C15.7395 1.12222 17.0286 1.43732 18.2402 2.0625C19.3947 2.80071 20.1467 3.83912 20.4961 5.17773C20.6427 5.74239 20.743 6.31529 20.7969 6.89648C20.8441 8.25653 20.8656 9.61718 20.8613 10.9785C20.8656 12.3399 20.8441 13.7005 20.7969 15.0605C20.7233 16.1859 20.4225 17.2458 19.8945 18.2402C19.1563 19.3947 18.1179 20.1467 16.7793 20.4961C16.2146 20.6427 15.6417 20.743 15.0605 20.7969C13.7005 20.8441 12.3399 20.8656 10.9785 20.8613C9.61718 20.8656 8.25653 20.8441 6.89649 20.7969C5.77105 20.7232 4.71114 20.4224 3.7168 19.8945C2.40296 19.0384 1.60804 17.8281 1.33203 16.2637C1.20944 15.6393 1.13783 15.0091 1.11719 14.373C1.08854 12.11 1.08854 9.84702 1.11719 7.58398C1.13802 6.45627 1.35286 5.36774 1.76172 4.31836C2.4406 2.8236 3.57927 1.87113 5.17774 1.46094C5.97493 1.26424 6.77699 1.1425 7.58399 1.0957ZM7.54102 2.51367C9.83271 2.50651 12.1244 2.51367 14.416 2.53516C15.4923 2.53837 16.5092 2.78186 17.4668 3.26563C18.3109 3.79578 18.8623 4.54773 19.1211 5.52148C19.2487 5.98649 19.3347 6.45915 19.3789 6.93945C19.4478 9.41669 19.4621 11.8946 19.4219 14.373C19.4032 15.2583 19.2457 16.1177 18.9492 16.9512C18.4588 18.0714 17.6209 18.7947 16.4355 19.1211C15.9708 19.2487 15.4981 19.3346 15.0176 19.3789C12.526 19.448 10.0338 19.4624 7.54102 19.4219C6.67056 19.3985 5.82549 19.241 5.00586 18.9492C3.8856 18.4588 3.1623 17.6209 2.83594 16.4355C2.65181 15.7605 2.55155 15.073 2.53516 14.373C2.50651 12.11 2.50651 9.84702 2.53516 7.58398C2.53317 6.40303 2.81962 5.30015 3.39453 4.27539C3.92821 3.54591 4.63719 3.0661 5.52149 2.83594C6.19047 2.66233 6.86366 2.55491 7.54102 2.51367Z" fill="#F86E6E"/>
    <path opacity="0.971" fill-rule="evenodd" clip-rule="evenodd" d="M9.99032 5.17776C10.7794 5.16268 11.5671 5.18416 12.3536 5.24221C12.9003 5.345 13.2799 5.65295 13.4923 6.16604C13.6034 6.56093 13.6822 6.96195 13.7286 7.36917C14.6911 7.41398 15.6507 7.49279 16.6075 7.6055C17.0188 7.77681 17.1692 8.08473 17.0587 8.52932C16.9269 8.82469 16.6977 8.98221 16.3712 9.00198C16.1772 8.97461 15.9838 8.94595 15.7911 8.91604C15.6495 11.0524 15.4848 13.1865 15.297 15.3184C15.1474 15.9263 14.7822 16.3489 14.2013 16.586C13.9494 16.6704 13.6916 16.7277 13.4278 16.7578C11.795 16.7865 10.1622 16.7865 8.52938 16.7578C7.57509 16.7224 6.95204 16.2569 6.66024 15.3614C6.58453 15.0218 6.54156 14.678 6.53133 14.3301C6.42374 12.5225 6.29483 10.7178 6.14461 8.91604C5.71033 9.08697 5.32361 9.01534 4.98446 8.7012C4.7477 8.20934 4.86943 7.8441 5.34969 7.6055C6.30785 7.493 7.26752 7.39989 8.2286 7.3262C8.239 6.7545 8.38939 6.22452 8.67977 5.73636C9.04956 5.3865 9.48642 5.20032 9.99032 5.17776ZM9.90438 6.59573C9.84646 6.62521 9.79631 6.66818 9.75399 6.72464C9.72271 6.89806 9.68692 7.06993 9.64657 7.24026C10.5198 7.28323 11.3935 7.29754 12.2677 7.28323C12.253 7.06632 12.2243 6.85148 12.1817 6.6387C11.4238 6.59809 10.6647 6.58378 9.90438 6.59573ZM10.2052 8.65823C9.33797 8.69677 8.46424 8.73974 7.58407 8.78714C7.72105 10.7631 7.85713 12.7397 7.99227 14.7168C7.99343 15.1047 8.18679 15.3123 8.57235 15.3399C10.1622 15.3685 11.752 15.3685 13.3419 15.3399C13.6406 15.3451 13.834 15.2091 13.922 14.9317C14.077 13.0068 14.2131 11.0804 14.3302 9.15237C14.3159 9.04495 14.3015 8.93753 14.2872 8.83011C12.9298 8.72595 11.5691 8.66863 10.2052 8.65823Z" fill="#F86E6E"/>
  </svg>`,
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5"/>
    <circle cx="8" cy="8" r="1.5"/>
    <circle cx="8" cy="14" r="1.5"/>
  </svg>`,
};
