import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-multi-select";

export const SVG: SvgAttribute = {
  remove: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_493_36306)">
    <circle cx="6.99992" cy="6.99992" r="4.66667" fill="white"/>
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M6.49412 0.560466C9.10867 0.475284 11.1184 1.52346 12.5234 3.705C13.5546 5.55398 13.6913 7.46804 12.9336 9.44718C12.083 11.3697 10.6475 12.6229 8.62693 13.207C6.0981 13.7675 3.94251 13.1158 2.16014 11.2519C0.694205 9.48289 0.238476 7.47767 0.792948 5.23625C1.39055 3.26233 2.63469 1.86324 4.52537 1.03898C5.16229 0.78402 5.81854 0.624516 6.49412 0.560466ZM4.88084 4.3339C5.03074 4.34891 5.16746 4.39906 5.291 4.48429C5.85192 5.04979 6.41701 5.61034 6.98631 6.16593C7.57458 5.5822 8.16704 5.00341 8.76365 4.42961C9.139 4.26841 9.41698 4.37325 9.59764 4.74406C9.6389 4.9435 9.60245 5.12578 9.48826 5.29093C8.92276 5.85186 8.36222 6.41695 7.80662 6.98625C8.39035 7.57452 8.96914 8.16698 9.54295 8.76359C9.70575 9.24011 9.54169 9.53179 9.05076 9.63859C8.91702 9.61527 8.79397 9.56515 8.68162 9.4882C8.12069 8.9227 7.5556 8.36216 6.98631 7.80656C6.41701 8.36216 5.85192 8.9227 5.291 9.4882C4.91726 9.69899 4.62105 9.6215 4.40232 9.25578C4.32737 9.03506 4.36382 8.83452 4.5117 8.65422C5.06806 8.10242 5.6195 7.54641 6.166 6.98625C5.6104 6.41695 5.04985 5.85186 4.48435 5.29093C4.29437 4.9863 4.32628 4.70829 4.58006 4.45695C4.67721 4.39895 4.77748 4.35794 4.88084 4.3339Z" fill="#9E9FA8"/>
    </g>
    <defs>
    <clipPath id="clip0_493_36306">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  chevron: `<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8785 4.29284C11.269 3.90232 11.9022 3.90232 12.2927 4.29284C12.6832 4.68336 12.6832 5.31653 12.2927 5.70705L7.34297 10.6568C6.95245 11.0473 6.31928 11.0473 5.92876 10.6568C5.53823 10.2663 5.53823 9.63311 5.92876 9.24259L10.8785 4.29284Z" fill="#9E9FA8"/>
    <path d="M7.34297 9.24259C7.73349 9.63311 7.7335 10.2663 7.34297 10.6568C6.95245 11.0473 6.31928 11.0473 5.92876 10.6568L0.97901 5.70705C0.588486 5.31653 0.588486 4.68336 0.97901 4.29284C1.36953 3.90232 2.0027 3.90232 2.39322 4.29284L7.34297 9.24259Z" fill="#9E9FA8"/>
  </svg>`,
  usd: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_493_36096)">
    <path d="M13.9689 7.65454H0.0314941V7.65475H13.9689V7.65454Z" fill="#C42126"/>
    <path d="M13.2424 3.83325H0.757324V3.83346H13.2424V3.83325Z" fill="#C42126"/>
    <path d="M13.7385 5.10693H0.260986V5.10714H13.7385V5.10693Z" fill="#C42126"/>
    <path d="M3.0085 12.7495H10.9914L10.9918 12.749H3.00806L3.0085 12.7495Z" fill="#C42126"/>
    <path d="M6.99987 0C6.86237 0 6.72597 0.00496541 6.59058 0.0127091H7.40925C7.27373 0.00496541 7.13736 0 6.99987 0Z" fill="#C42126"/>
    <path d="M13.9707 6.38086H0.0288086V6.38107H13.9707V6.38086Z" fill="#C42126"/>
    <path d="M9.0688 2.55977H12.4113C12.0143 2.07644 11.5529 1.64859 11.0417 1.28638H8.53174C8.73943 1.65178 8.91804 2.08294 9.0688 2.55977Z" fill="#F3F4F5"/>
    <path d="M8.53188 1.28641H11.0418C10.0042 0.551088 8.75846 0.0906049 7.40937 0.0126953H7.19385C7.82883 0.0926148 8.12049 0.562555 8.53188 1.28641Z" fill="#E73625"/>
    <path d="M13.7389 5.10713C13.6145 4.66326 13.4476 4.23739 13.2424 3.83374H9.39697C9.47488 4.24259 9.53636 4.66964 9.58188 5.10713H13.7389Z" fill="#F3F4F5"/>
    <path d="M9.18084 3.83341H13.2425C13.011 3.37816 12.733 2.95096 12.4115 2.55981H8.86353C8.98864 2.95584 9.09486 3.38304 9.18084 3.83341Z" fill="#E73625"/>
    <path d="M9.729 7.65458H13.9683C13.9884 7.43918 13.9998 7.22088 13.9998 7.00027C13.9998 6.79149 13.9888 6.58537 13.9709 6.3811H9.73001C9.74381 6.80464 9.74358 7.23128 9.729 7.65458Z" fill="#F3F4F5"/>
    <path d="M13.9712 6.38081C13.9328 5.94344 13.854 5.51793 13.739 5.10742H9.40332C9.44686 5.52443 9.47561 5.95107 9.48965 6.38081H13.9712Z" fill="#E73625"/>
    <path d="M9.32397 10.2016H13.2242C13.4323 9.79837 13.6018 9.37218 13.7289 8.92798H9.51254C9.46611 9.36612 9.4031 9.79308 9.32397 10.2016Z" fill="#F3F4F5"/>
    <path d="M13.7289 8.92793C13.8463 8.51755 13.9279 8.09203 13.9685 7.65454H9.59702C9.58209 8.08461 9.55248 8.51113 9.50806 8.92793H13.7289Z" fill="#E73625"/>
    <path d="M10.9919 12.749C11.5105 12.3882 11.9785 11.9599 12.3818 11.4756H9.09752C8.94401 11.9544 8.76174 12.3849 8.55029 12.749H10.9919Z" fill="#F3F4F5"/>
    <path d="M8.97388 11.4753H12.3817C12.7069 11.0846 12.9892 10.6574 13.2241 10.2017H9.29518C9.20799 10.6524 9.10067 11.0798 8.97388 11.4753Z" fill="#E73625"/>
    <path d="M6.7463 13.9949C6.73214 13.9945 6.71801 13.9939 6.70386 13.9934C6.71801 13.9938 6.73217 13.9945 6.7463 13.9949Z" fill="#DC3027"/>
    <path d="M7 13.9998C8.48404 13.9998 9.8591 13.5365 10.9916 12.749H8.45817C8.00854 13.5237 7.71189 13.9998 7 13.9998Z" fill="#E73625"/>
    <path d="M6.99999 14.0001C6.91498 14.0001 6.83054 13.9981 6.74634 13.9951C6.83143 13.998 6.91596 14.0001 6.99999 14.0001Z" fill="#C42126"/>
    <path d="M8.7637 1.28638H2.9584C2.44711 1.64859 1.98577 2.07641 1.58862 2.55977H9.30064C9.14988 2.08294 8.97139 1.65178 8.7637 1.28638Z" fill="#F3F4F5"/>
    <path d="M2.9585 1.28641H8.7638C8.35232 0.562555 7.82897 0.0926148 7.19399 0.0126953H6.59084C5.24176 0.0906049 3.996 0.551088 2.9585 1.28641Z" fill="#E73625"/>
    <path d="M0.26123 5.10713H9.80279C9.75725 4.66955 9.6958 4.24259 9.61789 3.83374H0.757712C0.552564 4.23742 0.385691 4.66326 0.26123 5.10713Z" fill="#F3F4F5"/>
    <path d="M1.58839 2.55981C1.2672 2.95096 0.988903 3.37813 0.757568 3.83341H9.61763C9.53174 3.38313 9.42555 2.95595 9.30041 2.55981C9.30041 2.55981 1.58839 2.55981 1.58839 2.55981Z" fill="#E73625"/>
    <path d="M0 6.99994C0 7.22055 0.0114973 7.43885 0.0315067 7.65425H9.88817C9.90277 7.23092 9.90298 6.8044 9.88917 6.38086H0.0288467C0.0109357 6.58503 0 6.79116 0 6.99994Z" fill="#F3F4F5"/>
    <path d="M0.0288086 6.38081H9.88902C9.87498 5.95098 9.84613 5.52446 9.80268 5.10742H0.260912C0.14585 5.51801 0.0670541 5.94344 0.0288086 6.38081Z" fill="#E73625"/>
    <path d="M9.79933 8.92798H0.27124C0.398361 9.37229 0.568012 9.79837 0.775909 10.2016H9.61076C9.68991 9.79317 9.75278 9.36621 9.79933 8.92798Z" fill="#F3F4F5"/>
    <path d="M0.271105 8.92793H9.7992C9.84353 8.51113 9.87323 8.08449 9.88816 7.65454H0.0314941C0.072045 8.09212 0.153738 8.51755 0.271105 8.92793Z" fill="#E73625"/>
    <path d="M3.00828 12.749H8.74241C8.95385 12.3848 9.13622 11.9544 9.28964 11.4756H1.61841C2.02173 11.9599 2.48957 12.3882 3.00828 12.749Z" fill="#F3F4F5"/>
    <path d="M0.775879 10.2017C1.01076 10.6574 1.29302 11.0846 1.6182 11.4753H9.28943C9.4161 11.0798 9.52342 10.6524 9.61061 10.2017H0.775879Z" fill="#E73625"/>
    <path d="M6.70378 13.9932C6.71794 13.9937 6.73207 13.9943 6.74623 13.9948C6.83046 13.9978 6.9149 13.9997 6.99988 13.9997C7.71179 13.9997 8.29227 13.5237 8.74199 12.749H3.00854C4.06508 13.4838 5.33327 13.9361 6.70378 13.9932Z" fill="#E73625"/>
    <path d="M6.85524 0.00366211C3.26812 0.0766359 0.344438 2.8463 0.0297852 6.36935H6.85536V0.00366211H6.85524Z" fill="#283991"/>
    <path d="M1.40066 3.96606L1.5073 4.29443H1.85281L1.5733 4.49748L1.68006 4.82609L1.40066 4.62292L1.12136 4.82609L1.22791 4.49748L0.948486 4.29443H1.294L1.40066 3.96606Z" fill="#EFEFEF"/>
    <path d="M1.40066 5.12622L1.5073 5.45479H1.85281L1.5733 5.65784L1.68006 5.98645L1.40066 5.78328L1.12136 5.98645L1.22791 5.65784L0.948486 5.45479H1.294L1.40066 5.12622Z" fill="#EFEFEF"/>
    <path d="M2.56094 2.22583L2.66761 2.55432H3.013L2.73357 2.75748L2.84024 3.08585L2.56094 2.88292L2.28152 3.08585L2.38818 2.75748L2.10864 2.55432H2.45415L2.56094 2.22583Z" fill="#EFEFEF"/>
    <path d="M2.56094 3.38623L2.66761 3.71472H3.013L2.73357 3.91765L2.84024 4.24625L2.56094 4.04332L2.28152 4.24625L2.38818 3.91765L2.10864 3.71472H2.45415L2.56094 3.38623Z" fill="#EFEFEF"/>
    <path d="M2.56094 4.54614L2.66761 4.87475H3.013L2.73357 5.07756L2.84024 5.40616L2.56094 5.20303L2.28152 5.40616L2.38818 5.07756L2.10864 4.87475H2.45415L2.56094 4.54614Z" fill="#EFEFEF"/>
    <path d="M0.519747 5.40634L0.412991 5.07773L0.692413 4.8748H0.347111L0.339367 4.85083C0.301447 4.96811 0.267191 5.08681 0.235596 5.20683L0.240472 5.2032L0.519747 5.40634Z" fill="#EFEFEF"/>
    <path d="M1.12146 3.66602L1.40077 3.46288L1.68019 3.66602L1.57343 3.33741L1.85285 3.13428H1.50735L1.40068 2.80591L1.29401 3.13428H1.16668C1.14304 3.1701 1.12016 3.20613 1.09717 3.24248L1.22804 3.33741L1.12146 3.66602Z" fill="#EFEFEF"/>
    <path d="M2.56091 1.72237L2.84021 1.9253L2.73355 1.59694L3.01285 1.3938H2.81335C2.6439 1.52048 2.48043 1.65454 2.32337 1.796L2.28125 1.9253L2.56091 1.72237Z" fill="#EFEFEF"/>
    <path d="M3.44189 1.34553L3.72132 1.14239L4.00062 1.34553L3.89395 1.01692L4.17325 0.813991H3.82786L3.81338 0.769775C3.69634 0.829567 3.58051 0.891783 3.46731 0.957988L3.54844 1.01689L3.44189 1.34553Z" fill="#EFEFEF"/>
    <path d="M3.72131 1.64575L3.82798 1.97403H4.17337L3.89395 2.17717L4.00062 2.50577L3.72131 2.30261L3.44189 2.50577L3.54855 2.17717L3.26904 1.97403H3.61452L3.72131 1.64575Z" fill="#EFEFEF"/>
    <path d="M3.72131 2.80615L3.82798 3.13443H4.17337L3.89395 3.33757L4.00062 3.66617L3.72131 3.463L3.44189 3.66617L3.54855 3.33757L3.26904 3.13443H3.61452L3.72131 2.80615Z" fill="#EFEFEF"/>
    <path d="M3.72131 3.96606L3.82798 4.29443H4.17337L3.89395 4.49748L4.00062 4.82609L3.72131 4.62292L3.44189 4.82609L3.54855 4.49748L3.26904 4.29443H3.61452L3.72131 3.96606Z" fill="#EFEFEF"/>
    <path d="M3.72131 5.12622L3.82798 5.45479H4.17337L3.89395 5.65784L4.00062 5.98645L3.72131 5.78328L3.44189 5.98645L3.54855 5.65784L3.26904 5.45479H3.61452L3.72131 5.12622Z" fill="#EFEFEF"/>
    <path d="M4.88147 1.06543L4.98813 1.39391H5.33352L5.05422 1.59708L5.16077 1.92545L4.88147 1.72252L4.60195 1.92545L4.70871 1.59708L4.4292 1.39391H4.77468L4.88147 1.06543Z" fill="#EFEFEF"/>
    <path d="M4.88147 2.22583L4.98813 2.55432H5.33352L5.05422 2.75748L5.16077 3.08585L4.88147 2.88292L4.60195 3.08585L4.70871 2.75748L4.4292 2.55432H4.77468L4.88147 2.22583Z" fill="#EFEFEF"/>
    <path d="M4.88147 3.38623L4.98813 3.71472H5.33352L5.05422 3.91765L5.16077 4.24625L4.88147 4.04332L4.60195 4.24625L4.70871 3.91765L4.4292 3.71472H4.77468L4.88147 3.38623Z" fill="#EFEFEF"/>
    <path d="M4.88147 4.54614L4.98813 4.87475H5.33352L5.05422 5.07756L5.16077 5.40616L4.88147 5.20303L4.60195 5.40616L4.70871 5.07756L4.4292 4.87475H4.77468L4.88147 4.54614Z" fill="#EFEFEF"/>
    <path d="M6.04187 0.485352L6.14853 0.813837H6.49392L6.2145 1.01677L6.32117 1.34537L6.04187 1.1422L5.76235 1.34537L5.86911 1.01677L5.5896 0.813837H5.93499L6.04187 0.485352Z" fill="#EFEFEF"/>
    <path d="M6.04187 1.64575L6.14853 1.97403H6.49392L6.2145 2.17717L6.32117 2.50577L6.04187 2.30261L5.76235 2.50577L5.86911 2.17717L5.5896 1.97403H5.93499L6.04187 1.64575Z" fill="#EFEFEF"/>
    <path d="M6.04187 2.80615L6.14853 3.13443H6.49392L6.2145 3.33757L6.32117 3.66617L6.04187 3.463L5.76235 3.66617L5.86911 3.33757L5.5896 3.13443H5.93499L6.04187 2.80615Z" fill="#EFEFEF"/>
    <path d="M6.04187 3.96606L6.14853 4.29443H6.49392L6.2145 4.49748L6.32117 4.82609L6.04187 4.62292L5.76235 4.82609L5.86911 4.49748L5.5896 4.29443H5.93499L6.04187 3.96606Z" fill="#EFEFEF"/>
    <path d="M6.04187 5.12622L6.14853 5.45479H6.49392L6.2145 5.65784L6.32117 5.98645L6.04187 5.78328L5.76235 5.98645L5.86911 5.65784L5.5896 5.45479H5.93499L6.04187 5.12622Z" fill="#EFEFEF"/>
    </g>
    <defs>
    <clipPath id="clip0_493_36096">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  eur: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_493_36156)">
    <path d="M7 0V14C10.8661 14 14.0001 10.866 14.0001 7.0001C14.0001 3.13388 10.8661 0 7 0Z" fill="#2B479D"/>
    <path d="M6.9999 0C3.134 0 0 3.13388 0 7.0001C0 10.866 3.134 14 6.9999 14C10.866 14 10.866 0 6.9999 0Z" fill="#2B479D"/>
    <path d="M6.98782 1.21436L7.15856 1.7398H7.71121L7.26414 2.06453L7.43489 2.58998L6.98782 2.26504L6.54084 2.58998L6.71162 2.06453L6.2644 1.7398H6.81704L6.98782 1.21436Z" fill="#F8D12E"/>
    <path d="M6.98782 11.1335L7.15856 11.6592H7.71121L7.26414 11.984L7.43489 12.5094L6.98782 12.1847L6.54084 12.5094L6.71162 11.984L6.2644 11.6592H6.81704L6.98782 11.1335Z" fill="#F8D12E"/>
    <path d="M9.71011 2.09351L9.88077 2.61907H10.4334L9.98631 2.94401L10.1571 3.46946L9.71011 3.14452L9.26305 3.46946L9.43379 2.94401L8.98682 2.61907H9.53946L9.71011 2.09351Z" fill="#F8D12E"/>
    <path d="M11.5677 3.92603L11.7383 4.45147H12.2909L11.8439 4.77632L12.0146 5.30177L11.5677 4.97704L11.1206 5.30177L11.2913 4.77632L10.8442 4.45147H11.3968L11.5677 3.92603Z" fill="#F8D12E"/>
    <path d="M11.6118 8.55591L11.7825 9.08156H12.3351L11.888 9.40629L12.0589 9.93162L11.6118 9.60701L11.1647 9.93162L11.3355 9.40629L10.8884 9.08156H11.4411L11.6118 8.55591Z" fill="#F8D12E"/>
    <path d="M2.47549 3.92603L2.64615 4.45147H3.19867L2.75169 4.77632L2.92235 5.30177L2.47549 4.97704L2.02843 5.30177L2.19917 4.77632L1.7522 4.45147H2.30484L2.47549 3.92603Z" fill="#F8D12E"/>
    <path d="M11.9983 6.13403L12.1689 6.65948H12.7216L12.2745 6.98421L12.4453 7.50978L11.9983 7.18516L11.5512 7.50978L11.7221 6.98421L11.2749 6.65948H11.8274L11.9983 6.13403Z" fill="#F8D12E"/>
    <path d="M2.05322 6.13403L2.22387 6.65948H2.77639L2.32933 6.98421L2.50019 7.50978L2.05322 7.18516L1.60612 7.50978L1.7769 6.98421L1.32983 6.65948H1.88244L2.05322 6.13403Z" fill="#F8D12E"/>
    <path d="M2.41552 8.55591L2.58618 9.08156H3.1387L2.69175 9.40629L2.86238 9.93162L2.41552 9.60701L1.96846 9.93162L2.1392 9.40629L1.69214 9.08156H2.24478L2.41552 8.55591Z" fill="#F8D12E"/>
    <path d="M9.79028 10.4668L9.96093 10.9922H10.5136L10.0664 11.3172L10.2373 11.8426L9.79028 11.5179L9.3431 11.8426L9.51399 11.3172L9.06689 10.9922H9.61953L9.79028 10.4668Z" fill="#F8D12E"/>
    <path d="M4.35367 2.09351L4.52429 2.61907H5.07693L4.62987 2.94401L4.80073 3.46946L4.35367 3.14452L3.90669 3.46946L4.07744 2.94401L3.63037 2.61907H4.18289L4.35367 2.09351Z" fill="#F8D12E"/>
    <path d="M4.15201 10.4331L4.32275 10.9588H4.87539L4.42832 11.2834L4.59907 11.8088L4.15201 11.4843L3.70503 11.8088L3.87578 11.2834L3.42871 10.9588H3.98123L4.15201 10.4331Z" fill="#F8D12E"/>
    </g>
    <defs>
    <clipPath id="clip0_493_36156">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  rub: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_493_36217)">
    <path d="M7.00044 13.9997C10.8663 13.9997 14.0001 10.8658 14.0001 6.99995C14.0001 3.13412 10.8663 0.000244141 7.00044 0.000244141C3.13461 0.000244141 0.000732422 3.13412 0.000732422 6.99995C0.000732422 10.8658 3.13461 13.9997 7.00044 13.9997Z" fill="white"/>
    <path d="M13.6 4.66602H9.28711C9.47446 6.16133 9.47556 7.80895 9.29075 9.30613H13.6092C13.8613 8.58384 13.9999 7.80818 13.9999 7.00001C14.0001 6.18122 13.8582 5.39604 13.6 4.66602Z" fill="#3757A6"/>
    <path d="M9.28688 4.6659H13.5997C13.5991 4.66369 13.5984 4.66182 13.5975 4.65981C12.7017 2.13377 10.3972 0.275267 7.62842 0.0285645C8.69999 0.615898 9.01299 2.47673 9.28688 4.6659Z" fill="#EFEFEF"/>
    <path d="M7.62866 13.9709C10.4024 13.7238 12.7101 11.8589 13.6025 9.32604C13.6048 9.3193 13.6068 9.31265 13.6092 9.30615H9.29064C9.01885 11.5072 8.70452 13.3811 7.62866 13.9709Z" fill="#E73B36"/>
    <path d="M0 7.00001C0 7.80831 0.138824 8.58385 0.390698 9.30614H9.65092C9.83594 7.80884 9.83461 6.16133 9.64749 4.66602H0.400008C0.141809 5.39607 0 6.18126 0 7.00001Z" fill="#3757A6"/>
    <path d="M0.402119 4.65973C0.401469 4.66171 0.400789 4.66361 0.399902 4.66582H9.64741C9.37331 2.47662 8.69997 0.615817 7.62855 0.0284841C7.4213 0.01013 7.21175 -0.00024414 6.99977 -0.00024414C3.95467 -0.000273696 1.36514 1.94453 0.402119 4.65973Z" fill="#EFEFEF"/>
    <path d="M9.65109 9.30615H0.390869C0.393204 9.31268 0.395391 9.3193 0.397608 9.32604C1.35678 12.0482 3.95004 13.9999 7.00002 13.9999C7.21203 13.9999 7.42158 13.9895 7.62879 13.9709C8.70477 13.3812 9.3793 11.5072 9.65109 9.30615Z" fill="#E73B36"/>
    </g>
    <defs>
    <clipPath id="clip0_493_36217">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  aed: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.996">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.37109 0C6.79036 0 7.20964 0 7.62891 0C10.0802 0.297743 11.9259 1.50998 13.166 3.63672C13.3276 3.92569 13.4597 4.22647 13.5625 4.53906C10.4089 4.53906 7.2552 4.53906 4.10156 4.53906C4.11064 3.23105 4.10153 1.92765 4.07422 0.628906C4.80514 0.291238 5.57077 0.0816019 6.37109 0Z" fill="#00722E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.07422 0.628906C4.10153 1.92765 4.11064 3.23105 4.10156 4.53906C4.10156 6.21614 4.10156 7.89324 4.10156 9.57031C4.11064 10.8419 4.10153 12.1088 4.07422 13.3711C2.34429 12.548 1.1275 11.2447 0.423828 9.46094C0.208313 8.86348 0.067037 8.25281 0 7.62891C0 7.20964 0 6.79036 0 6.37109C0.268097 4.06722 1.37096 2.28531 3.30859 1.02539C3.55239 0.87159 3.80759 0.73943 4.07422 0.628906Z" fill="#FE0100"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10156 4.53906C7.2552 4.53906 10.4089 4.53906 13.5625 4.53906C13.7849 5.13188 13.9308 5.74257 14 6.37109C14 6.78125 14 7.19141 14 7.60156C13.9291 8.27449 13.7742 8.93074 13.5352 9.57031C10.3906 9.57031 7.24609 9.57031 4.10156 9.57031C4.10156 7.89324 4.10156 6.21614 4.10156 4.53906Z" fill="#F3F4F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10156 9.57031C7.24609 9.57031 10.3906 9.57031 13.5352 9.57031C12.5998 11.7881 10.9683 13.2054 8.64062 13.8223C8.29295 13.8969 7.94659 13.9561 7.60156 14C7.19141 14 6.78125 14 6.37109 14C5.57069 13.9184 4.80506 13.7087 4.07422 13.3711C4.10153 12.1088 4.11064 10.8419 4.10156 9.57031Z" fill="#272727"/>
    </g>
  </svg>`,
};
