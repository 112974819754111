import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { HistoryDetails, HistoryDetailsTitle } from "./model";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isDetails": null,
  },
  computed: {
    displayedList(): HistoryDetails {
      return {
        dateTime: {
          title: HistoryDetailsTitle.dateTime,
          label: "07.05.2024 15:02:10",
        },
        type: {
          title: HistoryDetailsTitle.type,
          label: "Пополнение",
        },
        amountCurrency: {
          title: HistoryDetailsTitle.amountCurrency,
          label: "999,999.12345678 USDT",
        },
        commission: {
          title: HistoryDetailsTitle.commission,
          label: "0.6894 USDT",
        },
        status: {
          title: HistoryDetailsTitle.status,
          label: "Ожидание",
        },
        address: {
          title: HistoryDetailsTitle.address,
          label: "12erGfIdfdfEBDdfgDFG7pokmFwe3Z",
          info: {
            rs: {
              title: HistoryDetailsTitle.rs,
              label:
                "40702810123450101230 в Московский банк ПАО Сбербанк г. Москва",
            },
            ks: {
              title: HistoryDetailsTitle.ks,
              label: "30101234500000000225",
            },
            bik: {
              title: HistoryDetailsTitle.bik,
              label: "044521234",
            },
            name: {
              title: HistoryDetailsTitle.name,
              label: "Общество с ограниченной ответственностью «Весна»",
            },
            inn: {
              title: HistoryDetailsTitle.inn,
              label: "7712345678",
            },
            kpp: {
              title: HistoryDetailsTitle.kpp,
              label: "779101001",
            },
          },
        },
      };
    },
  },
  methods: {
    handleConfirm(): void {
      console.log("Handle Confirm");
    },

    handleClose(): void {
      this.$emit("update:isDetails", !this.isDetails);
    },
  },
});
