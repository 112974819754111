import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedInputPassword,
  SharedPasswordValidation,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { validationPassword } from "@/shared/utils/validation-helpers";
import { ValidationPassword } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedInputPassword,
    SharedPasswordValidation,
    UIButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    password: {
      type: String,
      default: "",
    },
    password_confirmation: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:password": null,
    "update:password_confirmation": null,
    forgot: null,
  },
  computed: {
    displayedValidationPassword(): ValidationPassword {
      return validationPassword(this.password, this.password_confirmation);
    },

    isValidationPasswordShow(): boolean {
      return !!this.password || !!this.password_confirmation;
    },

    isForgotDisabled(): boolean {
      return !this.displayedValidationPassword.isValidation || this.loading;
    },
  },
  methods: {
    changePassword(password: string): void {
      this.$emit("update:password", password);
    },

    changeConfirmPassword(password_confirmation: string): void {
      this.$emit("update:password_confirmation", password_confirmation);
    },

    handleForgot(): void {
      this.$emit("forgot");
    },
  },
});
