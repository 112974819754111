import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.label)), 3),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('column-sort'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('column-icon')),
              innerHTML: _ctx.displayedSortIcon
            }, null, 10, _hoisted_1)
          ], 2)
        ], 2))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedSmallColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column', { small: true })),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.label)), 3),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('column-sort'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('column-icon')),
              innerHTML: _ctx.displayedSortIcon
            }, null, 10, _hoisted_2)
          ], 2)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRows, (row) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('row')),
          key: row.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-date-time'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label', { dateTime: true }))
            }, _toDisplayString(row.dateTime), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-type'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.type), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-amount'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(
              _ctx.b('row-label', {
                refill: row.amount.type.refill,
                conclusion: row.amount.type.conclusion,
              })
            )
            }, _toDisplayString(row.amount.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-currency'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label', { currency: true }))
            }, _toDisplayString(row.currency), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-commission'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.commission), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-status'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-group'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-icon')),
                innerHTML: row.status.icon
              }, null, 10, _hoisted_3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('row-label', { status: true }))
              }, _toDisplayString(row.status.label), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-actions'))
            }, [
              _createVNode(_component_UIButton, {
                label: "Подтвердить",
                view: "main-small",
                onClick: ($event: any) => (_ctx.handleConfirm(row))
              }, null, 8, ["onClick"]),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.b('row-action')),
                onClick: ($event: any) => (_ctx.handleRemove(row))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-icon')),
                  innerHTML: _ctx.displayedRemoveIcon
                }, null, 10, _hoisted_5)
              ], 10, _hoisted_4)
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-block'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-block-group'))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-block-type'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(row.type), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(
                  _ctx.b('row-label', {
                    refill: row.amount.type.refill,
                    conclusion: row.amount.type.conclusion,
                    block: true,
                  })
                )
                }, [
                  _createTextVNode(_toDisplayString(row.amount.label) + " ", 1),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label', { currency: true }))
                  }, _toDisplayString(row.currency), 3)
                ], 2)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-block-date'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label', { dateTime: true }))
                }, _toDisplayString(row.dateTime), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-icon')),
                  innerHTML: row.status.icon
                }, null, 10, _hoisted_6)
              ], 2)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-block-group'))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-block-commission'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(_ctx.$t("Комиссия")), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(row.commission), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-block-other'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('row-block-number'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label'))
                  }, "№ " + _toDisplayString(_ctx.$t("Transaction ")), 3),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label'))
                  }, "24355", 2)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('row-block-actions'))
                }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.b('row-block-dots', { active: row.isActions })),
                    onClick: ($event: any) => (_ctx.handleRowActions(row))
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.b('row-icon')),
                      innerHTML: _ctx.displayedDotsIcon
                    }, null, 10, _hoisted_8)
                  ], 10, _hoisted_7),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.b('row-actions', { visible: row.isActions }))
                  }, [
                    _createVNode(_component_UIButton, {
                      label: "Подтвердить",
                      view: "main-small",
                      onClick: ($event: any) => (_ctx.handleConfirm(row))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("button", {
                      class: _normalizeClass(_ctx.b('row-action')),
                      onClick: ($event: any) => (_ctx.handleRemove(row))
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.b('row-icon')),
                        innerHTML: _ctx.displayedRemoveIcon
                      }, null, 10, _hoisted_10)
                    ], 10, _hoisted_9)
                  ], 2)
                ], 2)
              ], 2)
            ], 2)
          ], 2)
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}