import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedVerification,
    UIButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:code": null,
    verification: null,
  },
  computed: {
    isVerificationDisabled(): boolean {
      return this.code.length !== CODE_MAX_LENGTH || this.loading;
    },
  },
  methods: {
    changeCode(code: string): void {
      this.$emit("update:code", code);
    },

    handleVerification(): void {
      this.$emit("verification");
    },
  },
});
