import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";

import {
  WalletMain,
  WalletHistory,
  WalletApplications,
  WalletInput,
  WalletConclusion,
} from "@/components/wallet";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    WalletMain,
    WalletHistory,
    WalletApplications,
    WalletInput,
    WalletConclusion,
  },
  data() {
    return {
      isConclusion: false,
      isInput: false,
    };
  },
});
