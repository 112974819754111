<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <button :class="b('header-back')" @click="handleBack">
        <span :class="b('header-icon')" v-html="displayedChevronIcon"></span>
      </button>

      <h2 :class="b('header-title')">
        {{ $t("Крипто реквизиты") }}
      </h2>
    </div>

    <div :class="b('form')">
      <div :class="b('form-field')">
        <h4 :class="b('form-label')">{{ $t("Монета") }}*</h4>

        <UIInput
          view="light"
          :model-value="coin"
          @update:modelValue="changeCoin"
        />
      </div>

      <div :class="b('form-field')">
        <h4 :class="b('form-label')">{{ $t("Сеть") }}*</h4>

        <UIInput
          view="light"
          :model-value="network"
          @update:modelValue="changeNetwork"
        />
      </div>

      <div :class="b('form-field')">
        <h4 :class="b('form-label')">{{ $t("Адрес") }}*</h4>

        <UIInput
          view="light"
          :model-value="address"
          @update:modelValue="changeAddress"
        />
      </div>
    </div>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить"
      view="main"
      :disabled="isConfirmDisabled"
      @click="handleConfirm"
    />
  </div>
</template>
