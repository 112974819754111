import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-country";

export const SVG: SvgAttribute = {
  chevron: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M11.5449 4.2929C11.9354 3.90238 12.5686 3.90238 12.9591 4.2929C13.3496 4.68343 13.3496 5.31659 12.9591 5.70712L8.00935 10.6569C7.61883 11.0474 6.98566 11.0474 6.59514 10.6569C6.20462 10.2663 6.20462 9.63317 6.59514 9.24265L11.5449 4.2929Z" fill="#9E9FA8"/>
    <path d="M8.00935 9.24265C8.39988 9.63317 8.39988 10.2663 8.00935 10.6569C7.61883 11.0474 6.98566 11.0474 6.59514 10.6569L1.64539 5.70712C1.25487 5.31659 1.25487 4.68343 1.64539 4.2929C2.03592 3.90238 2.66908 3.90238 3.05961 4.2929L8.00935 9.24265Z" fill="#9E9FA8"/>
  </svg>`,
};

export enum Country {
  Afghanistan = "af",
  Albania = "al",
  Algeria = "dz",
  Andorra = "ad",
  Angola = "ao",
  "Antigua and Barbuda" = "ag",
  Argentina = "ar",
  Armenia = "am",
  Australia = "au",
  Austria = "at",
  Azerbaijan = "az",
  Bahamas = "bs",
  Bahrain = "bh",
  Bangladesh = "bd",
  Barbados = "bb",
  Belarus = "by",
  Belgium = "be",
  Belize = "bz",
  Benin = "bj",
  Bhutan = "bt",
  Bolivia = "bo",
  "Bosnia and Herzegovina" = "ba",
  Botswana = "bw",
  Brazil = "br",
  Brunei = "bn",
  Bulgaria = "bg",
  "Burkina Faso" = "bf",
  Burundi = "bi",
  "Cabo Verde" = "cv",
  Cambodia = "kh",
  Cameroon = "cm",
  Canada = "ca",
  "Central African Republic" = "cf",
  Chad = "td",
  Chile = "cl",
  China = "cn",
  Colombia = "co",
  Comoros = "km",
  "Congo Democratic Republic" = "cd",
  "Congo Republic of" = "cg",
  "Costa Rica" = "cr",
  "Cote dIvoire" = "ci",
  Croatia = "hr",
  Cuba = "cu",
  Cyprus = "cy",
  "Czech Republic" = "cz",
  Denmark = "dk",
  Djibouti = "dj",
  Dominica = "dm",
  "Dominican Republic" = "do",
  "East Timor" = "tl",
  Ecuador = "ec",
  Egypt = "eg",
  "El Salvador" = "sv",
  "Equatorial Guinea" = "gq",
  Eritrea = "er",
  Estonia = "ee",
  Eswatini = "sz",
  Ethiopia = "et",
  Fiji = "fj",
  Finland = "fi",
  France = "fr",
  Gabon = "ga",
  Gambia = "gm",
  Georgia = "ge",
  Germany = "de",
  Ghana = "gh",
  Greece = "gr",
  Grenada = "gd",
  Guatemala = "gt",
  Guinea = "gn",
  "Guinea Bissau" = "gw",
  Guyana = "gy",
  Haiti = "ht",
  Honduras = "hn",
  Hungary = "hu",
  Iceland = "is",
  India = "in",
  Indonesia = "id",
  Iran = "ir",
  Iraq = "iq",
  Ireland = "ie",
  Israel = "il",
  Italy = "it",
  Jamaica = "jm",
  Japan = "jp",
  Jordan = "jo",
  Kazakhstan = "kz",
  Kenya = "ke",
  Kiribati = "ki",
  "Korea North" = "kp",
  "Korea South" = "kr",
  Kosovo = "xk",
  Kuwait = "kw",
  Kyrgyzstan = "kg",
  Laos = "la",
  Latvia = "lv",
  Lebanon = "lb",
  Lesotho = "ls",
  Liberia = "lr",
  Libya = "ly",
  Liechtenstein = "li",
  Lithuania = "lt",
  Luxembourg = "lu",
  Madagascar = "mg",
  Malawi = "mw",
  Malaysia = "my",
  Maldives = "mv",
  Mali = "ml",
  Malta = "mt",
  "Marshall Islands" = "mh",
  Mauritania = "mr",
  Mauritius = "mu",
  Mexico = "mx",
  Micronesia = "fm",
  Moldova = "md",
  Monaco = "mc",
  Mongolia = "mn",
  Montenegro = "me",
  Morocco = "ma",
  Mozambique = "mz",
  Myanmar = "mm",
  Namibia = "na",
  Nauru = "nr",
  Nepal = "np",
  Netherlands = "nl",
  "New Zealand" = "nz",
  Nicaragua = "ni",
  Niger = "ne",
  Nigeria = "ng",
  "North Macedonia" = "mk",
  Norway = "no",
  Oman = "om",
  Pakistan = "pk",
  Palau = "pw",
  Palestine = "ps",
  Panama = "pa",
  "Papua New Guinea" = "pg",
  Paraguay = "py",
  Peru = "pe",
  Philippines = "ph",
  Poland = "pl",
  Portugal = "pt",
  Qatar = "qa",
  Romania = "ro",
  Russia = "ru",
  Rwanda = "rw",
  "Saint Kitts and Nevis" = "kn",
  "Saint Lucia" = "lc",
  "Saint Vincent and the Grenadines" = "vc",
  Samoa = "ws",
  "San Marino" = "sm",
  "Sao Tome and Principe" = "st",
  "Saudi Arabia" = "sa",
  Senegal = "sn",
  Serbia = "rs",
  Seychelles = "sc",
  "Sierra Leone" = "sl",
  Singapore = "sg",
  Slovakia = "sk",
  Slovenia = "si",
  "Solomon Islands" = "sb",
  Somalia = "so",
  "South Africa" = "za",
  "South Sudan" = "ss",
  Spain = "es",
  "Sri Lanka" = "lk",
  Sudan = "sd",
  Suriname = "sr",
  Sweden = "se",
  Switzerland = "ch",
  Syria = "sy",
  Taiwan = "tw",
  Tajikistan = "tj",
  Tanzania = "tz",
  Thailand = "th",
  Togo = "tg",
  Tonga = "to",
  "Trinidad and Tobago" = "tt",
  Tunisia = "tn",
  Turkey = "tr",
  Turkmenistan = "tm",
  Tuvalu = "tv",
  Uganda = "ug",
  Ukraine = "ua",
  "United Arab Emirates" = "ae",
  "United Kingdom" = "gb",
  "United States" = "us",
  Uruguay = "uy",
  Uzbekistan = "uz",
  Vanuatu = "vu",
  "Vatican City" = "va",
  Venezuela = "ve",
  Vietnam = "vn",
  Yemen = "ye",
  Zambia = "zm",
  Zimbabwe = "zw",
}
