<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('delimiter')"></div>

    <p :class="b('label')">or</p>

    <div :class="b('delimiter')"></div>
  </div>
</template>
