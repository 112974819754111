import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_UIButton, {
      class: _normalizeClass(_ctx.b('action')),
      label: "Продолжить",
      view: "main",
      onClick: _ctx.handleNext
    }, null, 8, ["class", "onClick"])
  ], 2))
}