<template>
  <div :class="b()">
    <transition name="opacity">
      <SharedNotification
        v-if="isNotificationShow"
        :class="b('notification')"
      />
    </transition>

    <transition name="opacity">
      <SharedModal v-if="isModalShow" :class="b('modal')" />
    </transition>

    <component :is="displayedLayout" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { AdminLayout, AuthLayout, DefaultLayout } from "@/layouts";
import { SharedNotification, SharedModal } from "@/components/shared";

import { Layout } from "@/shared/constants/constants";

type DisplayedLayout =
  | typeof AuthLayout
  | typeof DefaultLayout
  | typeof AdminLayout;

export default defineComponent({
  name: "app",
  components: {
    AuthLayout,
    DefaultLayout,
    AdminLayout,
    SharedNotification,
    SharedModal,
  },
  data() {
    return {
      languageRepo: this.$projectServices.languageRepo,
      notificationRepo: this.$projectServices.notificationRepo,
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  async created() {
    this.languageRepo.setLanguage();
    this.$i18n.locale = this.languageRepo.language;

    try {
      await this.$projectServices.projectRepository.csfr();
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    displayedLayout(): DisplayedLayout {
      const { layout } = this.$route.meta;

      switch (layout) {
        case Layout.default:
          return DefaultLayout;
        case Layout.auth:
          return AuthLayout;
        case Layout.admin:
          return AdminLayout;
        default:
          return DefaultLayout;
      }
    },

    isNotificationShow(): boolean {
      return !!this.notificationRepo.notification;
    },

    isModalShow(): boolean {
      return !!this.modalRepo.modal;
    },

    isAuthLayout(): boolean {
      const { layout } = this.$route.meta;

      return layout ? layout === Layout.auth : true;
    },
  },
});
</script>

<style lang="scss">
.app {
  @include full-screen;

  position: relative;

  &__notification {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
  }

  .opacity-enter-active {
    transition: $transition;
  }

  .opacity-leave-active {
    transition: $transition;
  }

  .opacity-enter-from,
  .opacity-leave-to {
    opacity: 0;
  }
}
</style>
