export interface WalletMainTab {
  id: number;
  label: WalletMainTabLabel;
  value: WalletMainTabValue;
  active: boolean;
}

export enum WalletMainTabLabel {
  all = "Все",
  fiat = "Фиат",
  crypto = "Крипто",
}

export enum WalletMainTabValue {
  all = "all",
  fiat = "fiat",
  crypto = "crypto",
}

export interface WalletMainColumn {
  id: number;
  label: WalletMainColumnLabel;
  sort: boolean;
}

export enum WalletMainColumnLabel {
  currency = "Валюта",
  total = "Всего",
  action = "Действие",
}

export interface WalletMainRow {
  id: number;
  currency: {
    name: string;
    icon: string;
    tag: string;
    isBank: boolean;
    isCrypto: boolean;
  };
  total: string;
  isActions?: boolean;
}
