<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Внести изменения") }}
        </h4>

        <SharedSelect view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Поиск по ID") }}
        </h4>

        <UIInput view="light" placeholder="Введите ID пользователя" />
      </div>
    </div>

    <div :class="b('divider')"></div>

    <div :class="b('fields')">
      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Комиссия MEX") }}
        </h4>

        <SharedInputDouble view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Комиссия MOEX") }}
        </h4>

        <SharedInputDouble view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">SWIFT</h4>

        <SharedInputDouble view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Комиссия Крипто биржи") }}
        </h4>

        <SharedInputDouble view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Блокчейн перевод") }}
        </h4>

        <SharedInputDouble view="light" />
      </div>

      <div :class="b('field')">
        <h4 :class="b('field-label')">
          {{ $t("Волатильность") }}
        </h4>

        <SharedInputDouble view="light" />
      </div>
    </div>

    <UIButton
      :class="b('save')"
      label="Cохранить"
      view="main"
      :disabled="isSaveDisabled"
      @click="handleSave"
    />
  </div>
</template>
