import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-select";

export const SVG: SvgAttribute = {
  chevron: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M11.5449 4.2929C11.9354 3.90238 12.5686 3.90238 12.9591 4.2929C13.3496 4.68343 13.3496 5.31659 12.9591 5.70712L8.00935 10.6569C7.61883 11.0474 6.98566 11.0474 6.59514 10.6569C6.20462 10.2663 6.20462 9.63317 6.59514 9.24265L11.5449 4.2929Z" fill="#9E9FA8"/>
    <path d="M8.00935 9.24265C8.39988 9.63317 8.39988 10.2663 8.00935 10.6569C7.61883 11.0474 6.98566 11.0474 6.59514 10.6569L1.64539 5.70712C1.25487 5.31659 1.25487 4.68343 1.64539 4.2929C2.03592 3.90238 2.66908 3.90238 3.05961 4.2929L8.00935 9.24265Z" fill="#9E9FA8"/>
  </svg>`,
};
