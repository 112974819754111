import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  AdminApplicationTableColumn,
  AdminApplicationTableColumnLabel,
  AdminApplicationTableRow,
} from "./model";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): AdminApplicationTableColumn[] {
      return [
        {
          id: 0,
          label: AdminApplicationTableColumnLabel.date,
        },
        {
          id: 1,
          label: AdminApplicationTableColumnLabel.id,
        },
        {
          id: 2,
          label: AdminApplicationTableColumnLabel.email,
        },
        {
          id: 3,
          label: AdminApplicationTableColumnLabel.type,
        },
        {
          id: 4,
          label: AdminApplicationTableColumnLabel.amount,
        },
        {
          id: 5,
          label: AdminApplicationTableColumnLabel.currency,
        },
        {
          id: 6,
          label: AdminApplicationTableColumnLabel.price,
        },
        {
          id: 7,
          label: AdminApplicationTableColumnLabel.commission,
        },
        {
          id: 8,
          label: AdminApplicationTableColumnLabel.number,
        },
        {
          id: 9,
          label: AdminApplicationTableColumnLabel.status,
        },
        {
          id: 10,
          label: AdminApplicationTableColumnLabel.stage,
        },
      ];
    },

    displayedRows(): AdminApplicationTableRow[] {
      return [
        {
          date: "07.05.2024 15:02:10",
          id: "1234567890",
          email: "emailemailemail@email...",
          type: "Пополнение",
          amount: "0.04",
          currency: "BTC",
          price: "47,205.09812345",
          commission: "0.0267",
          number: "24356",
          status: {
            label: "Создано",
          },
          stage: {
            label: "Средства ушли",
          },
        },
        {
          date: "07.05.2024 15:02:10",
          id: "1234567891",
          email: "emailemailemail@email...",
          type: "Пополнение",
          amount: "0.04",
          currency: "BTC",
          price: "47,205.09812345",
          commission: "0.0267",
          number: "24356",
          status: {
            label: "Создано",
          },
          stage: {
            label: "Средства ушли",
          },
        },
      ];
    },
  },
  methods: {
    handleColumnSort(): void {},
  },
});
