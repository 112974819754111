import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedLanguage = _resolveComponent("SharedLanguage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('logo')),
      innerHTML: _ctx.displayedLogo
    }, null, 10, _hoisted_1),
    _createVNode(_component_SharedLanguage, { view: "main" })
  ], 2))
}