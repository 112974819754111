import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { SharedVerificationField, SharedVerificationFields } from "./model";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";

enum FieldKey {
  zero = "zero",
  one = "one",
  two = "two",
  three = "three",
  four = "four",
  five = "five",
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    title: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    "update:code": null,
  },
  data() {
    return {
      fields: <SharedVerificationFields>{
        zero: "",
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
      },
    };
  },
  mounted(): void {
    this.handleFocusFirstField();
  },
  computed: {
    displayedFields(): SharedVerificationField[] {
      return [
        {
          id: 0,
          key: FieldKey.zero,
          prevKey: null,
          nextKey: FieldKey.one,
          value: this.fields.zero,
        },
        {
          id: 1,
          key: FieldKey.one,
          prevKey: FieldKey.zero,
          nextKey: FieldKey.two,
          value: this.fields.one,
        },
        {
          id: 2,
          key: FieldKey.two,
          prevKey: FieldKey.one,
          nextKey: FieldKey.three,
          value: this.fields.two,
        },
        {
          id: 3,
          key: FieldKey.three,
          prevKey: FieldKey.two,
          nextKey: FieldKey.four,
          value: this.fields.three,
        },
        {
          id: 4,
          key: FieldKey.four,
          prevKey: FieldKey.three,
          nextKey: FieldKey.five,
          value: this.fields.four,
        },
        {
          id: 5,
          key: FieldKey.five,
          prevKey: FieldKey.four,
          nextKey: null,
          value: this.fields.five,
        },
      ];
    },
  },
  methods: {
    changeField(event: Event, { key, nextKey }: SharedVerificationField): void {
      const { value } = <HTMLInputElement>event.target;

      const changedValue = value.substring(0, 1);

      if (value.length === CODE_MAX_LENGTH) {
        const symbols = value.split("");

        Object.keys(this.fields).forEach((fieldKey, fieldKeyIndex) => {
          this.fields[fieldKey] = symbols[fieldKeyIndex];
        });

        const fields = <HTMLInputElement[]>this.$refs[key];

        fields[0].blur();
      } else {
        (<HTMLInputElement>event.target).value = changedValue;

        this.fields[key] = changedValue;
        if (value) {
          if (nextKey) {
            const fields = <HTMLInputElement[]>this.$refs[nextKey];

            fields[0].focus();
          } else {
            const fields = <HTMLInputElement[]>this.$refs[key];

            fields[0].focus();
          }
        }
      }

      this.changeCode();
    },

    handleBackspace(
      event: KeyboardEvent,
      { key, prevKey }: SharedVerificationField
    ): void {
      const { value } = <HTMLInputElement>event.target;

      if (event.key === "Backspace") {
        if (!value) {
          if (prevKey) {
            const fields = <HTMLInputElement[]>this.$refs[prevKey];

            fields[0].focus();
          } else {
            const fields = <HTMLInputElement[]>this.$refs[key];

            fields[0].focus();
          }
        }
      }
    },

    changeCode(): void {
      const code: string = Object.values(this.fields)
        .map((symbol: string) => symbol)
        .join("");

      this.$emit("update:code", code);
    },

    handleFocusFirstField(): void {
      const fields = <HTMLInputElement[]>this.$refs[FieldKey.zero];

      fields[0].focus();
    },
  },
});
