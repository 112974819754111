import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsTabs = _resolveComponent("SettingsTabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Настройки")), 3),
    _createVNode(_component_SettingsTabs, {
      class: _normalizeClass(_ctx.b('tabs')),
      tab: _ctx.tab,
      "onUpdate:tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
    }, null, 8, ["class", "tab"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent)))
  ], 2))
}