<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow
    title="Ввод"
    :is-big="isBig"
    :is-center="true"
    @close="handleClose"
  >
    <div :class="b('wrapper')">
      <div :class="b('header')" v-if="!isHeaderShow">
        <WalletTabs :class="b('tabs')" v-model:tab="tab" />

        <WalletRequisites v-model:requisites="requisites" />
      </div>

      <component v-if="isRequisitesShow" :is="displayedRequisites" />

      <component v-else :is="displayedComponent" />
    </div>
  </SharedWindow>
</template>
