import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedError = _resolveComponent("SharedError")!
  const _component_SharedInputPassword = _resolveComponent("SharedInputPassword")!
  const _component_SharedPasswordValidation = _resolveComponent("SharedPasswordValidation")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UILink = _resolveComponent("UILink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createVNode(_component_UIInput, {
          placeholder: "Адрес электронной почты*",
          view: "light",
          modelValue: _ctx.email,
          "is-error": _ctx.isErrorDisplayed,
          "onUpdate:modelValue": _ctx.changeEmail
        }, null, 8, ["modelValue", "is-error", "onUpdate:modelValue"]),
        (_ctx.isErrorDisplayed)
          ? (_openBlock(), _createBlock(_component_SharedError, {
              key: 0,
              message: "Такой email уже существует, авторизуйтесь"
            }))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields-validation'))
      }, [
        _createVNode(_component_SharedInputPassword, {
          placeholder: "Пароль*",
          view: "light",
          modelValue: _ctx.password,
          isError: _ctx.isPasswordError,
          "onUpdate:modelValue": _ctx.changePassword
        }, null, 8, ["modelValue", "isError", "onUpdate:modelValue"]),
        (_ctx.isPasswordValidationShow)
          ? (_openBlock(), _createBlock(_component_SharedPasswordValidation, {
              key: 0,
              validation: _ctx.displayedValidationPassword
            }, null, 8, ["validation"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields-validation'))
      }, [
        _createVNode(_component_SharedInputPassword, {
          placeholder: "Подтвердите пароль*",
          view: "light",
          modelValue: _ctx.password_confirmation,
          isError: _ctx.isConfirmPasswordError,
          "onUpdate:modelValue": _ctx.changeConfirmPassword
        }, null, 8, ["modelValue", "isError", "onUpdate:modelValue"]),
        (_ctx.isConfirmPasswordValidationShow)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.b('fields-label'))
            }, _toDisplayString(_ctx.$t("Пароль не совпадает")), 3))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('actions'))
    }, [
      _createVNode(_component_UIButton, {
        label: "Создать аккаунт",
        view: "main",
        disabled: _ctx.isRegistrationDisabled,
        onClick: _ctx.handleRegistration
      }, null, 8, ["disabled", "onClick"])
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("Нажав “Создать учетную запись“, я принимаю")) + " ", 1),
      _createElementVNode("br", {
        class: _normalizeClass(_ctx.b('label-br'))
      }, null, 2),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("the")) + " ", 1),
      _createVNode(_component_UILink, {
        label: "Условия обслуживания",
        view: "main-text-12",
        to: "/terms"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("и")) + " ", 1),
      _createVNode(_component_UILink, {
        label: "Политика конфиденциальности",
        view: "main-text-12",
        to: "/privacy"
      })
    ], 2)
  ], 2))
}