import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    view: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:checked": null,
  },
  computed: {
    displayedIcon(): string {
      return SVG.checked;
    },
  },
  methods: {
    changeChecked(): void {
      this.$emit("update:checked", !this.checked);
    },
  },
});
