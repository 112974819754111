<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('form')">
      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Валюта") }}
          </h4>

          <SharedSelect view="light" />
        </div>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{
              $t("Полное фирменное наименование Общества на официальном языке")
            }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{
              $t("Полное фирменное наименование Общества на английском языке")
            }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("ИНН") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Р/с № (мультивалютный)") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Код назначения платежа") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Назначение платежа") }}
          </h4>

          <UIInput view="light" />
        </div>
      </div>
    </div>

    <div :class="b('form')">
      <div :class="b('controls')">
        <button
          :class="
            b('control', { active: control.isActive, added: control.isAdded })
          "
          v-for="control in displayedControls"
          :key="control.id"
          @click="handleControl(control)"
        >
          {{ control.label }}

          <span
            v-if="control.isAdded"
            :class="b('control-icon')"
            v-html="displayedIcons.added"
          />
        </button>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("БАНК-корреспондент") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("БИК") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("ИНН") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Корр. счет") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">SWIFT</h4>

          <UIInput view="light" />
        </div>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("БАНК получателя") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Корр. счет") }}
          </h4>

          <UIInput view="light" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">SWIFT</h4>

          <UIInput view="light" />
        </div>
      </div>

      <UIButton
        :class="b('save')"
        view="main"
        label="Сохранить"
        :disabled="isSaveDisabled"
        @click="handleSave"
      />
    </div>
  </div>
</template>
