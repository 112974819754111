import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedCard, SharedBack } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { RequisitesVerification } from "../verification";
import { RequisitesComponent } from "@/views/requisites/model";
import { RequisiteCryptoRequestFactory } from "@/shared/factory/requisites";
import {
  ModalTitle,
  ModalLabel,
  ModalIcon,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    RequisitesVerification,
    SharedCard,
    SharedBack,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<RequisitesComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      requisiteCrypto: RequisiteCryptoRequestFactory(),
      modalRepo: this.$projectServices.modalRepo,
      loading: false,
      isVerification: false,
    };
  },
  computed: {
    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },

    isContinueDisabled(): boolean {
      return (
        !this.requisiteCrypto.name ||
        !this.requisiteCrypto.coin ||
        !this.requisiteCrypto.network ||
        !this.requisiteCrypto.address ||
        this.loading
      );
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", RequisitesComponent.requisitesMain);
    },

    handleVerificationClose(): void {
      this.isVerification = false;

      this.$emit("update:component", RequisitesComponent.requisitesMain);
    },

    async handleContinue(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .requisiteCrypto(
            RequisiteCryptoRequestFactory({
              ...this.requisiteCrypto,
              user_id: this.displayedUserId,
            })
          )
          .then(() => {
            this.modalRepo.UPDATE_MODAL({
              title: ModalTitle.requisites,
              label: ModalLabel.requisites,
              icon: ModalIcon.requisites,
              callback: () => {
                this.isVerification = true;
              },
            });
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
