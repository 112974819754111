import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-header";

export const SVG: SvgAttribute = {
  logout: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_11379)">
    <path opacity="0.976" fill-rule="evenodd" clip-rule="evenodd" d="M9.08203 -0.0195312C9.66797 -0.0195312 10.2539 -0.0195312 10.8398 -0.0195312C14.1982 0.372988 16.7828 1.98757 18.5938 4.82422C18.7945 5.1607 18.9638 5.51227 19.1016 5.87891C19.152 6.58387 18.8264 6.98754 18.125 7.08984C17.7271 7.06676 17.4276 6.88449 17.2266 6.54297C15.8766 3.89734 13.7217 2.38042 10.7617 1.99219C7.48188 1.81245 4.93633 3.08198 3.125 5.80078C1.83515 8.02816 1.6138 10.3589 2.46094 12.793C3.61199 15.573 5.66277 17.2722 8.61328 17.8906C11.8829 18.3158 14.5196 17.2286 16.5234 14.6289C16.8441 14.1374 17.1501 13.6361 17.4414 13.125C17.9941 12.7482 18.4954 12.8068 18.9453 13.3008C19.0966 13.5414 19.1487 13.8018 19.1016 14.082C18.469 15.5096 17.5511 16.7271 16.3477 17.7344C14.7341 19.0227 12.8982 19.7714 10.8398 19.9805C10.2539 19.9805 9.66797 19.9805 9.08203 19.9805C5.61926 19.5551 2.99558 17.8493 1.21094 14.8633C0.535453 13.6119 0.125297 12.2838 -0.0195312 10.8789C-0.0195312 10.28 -0.0195312 9.68098 -0.0195312 9.08203C0.482285 5.1418 2.53958 2.34883 6.15234 0.703125C7.10234 0.332147 8.07891 0.0912613 9.08203 -0.0195312Z"/>
    <path opacity="0.979" fill-rule="evenodd" clip-rule="evenodd" d="M10.8045 6.36963C11.073 6.3452 11.3225 6.39711 11.5529 6.52537C12.4197 7.32713 13.2864 8.12885 14.1532 8.9306C14.6628 9.62743 14.6753 10.3311 14.1907 11.0417C13.3114 11.855 12.4321 12.6682 11.5529 13.4815C11.0317 13.7466 10.5764 13.6716 10.1872 13.2566C10.0023 12.9039 10.0147 12.5578 10.2246 12.2183C10.6923 11.7857 11.16 11.3531 11.6277 10.9205C9.84419 10.909 8.06074 10.8975 6.27725 10.8859C5.7422 10.8005 5.46783 10.5064 5.4541 10.0034C5.47251 9.56524 5.70946 9.2826 6.165 9.15555C7.99837 9.14403 9.83173 9.13247 11.6651 9.12095C11.1724 8.66527 10.6798 8.20962 10.1872 7.75394C9.87158 7.08902 10.0774 6.62757 10.8045 6.36963Z"/>
    </g>
    <defs>
    <clipPath id="clip0_1_11379">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
