<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAuthCard
      headerSvg="fingerprint"
      headerTitle="Вход в систему"
      footerTitle="У вас еще нет учетной записи?"
    >
      <template #default>
        <LoginForm
          :loading="loading"
          :message="message"
          v-model:email="user.email"
          v-model:password="user.password"
          v-model:isRemember="isRemember"
          @login="handleLogin"
          @google="handleGoogle"
        />
      </template>

      <template #footer>
        <UILink
          label="Создать аккаунт"
          view="main-outline"
          to="/registration"
        />
      </template>
    </SharedAuthCard>
  </div>
</template>
