export interface WalletRequisitesRadio {
  id: number;
  label: string;
  value: string;
}

export enum WalletRequisitesRadioLabel {
  pattern = "Использовать шаблон реквизитов",
  new = "Новые реквизиты",
}

export enum WalletRequisitesRadioValue {
  pattern = "pattern",
  new = "new",
}
