import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, {
          class: _normalizeClass(_ctx.b('header-back')),
          onClick: _ctx.handleBack
        }, null, 8, ["class", "onClick"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-group'))
        }, [
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t("Регистрационная информация")), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('header-label'))
          }, _toDisplayString(_ctx.$t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )), 3)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Организационно-правовая форма вашей организации*")), 3),
          _createVNode(_component_SharedSelect, {
            placeholder: "Выберите организационно-правовую форму",
            view: "light",
            options: _ctx.displayedOrganizationalAndLegalFormOptions,
            value: _ctx.kybRegistrationInfo.organisation_legal_form,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybRegistrationInfo.organisation_legal_form) = $event))
          }, null, 8, ["options", "value"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("К какой сфере относится ваш бизнес?*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kybRegistrationInfo.business_area,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybRegistrationInfo.business_area) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group', { address: true }))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Адрес организации*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-block'))
          }, [
            _createVNode(_component_SharedSelect, {
              class: _normalizeClass(_ctx.b('form-country')),
              placeholder: "Cтрана",
              view: "light",
              options: _ctx.displayedCountryOptions,
              value: _ctx.kybRegistrationInfo.address.country,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybRegistrationInfo.address.country) = $event))
            }, null, 8, ["class", "options", "value"]),
            _createVNode(_component_SharedSelect, {
              class: _normalizeClass(_ctx.b('form-city')),
              placeholder: "Город",
              view: "light",
              options: _ctx.displayedCityOptions,
              value: _ctx.kybRegistrationInfo.address.city,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybRegistrationInfo.address.city) = $event))
            }, null, 8, ["class", "options", "value"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-state')),
              placeholder: "Штат / Область",
              view: "light",
              modelValue: _ctx.kybRegistrationInfo.address.state,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybRegistrationInfo.address.state) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-street')),
              placeholder: "Улица",
              view: "light",
              modelValue: _ctx.kybRegistrationInfo.address.street,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybRegistrationInfo.address.street) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-office')),
              placeholder: "Номер Офиса",
              view: "light",
              modelValue: _ctx.kybRegistrationInfo.address.office,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybRegistrationInfo.address.office) = $event))
            }, null, 8, ["class", "modelValue"]),
            _createVNode(_component_UIInput, {
              class: _normalizeClass(_ctx.b('form-index')),
              placeholder: "Индекс",
              view: "light",
              modelValue: _ctx.kybRegistrationInfo.address.zip,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybRegistrationInfo.address.zip) = $event))
            }, null, 8, ["class", "modelValue"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Фактический адрес совпадает с адресом регистрации?*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-checkboxes'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-checkbox'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: true,
                value: _ctx.kybRegistrationInfo.address.isActual,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('form-checkbox-label'))
              }, _toDisplayString(_ctx.$t("Да")), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-checkbox'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: false,
                value: _ctx.kybRegistrationInfo.address.isActual,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('form-checkbox-label'))
              }, _toDisplayString(_ctx.$t("Нет")), 3)
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Контактный номер*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            maska: "+7 ### ###-##-##",
            modelValue: _ctx.kybRegistrationInfo.phone,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybRegistrationInfo.phone) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Сайт")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.kybRegistrationInfo.site,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybRegistrationInfo.site) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}