import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "default-navbar";

export const SVG: SvgAttribute = {
  wallet: `<svg width="32" height="33" viewBox="0 0 32 33" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.968" fill-rule="evenodd" clip-rule="evenodd" d="M7.03126 3.21875C9.90632 3.20833 12.7813 3.21875 15.6563 3.25C17.9073 3.41038 19.5428 4.4833 20.5625 6.46875C20.7258 6.851 20.8403 7.24681 20.9063 7.65625C23.4713 7.58963 25.4401 8.63125 26.8125 10.7813C27.5136 12.0681 27.7949 13.4431 27.6563 14.9063C29.1001 14.8826 29.9855 15.5701 30.3125 16.9688C30.3542 18.0938 30.3542 19.2188 30.3125 20.3438C29.9997 21.7713 29.1143 22.4588 27.6563 22.4063C27.8685 25.0538 26.9101 27.1475 24.7813 28.6875C23.8293 29.2965 22.7876 29.6298 21.6563 29.6875C16.9688 29.7292 12.2813 29.7292 7.59376 29.6875C5.0627 29.4386 3.23979 28.1782 2.12501 25.9063C1.85037 25.2452 1.68371 24.5577 1.62501 23.8438C1.56388 18.6131 1.58472 13.3839 1.68751 8.15625C2.34238 5.28235 4.12364 3.63651 7.03126 3.21875ZM7.09376 5.28125C10.0539 5.25217 13.0123 5.28342 15.9688 5.375C17.3256 5.59773 18.2631 6.33731 18.7813 7.59375C14.9065 7.64581 11.0314 7.67706 7.15626 7.6875C5.82777 7.85713 4.67152 8.38838 3.68751 9.28125C3.73828 7.46006 4.60286 6.19968 6.28126 5.5C6.55745 5.41548 6.82826 5.34256 7.09376 5.28125ZM7.34376 9.71875C12.3252 9.68838 17.3044 9.71963 22.2813 9.8125C24.7005 10.6407 25.8046 12.3386 25.5938 14.9063C23.0947 14.9262 21.6989 16.1762 21.4063 18.6563C21.7014 21.1278 23.0972 22.3778 25.5938 22.4063C25.8216 24.5863 24.9883 26.2009 23.0938 27.25C22.7725 27.3918 22.4392 27.4959 22.0938 27.5625C17.2828 27.6652 12.4703 27.686 7.65626 27.625C5.82896 27.4197 4.57896 26.4614 3.90626 24.75C3.79871 24.4136 3.72579 24.0698 3.68751 23.7188C3.64584 20.3438 3.64584 16.9688 3.68751 13.5938C4.04308 11.478 5.26183 10.1863 7.34376 9.71875ZM24.8438 16.9688C25.9538 16.9392 27.058 16.9704 28.1563 17.0625C28.1954 17.1094 28.2266 17.1615 28.25 17.2188C28.2917 18.1979 28.2917 19.1771 28.25 20.1563C28.198 20.2083 28.1458 20.2604 28.0938 20.3125C27.0313 20.3542 25.9688 20.3542 24.9063 20.3125C23.5235 19.7884 23.1798 18.8822 23.875 17.5938C24.1583 17.3122 24.4813 17.1039 24.8438 16.9688Z"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M9.15646 14.2813C11.9274 14.2708 14.6982 14.2813 17.469 14.3125C18.275 14.5839 18.5146 15.1151 18.1877 15.9063C18.0462 16.0896 17.8691 16.225 17.6565 16.3125C14.7606 16.3542 11.8648 16.3542 8.96896 16.3125C8.24333 15.9268 8.08708 15.3747 8.50021 14.6563C8.69202 14.4721 8.91077 14.3471 9.15646 14.2813Z"/>
  </svg>`,
  application: `<svg width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M11.4063 3.46875C14.448 3.45833 17.4896 3.46875 20.5313 3.5C26.0418 3.59381 28.8438 6.39589 28.9375 11.9063C28.9792 14.9479 28.9792 17.9896 28.9375 21.0312C28.8438 26.5416 26.0418 29.3437 20.5313 29.4375C17.4896 29.4792 14.4479 29.4792 11.4063 29.4375C5.89578 29.3437 3.09369 26.5416 3 21.0312C2.95833 17.9896 2.95833 14.9479 3 11.9063C3.10465 6.39537 5.90673 3.58287 11.4063 3.46875ZM14.4688 5.46875C16.4898 5.48003 18.5107 5.51128 20.5313 5.5625C21.6457 5.58177 22.7291 5.76927 23.7813 6.125C25.4253 6.92294 26.394 8.225 26.6875 10.0313C26.7866 10.6735 26.8491 11.3193 26.875 11.9688C26.886 13.4714 26.9173 14.9714 26.9688 16.4688C26.9173 17.9661 26.886 19.4661 26.875 20.9688C26.8584 22.0016 26.7021 23.0121 26.4063 24C25.6835 25.8267 24.3502 26.8892 22.4063 27.1875C21.7845 27.2821 21.1595 27.3446 20.5313 27.375C19.0103 27.4011 17.4895 27.4324 15.9688 27.4688C14.448 27.4324 12.9272 27.4011 11.4063 27.375C10.3944 27.3525 9.40475 27.1963 8.4375 26.9062C6.87038 26.2766 5.84954 25.1516 5.375 23.5312C5.25673 22.995 5.1734 22.4533 5.125 21.9062C5.05934 20.0938 5.00725 18.2813 4.96875 16.4688C5.00725 14.6563 5.05934 12.8438 5.125 11.0313C5.14571 8.76881 6.15613 7.13338 8.15625 6.125C8.92381 5.84648 9.7155 5.67981 10.5313 5.625C11.8533 5.56398 13.1658 5.5119 14.4688 5.46875Z"/>
    <path opacity="0.988" fill-rule="evenodd" clip-rule="evenodd" d="M15.5941 11.4687C16.2103 11.3343 16.6583 11.5427 16.9378 12.0937C16.9691 13.2185 16.9795 14.3435 16.9691 15.4687C18.0943 15.4583 19.2193 15.4687 20.3441 15.5C20.7808 15.6754 20.9891 15.9983 20.9691 16.4687C20.9891 16.9392 20.7808 17.262 20.3441 17.4375C19.2193 17.4687 18.0943 17.4792 16.9691 17.4687C16.9795 18.5939 16.9691 19.7189 16.9378 20.8437C16.7624 21.2804 16.4395 21.4887 15.9691 21.4687C15.4987 21.4887 15.1758 21.2804 15.0003 20.8437C14.9691 19.7189 14.9587 18.5939 14.9691 17.4687C13.8439 17.4792 12.7189 17.4687 11.5941 17.4375C11.1574 17.262 10.9491 16.9392 10.9691 16.4687C10.9491 15.9983 11.1574 15.6754 11.5941 15.5C12.7189 15.4687 13.8439 15.4583 14.9691 15.4687C14.9587 14.3435 14.9691 13.2185 15.0003 12.0937C15.1267 11.8112 15.3247 11.6029 15.5941 11.4687Z"/>
    <path d="M20 28C20.5 22 21 21.5 27.5 21.5" stroke-width="2"/>
  </svg>`,
  history: `<svg width="32" height="33" viewBox="0 0 32 33" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M3.66174 3.5C3.89929 3.5 4.13684 3.5 4.37439 3.5C4.6788 3.62634 4.8909 3.84692 5.01068 4.16174C5.06078 4.63578 5.1032 5.11087 5.13794 5.58704C5.20453 6.50051 5.13794 7.16186 6.20691 7.44501C6.88562 7.46197 7.56435 7.47895 8.24304 7.49591C8.9535 7.73755 9.19957 8.22113 8.98114 8.94666C8.84579 9.25167 8.61672 9.44678 8.29395 9.53204C6.78381 9.566 5.27368 9.566 3.76355 9.53204C3.40222 9.4252 3.15619 9.19614 3.02545 8.84485C2.99152 7.30079 2.99152 5.75671 3.02545 4.21265C3.12352 3.86878 3.33562 3.63123 3.66174 3.5Z"/>
    <path opacity="0.975" fill-rule="evenodd" clip-rule="evenodd" d="M15.612 9.44406C16.2391 9.32073 16.6887 9.54129 16.9609 10.1058C16.9778 12.0741 16.9948 14.0423 17.0118 16.0106C18.3198 17.3016 19.6093 18.6081 20.8804 19.9301C21.1568 20.5772 20.9956 21.0777 20.3969 21.4318C19.9962 21.5667 19.6228 21.5158 19.277 21.2791C17.9111 19.9132 16.5452 18.5473 15.1793 17.1814C15.0985 17.0709 15.0306 16.9521 14.9757 16.825C14.9417 14.5853 14.9417 12.3456 14.9757 10.1058C15.0954 9.79097 15.3075 9.57041 15.612 9.44406Z"/>
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M20.5312 3.5C17.4896 3.46875 14.448 3.45833 11.4062 3.46875C7.18537 3.55634 4.55343 5.23342 3.51043 8.5H5.68095C6.17584 7.49913 7.00094 6.70746 8.15625 6.125C8.92381 5.84648 9.7155 5.67981 10.5312 5.625C11.8533 5.56398 13.1658 5.5119 14.4688 5.46875C16.4898 5.48003 18.5107 5.51128 20.5312 5.5625C21.6457 5.58177 22.7291 5.76927 23.7812 6.125C25.4253 6.92294 26.394 8.225 26.6875 10.0313C26.7866 10.6735 26.8491 11.3193 26.875 11.9688C26.886 13.4714 26.9172 14.9714 26.9688 16.4688C26.9172 17.9661 26.886 19.4661 26.875 20.9688C26.8584 22.0016 26.7021 23.0121 26.4062 24C25.6835 25.8268 24.3502 26.8893 22.4062 27.1875C21.7845 27.2821 21.1595 27.3446 20.5312 27.375C19.0103 27.4011 17.4895 27.4324 15.9688 27.4688C14.448 27.4324 12.9272 27.4011 11.4062 27.375C10.3944 27.3525 9.40475 27.1963 8.4375 26.9063C6.87038 26.2766 5.84954 25.1516 5.375 23.5313C5.25673 22.995 5.17339 22.4533 5.125 21.9063C5.05972 20.1042 5.00785 18.3021 4.96942 16.5H2.96875C2.96889 18.0104 2.97931 19.5208 3 21.0313C3.09369 26.5416 5.89578 29.3437 11.4062 29.4375C14.4479 29.4792 17.4896 29.4792 20.5312 29.4375C26.0417 29.3437 28.8438 26.5416 28.9375 21.0313C28.9792 17.9896 28.9792 14.9479 28.9375 11.9063C28.8438 6.39589 26.0417 3.59381 20.5312 3.5Z"/>
    <rect x="3" y="15.5" width="2" height="4" rx="1"/>
  </svg>`,
  settings: `<svg width="32" height="32" viewBox="0 0 32 32" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M15.0938 1.78136C16.1487 1.6809 17.1695 1.81631 18.1563 2.18761C20.6979 3.64594 23.2396 5.10428 25.7813 6.56261C26.3438 6.95842 26.9063 7.3543 27.4688 7.75011C28.5587 8.56573 29.0691 9.65948 29 11.0314C29.0417 14.323 29.0417 17.6147 29 20.9064C29.0692 22.2781 28.5588 23.3719 27.4688 24.1876C26.9063 24.5834 26.3438 24.9793 25.7813 25.3751C23.3646 26.7709 20.9479 28.1668 18.5313 29.5626C16.9258 30.3049 15.3008 30.3465 13.6563 29.6876C10.9585 28.1409 8.271 26.5784 5.59375 25.0001C5.09375 24.6251 4.59375 24.2501 4.09375 23.8751C3.23863 23.0516 2.85322 22.0412 2.9375 20.8439C2.89583 17.573 2.89583 14.3022 2.9375 11.0314C2.85394 9.78055 3.28102 8.7493 4.21875 7.93761C4.81186 7.4848 5.41603 7.0473 6.03125 6.62511C8.65625 5.12511 11.2813 3.62511 13.9063 2.12511C14.3022 1.98202 14.6981 1.86744 15.0938 1.78136ZM15.3438 3.84386C15.9365 3.80885 16.5198 3.86093 17.0938 4.00011C19.8953 5.55692 22.6661 7.16111 25.4063 8.81261C25.7891 9.09111 26.1536 9.39317 26.5 9.71886C26.6931 9.98023 26.8181 10.2719 26.875 10.5939C26.9997 14.1772 26.9997 17.7605 26.875 21.3439C26.8181 21.6658 26.6931 21.9575 26.5 22.2189C25.9836 22.6937 25.4316 23.1209 24.8438 23.5001C22.4688 24.8751 20.0938 26.2501 17.7188 27.6251C16.6039 28.2241 15.4789 28.2449 14.3438 27.6876C12.0414 26.3385 9.72894 25.0052 7.40625 23.6876C6.83281 23.3072 6.27031 22.9113 5.71875 22.5001C5.36266 22.1944 5.14391 21.8089 5.0625 21.3439C5.02083 17.7605 5.02083 14.1772 5.0625 10.5939C5.11369 10.3038 5.21785 10.0329 5.375 9.78136C6.00169 9.20255 6.67875 8.69217 7.40625 8.25011C9.68731 6.95342 11.9581 5.6409 14.2188 4.31261C14.5794 4.10041 14.9544 3.94416 15.3438 3.84386Z"/>
    <path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M15.1563 10.9688C17.6915 10.7513 19.5145 11.7722 20.6251 14.0313C21.4594 16.5903 20.8031 18.6633 18.6563 20.2501C17.1719 21.062 15.6302 21.187 14.0313 20.6251C11.6471 19.43 10.6576 17.5029 11.0626 14.8438C11.4891 13.1885 12.4787 12.0115 14.0313 11.3126C14.4083 11.1771 14.7833 11.0625 15.1563 10.9688ZM15.4063 13.0313C13.6956 13.5665 12.9143 14.7332 13.0626 16.5313C13.5785 18.2549 14.7347 19.0361 16.5313 18.8751C18.2549 18.3592 19.0361 17.203 18.8751 15.4063C18.3571 13.6915 17.2009 12.8998 15.4063 13.0313Z"/>
  </svg>`,
};
