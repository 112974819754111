import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Валюта")), 3),
          _createVNode(_component_SharedSelect, { view: "light" })
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Полное фирменное наименование Общества на официальном языке")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Полное фирменное наименование Общества на английском языке")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("ИНН")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Р/с № (мультивалютный)")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Код назначения платежа")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Назначение платежа")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('controls'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedControls, (control) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(
            _ctx.b('control', { active: control.isActive, added: control.isAdded })
          ),
            key: control.id,
            onClick: ($event: any) => (_ctx.handleControl(control))
          }, [
            _createTextVNode(_toDisplayString(control.label) + " ", 1),
            (control.isAdded)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(_ctx.b('control-icon')),
                  innerHTML: _ctx.displayedIcons.added
                }, null, 10, _hoisted_2))
              : _createCommentVNode("", true)
          ], 10, _hoisted_1))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("БАНК-корреспондент")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("БИК")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("ИНН")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Корр. счет")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, "SWIFT", 2),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("БАНК получателя")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Корр. счет")), 3),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, "SWIFT", 2),
          _createVNode(_component_UIInput, { view: "light" })
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('save')),
        view: "main",
        label: "Сохранить",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.handleSave
      }, null, 8, ["class", "disabled", "onClick"])
    ], 2)
  ], 2))
}