import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";

import { KycUploadFileResponseFactory } from "@/shared/factory/kyc";
import {
  FileType,
  KybDocumentsFileName,
  KycPersonalDocumentsFileName,
} from "@/shared/constants/constants";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { KycUploadFileResponse } from "@/shared/repository/repo";
import { KybUserFileResponseFactory } from "@/shared/factory/kyb";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
  },
  data() {
    return {
      passportSelfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ],
        type: FileType.image,
      }),
      passportOrganization: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ],
        type: FileType.image,
      }),
      passportAddress: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ],
        type: FileType.image,
      }),
      selfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-selfie"],
        type: FileType.image,
      }),
      documentBroker: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-broker"],
        type: FileType.document,
      }),
      declaration: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-declaration"
        ],
        type: FileType.document,
      }),
      broker: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-broker"],
        type: FileType.document,
      }),
    };
  },
  // async created(): Promise<void> {
  //   try {
  //     await this.projectRepo.kycUserFiles(this.id).then((userFiles) => {
  //       userFiles.forEach((userFile) =>
  //         this.handleCurrentFileResponse(userFile)
  //       );
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
  methods: {
    handleKycCurrentFileResponse(params: KycUploadFileResponse): void {
      switch (extractFileName(params.name)) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          this.passportSelfie = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          this.passportOrganization = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          this.passportAddress = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          this.selfie = KycUploadFileResponseFactory(params);

          break;
        default:
          break;
      }
    },
  },
});
