export interface WalletTabsTab {
  id: number;
  label: WalletTabsTabLabel;
  value: WalletTabsTabValue;
  isActive: boolean;
}

export enum WalletTabsTabLabel {
  fiat = "Фиат",
  crypto = "Крипто",
}

export enum WalletTabsTabValue {
  fiat = "fiat",
  crypto = "crypto",
}
