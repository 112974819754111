import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_UIInput, {
      placeholder: "Адрес электронной почты*",
      view: "light",
      modelValue: _ctx.email,
      "onUpdate:modelValue": _ctx.changeEmail
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_UIButton, {
      label: "ПРОДОЛЖИТЬ",
      view: "main",
      disabled: _ctx.isNextDisabled,
      onClick: _ctx.handleNext
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}