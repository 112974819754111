import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { RequisitesComponent } from "./model";

import {
  RequisitesMain,
  RequisitesBank,
  RequisitesCrypto,
} from "@/components/requisites";

type ComponentType =
  | typeof RequisitesMain
  | typeof RequisitesBank
  | typeof RequisitesCrypto;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    RequisitesMain,
    RequisitesBank,
    RequisitesCrypto,
  },
  data() {
    return {
      component: RequisitesComponent.requisitesMain,
    };
  },
  computed: {
    displayedComponent(): ComponentType {
      switch (this.component) {
        case RequisitesComponent.requisitesMain:
          return RequisitesMain;
        case RequisitesComponent.requisitesBank:
          return RequisitesBank;
        case RequisitesComponent.requisitesCrypto:
          return RequisitesCrypto;
        default:
          return RequisitesMain;
      }
    },
  },
});
