import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('wrapper', { big: _ctx.isBig }))
    }, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass(_ctx.b('title', { center: _ctx.isCenter }))
          }, _toDisplayString(_ctx.$t(_ctx.title)), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('close')),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('close-icon')),
          innerHTML: _ctx.displayedCloseIcon
        }, null, 10, _hoisted_1)
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleClose]
    ])
  ], 2))
}