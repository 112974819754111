import { createApp } from "vue";
import VueBemCn from "vue-bem-cn";
import ClickOutside from "click-outside-vue3";
import { vMaska } from "maska";
import VueDatePicker from "@vuepic/vue-datepicker";
import { createI18n } from "vue-i18n";

import ruRU from "./locales/ru-RU.json";
import enUS from "./locales/en-US.json";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import "@/assets/styles/main.scss";
import "@vuepic/vue-datepicker/dist/main.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import AxiosPlugin from "@/plugins/axios";
import ProjectServicesPlugin from "@/plugins/project-services";

type MessageSchema = typeof ruRU;

const i18n = createI18n<[MessageSchema], "ru-RU" | "en-US">({
  locale: "ru-RU",
  fallbackLocale: "en-US",
  messages: {
    "ru-RU": ruRU,
    "en-US": enUS,
  },
});

createApp(App)
  .use(store)
  .use(VueBemCn)
  .use(ClickOutside)
  .use(router)
  .use(AxiosPlugin)
  .use(ProjectServicesPlugin)
  .use(i18n)
  .directive("maska", vMaska)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
