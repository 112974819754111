import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  computed: {
    displayedLogo(): string {
      return SVG.logo;
    },
  },
});
