<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('links')">
      <router-link
        :class="b('link')"
        v-for="link in displayedLinks"
        :key="link.id"
        :to="link.to"
      >
        <span :class="b('link-icon')" v-html="link.icon"></span>

        {{ $t(link.label) }}
      </router-link>
    </div>
  </div>
</template>
