import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletMain = _resolveComponent("WalletMain")!
  const _component_WalletHistory = _resolveComponent("WalletHistory")!
  const _component_WalletApplications = _resolveComponent("WalletApplications")!
  const _component_WalletInput = _resolveComponent("WalletInput")!
  const _component_WalletConclusion = _resolveComponent("WalletConclusion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Кошелек")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_WalletMain, {
        isConclusion: _ctx.isConclusion,
        "onUpdate:isConclusion": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isConclusion) = $event)),
        isInput: _ctx.isInput,
        "onUpdate:isInput": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isInput) = $event))
      }, null, 8, ["isConclusion", "isInput"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createVNode(_component_WalletHistory),
        _createVNode(_component_WalletApplications)
      ], 2)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isInput)
          ? (_openBlock(), _createBlock(_component_WalletInput, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isInput: _ctx.isInput,
              "onUpdate:isInput": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isInput) = $event))
            }, null, 8, ["class", "isInput"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isConclusion)
          ? (_openBlock(), _createBlock(_component_WalletConclusion, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isConclusion: _ctx.isConclusion,
              "onUpdate:isConclusion": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isConclusion) = $event))
            }, null, 8, ["class", "isConclusion"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}