<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('wrapper')">
      <p :class="b('label')">
        {{ $t("Код-подтверждение отправлен на email") }}
      </p>

      <div :class="b('form')">
        <SharedVerification :class="b('verification')" v-model:code="code" />

        <div :class="b('repeat')">
          <button
            :class="b('repeat-action')"
            :disabled="isRepeatDisabled"
            @click="handleRepeat"
          >
            {{ $t("Отправить повторно") }}
          </button>

          <span :class="b('repeat-label')" v-if="seconds">
            {{ displayedRepeatLabel }}
          </span>
        </div>
      </div>

      <UIButton
        label="Создать заявку"
        view="main"
        :disabled="isCreateDisabled"
        @click="handleCreate"
      />
    </div>
  </div>
</template>
