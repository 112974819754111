<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAuthCard
      headerSvg="forgot"
      headerTitle="Восстановление пароля"
      footerTitle="У вас еще нет учетной записи?"
      :isTerms="true"
    >
      <template #default>
        <component
          :is="displayedComponent"
          :loading="loading"
          :isVerification="isVerification"
          v-model:email="user.email"
          v-model:code="user.code"
          v-model:password="user.password"
          v-model:password_confirmation="user.password_confirmation"
          @next="handleNext"
          @verification="handleVerification"
          @forgot="handleForgot"
        ></component>
      </template>

      <template #footer>
        <UILink
          label="Создать аккаунт"
          view="main-outline"
          to="/registration"
        />
      </template>
    </SharedAuthCard>
  </div>
</template>
