import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSwitch = _resolveComponent("SharedSwitch")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCheckbox = _resolveComponent("SharedCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('security'))
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Безопасность")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('security-block'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('security-two-factor'))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedIcons.twoFactor
          }, null, 10, _hoisted_1),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('security-two-factor-group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('security-title'))
            }, _toDisplayString(_ctx.$t("Двухфакторная аутентификация")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('security-label'))
            }, _toDisplayString(_ctx.$t(
                  "Используется для вывода средств и внесения изменений в систему безопасности"
                )), 3)
          ], 2),
          _createVNode(_component_SharedSwitch, {
            value: _ctx.twoFactor,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.twoFactor) = $event))
          }, null, 8, ["value"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('security-password'))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedIcons.password
          }, null, 10, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('security-password-info'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('security-title'))
            }, _toDisplayString(_ctx.$t("Пароль")), 3)
          ], 2),
          _createVNode(_component_UIButton, {
            label: "Изменить",
            view: "main-outline-small",
            onClick: _ctx.handlePassword
          }, null, 8, ["onClick"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('divider'))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('security-verification'))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedIcons.email
          }, null, 10, _hoisted_3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('security-verification-group'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('security-verification-info'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('security-title', { email: true }))
              }, " email@email.com ", 2),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('security-label', { email: true }))
              }, _toDisplayString(_ctx.$t(
                    "Используется для вывода средств и внесения изменений в систему безопасности"
                  )), 3)
            ], 2),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('security-verification-tag'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('icon')),
                innerHTML: _ctx.displayedIcons.check
              }, null, 10, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("Подтвержден")), 1)
            ], 2)
          ], 2),
          _createVNode(_component_UIButton, {
            label: "Изменить",
            view: "main-outline-small",
            onClick: _ctx.handleEmail
          }, null, 8, ["onClick"])
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('email'))
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Email уведомления")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('email-groups'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('email-group'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('email-title'))
          }, _toDisplayString(_ctx.$t("Финансовые операции")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('email-list'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('email-item'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('email-item-label'))
              }, _toDisplayString(_ctx.$t("Выплата за майнинг")), 3),
              _createVNode(_component_SharedCheckbox, { view: "22" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('email-item'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('email-item-label'))
              }, _toDisplayString(_ctx.$t("Пополните свой кошелек")), 3),
              _createVNode(_component_SharedCheckbox, { view: "22" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('email-item'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('email-item-label'))
              }, _toDisplayString(_ctx.$t("Выплата за майнинг")), 3),
              _createVNode(_component_SharedCheckbox, { view: "22" })
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('email-group'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('email-title'))
          }, _toDisplayString(_ctx.$t("Статусы майнинга")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('email-list'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('email-item'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('email-item-label'))
              }, _toDisplayString(_ctx.$t("Подключение рабочего")), 3),
              _createVNode(_component_SharedCheckbox, { view: "22" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('email-item'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('email-item-label'))
              }, _toDisplayString(_ctx.$t("Отключение рабочего")), 3),
              _createVNode(_component_SharedCheckbox, { view: "22" })
            ], 2)
          ], 2)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}