<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack :class="b('header-back')" @click="handleBack" />

      <div :class="b('header-group')">
        <h3 :class="b('header-title')">
          {{ $t("Основная информация об организации") }}
        </h3>

        <p :class="b('header-label')">
          {{
            $t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )
          }}
        </p>
      </div>
    </div>

    <div :class="b('form')">
      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("В какой стране зарегистрирована ваша организация?*") }}
        </h5>

        <SharedCountry
          placeholder="Выберите страну"
          v-model:value="kybBasicInfo.country"
        />
      </div>

      <div :class="b('form-block')">
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Как называется ваша организация?*") }}
          </h5>

          <UIInput view="light" v-model="kybBasicInfo.organisation" />
        </div>

        <p :class="b('form-label')">
          {{
            $t(
              "Укажите полное юридическое название вашей организации в соответствии с Меморандумом и Уставом/Конституцией/Положениями."
            )
          }}
        </p>
      </div>

      <div :class="b('form-block')">
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Какой у вас регистрационный номер?*") }}
          </h5>

          <UIInput view="light" v-model="kybBasicInfo.regional_number" />
        </div>

        <p :class="b('form-label')">
          {{
            $t(
              "Введите регистрационный номер вашей компании. Мы используем этот номер для получения общедоступной информации о вашем бизнесе."
            )
          }}
        </p>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Дата регистрации*") }}
        </h5>

        <SharedDate
          format="yyyy-MM-dd"
          v-model:date="kybBasicInfo.registration_date"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Номер Налогоплательщика*") }}
        </h5>

        <UIInput view="light" v-model="kybBasicInfo.inn" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Ваша деятельность лицензируется?*") }}
        </h5>

        <div :class="b('form-radios')">
          <div :class="b('form-radio')">
            <SharedRadio
              view="light"
              :checked-value="true"
              v-model:value="kybBasicInfo.licensed"
            />

            <p :class="b('form-radio-label')">
              {{ $t("Да") }}
            </p>
          </div>

          <div :class="b('form-radio')">
            <SharedRadio
              view="light"
              :checked-value="false"
              v-model:value="kybBasicInfo.licensed"
            />

            <p :class="b('form-radio-label')">
              {{ $t("Нет") }}
            </p>
          </div>
        </div>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Телеграм для связи*") }}
        </h5>

        <UIInput
          view="light"
          :is-telegram="true"
          v-model="kybBasicInfo.telegram"
        />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
