<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Проверка") }}
    </h1>

    <div :class="b('content')">
      <VerificationTabs :class="b('tabs')" :tab="tab" @tab="changeTab" />

      <component
        v-if="isKycShow"
        :is="displayedKycComponent"
        v-model:component="kycComponent"
      />

      <component
        v-if="isKybShow"
        :is="displayedKybComponent"
        v-model:component="kybComponent"
        v-model:participant-id="participantId"
      />
    </div>
  </div>
</template>
