import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedVerification = _resolveComponent("SharedVerification")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b('', { empty: !_ctx.isIconShow }))
  }, [
    _createVNode(_component_SharedVerification, {
      title: "Проверка безопасности",
      code: _ctx.code,
      isError: _ctx.isVerificationError,
      "onUpdate:code": _ctx.changeCode
    }, null, 8, ["code", "isError", "onUpdate:code"]),
    (_ctx.isIconShow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedIcon
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}