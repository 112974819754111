import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedVerification } from "@/components/shared";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedVerification,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: "",
    },
    isVerification: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:code": null,
    verification: null,
  },
  computed: {
    displayedIcon(): string {
      return this.isVerification ? SVG.success : SVG.error;
    },

    isIconShow(): boolean {
      return this.code.length === CODE_MAX_LENGTH;
    },

    isVerificationError(): boolean {
      return this.isIconShow && !this.isVerification;
    },
  },
  methods: {
    changeCode(code: string): void {
      this.$emit("update:code", code);

      if (code.length === CODE_MAX_LENGTH) {
        this.$emit("verification", code);
      }
    },
  },
});
