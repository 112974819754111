import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-users-filter";

export const SVG: SvgAttribute = {
  search: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.972" fill-rule="evenodd" clip-rule="evenodd" d="M7.33821 0.0179528C10.1653 -0.147683 12.4984 0.83282 14.3376 2.95945C16.2181 5.42981 16.6384 8.13317 15.5987 11.0696C15.3012 11.8137 14.9019 12.493 14.4006 13.1076C15.5286 14.2352 16.6567 15.3628 17.7847 16.4904C18.1145 16.9855 18.0654 17.4408 17.6376 17.8561C17.2121 18.0728 16.8057 18.0448 16.4184 17.772C15.3044 16.6584 14.1904 15.5449 13.0764 14.4313C10.959 16.0509 8.5978 16.5482 5.99299 15.9231C3.12217 15.0593 1.22346 13.2034 0.296828 10.3552C-0.499424 7.10151 0.306304 4.32107 2.71402 2.01397C4.05008 0.87706 5.59149 0.211722 7.33821 0.0179528ZM7.80063 1.82487C10.2905 1.8314 12.1893 2.88192 13.4968 4.97648C14.5582 6.99275 14.5863 9.02378 13.5809 11.0696C12.2078 13.3494 10.183 14.435 7.50636 14.3262C4.7667 13.9546 2.95204 12.4628 2.06243 9.85097C1.41204 7.16517 2.1337 4.93106 4.22739 3.14855C5.28898 2.35365 6.48009 1.91242 7.80063 1.82487Z" fill="#36A1EA"/>
  </svg>`,
  searchClear: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1680_35449)">
    <circle cx="7.00016" cy="6.99967" r="4.66667" fill="white"/>
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M6.49436 0.560466C9.10892 0.475284 11.1187 1.52346 12.5237 3.705C13.5549 5.55398 13.6916 7.46804 12.9338 9.44718C12.0833 11.3697 10.6477 12.6229 8.62718 13.207C6.09834 13.7675 3.94275 13.1158 2.16038 11.2519C0.694449 9.48289 0.23872 7.47767 0.793193 5.23625C1.3908 3.26233 2.63494 1.86324 4.52561 1.03898C5.16253 0.78402 5.81878 0.624516 6.49436 0.560466ZM4.88108 4.3339C5.03098 4.34891 5.1677 4.39906 5.29124 4.48429C5.85217 5.04979 6.41725 5.61034 6.98655 6.16593C7.57482 5.5822 8.16728 5.00341 8.7639 4.42961C9.13924 4.26841 9.41722 4.37325 9.59788 4.74406C9.63914 4.9435 9.60269 5.12578 9.4885 5.29093C8.92301 5.85186 8.36246 6.41695 7.80686 6.98625C8.3906 7.57452 8.96938 8.16698 9.54319 8.76359C9.706 9.24011 9.54193 9.53179 9.051 9.63859C8.91727 9.61527 8.79422 9.56515 8.68186 9.4882C8.12093 8.9227 7.55585 8.36216 6.98655 7.80656C6.41725 8.36216 5.85217 8.9227 5.29124 9.4882C4.9175 9.69899 4.62129 9.6215 4.40257 9.25578C4.32762 9.03506 4.36407 8.83452 4.51194 8.65422C5.06831 8.10242 5.61975 7.54641 6.16624 6.98625C5.61064 6.41695 5.05009 5.85186 4.4846 5.29093C4.29461 4.9863 4.32652 4.70829 4.5803 4.45695C4.67745 4.39895 4.77772 4.35794 4.88108 4.3339Z" fill="#9E9FA8"/>
    </g>
    <defs>
    <clipPath id="clip0_1680_35449">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5" />
    <circle cx="8" cy="8" r="1.5" />
    <circle cx="8" cy="14" r="1.5" />
  </svg>`,
};
