import { defineComponent } from "vue";

import { ForgotComponent, PAGE_NAME } from "./attributes";

import { SharedAuthCard } from "@/components/shared";
import { UILink } from "@/components/ui";
import {
  ForgotEmail,
  ForgotVerification,
  ForgotPassword,
} from "@/components/forgot";

import { ForgotFactory } from "@/shared/factory/forgot";
import { NotifcationFactory } from "@/shared/factory/notification";
import {
  DEFAILT_SECONDS,
  NotificationIcon,
  NotificationLabel,
  NotificationTitle,
  RoutesName,
} from "@/shared/constants/constants";

type DisplayedComponent =
  | typeof ForgotEmail
  | typeof ForgotVerification
  | typeof ForgotPassword;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    SharedAuthCard,
    UILink,
    ForgotEmail,
    ForgotVerification,
    ForgotPassword,
  },
  data() {
    return {
      loading: false,
      user: ForgotFactory(),
      component: ForgotComponent.email,
      isVerification: false,
      notificationRepo: this.$projectServices.notificationRepo,
    };
  },
  computed: {
    displayedComponent(): DisplayedComponent {
      switch (this.component) {
        case ForgotComponent.email:
          return ForgotEmail;
        case ForgotComponent.verification:
          return ForgotVerification;
        case ForgotComponent.password:
          return ForgotPassword;
        default:
          return ForgotEmail;
      }
    },
  },
  methods: {
    async handleNext(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .emailCode(this.user.email)
          .then(() => {
            this.component = ForgotComponent.verification;
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async handleVerification(code: string): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .codeVerification(code)
          .then(() => {
            setTimeout(() => {
              this.component = ForgotComponent.password;
            }, DEFAILT_SECONDS);
            this.isVerification = true;
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async handleForgot(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .forgot(this.user)
          .then(() => {
            this.notificationRepo.updateNotification(
              NotifcationFactory({
                title: NotificationTitle.password,
                label: NotificationLabel.password,
                icon: NotificationIcon.password,
              })
            );

            this.$router.push({ name: RoutesName.login });
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
