import { defineComponent, PropType } from "vue";

import { COMPONENt_NAME } from "./attributes";
import {
  VerificationTab,
  VerificationTabLabel,
  VerificationTabLabelSmall,
  VerificationTabValue,
} from "./model";

export default defineComponent({
  name: COMPONENt_NAME,
  props: {
    tab: {
      type: <PropType<VerificationTabValue>>String,
      default: VerificationTabValue.kyc,
    },
  },
  emits: {
    tab: null,
  },
  computed: {
    displayedTabs(): VerificationTab[] {
      return [
        {
          id: 0,
          label: VerificationTabLabel.kyc,
          labelSmall: VerificationTabLabelSmall.kyc,
          value: VerificationTabValue.kyc,
          isActive: this.tab === VerificationTabValue.kyc,
        },
        {
          id: 1,
          label: VerificationTabLabel.kyb,
          labelSmall: VerificationTabLabelSmall.kyb,
          value: VerificationTabValue.kyb,
          isActive: this.tab === VerificationTabValue.kyb,
        },
      ];
    },
  },
  methods: {
    changeTab(tab: VerificationTab): void {
      this.$emit("tab", tab);
    },
  },
});
