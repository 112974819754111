import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-checkbox";

export const SVG: SvgAttribute = {
  checked: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_1_11156)">
      <path opacity="0.984" fill-rule="evenodd" clip-rule="evenodd" d="M15.9844 3.29684C15.9844 3.48434 15.9844 3.67184 15.9844 3.85934C15.9277 4.06631 15.8287 4.25381 15.6875 4.42184C12.4844 7.62496 9.28125 10.8281 6.07812 14.0312C5.84194 14.1931 5.58153 14.2451 5.29688 14.1875C5.16828 14.1492 5.05369 14.0867 4.95312 14C3.35331 12.4106 1.76476 10.8116 0.1875 9.20309C0.109552 9.05787 0.0418431 8.91202 -0.015625 8.76559C-0.015625 8.57809 -0.015625 8.39059 -0.015625 8.20309C0.0383544 8.0118 0.126896 7.83471 0.25 7.67184C0.516556 7.39484 0.792597 7.12921 1.07812 6.87496C1.57518 6.59552 2.06476 6.60593 2.54688 6.90621C3.54166 7.90099 4.53647 8.89581 5.53125 9.89059C8.15103 7.27081 10.7708 4.65099 13.3906 2.03121C13.876 1.70194 14.376 1.68111 14.8906 1.96871C15.2628 2.27831 15.5909 2.62726 15.875 3.01559C15.9134 3.11241 15.9498 3.20615 15.9844 3.29684Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1_11156">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>`,
};
