import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('logo')),
      innerHTML: _ctx.displayedLogo
    }, null, 10, _hoisted_1),
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t(
          "Объединение международных рынков и расширение прав и возможностей глобальных трейдеров"
        )), 3)
  ], 2))
}