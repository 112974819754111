import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.b('title'))
        }, _toDisplayString(_ctx.$t("Изменение баланса")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-label'))
              }, _toDisplayString(_ctx.$t("Кошелек")), 3),
              _createVNode(_component_SharedSelect, { view: "light" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-label'))
              }, _toDisplayString(_ctx.$t("Сумма")), 3),
              _createVNode(_component_UIInput, { view: "light" })
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Комментарий")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              "placeholder-view": "light",
              placeholder: "Добавьте комментарий при необходимости"
            })
          ], 2)
        ], 2),
        _createVNode(_component_UIButton, {
          view: "main",
          label: "Изменить баланс пользователя",
          disabled: _ctx.isChangeBalanceDisabled,
          onClick: _ctx.handleChangeBalance
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}