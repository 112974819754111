<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Загрузка документов") }}
      </h3>
    </div>

    <div :class="b('upload')">
      <h5 :class="b('title')">
        {{ $t("Загрузите подтверждающие документы*") }}
      </h5>

      <div :class="b('upload-group')">
        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentRegistrationCompany.file"
          :link="documentRegistrationCompany.link"
          @upload="handleUploadFile($event, documentRegistrationCompany.name)"
          @remove="handleRemoveFile(documentRegistrationCompany.name)"
        >
          <template #label>
            {{ $t("Сертификат о") }} <br />
            {{ $t("регистрации") }} <br />
            {{ $t("компании") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentRegulation.file"
          :link="documentRegulation.link"
          @upload="handleUploadFile($event, documentRegulation.name)"
          @remove="handleRemoveFile(documentRegulation.name)"
        >
          <template #label>
            <br />
            {{ $t("Устав") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentListOfShareholders.file"
          :link="documentListOfShareholders.link"
          @upload="handleUploadFile($event, documentListOfShareholders.name)"
          @remove="handleRemoveFile(documentListOfShareholders.name)"
        >
          <template #label>
            <br />
            {{ $t("Список акционеров") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentLicense.file"
          :link="documentLicense.link"
          @upload="handleUploadFile($event, documentLicense.name)"
          @remove="handleRemoveFile(documentLicense.name)"
        >
          <template #label>
            <br />
            {{ $t("Лицензия") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentCheckAddresses.file"
          :link="documentCheckAddresses.link"
          @upload="handleUploadFile($event, documentCheckAddresses.name)"
          @remove="handleRemoveFile(documentCheckAddresses.name)"
        >
          <template #label>
            {{ $t("Подтверждение") }} <br />
            {{ $t("адреса") }} <br />
            {{ $t("регистрации") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentSow.file"
          :link="documentSow.link"
          @upload="handleUploadFile($event, documentSow.name)"
          @remove="handleRemoveFile(documentSow.name)"
        >
          <template #label>
            {{ $t("Доказательства") }} <br />
            {{ $t("источника дохода") }} <br />
            SoW
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentSof.file"
          :link="documentSof.link"
          @upload="handleUploadFile($event, documentSof.name)"
          @remove="handleRemoveFile(documentSof.name)"
        >
          <template #label>
            {{ $t("Доказательства") }} <br />
            {{ $t("источника средств") }} <br />
            SoF
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentInn.file"
          :link="documentInn.link"
          @upload="handleUploadFile($event, documentInn.name)"
          @remove="handleRemoveFile(documentInn.name)"
        >
          <template #label>
            {{ $t("Свидетельство") }} <br />
            {{ $t("ИНН") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentRecordSheet.file"
          :link="documentRecordSheet.link"
          @upload="handleUploadFile($event, documentRecordSheet.name)"
          @remove="handleRemoveFile(documentRecordSheet.name)"
        >
          <template #label>
            {{ $t("Лист Записи") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentCreate.file"
          :link="documentCreate.link"
          @upload="handleUploadFile($event, documentCreate.name)"
          @remove="handleRemoveFile(documentCreate.name)"
        >
          <template #label>
            {{ $t("Решение о создании/") }} <br />
            {{ $t("Учредительный") }} <br />
            {{ $t("договор") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentUpdate.file"
          :link="documentUpdate.link"
          @upload="handleUploadFile($event, documentUpdate.name)"
          @remove="handleRemoveFile(documentUpdate.name)"
        >
          <template #label>
            {{ $t("Изменения в") }} <br />
            {{ $t("учредительные") }} <br />
            {{ $t("документы") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentPurpose.file"
          :link="documentPurpose.link"
          @upload="handleUploadFile($event, documentPurpose.name)"
          @remove="handleRemoveFile(documentPurpose.name)"
        >
          <template #label>
            {{ $t("О назначении") }} <br />
            {{ $t("единоличного") }} <br />
            {{ $t("исполнительного") }} <br />
            {{ $t("органа") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentFinance.file"
          :link="documentFinance.link"
          @upload="handleUploadFile($event, documentFinance.name)"
          @remove="handleRemoveFile(documentFinance.name)"
        >
          <template #label>
            {{ $t("Финансовая") }} <br />
            {{ $t("отчетность") }} <br />
            {{ $t("(за год/за квартал)") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('shared-upload')"
          :is-loading="loading"
          :is-document="true"
          :file="documentExample.file"
          :link="documentExample.link"
          @upload="handleUploadFile($event, documentExample.name)"
          @remove="handleRemoveFile(documentExample.name)"
        >
          <template #label>
            {{ $t("Карточка образцов") }} <br />
            {{ $t("подписей и печатей") }}
          </template>
        </SharedUpload>
      </div>
    </div>

    <div :class="b('download')">
      <h5 :class="b('title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('download-groups')">
        <div :class="b('download-group')">
          <SharedDownload>
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("юридического лица") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :isCenter="true"
            :is-loading="loading"
            :is-document="true"
            :file="documentKyb.file"
            :link="documentKyb.link"
            @upload="handleUploadFile($event, documentKyb.name)"
            @remove="handleRemoveFile(documentKyb.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('download-group')">
          <SharedDownload>
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("бенефициарного") }} <br />
              {{ $t("владельца") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :isCenter="true"
            :is-loading="loading"
            :is-document="true"
            :file="documentBeneficiary.file"
            :link="documentBeneficiary.link"
            @upload="handleUploadFile($event, documentBeneficiary.name)"
            @remove="handleRemoveFile(documentBeneficiary.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('download-group')">
          <SharedDownload>
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :isCenter="true"
            :is-loading="loading"
            :is-document="true"
            :file="documentBroker.file"
            :link="documentBroker.link"
            @upload="handleUploadFile($event, documentBroker.name)"
            @remove="handleRemoveFile(documentBroker.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('download-group')">
          <SharedDownload>
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :isCenter="true"
            :is-loading="loading"
            :is-document="true"
            :file="documentDeclaration.file"
            :link="documentDeclaration.link"
            @upload="handleUploadFile($event, documentDeclaration.name)"
            @remove="handleRemoveFile(documentDeclaration.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>

    <div :class="b('other')">
      <h5 :class="b('title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h5>

      <SharedUpload
        :class="b('other-upload')"
        :isCenter="true"
        :is-loading="loading"
        :is-document="true"
        :file="documentDirector.file"
        :link="documentDirector.link"
        @upload="handleUploadFile($event, documentDirector.name)"
        @remove="handleRemoveFile(documentDirector.name)"
      >
        <template #label>
          {{ $t("Приказ о назначении") }} <br />
          {{ $t("директора") }}
        </template>
      </SharedUpload>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
