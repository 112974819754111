<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Реквизиты для пополнения/вывода средств") }}
    </h1>

    <component :is="displayedComponent" v-model:component="component" />
  </div>
</template>
