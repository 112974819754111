import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminCommissionTabsTab, AdminCommissionTabsTabLabel } from "./model";

import { AdminCommissionTabValue } from "@/views/admin-commission/model";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    tab: {
      type: <PropType<AdminCommissionTabValue>>String,
      required: true,
    },
  },
  emits: {
    "update:tab": null,
  },
  computed: {
    displayedTabs(): AdminCommissionTabsTab[] {
      return [
        {
          id: 0,
          label: AdminCommissionTabsTabLabel.main,
          value: AdminCommissionTabValue.main,
          isActive: this.tab === AdminCommissionTabValue.main,
        },
        {
          id: 1,
          label: AdminCommissionTabsTabLabel.requisites,
          value: AdminCommissionTabValue.requisites,
          isActive: this.tab === AdminCommissionTabValue.requisites,
        },
      ];
    },
  },
  methods: {
    handleTab({ value }: AdminCommissionTabsTab): void {
      this.$emit("update:tab", value);
    },
  },
});
