import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { AdminUserAppStatusRequestFactory } from "@/shared/factory/admin-users";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminUserAppStatus } from "@/shared/repository/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIInput,
    UIButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: <PropType<AdminUserAppStatus>>String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:status": null,
    "update:loading": null,
  },
  data() {
    return {
      userApp: AdminUserAppStatusRequestFactory({
        id: this.id,
        status: this.status,
      }),
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "pending",
            value: "pending",
          },
          this.userApp.status
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "waiting",
            value: "waiting",
          },
          this.userApp.status
        ),
        SelectOptionFactory(
          {
            id: 2,
            label: "completed",
            value: "completed",
          },
          this.userApp.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "reject",
            value: "reject",
          },
          this.userApp.status
        ),
      ];
    },

    isSaveDisabled(): boolean {
      return (
        !this.id ||
        !this.userApp.status ||
        !this.userApp.message ||
        this.loading
      );
    },
  },
  methods: {
    handleChangeStatus(status: AdminUserAppStatus): void {
      this.userApp.status = status;

      this.$emit("update:status", status);
    },

    async handleSave(): Promise<void> {
      try {
        this.$emit("update:loading", true);

        await this.projectRepo.adminUserAppStatus(this.userApp);
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit("update:loading", false);
      }
    },
  },
});
