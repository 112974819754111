export interface AdminCommissionRequisitesControl {
  id: string;
  label: AdminCommissionRequisitesControlLabel;
  isActive: boolean;
  isAdded: boolean;
}

export enum AdminCommissionRequisitesControlLabel {
  "МБА Москва" = "МБА Москва",
  "Банк 131" = "Банк 131",
  "Биткоин банк" = "Биткоин банк",
  "Сбербанк" = "Сбербанк",
  "ВТБ" = "ВТБ",
  "Raifaisen Bank" = "Raifaisen Bank",
}
