import { Notification } from "@/shared/constants/constants";

export const NotifcationFactory = (
  params: Partial<Notification> = {}
): Notification => {
  return {
    title: params.title ? params.title : "",
    label: params.label ? params.label : "",
    icon: params.icon ? params.icon : "",
  };
};
