// Promise status

export enum PromiseStatus {
  fulfilled = "fulfilled",
  rejected = "rejected",
}

// Cookie

export enum CookieExpires {
  month = 31,
}

// Layout

export enum Layout {
  default = "default",
  auth = "auth",
  admin = "/admin",
}

// Routes name

export enum RoutesName {
  login = "login",
  registration = "registration",
  forgot = "forgot",
  wallet = "wallet",
  application = "application",
  history = "history",
  settings = "settings",
  verification = "verification",
  requisites = "requisites",
  adminUsers = "admin-users",
  adminKyc = "admin-kyc",
  adminApplication = "admin-application",
  adminCommission = "admin-commission",
}

// Routes to

export enum RoutesTo {
  login = "/login",
  registration = "/registration",
  forgot = "/forgot",
  wallet = "/",
  application = "/application",
  history = "/history",
  settings = "/settings",
  verification = "/verification",
  requisites = "/requisites",
  adminUsers = "/admin/users",
  adminKyc = "/admin/kyc",
  adminApplication = "/admin/application",
  adminCommission = "/admin/commission",
}

// Svg

export type SvgAttribute = Record<string, string>;

// Language

export enum Language {
  "ru-RU" = "ru-RU",
  "en-US" = "en-US",
}

// Password

export interface ValidationPassword {
  containNumbers: boolean;
  containSpecialCharacters: boolean;
  charactersLong: boolean;
  match: boolean;
  isValidation: boolean;
}

// Code

export const CODE_MAX_LENGTH = 6;

// Notification

export interface Notification {
  title: NotificationTitle | string;
  label: NotificationLabel | string;
  icon: NotificationIcon | string;
}

export enum NotificationTitle {
  password = "Password Changed Successfully",
}

export enum NotificationLabel {
  password = "Congratulations, now you can sign in using your new password",
}

export enum NotificationIcon {
  password = "password",
}

// Seconds

export const DEFAILT_SECONDS = 1500;

// File

export interface FileParams {
  file: File | string;
}

export interface FileImageParams {
  file: string;
  link: string;
}

// Modal

export interface Modal {
  title: ModalTitle | string;
  label: ModalLabel | string;
  icon: ModalIcon | string;
  actionLabel?: ModalActionLabel | string;
  callback: () => void;
}

export enum ModalTitle {
  application = "Заявка отправлена",
  requisites = "Подтверждение реквизитов",
  confirmTransfer = "Подтверждение перевода",
  adminStatusUdate = "Статус пользователя изменен",
  adminAccountBlocked = "Аккаунт заблокирован",
  adminBalanceUpdate = "Баланс изменен",
}

export enum ModalLabel {
  application = `<span>Ваша заявка на прохождение верификации отправлена на рассмотрение. Ориентировочное время ожидания 24 часа</span>`,
  requisites = `<span>Для продолжения, подтвердите реквизиты через письмо на почтовом ящике</span>`,
  confirmTransfer = `<span>Вы подтвердили перевод по указанным реквизитам. Проверяем.</span>`,
  adminStatusUpdate = `<span>С “На рассмотрении” на “Отклонено”. <br /> Комментарий отправлен на почту email@email.com</span>`,
  adminAccountBlocked = `<span>Ваш аккаунт заблокирован и будет удален через 6:59:59:59</span>`,
  adminBalanceUpdate = "Комментарий отправлен на почту email@email.com",
}

export enum ModalIcon {
  application = "application",
  requisites = "requisites",
  confirmTransfer = "confirmTransfer",
  adminStatusUdate = "adminStatusUpdate",
  adminAccountBlocked = "adminAccountBlocked",
  adminBalanceUpdate = "adminBalanceUpdate",
}

export enum ModalActionLabel {
  ok = "ok",
  adminAccountBlocked = "Отменить удаление",
}

// Format

export enum MomentFormat {
  date = "MM.DD.YYYY",
  dateReverse = "YYYY-MM-DD",
}

// Select

export enum SharedSelectOptionLabel {
  RF = "Российская федерация",
  russia = "Россия",
  moscow = "Москва",
}

export enum SharedSelectOptionValue {
  RUSSIA = "Россия",
  RF = "РФ",
  russia = "Russia",
  moscow = "Москва",
}

// Login

export enum LoginMessage {
  already = "Already authenticated",
}

// Multi Select

export interface SharedMultiSelectOption {
  id: number;
  label: string;
  value: string;
  icon?: string;
  isActive: boolean;
}

// Crypto Select

export interface SharedCryptoSelectOption {
  id: number;
  label: string;
  currency: {
    icon: string;
    coin: string;
  };
  isActive?: boolean;
}

// History

export interface HistoryFilter {
  currency: SharedMultiSelectOption[];
  volume: {
    from: string;
    to: string;
  };
  date: string[];
  status: SharedMultiSelectOption[];
  type: SharedMultiSelectOption[];
}

// Application

export interface ApplicationRequest {
  from: SharedCryptoSelectOption;
  to: SharedCryptoSelectOption;
  amount: string;
  crypto: {
    coin: string;
    network: string;
    address: string;
  };
}

// File type

export enum FileType {
  image = "image",
  document = "document",
}

// Sort

export interface Sort {
  key: string;
  isUp: boolean;
  isDown: boolean;
}

// Kyc

export enum KycPersonalDocumentsFileName {
  "kyc-personal-documents-passport-selfie" = "kyc-personal-documents-passport-selfie",
  "kyc-personal-documents-passport-organization" = "kyc-personal-documents-passport-organization",
  "kyc-personal-documents-passport-address" = "kyc-personal-documents-passport-address",
  "kyc-personal-documents-selfie" = "kyc-personal-documents-selfie",
  "kyc-personal-documents-questionnaire" = "kyc-personal-documents-questionnaire",
  "kyc-personal-documents-personal-data" = "kyc-personal-documents-personal-data",
  "kyc-personal-documents-declaration" = "kyc-personal-documents-declaration",
  "kyc-personal-documents-broker" = "kyc-personal-documents-broker",
  "kyc-personal-documents-origin-of-funds" = "kyc-personal-documents-origin-of-funds",
  "kyc-personal-documents-confirming-registration" = "kyc-personal-documents-confirming-registration",
}

// Kyb

export enum KybParticipantDocumentsFileName {
  "kyb-participant-documents-passport-selfie" = "kyb-participant-documents-passport-selfie",
  "kyb-participant-documents-passport-organization" = "kyb-participant-documents-passport-organization",
  "kyb-participant-documents-passport-address" = "kyb-participant-documents-passport-address",
  "kyb-participant-documents-selfie" = "kyb-participant-documents-selfie",
  "kyb-participant-documents-questionnaire" = "kyb-participant-documents-questionnaire",
  "kyb-participant-documents-personal-data" = "kyb-participant-documents-personal-data",
  "kyb-participant-documents-director" = "kyb-participant-documents-director",
}

export enum KybDocumentsFileName {
  "kyb-documents-registration-company" = "kyb-documents-registration-company",
  "kyb-documents-regulation" = "kyb-documents-regulation",
  "kyb-documents-list-of-shareholders" = "kyb-documents-list-of-shareholders",
  "kyb-documents-license" = "kyb-documents-license",
  "kyb-documents-check-addresses" = "kyb-documents-check-addresses",
  "kyb-documents-sow" = "kyb-documents-sow",
  "kyb-documents-sof" = "kyb-documents-sof",
  "kyb-documents-inn" = "kyb-documents-inn",
  "kyb-documents-record-sheet" = "kyb-documents-record-sheet",
  "kyb-documents-create" = "kyb-documents-create",
  "kyb-documents-update" = "kyb-documents-update",
  "kyb-documents-purpose" = "kyb-documents-purpose",
  "kyb-documents-finance" = "kyb-documents-finance",
  "kyb-documents-example" = "kyb-documents-example",
  "kyb-documents-document" = "kyb-documents-document",
  "kyb-documents-beneficiary" = "kyb-documents-beneficiary",
  "kyb-documents-broker" = "kyb-documents-broker",
  "kyb-documents-declaration" = "kyb-documents-declaration",
  "kyb-documents-director" = "kyb-documents-director",
}
