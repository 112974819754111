import { AxiosInstance } from "axios";

import {
  ForgotRequest,
  KybBasicInfoResponse,
  LoginRequest,
  LoginResponse,
  KycPersonalInfoRequest,
  KycPersonalInfoResponse,
  ProjectRepository,
  RegistrationRequest,
  UserInfoResponse,
  UserResponse,
  ResponseData,
  KybBasicInfoRequest,
  KycUploadFileRequest,
  KycUploadFileResponse,
  KybParticipant,
  KybRegistrationInfoRequest,
  KybRegistrationInfoResponse,
  KybParticipantResponse,
  KybParticipantRequest,
  KybParticipantUploadFileRequest,
  KybParticipantUploadFileResponse,
  RequisiteBankRequest,
  RequisiteBankResponse,
  RequisiteCryptoRequest,
  RequisiteCryptoResponse,
  KybUserFileResponse,
  KybUserFileRequest,
  AdminUserResponse,
  AdminUserAppStatusRequest,
  AdminUserAppResponse,
} from "@/shared/repository/repo";
import { UrlGenerator } from "@/shared/repository/url-generator";

export class HttpRepo implements ProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: UrlGenerator
  ) {}

  // Login, Registration, Verification, Forgot, Logout

  async csfr(): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.csfr());
  }

  async login(params: LoginRequest): Promise<LoginResponse> {
    const { data } = await this.axios.post<LoginResponse>(
      this.urlGenerator.login(),
      params
    );

    return data;
  }

  async registration(params: RegistrationRequest): Promise<string> {
    const { data } = await this.axios.post<string>(
      this.urlGenerator.registration(),
      {
        name: `User ${Date.now()}`,
        email: params.email,
        password: params.password,
        password_confirmation: params.password_confirmation,
      }
    );

    return data;
  }

  async emailCode(params: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.emailCode(), {
      email: params,
    });
  }

  async codeVerification(params: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.codeVerification(), {
      code: params,
    });
  }

  async forgot(params: ForgotRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.forgot(), {
      email: params.email,
      password: params.password,
      password_confirmation: params.password_confirmation,
    });
  }

  async logout(): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.logout());
  }

  // User

  async user(): Promise<UserResponse> {
    const { data } = await this.axios.get<UserResponse>(
      this.urlGenerator.user()
    );

    return data;
  }

  async userInfo(): Promise<UserInfoResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.userInfo()
    );

    return data.data;
  }

  // Personal info

  async getKycPersonalInfo(user_id: number): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kycPersonalInfo(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async kycPersonalInfo(
    params: KycPersonalInfoRequest
  ): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kycPersonalInfo(),
      params
    );

    return data.data;
  }

  async kycUploadFile(
    params: KycUploadFileRequest
  ): Promise<KycUploadFileResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kycUploadFile(),
      params
    );

    return data.data;
  }

  async kycDeleteFile(id: number): Promise<void> {
    const { data } = await this.axios.delete<ResponseData>(
      `${this.urlGenerator.kycDeleteFile()}${id}`
    );

    return data.data;
  }

  async kycUserFiles(user_id: number): Promise<KycUploadFileResponse[]> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kycUserFiles(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async kycVerification(): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.patch<ResponseData>(
      this.urlGenerator.kycVerification()
    );

    return data.data;
  }

  // Kyb

  async getKybBasicInfo(user_id: number): Promise<KybBasicInfoResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybBasicInfo(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async kybBasicInfo(
    params: KybBasicInfoRequest
  ): Promise<KybBasicInfoResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kybBasicInfo(),
      params
    );

    return data.data;
  }

  async getKybRegistrationInfo(
    user_id: number
  ): Promise<KybRegistrationInfoResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybRegistrationInfo(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async kybRegistrationInfo(
    params: KybRegistrationInfoRequest
  ): Promise<KybRegistrationInfoResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kybRegistrationInfo(),
      params
    );

    return data.data;
  }

  async getParticipants(user_id: number): Promise<KybParticipant[]> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybParticipants(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async getKybParticipant(id: number): Promise<KybParticipantResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybParticipantById(id)
    );

    return data.data;
  }

  async kybParticipant(
    params: KybParticipantRequest
  ): Promise<KybParticipantResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kybParticipant(),
      params
    );

    return data.data;
  }

  async kybParticipantUpdate(
    id: number,
    params: KybParticipantRequest
  ): Promise<KybParticipantResponse> {
    const { data } = await this.axios.patch<ResponseData>(
      this.urlGenerator.kybParticipantById(id),
      params
    );

    return data.data;
  }

  async kybParticipantUploadFile(
    params: KybParticipantUploadFileRequest
  ): Promise<KybParticipantUploadFileResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kybParticipantUploadFile(),
      params
    );

    return data.data;
  }

  async kybParticipantDeleteFile(
    id: number,
    kyb_participant_id: number
  ): Promise<ResponseData> {
    const { data } = await this.axios.delete<ResponseData>(
      `${this.urlGenerator.kybParticipantDeleteFile()}${id}`,
      {
        params: {
          kyb_participant_id,
        },
      }
    );

    return data;
  }

  async getKybParticipantFiles(
    kyb_participant_id: number
  ): Promise<KybParticipantUploadFileResponse[]> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybParticipantFiles(),
      {
        params: {
          kyb_participant_id,
        },
      }
    );

    return data.data;
  }

  async getKybUserFiles(user_id: number): Promise<KybUserFileResponse[]> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.kybUserFiles(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async kybUserFile(params: KybUserFileRequest): Promise<KybUserFileResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.kybUserFile(),
      params
    );

    return data.data;
  }

  async kybUserFileDelete(id: number): Promise<ResponseData> {
    const { data } = await this.axios.delete<ResponseData>(
      `${this.urlGenerator.kybUserFileDelete()}${id}`
    );

    return data.data;
  }

  async kybVerification(): Promise<ResponseData> {
    const { data } = await this.axios.patch<ResponseData>(
      this.urlGenerator.kybVerification()
    );

    return data;
  }

  // Requisites

  async requisiteBank(
    params: RequisiteBankRequest
  ): Promise<RequisiteBankResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.requisiteBank(),
      params
    );

    return data.data;
  }

  async requisiteBankShow(user_id: number): Promise<RequisiteBankResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.requisiteBankShow(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async requisiteCrypto(
    params: RequisiteCryptoRequest
  ): Promise<RequisiteCryptoResponse> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.requisiteCrypto(),
      params
    );

    return data.data;
  }

  async requisiteCryptoShow(user_id: number): Promise<RequisiteCryptoResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.requisiteCryptoShow(),
      {
        params: {
          user_id,
        },
      }
    );

    return data.data;
  }

  async requisiteVerification(
    code: string,
    type: string
  ): Promise<ResponseData> {
    const { data } = await this.axios.post<ResponseData>(
      this.urlGenerator.requisiteVerification(),
      {
        code,
        type,
      }
    );

    return data.data;
  }

  // Admin

  async adminUsers(): Promise<AdminUserResponse[]> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.adminUsers()
    );

    return data.data;
  }

  async adminUserApp(id: number): Promise<AdminUserAppResponse> {
    const { data } = await this.axios.get<ResponseData>(
      this.urlGenerator.adminUserApp(),
      {
        params: {
          id,
        },
      }
    );

    return {
      ...data.data,
      type: data.metadata.message,
    };
  }

  async adminUserAppStatus(params: AdminUserAppStatusRequest): Promise<void> {
    await this.axios.patch<ResponseData>(
      this.urlGenerator.adminUserAppStatus(params)
    );
  }
}
