import { SettingsTabValue } from "@/views/settings/model";

export interface SettingsTab {
  id: number;
  label: SettingsTabLabel;
  value: SettingsTabValue;
  isActive: boolean;
}

export enum SettingsTabLabel {
  main = "Основные",
  "kyc-kyb" = "КУС/КУВ",
  requisites = "Реквизиты",
}
