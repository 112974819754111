import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b('', { active: _ctx.value })),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSwitch && _ctx.handleSwitch(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('circle', { active: _ctx.value }))
    }, null, 2)
  ], 2))
}