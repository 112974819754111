import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerificationTabs = _resolveComponent("VerificationTabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Проверка")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('content'))
    }, [
      _createVNode(_component_VerificationTabs, {
        class: _normalizeClass(_ctx.b('tabs')),
        tab: _ctx.tab,
        onTab: _ctx.changeTab
      }, null, 8, ["class", "tab", "onTab"]),
      (_ctx.isKycShow)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedKycComponent), {
            key: 0,
            component: _ctx.kycComponent,
            "onUpdate:component": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kycComponent) = $event))
          }, null, 40, ["component"]))
        : _createCommentVNode("", true),
      (_ctx.isKybShow)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedKybComponent), {
            key: 1,
            component: _ctx.kybComponent,
            "onUpdate:component": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybComponent) = $event)),
            "participant-id": _ctx.participantId,
            "onUpdate:participantId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.participantId) = $event))
          }, null, 40, ["component", "participant-id"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}