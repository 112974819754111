<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button :class="b('toggle', { view })" @click="changeIsVisible">
      <span :class="b('toggle-label')">
        {{ displayedPlaceholder }}
      </span>

      <span
        :class="b('toggle-icon', { active: isVisible })"
        v-html="displayedIcon"
      ></span>
    </button>

    <div :class="b('options', { view, visible: isVisible })">
      <button
        :class="[b('option', { view }), displayedOptionActiveClass(option)]"
        v-for="option in options"
        :key="option.id"
        @click="changeOption(option)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>
