export interface AdminUsersOption {
  id: number;
  label: AdminUsersLabel;
  value: string;
  isActive: boolean;
}

export enum AdminUsersLabel {
  all = "Все",
  id = "ID",
  email = "Почта",
  fio = "ФИО",
}
