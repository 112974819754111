import {
  KybBasicInfoRequest,
  KybBasicInfoResponse,
  KybParticipant,
  KybParticipantRequest,
  KybParticipantResponse,
  KybParticipantUploadFileRequest,
  KybParticipantUploadFileResponse,
  KybRegistrationInfoRequest,
  KybRegistrationInfoResponse,
  KybUserFileRequest,
  KybUserFileResponse,
} from "@/shared/repository/repo";
import { FileType } from "../constants/constants";
import {
  extractFileName,
  uniqueFileName,
} from "@/shared/utils/file-name-helpers";

export const KybBasicInfoRequestFactory = (
  params: Partial<KybBasicInfoResponse> = {}
): KybBasicInfoRequest => {
  return {
    country: params.country ? params.country : "",
    organisation: params.organisation ? params.organisation : "",
    regional_number: params.regional_number ? params.regional_number : "",
    registration_date: params.registration_date ? params.registration_date : "",
    inn: params.inn ? params.inn : "",
    licensed: params.licensed ? Boolean(params.licensed) : false,
    telegram: params.telegram ? params.telegram : "",
  };
};

export const KybBasicInfoResponseFactory = (
  params: Partial<KybBasicInfoResponse> = {}
): KybBasicInfoResponse => {
  return {
    id: params && params.id ? params.id : null,
    user_app_id: params && params.user_app_id ? params.user_app_id : null,
    country: params && params.country ? params.country : "",
    organisation: params && params.organisation ? params.organisation : "",
    regional_number:
      params && params.regional_number ? params.regional_number : "",
    registration_date:
      params && params.registration_date ? params.registration_date : "",
    inn: params && params.inn ? params.inn : "",
    licensed:
      params && Number(params.licensed) ? Boolean(params.licensed) : false,
    telegram: params && params.telegram ? params.telegram : "",
    status: params && params.status ? params.status : "",
    creatd_at: params && params.creatd_at ? params.creatd_at : "",
    updated_at: params && params.updated_at ? params.updated_at : "",
  };
};

export const KybRegistrationInfoRequestFactory = (
  params: Partial<KybRegistrationInfoResponse> = {}
): KybRegistrationInfoRequest => {
  return {
    organisation_legal_form: params.organisation_legal_form
      ? params.organisation_legal_form
      : "",
    business_area: params.business_area ? params.business_area : "",
    country: params.address ? params.address.country : "",
    city: params.address ? params.address.city : "",
    state: params.address ? params.address.state : "",
    street: params.address ? params.address.street : "",
    office: params.address ? params.address.office : "",
    zip: params.address ? params.address.zip : "",
    country_actual:
      params.address && params.address.isActual ? params.address.country : "",
    city_actual:
      params.address && params.address.isActual ? params.address.city : "",
    state_actual:
      params.address && params.address.isActual ? params.address.state : "",
    street_actual:
      params.address && params.address.isActual ? params.address.street : "",
    office_actual:
      params.address && params.address.isActual ? params.address.office : "",
    zip_actual:
      params.address && params.address.isActual ? params.address.zip : "",
    phone: params.phone ? params.phone : "",
    site: params.site ? params.site : "",
  };
};

export const KybRegistrationInfoResponseFactory = (
  params: Partial<KybRegistrationInfoResponse> = {}
): KybRegistrationInfoResponse => {
  return {
    id: params.id ? params.id : "",
    kyb_basic_infos_id: params.kyb_basic_infos_id
      ? params.kyb_basic_infos_id
      : "",
    organisation_legal_form: params.organisation_legal_form
      ? params.organisation_legal_form
      : "",
    business_area: params.business_area ? params.business_area : "",
    phone: params.phone ? params.phone : "",
    site: params.site ? params.site : "",
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
    address: {
      country: params.address ? params.address.country : "",
      id: params.address ? params.address.id : "",
      user_app_id: params.address ? params.address.user_app_id : "",
      city: params.address ? params.address.city : "",
      state: params.address ? params.address.state : "",
      street: params.address ? params.address.street : "",
      office: params.address ? params.address.office : "",
      zip: params.address ? params.address.zip : "",
      contact: params.address ? params.address.contact : "",
      country_actual: params.address ? params.address.country_actual : "",
      city_actual: params.address ? params.address.city_actual : "",
      state_actual: params.address ? params.address.state_actual : "",
      street_actual: params.address ? params.address.street_actual : "",
      office_actual: params.address ? params.address.office_actual : "",
      zip_actual: params.address ? params.address.zip_actual : "",
      isActual:
        params.address &&
        (params.address.country_actual ||
          params.address.city_actual ||
          params.address.state_actual ||
          params.address.street_actual ||
          params.address.office_actual ||
          params.address.zip_actual)
          ? true
          : false,
      creatd_at: params.address ? params.address.creatd_at : "",
      updated_at: params.address ? params.address.updated_at : "",
    },
  };
};

export const KybParticipantFactory = (
  params: Partial<KybParticipant> = {}
): KybParticipant => {
  return {
    id: params.id ? params.id : null,
    title: params.title ? params.title : "",
    kyb_basic_infos_id: params.kyb_basic_infos_id
      ? params.kyb_basic_infos_id
      : "",
    sur_name: params.sur_name ? params.sur_name : "",
    name: params.name ? params.name : "",
    patronymic: params.patronymic ? params.patronymic : "",
    citizenship: params.citizenship ? params.citizenship : "",
    gender: params.gender ? params.gender : "",
    birth_date: params.birth_date ? params.birth_date : "",
    share_in_org: params.share_in_org ? params.share_in_org : 0,
    isShow: params.isShow ? params.isShow : false,
  };
};

export const KybParticipantRequestFactory = (
  params: Partial<KybParticipantResponse> = {}
): KybParticipantRequest => {
  return {
    sur_name: params.sur_name ? params.sur_name : "",
    name: params.name ? params.name : "",
    patronymic: params.patronymic ? params.patronymic : "",
    citizenship: params.citizenship ? params.citizenship : "",
    birth_date: params.birth_date ? params.birth_date : "",
    gender: params.gender ? params.gender : "",
    share_in_org: params.share_in_org ? Number(params.share_in_org) : "",
    country: params.address ? params.address.country : "",
    city: params.address ? params.address.city : "",
    state: params.address ? params.address.state : "",
    street: params.address ? params.address.street : "",
    office: params.address ? params.address.office : "",
    zip: params.address ? params.address.zip : "",
    phone: params.address ? params.address.phone : "",
    is_beneficiary: params.is_beneficiary ? params.is_beneficiary : false,
    is_director: params.is_director ? params.is_director : false,
  };
};

export const KybParticipantResponseFactory = (
  params: Partial<KybParticipantResponse> = {}
): KybParticipantResponse => {
  return {
    id: params.id ? params.id : null,
    kyb_basic_infos_id: params.kyb_basic_infos_id
      ? params.kyb_basic_infos_id
      : "",
    sur_name: params.sur_name ? params.sur_name : "",
    name: params.name ? params.name : "",
    patronymic: params.patronymic ? params.patronymic : "",
    citizenship: params.citizenship ? params.citizenship : "",
    gender: params.gender ? params.gender : "",
    birth_date: params.birth_date ? params.birth_date : "",
    share_in_org: params.share_in_org ? params.share_in_org.toString() : "",
    is_beneficiary: params.is_beneficiary
      ? Boolean(params.is_beneficiary)
      : false,
    is_director: params.is_director ? Boolean(params.is_director) : false,
    address: {
      country: params.address ? params.address.country : "",
      id: params.address ? params.address.id : "",
      user_app_id: params.address ? params.address.user_app_id : "",
      city: params.address ? params.address.city : "",
      state: params.address ? params.address.state : "",
      street: params.address ? params.address.street : "",
      office: params.address ? params.address.office : "",
      zip: params.address ? params.address.zip : "",
      contact: params.address ? params.address.contact : "",
      country_actual: params.address ? params.address.country_actual : "",
      city_actual: params.address ? params.address.city_actual : "",
      state_actual: params.address ? params.address.state_actual : "",
      street_actual: params.address ? params.address.street_actual : "",
      office_actual: params.address ? params.address.office_actual : "",
      zip_actual: params.address ? params.address.zip_actual : "",
      phone: params.address ? params.address.phone : "",
      creatd_at: params.address ? params.address.creatd_at : "",
      updated_at: params.address ? params.address.updated_at : "",
    },
  };
};

export const KybParticipantUploadFileRequestFactory = (
  params: Partial<KybParticipantUploadFileResponse> = {}
): KybParticipantUploadFileRequest => {
  return {
    file: params.file ? params.file : "",
    name: params.name ? uniqueFileName(params.name) : "",
    type: params.type ? params.type : FileType.image,
    kyb_participant_id: params.kyb_participant_id
      ? params.kyb_participant_id
      : null,
  };
};

export const KybParticipantUploadFileResponseFactory = (
  params: Partial<KybParticipantUploadFileResponse> = {}
): KybParticipantUploadFileResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    kyb_participant_id: params.kyb_participant_id
      ? params.kyb_participant_id
      : null,
    name: params.name ? extractFileName(params.name) : "",
    generated_name: params.generated_name ? params.generated_name : "",
    link: params.link ? params.link : "",
    file: params.file ? params.file : "",
    type: params.type ? params.type : FileType.image,
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};

export const KybUserFileRequestFactory = (
  params: Partial<KybUserFileResponse> = {}
): KybUserFileRequest => {
  return {
    file: params.file ? params.file : "",
    name: params.name ? uniqueFileName(params.name) : "",
    type: params.type ? params.type : FileType.document,
  };
};

export const KybUserFileResponseFactory = (
  params: Partial<KybUserFileResponse> = {}
): KybUserFileResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? params.name : "",
    generated_name: params.generated_name ? params.generated_name : "",
    link: params.link ? params.link : "",
    file: params.file ? params.file : "",
    type: params.type ? params.type : FileType.document,
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};
