import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "wallet-history";

export const SVG: SvgAttribute = {
  conclusion: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0238 2.53011C12.9975 2.52724 12.9707 2.52577 12.9436 2.52577L6.59383 2.52577C6.18943 2.52577 5.86161 2.8536 5.86161 3.25799C5.86161 3.66239 6.18943 3.99021 6.59383 3.99021L11.2272 3.99021L4.71991 10.4975C4.42702 10.7904 4.42702 11.2652 4.71991 11.5581C5.01281 11.851 5.48768 11.851 5.78058 11.5581L12.2963 5.04247L12.2963 9.69113C12.2963 10.0955 12.6241 10.4233 13.0285 10.4233C13.4329 10.4233 13.7607 10.0955 13.7607 9.69113L13.7607 3.26232C13.7607 2.85793 13.4329 2.5301 13.0285 2.5301C13.0269 2.5301 13.0254 2.5301 13.0238 2.53011Z" fill="#415C6B" fill-opacity="0.4"/>
    <rect x="1.5" y="15.0339" width="15" height="1.5" rx="0.75" fill="#415C6B" fill-opacity="0.4"/>
  </svg>`,
  input: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.23056 11.3979C5.25882 11.4012 5.28757 11.4029 5.31671 11.4029L11.6665 11.4029C12.0709 11.4029 12.3987 11.0751 12.3987 10.6707C12.3987 10.2663 12.0709 9.93844 11.6665 9.93844L7.0334 9.93844L13.5407 3.43119C13.8336 3.1383 13.8336 2.66342 13.5407 2.37053C13.2478 2.07764 12.7729 2.07764 12.48 2.37053L5.96435 8.88618L5.96435 4.23684C5.96435 3.83245 5.63652 3.50462 5.23213 3.50462C4.82773 3.50462 4.49991 3.83245 4.49991 4.23684L4.49991 10.6657C4.49991 11.0695 4.82689 11.397 5.23056 11.3979Z" fill="#415C6B" fill-opacity="0.4"/>
    <rect x="1.5" y="14.6587" width="15" height="1.5" rx="0.75" fill="#415C6B" fill-opacity="0.4"/>
  </svg>`,
  eur: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_278_14080)">
    <path d="M7.99988 0.5V16.5C12.4183 16.5 16 12.9183 16 8.50012C16 4.08158 12.4183 0.5 7.99988 0.5Z" fill="#2B479D"/>
    <path d="M7.99988 0.5C3.58172 0.5 0 4.08158 0 8.50012C0 12.9183 3.58172 16.5 7.99988 16.5C12.4183 16.5 12.4183 0.5 7.99988 0.5Z" fill="#2B479D"/>
    <path d="M7.98624 1.88773L8.18138 2.48824H8.81297L8.30204 2.85936L8.49718 3.45987L7.98624 3.08852L7.47541 3.45987L7.67059 2.85936L7.15948 2.48824H7.79107L7.98624 1.88773Z" fill="#F8D12E"/>
    <path d="M7.98624 13.224L8.18138 13.8248H8.81297L8.30204 14.1959L8.49718 14.7964L7.98624 14.4253L7.47541 14.7964L7.67059 14.1959L7.15948 13.8248H7.79107L7.98624 13.224Z" fill="#F8D12E"/>
    <path d="M11.0974 2.89243L11.2925 3.49307H11.9241L11.4131 3.86443L11.6083 4.46494L11.0974 4.09358L10.5865 4.46494L10.7816 3.86443L10.2708 3.49307H10.9024L11.0974 2.89243Z" fill="#F8D12E"/>
    <path d="M13.2203 4.98677L13.4153 5.58728H14.0469L13.5359 5.95854L13.7311 6.55905L13.2203 6.18793L12.7093 6.55905L12.9045 5.95854L12.3935 5.58728H13.0249L13.2203 4.98677Z" fill="#F8D12E"/>
    <path d="M13.2707 10.278L13.4658 10.8788H14.0973L13.5864 11.2499L13.7817 11.8503L13.2707 11.4793L12.7598 11.8503L12.9549 11.2499L12.444 10.8788H13.0756L13.2707 10.278Z" fill="#F8D12E"/>
    <path d="M2.82906 4.98677L3.0241 5.58728H3.65555L3.14472 5.95854L3.33976 6.55905L2.82906 6.18793L2.31813 6.55905L2.51327 5.95854L2.00244 5.58728H2.63403L2.82906 4.98677Z" fill="#F8D12E"/>
    <path d="M13.7123 7.51031L13.9073 8.11083H14.5389L14.028 8.48195L14.2231 9.08259L13.7123 8.71161L13.2014 9.08259L13.3966 8.48195L12.8856 8.11083H13.517L13.7123 7.51031Z" fill="#F8D12E"/>
    <path d="M2.34656 7.51031L2.5416 8.11083H3.17305L2.66212 8.48195L2.85739 9.08259L2.34656 8.71161L1.8356 9.08259L2.03077 8.48195L1.51984 8.11083H2.15139L2.34656 7.51031Z" fill="#F8D12E"/>
    <path d="M2.76068 10.278L2.95572 10.8788H3.58717L3.07638 11.2499L3.27138 11.8503L2.76068 11.4793L2.24975 11.8503L2.44489 11.2499L1.93396 10.8788H2.56555L2.76068 10.278Z" fill="#F8D12E"/>
    <path d="M11.189 12.4618L11.384 13.0623H12.0156L11.5045 13.4337L11.6998 14.0342L11.189 13.6631L10.6779 14.0342L10.8732 13.4337L10.3622 13.0623H10.9938L11.189 12.4618Z" fill="#F8D12E"/>
    <path d="M4.97579 2.89243L5.17079 3.49307H5.80238L5.29145 3.86443L5.48672 4.46494L4.97579 4.09358L4.46496 4.46494L4.6601 3.86443L4.14917 3.49307H4.78062L4.97579 2.89243Z" fill="#F8D12E"/>
    <path d="M4.74532 12.4233L4.94046 13.0241H5.57205L5.06112 13.3951L5.25625 13.9956L4.74532 13.6247L4.23449 13.9956L4.42963 13.3951L3.9187 13.0241H4.55015L4.74532 12.4233Z" fill="#F8D12E"/>
    </g>
    <defs>
    <clipPath id="clip0_278_14080">
    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
  </svg>`,
  aed: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.996">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.28125 0.5C7.76041 0.5 8.23959 0.5 8.71875 0.5C11.5202 0.840278 13.6296 2.22569 15.0469 4.65625C15.2315 4.9865 15.3825 5.33025 15.5 5.6875C11.8958 5.6875 8.29166 5.6875 4.6875 5.6875C4.69788 4.19263 4.68747 2.70303 4.65625 1.21875C5.49159 0.832844 6.36659 0.593259 7.28125 0.5Z" fill="#00722E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.65625 1.21875C4.68747 2.70303 4.69788 4.19263 4.6875 5.6875C4.6875 7.60416 4.6875 9.52084 4.6875 11.4375C4.69788 12.8907 4.68747 14.3386 4.65625 15.7812C2.67919 14.8406 1.28857 13.351 0.484375 11.3125C0.238072 10.6297 0.0766137 9.93178 0 9.21875C0 8.73959 0 8.26041 0 7.78125C0.306396 5.14825 1.56681 3.11178 3.78125 1.67188C4.05988 1.4961 4.35153 1.34506 4.65625 1.21875Z" fill="#FE0100"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6875 5.6875C8.29166 5.6875 11.8958 5.6875 15.5 5.6875C15.7542 6.365 15.9209 7.06294 16 7.78125C16 8.25 16 8.71875 16 9.1875C15.919 9.95656 15.7419 10.7066 15.4688 11.4375C11.875 11.4375 8.28125 11.4375 4.6875 11.4375C4.6875 9.52084 4.6875 7.60416 4.6875 5.6875Z" fill="#F3F4F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.6875 11.4375C8.28125 11.4375 11.875 11.4375 15.4688 11.4375C14.3998 13.9722 12.5352 15.5919 9.875 16.2969C9.47766 16.3822 9.08181 16.4499 8.6875 16.5C8.21875 16.5 7.75 16.5 7.28125 16.5C6.3665 16.4067 5.4915 16.1671 4.65625 15.7812C4.68747 14.3386 4.69788 12.8907 4.6875 11.4375Z" fill="#272727"/>
    </g>
  </svg>`,
  usdt: `<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 16.5C15.4183 16.5 19 12.9183 19 8.5C19 4.08172 15.4183 0.5 11 0.5C6.58172 0.5 3 4.08172 3 8.5C3 12.9183 6.58172 16.5 11 16.5Z" fill="white"/>
    <g filter="url(#filter0_d_278_14181)">
    <path d="M16 8.5L11 13.5L6 8.5L11 3.5L16 8.5Z" fill="url(#paint0_linear_278_14181)"/>
    </g>
    <path d="M11 0.5C6.58167 0.5 3 4.08167 3 8.5C3 12.918 6.58167 16.5 11 16.5C15.4183 16.5 19 12.918 19 8.5C19 4.08167 15.4183 0.5 11 0.5Z" fill="#4EAF94"/>
    <path d="M14.209 4.5V6.30767H11.8366V7.176C13.7366 7.281 15.1666 7.736 15.1666 8.28067C15.1666 8.826 13.7366 9.28067 11.8366 9.38567V12.5H10.094V9.38133C8.22865 9.26933 6.83331 8.81933 6.83331 8.28067C6.83331 7.74233 8.22865 7.29233 10.094 7.18033V6.30767H7.72198V4.5H14.209ZM11.8366 7.37333V8.431C11.2562 8.46105 10.6746 8.45972 10.0943 8.427V7.377C8.49265 7.457 7.30731 7.744 7.30731 8.08633C7.30731 8.49033 8.96065 8.818 11 8.818C13.0393 8.818 14.6926 8.49033 14.6926 8.08633C14.6926 7.739 13.4726 7.44867 11.8366 7.37367V7.37333Z" fill="white"/>
    <defs>
    <filter id="filter0_d_278_14181" x="0" y="0.5" width="22" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_278_14181"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_278_14181" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_278_14181" x1="16" y1="13.5" x2="16" y2="3.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0055FF"/>
    <stop offset="1" stop-color="#0055FF" stop-opacity="0.8"/>
    </linearGradient>
    </defs>
  </svg>`,
  confirm: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 0.996094C8.95187 0.989583 11.0091 0.996094 13.0664 1.01563C14.3086 1.0202 15.4805 1.30665 16.582 1.875C17.6316 2.5461 18.3152 3.49011 18.6328 4.70703C18.7661 5.22035 18.8573 5.74117 18.9062 6.26953C18.9492 7.50594 18.9687 8.74289 18.9648 9.98047C18.9687 11.218 18.9492 12.455 18.9062 13.6914C18.8393 14.7144 18.5659 15.678 18.0859 16.582C17.4148 17.6316 16.4708 18.3151 15.2539 18.6328C14.7405 18.7661 14.2197 18.8573 13.6914 18.9062C12.455 18.9492 11.218 18.9687 9.98047 18.9648C8.74289 18.9687 7.50594 18.9492 6.26953 18.9062C5.24641 18.8393 4.28285 18.5659 3.37891 18.0859C2.18451 17.3076 1.46185 16.2074 1.21094 14.7852C1.09949 14.2175 1.03439 13.6446 1.01563 13.0664C0.989583 11.0091 0.989583 8.95183 1.01563 6.89453C1.03456 5.86933 1.22987 4.87976 1.60156 3.92578C2.21873 2.56691 3.25388 1.70103 4.70703 1.32813C5.43176 1.14931 6.1609 1.03863 6.89453 0.996094ZM6.85547 2.28516C8.93883 2.27864 11.0222 2.28516 13.1055 2.30469C14.0839 2.30761 15.0084 2.52896 15.8789 2.96875C16.6463 3.45071 17.1476 4.1343 17.3828 5.01953C17.4988 5.44226 17.577 5.87195 17.6172 6.30859C17.6798 8.56062 17.6928 10.8132 17.6562 13.0664C17.6393 13.8712 17.4961 14.6524 17.2266 15.4102C16.7807 16.4285 16.019 17.0861 14.9414 17.3828C14.5189 17.4988 14.0892 17.5769 13.6523 17.6172C11.3873 17.68 9.12168 17.693 6.85547 17.6562C6.06414 17.635 5.2959 17.4918 4.55078 17.2266C3.53237 16.7807 2.87482 16.019 2.57813 14.9414C2.41074 14.3277 2.31959 13.7027 2.30469 13.0664C2.27865 11.0091 2.27865 8.95183 2.30469 6.89453C2.30288 5.82094 2.56329 4.81832 3.08594 3.88672C3.5711 3.22355 4.21563 2.78736 5.01953 2.57812C5.6277 2.4203 6.23969 2.32264 6.85547 2.28516Z" fill="#73D264"/>
    <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M13.3398 6.97266C14.0451 6.98125 14.286 7.3198 14.0625 7.98828C12.4143 9.64953 10.7541 11.2966 9.08201 12.9297C8.83494 13.0209 8.60057 12.9948 8.37889 12.8516C7.5651 12.0378 6.75127 11.2239 5.93748 10.4102C5.74111 10.0892 5.76064 9.7832 5.99607 9.49219C6.26158 9.3284 6.53502 9.31539 6.81639 9.45312C7.47393 10.1107 8.1315 10.7682 8.78904 11.4258C10.2278 9.98699 11.6667 8.54816 13.1054 7.10938C13.1809 7.05238 13.259 7.00684 13.3398 6.97266Z" fill="#73D264"/>
  </svg>`,
  error: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.966" fill-rule="evenodd" clip-rule="evenodd" d="M7.32422 0.996094C9.09512 0.989583 10.8659 0.996094 12.6367 1.01563C13.3559 1.10849 13.9939 1.38193 14.5508 1.83594C15.7422 3.02734 16.9336 4.21875 18.125 5.41016C18.5797 5.96824 18.8531 6.60629 18.9453 7.32422C18.9714 9.09504 18.9714 10.8659 18.9453 12.6367C18.8531 13.3546 18.5797 13.9927 18.125 14.5508C16.9336 15.7422 15.7422 16.9336 14.5508 18.125C13.9927 18.5797 13.3547 18.8531 12.6367 18.9453C10.8659 18.9714 9.09504 18.9714 7.32422 18.9453C6.60629 18.8531 5.96824 18.5797 5.41016 18.125C4.21875 16.9336 3.02735 15.7422 1.83594 14.5508C1.38124 13.9927 1.1078 13.3546 1.01563 12.6367C0.989583 10.8659 0.989583 9.09504 1.01563 7.32422C1.1077 6.60645 1.38114 5.96844 1.83594 5.41016C3.02735 4.21875 4.21875 3.02734 5.41016 1.83594C5.97563 1.38378 6.61367 1.10383 7.32422 0.996094ZM7.40235 2.28516C9.12114 2.27865 10.8399 2.28516 12.5586 2.30469C13.0001 2.38225 13.3907 2.56455 13.7305 2.85156C14.8698 3.9909 16.0091 5.1302 17.1484 6.26953C17.4127 6.60293 17.582 6.98051 17.6563 7.40234C17.6823 9.12109 17.6823 10.8398 17.6563 12.5586C17.5787 13.0001 17.3964 13.3907 17.1094 13.7305C15.97 14.8698 14.8307 16.0091 13.6914 17.1484C13.358 17.4127 12.9804 17.582 12.5586 17.6562C10.8398 17.6823 9.1211 17.6823 7.40235 17.6562C6.96086 17.5787 6.57024 17.3964 6.23047 17.1094C5.09114 15.97 3.95184 14.8307 2.8125 13.6914C2.54822 13.358 2.37895 12.9804 2.30469 12.5586C2.27865 10.8398 2.27865 9.12109 2.30469 7.40234C2.38226 6.96086 2.56455 6.57023 2.85156 6.23047C3.9909 5.09113 5.1302 3.95184 6.26953 2.8125C6.61051 2.55077 6.98813 2.37498 7.40235 2.28516Z" fill="#F86E6E"/>
    <path opacity="0.961" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 6.42578C7.08641 6.40871 7.26867 6.44125 7.44141 6.52344C8.2818 7.37035 9.12812 8.21019 9.98047 9.04297C10.8328 8.21019 11.6791 7.37035 12.5195 6.52344C13.0845 6.29801 13.4231 6.48027 13.5352 7.07031C13.5345 7.20246 13.502 7.32617 13.4375 7.44141C12.5906 8.2818 11.7507 9.12812 10.918 9.98047C11.7507 10.8328 12.5906 11.6791 13.4375 12.5195C13.6629 13.0845 13.4807 13.4231 12.8906 13.5352C12.7585 13.5345 12.6348 13.502 12.5195 13.4375C11.6791 12.5906 10.8328 11.7507 9.98047 10.918C9.12812 11.7507 8.2818 12.5906 7.44141 13.4375C6.87641 13.6629 6.53785 13.4807 6.42578 12.8906C6.42645 12.7585 6.45898 12.6348 6.52344 12.5195C7.37035 11.6791 8.2102 10.8328 9.04297 9.98047C8.2102 9.12812 7.37035 8.2818 6.52344 7.44141C6.32035 6.97601 6.44402 6.63746 6.89453 6.42578Z" fill="#F86E6E"/>
  </svg>`,
  waiting: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 0.996094C8.95187 0.989583 11.0091 0.996094 13.0664 1.01563C14.3086 1.0202 15.4805 1.30665 16.582 1.875C17.6316 2.5461 18.3152 3.49011 18.6328 4.70703C18.7661 5.22035 18.8573 5.74117 18.9062 6.26953C18.9492 7.50594 18.9687 8.74289 18.9648 9.98047C18.9687 11.218 18.9492 12.455 18.9062 13.6914C18.8393 14.7144 18.5659 15.678 18.0859 16.582C17.4148 17.6316 16.4708 18.3151 15.2539 18.6328C14.7405 18.7661 14.2197 18.8573 13.6914 18.9062C12.455 18.9492 11.218 18.9687 9.98047 18.9648C8.74289 18.9687 7.50594 18.9492 6.26953 18.9062C5.24641 18.8393 4.28285 18.5659 3.37891 18.0859C2.18451 17.3076 1.46185 16.2074 1.21094 14.7852C1.09949 14.2175 1.03439 13.6446 1.01563 13.0664C0.989583 11.0091 0.989583 8.95183 1.01563 6.89453C1.03456 5.86933 1.22987 4.87976 1.60156 3.92578C2.21873 2.56691 3.25388 1.70103 4.70703 1.32813C5.43176 1.14931 6.1609 1.03863 6.89453 0.996094ZM6.85547 2.28516C8.93883 2.27864 11.0222 2.28516 13.1055 2.30469C14.0839 2.30761 15.0084 2.52896 15.8789 2.96875C16.6463 3.45071 17.1476 4.1343 17.3828 5.01953C17.4988 5.44226 17.577 5.87195 17.6172 6.30859C17.6798 8.56062 17.6928 10.8132 17.6562 13.0664C17.6393 13.8712 17.4961 14.6524 17.2266 15.4102C16.7807 16.4285 16.019 17.0861 14.9414 17.3828C14.5189 17.4988 14.0892 17.5769 13.6523 17.6172C11.3873 17.68 9.12168 17.693 6.85547 17.6562C6.06414 17.635 5.2959 17.4918 4.55078 17.2266C3.53237 16.7807 2.87482 16.019 2.57813 14.9414C2.41074 14.3277 2.31959 13.7027 2.30469 13.0664C2.27865 11.0091 2.27865 8.95183 2.30469 6.89453C2.30288 5.82094 2.56329 4.81832 3.08594 3.88672C3.5711 3.22355 4.21563 2.78736 5.01953 2.57812C5.6277 2.4203 6.23969 2.32264 6.85547 2.28516Z" fill="#A1A2AB"/>
    <path d="M9.1667 4.8747C9.1667 4.48366 9.46649 4.16666 9.83631 4.16666C10.2061 4.16666 10.5059 4.48366 10.5059 4.8747V9.95628C10.5059 10.3473 10.2061 10.6643 9.83631 10.6643C9.46649 10.6643 9.1667 10.3473 9.1667 9.95628V4.8747Z" fill="#A1A2AB"/>
    <path d="M13.8318 12.012C14.152 12.2075 14.2618 12.6405 14.0769 12.9792C13.892 13.3178 13.4824 13.4339 13.1622 13.2384L9.74661 10.8286C9.16638 10.4167 9.17212 10.0506 9.16638 9.58333C9.35129 9.24468 10.096 9.40674 10.4162 9.60226L13.8318 12.012Z" fill="#A1A2AB"/>
  </svg>`,
};
