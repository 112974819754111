import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";

import { AdminApplicationTable } from "@/components/admin-application";
import {
  SharedSearch,
  SharedAdminHeader,
  SharedButtonIcon,
} from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    AdminApplicationTable,
    SharedSearch,
    SharedAdminHeader,
    SharedButtonIcon,
  },
  data() {
    return {
      search: "",
      email: "",
      id: "",
      loading: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleSearch(): void {},

    handleMore(): void {},
  },
});
