<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('wrapper')">
      <h2 :class="b('title')">
        {{ $t("Поздравляем с успешной верификацией") }}
      </h2>

      <span :class="b('logo')" v-html="displayedIcons.logo"></span>

      <span
        :class="b('logo', { small: true })"
        v-html="displayedIcons.logoSmall"
      ></span>

      <div :class="b('list')">
        <p :class="b('item')">
          {{ $t("Уведомляем вас об успешном открытии") }}
          <a :class="b('item-link')" href="">
            {{ $t("брокерского счета") }}
          </a>
          .
        </p>

        <p :class="b('item')">
          {{
            $t(
              "Для продолжения работы ознакомьтесь с нашими внутренними документами:"
            )
          }}
        </p>

        <p :class="b('item')">
          -
          <a :class="b('item-link')" href="">
            {{ $t("Политика AML") }}
          </a>
        </p>

        <p :class="b('item')">
          -
          <a :class="b('item-link')" href="">
            {{ $t("Лицензия") }}
          </a>
        </p>

        <p :class="b('item')">
          -
          <a :class="b('item-link')" href="">
            {{ $t("Инвестиционная декларация") }}
          </a>
        </p>

        <p :class="b('item')">
          -
          <a :class="b('item-link')" href="">
            {{
              $t(
                "Регламент о порядке осуществления деятельность на рынке ценных бумаг и срочном рынке"
              )
            }}
          </a>
        </p>
      </div>

      <div :class="b('footer')">
        <p :class="b('footer-label')">
          {{
            $t(
              "Нажимая “Продолжить”, вы соглашаетесь с вышеуказанными документами"
            )
          }}
        </p>

        <UIButton
          :class="b('footer-next')"
          label="Продолжить"
          view="main"
          @click="handleNext"
        />
      </div>
    </div>
  </div>
</template>
