<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div>
        <SharedInputPassword
          placeholder="Новый пароль*"
          view="light"
          :modelValue="password"
          @update:modelValue="changePassword"
        />

        <SharedPasswordValidation
          v-if="isValidationPasswordShow"
          :validation="displayedValidationPassword"
        />
      </div>

      <SharedInputPassword
        placeholder="Подтвердите пароль*"
        view="light"
        :modelValue="password_confirmation"
        @update:modelValue="changeConfirmPassword"
      />
    </div>

    <UIButton
      label="Изменить пароль"
      view="main"
      :disabled="isForgotDisabled"
      @click="handleForgot"
    />
  </div>
</template>
