<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h3 :class="b('title')">
      {{ $t("Отправьте 1,000 USDT по этим реквизитам:") }}
    </h3>

    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Сеть") }}
        </p>

        <UIInput view="light" />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Адрес") }}
        </p>

        <UIInput view="light" />
      </div>
    </div>

    <p :class="b('label')">
      {{
        $t(
          "Нажимая кнопку, вы подтверждаете факт отправки средств на указанный адрес."
        )
      }}
      <br :class="b('label-delimeter')" />
      {{ $t("Ваш баланс обновится после зачисления средств на ваш счет") }}
    </p>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить отправление"
      view="main"
    />
  </div>
</template>
