export enum AdminKycComponent {
  personalInformation = "personal-information",
  personalDocuments = "personal-documents",
  kybCompany = "kyc-company",
  kybBasicInformation = "kyb-basic-information",
  kybRegistrationInformation = "kyb-registration-information",
  kybParticipants = "kyb-participants",
  kybParticipant = "kyb-participant",
  kybParticipantDocuments = "kyb-participant-documents",
  kybDocuments = "kyb-documents",
}
