import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(
      _ctx.b('', {
        success: _ctx.isSuccess,
        center: _ctx.isCenter,
        link: _ctx.displayedIsLink,
        view: _ctx.view,
      })
    ),
    style: _normalizeStyle({ backgroundImage: _ctx.displayedBackgroundImage }),
    disabled: _ctx.isDisabled,
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleUpload && _ctx.handleUpload(...args)))
  }, [
    (_ctx.isIconShow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedIcon
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isLabelShow)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(_ctx.b('label', { success: _ctx.isSuccess }))
        }, [
          _renderSlot(_ctx.$slots, "label", {
            class: _normalizeClass(_ctx.b('label'))
          })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isRemoveShow)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: _normalizeClass(_ctx.b('remove')),
          disabled: _ctx.isDisabledRemove,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleRemove && _ctx.handleRemove(...args)), ["stop"]))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedRemoveIcon
          }, null, 10, _hoisted_4)
        ], 10, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('file')),
      ref: "file",
      type: "file",
      accept: _ctx.displayedAccept,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeFile && _ctx.changeFile(...args)))
    }, null, 42, _hoisted_5)
  ], 14, _hoisted_1))
}