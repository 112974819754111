import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "application-form";

export const SVG: SvgAttribute = {
  arrow: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.78809" y="1.25708" width="2.51416" height="11.3137" rx="1.25708" fill="#36A1EA"/>
    <path d="M13.3333 7.11111C13.8243 6.62019 14.6202 6.62019 15.1111 7.11111C15.602 7.60203 15.602 8.39797 15.1111 8.88889L8.88889 15.1111C8.39797 15.602 7.60203 15.602 7.11111 15.1111C6.62019 14.6202 6.62019 13.8243 7.11111 13.3333L13.3333 7.11111Z" fill="#36A1EA"/>
    <path d="M8.88889 13.3333C9.37981 13.8243 9.37981 14.6202 8.88889 15.1111C8.39797 15.602 7.60203 15.602 7.11111 15.1111L0.88889 8.88889C0.39797 8.39797 0.39797 7.60203 0.88889 7.11111C1.37981 6.62019 2.17575 6.62019 2.66667 7.11111L8.88889 13.3333Z" fill="#36A1EA"/>
  </svg>`,
};
