import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  data() {
    return {
      notificationRepo: this.$projectServices.notificationRepo,
    };
  },
  computed: {
    displayedIcon(): string {
      return SVG[<string>this.notificationRepo.notification?.icon];
    },

    displayedTitle(): string {
      return <string>this.notificationRepo.notification?.title;
    },

    displayedLabel(): string {
      return <string>this.notificationRepo.notification?.label;
    },
  },
});
