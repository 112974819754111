import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-commission-requisites";

export const SVG: SvgAttribute = {
  added: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="7.28631" cy="7.29989" rx="5.81048" ry="5.81259" fill="white"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M7.38274 0.0059806C10.6381 -0.100117 13.1405 1.20544 14.8898 3.92266C16.1738 6.22568 16.344 8.60974 15.4005 11.0749C14.3415 13.4694 12.5541 15.0304 10.0383 15.7579C6.88966 16.4561 4.20573 15.6443 1.98649 13.3227C0.16125 11.1194 -0.406179 8.62173 0.284196 5.82992C1.02828 3.3713 2.57736 1.62866 4.93145 0.601998C5.72447 0.28443 6.54157 0.0857584 7.38274 0.0059806ZM10.7533 5.48934C11.2557 5.46982 11.5394 5.70823 11.6044 6.20456C11.5955 6.33909 11.5558 6.46398 11.4853 6.5792C10.2199 7.89044 8.93183 9.17896 7.62106 10.4448C7.36494 10.5664 7.11524 10.555 6.87206 10.4107C6.08332 9.62171 5.29461 8.83272 4.50587 8.0437C4.30776 7.67938 4.35883 7.35583 4.65908 7.07304C4.89451 6.90139 5.1442 6.87867 5.40809 7.00492C6.03698 7.59995 6.65546 8.20735 7.26358 8.82703C8.31332 7.77692 9.36308 6.72677 10.4128 5.67666C10.524 5.60411 10.6375 5.54169 10.7533 5.48934Z" fill="#51BC40"/>
  </svg>`,
};
