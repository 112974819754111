import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header', { view: _ctx.headerView }))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header-image')),
        innerHTML: _ctx.displayedSvg
      }, null, 10, _hoisted_1),
      _createElementVNode("h3", {
        class: _normalizeClass(_ctx.b('header-title'))
      }, _toDisplayString(_ctx.$t(_ctx.headerTitle)), 3)
    ], 2),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('footer'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('footer-title'))
      }, _toDisplayString(_ctx.$t(_ctx.footerTitle)), 3),
      _renderSlot(_ctx.$slots, "footer"),
      (_ctx.isTerms)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.b('footer-label'))
          }, _toDisplayString(_ctx.$t("Условия обслуживания")), 3))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}