import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedCurrency, SharedCryptoSelect } from "@/components/shared";

import { ApplicationComponent } from "@/views/application/model";
import { SharedCryptoSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCurrency,
    SharedCryptoSelect,
  },
  props: {
    from: {
      type: <PropType<SharedCryptoSelectOption>>Object,
      required: true,
    },
    to: {
      type: <PropType<SharedCryptoSelectOption>>Object,
      required: true,
    },
    amount: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:from": null,
    "update:to": null,
    "update:amount": null,
    next: null,
  },
  computed: {
    displayedFromOptions(): SharedCryptoSelectOption[] {
      return [
        {
          id: 0,
          label: "0.00545",
          currency: {
            icon: "btc",
            coin: "BTC",
          },
          isActive: false,
        },
        {
          id: 1,
          label: "500.12345678",
          currency: {
            icon: "usdt",
            coin: "USDT",
          },
          isActive: false,
        },
      ].map((option) => ({ ...option, isActive: option.id === this.from.id }));
    },

    displayedToOptions(): SharedCryptoSelectOption[] {
      return [
        {
          id: 0,
          label: "0.00545",
          currency: {
            icon: "btc",
            coin: "BTC",
          },
          isActive: false,
        },
        {
          id: 1,
          label: "500.12345678",
          currency: {
            icon: "usdt",
            coin: "USDT",
          },
          isActive: false,
        },
      ].map((option) => ({ ...option, isActive: option.id === this.to.id }));
    },

    displayedArrowIcon(): string {
      return SVG.arrow;
    },

    isNextDisabled(): boolean {
      return !this.from || !this.to || !this.amount;
    },
  },
  methods: {
    changeFrom(from: SharedCryptoSelectOption): void {
      this.$emit("update:from", from);
    },

    changeTo(to: SharedCryptoSelectOption): void {
      this.$emit("update:to", to);
    },

    changeAmount(amount: string): void {
      this.$emit("update:amount", amount);
    },

    handleFromCreate(): void {
      this.$emit("next", ApplicationComponent.crypto);
    },

    handleToCreate(): void {
      this.$emit("next", ApplicationComponent.crypto);
    },

    handleMax(): void {
      console.log("Handle Max");
    },

    handleNext(): void {
      this.$emit("next");
    },
  },
});
