<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('security')">
      <h2 :class="b('title')">
        {{ $t("Безопасность") }}
      </h2>

      <div :class="b('security-block')">
        <div :class="b('security-two-factor')">
          <span :class="b('icon')" v-html="displayedIcons.twoFactor"></span>

          <div :class="b('security-two-factor-group')">
            <p :class="b('security-title')">
              {{ $t("Двухфакторная аутентификация") }}
            </p>

            <p :class="b('security-label')">
              {{
                $t(
                  "Используется для вывода средств и внесения изменений в систему безопасности"
                )
              }}
            </p>
          </div>

          <SharedSwitch v-model:value="twoFactor" />
        </div>

        <div :class="b('security-password')">
          <span :class="b('icon')" v-html="displayedIcons.password"></span>

          <div :class="b('security-password-info')">
            <p :class="b('security-title')">
              {{ $t("Пароль") }}
            </p>
          </div>

          <UIButton
            label="Изменить"
            view="main-outline-small"
            @click="handlePassword"
          />
        </div>

        <div :class="b('divider')"></div>

        <div :class="b('security-verification')">
          <span :class="b('icon')" v-html="displayedIcons.email"></span>

          <div :class="b('security-verification-group')">
            <div :class="b('security-verification-info')">
              <p :class="b('security-title', { email: true })">
                email@email.com
              </p>

              <p :class="b('security-label', { email: true })">
                {{
                  $t(
                    "Используется для вывода средств и внесения изменений в систему безопасности"
                  )
                }}
              </p>
            </div>

            <span :class="b('security-verification-tag')">
              <span :class="b('icon')" v-html="displayedIcons.check"></span>

              {{ $t("Подтвержден") }}
            </span>
          </div>

          <UIButton
            label="Изменить"
            view="main-outline-small"
            @click="handleEmail"
          />
        </div>
      </div>
    </div>

    <div :class="b('email')">
      <h2 :class="b('title')">
        {{ $t("Email уведомления") }}
      </h2>

      <div :class="b('email-groups')">
        <div :class="b('email-group')">
          <h4 :class="b('email-title')">
            {{ $t("Финансовые операции") }}
          </h4>

          <div :class="b('email-list')">
            <div :class="b('email-item')">
              <p :class="b('email-item-label')">
                {{ $t("Выплата за майнинг") }}
              </p>

              <SharedCheckbox view="22" />
            </div>

            <div :class="b('email-item')">
              <p :class="b('email-item-label')">
                {{ $t("Пополните свой кошелек") }}
              </p>

              <SharedCheckbox view="22" />
            </div>

            <div :class="b('email-item')">
              <p :class="b('email-item-label')">
                {{ $t("Выплата за майнинг") }}
              </p>

              <SharedCheckbox view="22" />
            </div>
          </div>
        </div>

        <div :class="b('email-group')">
          <h4 :class="b('email-title')">
            {{ $t("Статусы майнинга") }}
          </h4>

          <div :class="b('email-list')">
            <div :class="b('email-item')">
              <p :class="b('email-item-label')">
                {{ $t("Подключение рабочего") }}
              </p>

              <SharedCheckbox view="22" />
            </div>

            <div :class="b('email-item')">
              <p :class="b('email-item-label')">
                {{ $t("Отключение рабочего") }}
              </p>

              <SharedCheckbox view="22" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
