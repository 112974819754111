import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedLanguage = _resolveComponent("SharedLanguage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedLanguage, {
      class: _normalizeClass(_ctx.b('language')),
      view: "light"
    }, null, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('profile'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('profile-label'))
      }, _toDisplayString(_ctx.displayedEmail), 3),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('profile-logout')),
        disabled: _ctx.isLogoutDisabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('profile-icon')),
          innerHTML: _ctx.displayedIcons.logout
        }, null, 10, _hoisted_2)
      ], 10, _hoisted_1)
    ], 2)
  ], 2))
}