import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedCard, SharedAdd } from "@/components/shared";

import { RequisitesComponent } from "@/views/requisites/model";

import { PromiseStatus } from "@/shared/constants/constants";
import {
  RequisiteBankResponseFactory,
  RequisiteCryptoResponseFactory,
} from "@/shared/factory/requisites";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedAdd,
  },
  props: {
    component: {
      type: <PropType<RequisitesComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      requisiteBank: RequisiteBankResponseFactory(),
      requisiteCrypto: RequisiteCryptoResponseFactory(),
    };
  },
  async created(): Promise<void> {
    await Promise.allSettled([
      this.$projectServices.projectRepository.requisiteBankShow(
        this.displayedUserId
      ),
      this.$projectServices.projectRepository.requisiteCryptoShow(
        this.displayedUserId
      ),
    ]).then((response) => {
      const [requisiteBank, requisiteCrypto] = response;

      if (requisiteBank.status === PromiseStatus.fulfilled) {
        this.requisiteBank = RequisiteBankResponseFactory(requisiteBank.value);
      }

      if (requisiteCrypto.status === PromiseStatus.fulfilled) {
        this.requisiteCrypto = RequisiteCryptoResponseFactory(
          requisiteCrypto.value
        );
      }
    });
  },
  computed: {
    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },
  },
  methods: {
    handleRequisiteBank(): void {
      this.$emit("update:component", RequisitesComponent.requisitesBank);
    },

    handleRequisiteCrypto(): void {
      this.$emit("update:component", RequisitesComponent.requisitesCrypto);
    },
  },
});
