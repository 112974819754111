<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAuthCard
      headerSvg="user"
      headerTitle="Зарегистрируйте новую учетную запись"
      headerView="small"
      footerTitle="У вас уже есть учетная запись?"
      :isTerms="isTerms"
    >
      <template #default>
        <component
          :is="displayedComponent"
          :loading="loading"
          :message="message"
          v-model:email="user.email"
          v-model:password="user.password"
          v-model:password_confirmation="user.password_confirmation"
          @registration="handleRegistration"
          @verification="handleVerification"
          @google="handleGoogle"
        ></component>
      </template>

      <template #footer>
        <UILink label="Вход в систему" view="main-outline" to="/login" />
      </template>
    </SharedAuthCard>
  </div>
</template>
