import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "default-toolbar";

export const SVG: SvgAttribute = {
  logo: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 40 36" fill="none">
    <path d="M15.688 0.0899037V8.24545H26.0241L19.3532 15.1038L0 0V36H8.05326V16.5808L20.0761 25.9693L31.9594 13.7681V23.7603H40V0.0899037H15.688Z" fill="white"/>
  </svg>`,
  wallet: `<svg width="32" height="33" viewBox="0 0 32 33" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.968" fill-rule="evenodd" clip-rule="evenodd" d="M7.03126 3.21875C9.90632 3.20833 12.7813 3.21875 15.6563 3.25C17.9073 3.41038 19.5428 4.4833 20.5625 6.46875C20.7258 6.851 20.8403 7.24681 20.9063 7.65625C23.4713 7.58963 25.4401 8.63125 26.8125 10.7813C27.5136 12.0681 27.7949 13.4431 27.6563 14.9063C29.1001 14.8826 29.9855 15.5701 30.3125 16.9688C30.3542 18.0938 30.3542 19.2188 30.3125 20.3438C29.9997 21.7713 29.1143 22.4588 27.6563 22.4063C27.8685 25.0538 26.9101 27.1475 24.7813 28.6875C23.8293 29.2965 22.7876 29.6298 21.6563 29.6875C16.9688 29.7292 12.2813 29.7292 7.59376 29.6875C5.0627 29.4386 3.23979 28.1782 2.12501 25.9063C1.85037 25.2452 1.68371 24.5577 1.62501 23.8438C1.56388 18.6131 1.58472 13.3839 1.68751 8.15625C2.34238 5.28235 4.12364 3.63651 7.03126 3.21875ZM7.09376 5.28125C10.0539 5.25217 13.0123 5.28342 15.9688 5.375C17.3256 5.59773 18.2631 6.33731 18.7813 7.59375C14.9065 7.64581 11.0314 7.67706 7.15626 7.6875C5.82777 7.85713 4.67152 8.38838 3.68751 9.28125C3.73828 7.46006 4.60286 6.19968 6.28126 5.5C6.55745 5.41548 6.82826 5.34256 7.09376 5.28125ZM7.34376 9.71875C12.3252 9.68838 17.3044 9.71963 22.2813 9.8125C24.7005 10.6407 25.8046 12.3386 25.5938 14.9063C23.0947 14.9262 21.6989 16.1762 21.4063 18.6563C21.7014 21.1278 23.0972 22.3778 25.5938 22.4063C25.8216 24.5863 24.9883 26.2009 23.0938 27.25C22.7725 27.3918 22.4392 27.4959 22.0938 27.5625C17.2828 27.6652 12.4703 27.686 7.65626 27.625C5.82896 27.4197 4.57896 26.4614 3.90626 24.75C3.79871 24.4136 3.72579 24.0698 3.68751 23.7188C3.64584 20.3438 3.64584 16.9688 3.68751 13.5938C4.04308 11.478 5.26183 10.1863 7.34376 9.71875ZM24.8438 16.9688C25.9538 16.9392 27.058 16.9704 28.1563 17.0625C28.1954 17.1094 28.2266 17.1615 28.25 17.2188C28.2917 18.1979 28.2917 19.1771 28.25 20.1563C28.198 20.2083 28.1458 20.2604 28.0938 20.3125C27.0313 20.3542 25.9688 20.3542 24.9063 20.3125C23.5235 19.7884 23.1798 18.8822 23.875 17.5938C24.1583 17.3122 24.4813 17.1039 24.8438 16.9688Z"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M9.15646 14.2813C11.9274 14.2708 14.6982 14.2813 17.469 14.3125C18.275 14.5839 18.5146 15.1151 18.1877 15.9063C18.0462 16.0896 17.8691 16.225 17.6565 16.3125C14.7606 16.3542 11.8648 16.3542 8.96896 16.3125C8.24333 15.9268 8.08708 15.3747 8.50021 14.6563C8.69202 14.4721 8.91077 14.3471 9.15646 14.2813Z"/>
  </svg>`,
  application: `<svg width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M11.4063 3.46875C14.448 3.45833 17.4896 3.46875 20.5313 3.5C26.0418 3.59381 28.8438 6.39589 28.9375 11.9063C28.9792 14.9479 28.9792 17.9896 28.9375 21.0312C28.8438 26.5416 26.0418 29.3437 20.5313 29.4375C17.4896 29.4792 14.4479 29.4792 11.4063 29.4375C5.89578 29.3437 3.09369 26.5416 3 21.0312C2.95833 17.9896 2.95833 14.9479 3 11.9063C3.10465 6.39537 5.90673 3.58287 11.4063 3.46875ZM14.4688 5.46875C16.4898 5.48003 18.5107 5.51128 20.5313 5.5625C21.6457 5.58177 22.7291 5.76927 23.7813 6.125C25.4253 6.92294 26.394 8.225 26.6875 10.0313C26.7866 10.6735 26.8491 11.3193 26.875 11.9688C26.886 13.4714 26.9173 14.9714 26.9688 16.4688C26.9173 17.9661 26.886 19.4661 26.875 20.9688C26.8584 22.0016 26.7021 23.0121 26.4063 24C25.6835 25.8267 24.3502 26.8892 22.4063 27.1875C21.7845 27.2821 21.1595 27.3446 20.5313 27.375C19.0103 27.4011 17.4895 27.4324 15.9688 27.4688C14.448 27.4324 12.9272 27.4011 11.4063 27.375C10.3944 27.3525 9.40475 27.1963 8.4375 26.9062C6.87038 26.2766 5.84954 25.1516 5.375 23.5312C5.25673 22.995 5.1734 22.4533 5.125 21.9062C5.05934 20.0938 5.00725 18.2813 4.96875 16.4688C5.00725 14.6563 5.05934 12.8438 5.125 11.0313C5.14571 8.76881 6.15613 7.13338 8.15625 6.125C8.92381 5.84648 9.7155 5.67981 10.5313 5.625C11.8533 5.56398 13.1658 5.5119 14.4688 5.46875Z"/>
    <path opacity="0.988" fill-rule="evenodd" clip-rule="evenodd" d="M15.5941 11.4687C16.2103 11.3343 16.6583 11.5427 16.9378 12.0937C16.9691 13.2185 16.9795 14.3435 16.9691 15.4687C18.0943 15.4583 19.2193 15.4687 20.3441 15.5C20.7808 15.6754 20.9891 15.9983 20.9691 16.4687C20.9891 16.9392 20.7808 17.262 20.3441 17.4375C19.2193 17.4687 18.0943 17.4792 16.9691 17.4687C16.9795 18.5939 16.9691 19.7189 16.9378 20.8437C16.7624 21.2804 16.4395 21.4887 15.9691 21.4687C15.4987 21.4887 15.1758 21.2804 15.0003 20.8437C14.9691 19.7189 14.9587 18.5939 14.9691 17.4687C13.8439 17.4792 12.7189 17.4687 11.5941 17.4375C11.1574 17.262 10.9491 16.9392 10.9691 16.4687C10.9491 15.9983 11.1574 15.6754 11.5941 15.5C12.7189 15.4687 13.8439 15.4583 14.9691 15.4687C14.9587 14.3435 14.9691 13.2185 15.0003 12.0937C15.1267 11.8112 15.3247 11.6029 15.5941 11.4687Z"/>
    <path d="M20 28C20.5 22 21 21.5 27.5 21.5" stroke-width="2"/>
  </svg>`,
  history: `<svg width="32" height="33" viewBox="0 0 32 33" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M3.66174 3.5C3.89929 3.5 4.13684 3.5 4.37439 3.5C4.6788 3.62634 4.8909 3.84692 5.01068 4.16174C5.06078 4.63578 5.1032 5.11087 5.13794 5.58704C5.20453 6.50051 5.13794 7.16186 6.20691 7.44501C6.88562 7.46197 7.56435 7.47895 8.24304 7.49591C8.9535 7.73755 9.19957 8.22113 8.98114 8.94666C8.84579 9.25167 8.61672 9.44678 8.29395 9.53204C6.78381 9.566 5.27368 9.566 3.76355 9.53204C3.40222 9.4252 3.15619 9.19614 3.02545 8.84485C2.99152 7.30079 2.99152 5.75671 3.02545 4.21265C3.12352 3.86878 3.33562 3.63123 3.66174 3.5Z"/>
    <path opacity="0.975" fill-rule="evenodd" clip-rule="evenodd" d="M15.612 9.44406C16.2391 9.32073 16.6887 9.54129 16.9609 10.1058C16.9778 12.0741 16.9948 14.0423 17.0118 16.0106C18.3198 17.3016 19.6093 18.6081 20.8804 19.9301C21.1568 20.5772 20.9956 21.0777 20.3969 21.4318C19.9962 21.5667 19.6228 21.5158 19.277 21.2791C17.9111 19.9132 16.5452 18.5473 15.1793 17.1814C15.0985 17.0709 15.0306 16.9521 14.9757 16.825C14.9417 14.5853 14.9417 12.3456 14.9757 10.1058C15.0954 9.79097 15.3075 9.57041 15.612 9.44406Z"/>
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M20.5312 3.5C17.4896 3.46875 14.448 3.45833 11.4062 3.46875C7.18537 3.55634 4.55343 5.23342 3.51043 8.5H5.68095C6.17584 7.49913 7.00094 6.70746 8.15625 6.125C8.92381 5.84648 9.7155 5.67981 10.5312 5.625C11.8533 5.56398 13.1658 5.5119 14.4688 5.46875C16.4898 5.48003 18.5107 5.51128 20.5312 5.5625C21.6457 5.58177 22.7291 5.76927 23.7812 6.125C25.4253 6.92294 26.394 8.225 26.6875 10.0313C26.7866 10.6735 26.8491 11.3193 26.875 11.9688C26.886 13.4714 26.9172 14.9714 26.9688 16.4688C26.9172 17.9661 26.886 19.4661 26.875 20.9688C26.8584 22.0016 26.7021 23.0121 26.4062 24C25.6835 25.8268 24.3502 26.8893 22.4062 27.1875C21.7845 27.2821 21.1595 27.3446 20.5312 27.375C19.0103 27.4011 17.4895 27.4324 15.9688 27.4688C14.448 27.4324 12.9272 27.4011 11.4062 27.375C10.3944 27.3525 9.40475 27.1963 8.4375 26.9063C6.87038 26.2766 5.84954 25.1516 5.375 23.5313C5.25673 22.995 5.17339 22.4533 5.125 21.9063C5.05972 20.1042 5.00785 18.3021 4.96942 16.5H2.96875C2.96889 18.0104 2.97931 19.5208 3 21.0313C3.09369 26.5416 5.89578 29.3437 11.4062 29.4375C14.4479 29.4792 17.4896 29.4792 20.5312 29.4375C26.0417 29.3437 28.8438 26.5416 28.9375 21.0313C28.9792 17.9896 28.9792 14.9479 28.9375 11.9063C28.8438 6.39589 26.0417 3.59381 20.5312 3.5Z"/>
    <rect x="3" y="15.5" width="2" height="4" rx="1"/>
  </svg>`,
  settings: `<svg width="32" height="32" viewBox="0 0 32 32" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M15.0938 1.78136C16.1487 1.6809 17.1695 1.81631 18.1563 2.18761C20.6979 3.64594 23.2396 5.10428 25.7813 6.56261C26.3438 6.95842 26.9063 7.3543 27.4688 7.75011C28.5587 8.56573 29.0691 9.65948 29 11.0314C29.0417 14.323 29.0417 17.6147 29 20.9064C29.0692 22.2781 28.5588 23.3719 27.4688 24.1876C26.9063 24.5834 26.3438 24.9793 25.7813 25.3751C23.3646 26.7709 20.9479 28.1668 18.5313 29.5626C16.9258 30.3049 15.3008 30.3465 13.6563 29.6876C10.9585 28.1409 8.271 26.5784 5.59375 25.0001C5.09375 24.6251 4.59375 24.2501 4.09375 23.8751C3.23863 23.0516 2.85322 22.0412 2.9375 20.8439C2.89583 17.573 2.89583 14.3022 2.9375 11.0314C2.85394 9.78055 3.28102 8.7493 4.21875 7.93761C4.81186 7.4848 5.41603 7.0473 6.03125 6.62511C8.65625 5.12511 11.2813 3.62511 13.9063 2.12511C14.3022 1.98202 14.6981 1.86744 15.0938 1.78136ZM15.3438 3.84386C15.9365 3.80885 16.5198 3.86093 17.0938 4.00011C19.8953 5.55692 22.6661 7.16111 25.4063 8.81261C25.7891 9.09111 26.1536 9.39317 26.5 9.71886C26.6931 9.98023 26.8181 10.2719 26.875 10.5939C26.9997 14.1772 26.9997 17.7605 26.875 21.3439C26.8181 21.6658 26.6931 21.9575 26.5 22.2189C25.9836 22.6937 25.4316 23.1209 24.8438 23.5001C22.4688 24.8751 20.0938 26.2501 17.7188 27.6251C16.6039 28.2241 15.4789 28.2449 14.3438 27.6876C12.0414 26.3385 9.72894 25.0052 7.40625 23.6876C6.83281 23.3072 6.27031 22.9113 5.71875 22.5001C5.36266 22.1944 5.14391 21.8089 5.0625 21.3439C5.02083 17.7605 5.02083 14.1772 5.0625 10.5939C5.11369 10.3038 5.21785 10.0329 5.375 9.78136C6.00169 9.20255 6.67875 8.69217 7.40625 8.25011C9.68731 6.95342 11.9581 5.6409 14.2188 4.31261C14.5794 4.10041 14.9544 3.94416 15.3438 3.84386Z"/>
    <path opacity="0.965" fill-rule="evenodd" clip-rule="evenodd" d="M15.1563 10.9688C17.6915 10.7513 19.5145 11.7722 20.6251 14.0313C21.4594 16.5903 20.8031 18.6633 18.6563 20.2501C17.1719 21.062 15.6302 21.187 14.0313 20.6251C11.6471 19.43 10.6576 17.5029 11.0626 14.8438C11.4891 13.1885 12.4787 12.0115 14.0313 11.3126C14.4083 11.1771 14.7833 11.0625 15.1563 10.9688ZM15.4063 13.0313C13.6956 13.5665 12.9143 14.7332 13.0626 16.5313C13.5785 18.2549 14.7347 19.0361 16.5313 18.8751C18.2549 18.3592 19.0361 17.203 18.8751 15.4063C18.3571 13.6915 17.2009 12.8998 15.4063 13.0313Z"/>
  </svg>`,
  burger: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_215_15323)">
    <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M23.9766 4.42969C23.9766 4.66406 23.9766 4.89844 23.9766 5.13281C23.8134 5.52253 23.5321 5.79595 23.1328 5.95313C17.6953 5.98439 12.2578 5.98439 6.82035 5.95313C6.12411 5.67806 5.85064 5.17022 6.00003 4.42969C6.15139 3.92795 6.48735 3.63889 7.00785 3.5625C12.3203 3.53125 17.6329 3.53125 22.9453 3.5625C23.4697 3.64398 23.8135 3.93305 23.9766 4.42969Z" fill="white"/>
    <path opacity="0.982" fill-rule="evenodd" clip-rule="evenodd" d="M23.9766 11.6484C23.9766 11.8672 23.9766 12.0859 23.9766 12.3047C23.8238 12.762 23.5113 13.051 23.0391 13.1719C15.6641 13.2032 8.28905 13.2032 0.914062 13.1719C0.441811 13.051 0.129311 12.762 -0.0234375 12.3047C-0.0234375 12.0859 -0.0234375 11.8672 -0.0234375 11.6484C0.129311 11.1911 0.441811 10.9021 0.914062 10.7813C8.28905 10.75 15.6641 10.75 23.0391 10.7813C23.5113 10.9021 23.8238 11.1911 23.9766 11.6484Z" fill="white"/>
    <path opacity="0.98" fill-rule="evenodd" clip-rule="evenodd" d="M23.9764 18.8203C23.9764 19.0547 23.9764 19.2891 23.9764 19.5234C23.7959 19.9978 23.4522 20.2869 22.9451 20.3906C19.242 20.4219 15.5389 20.4219 11.8358 20.3906C10.9565 20.1718 10.6205 19.6172 10.8279 18.7266C10.9838 18.3519 11.2573 18.1097 11.6483 18C15.4764 17.9687 19.3045 17.9687 23.1326 18C23.5319 18.1572 23.8132 18.4306 23.9764 18.8203Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_215_15323">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  close: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M20.2537 3.04055C20.4194 3.20628 20.5851 3.37201 20.7508 3.53774C20.8727 3.96706 20.8011 4.42532 20.5361 4.91257C15.4363 10.0566 10.3144 15.1785 5.17031 20.2784C4.31999 20.7397 3.70329 20.6382 3.32037 19.9738C3.10817 19.4765 3.22023 18.9556 3.6565 18.4113C8.63858 13.385 13.6428 8.38083 18.6691 3.39876C19.2206 2.96242 19.7489 2.84299 20.2537 3.04055Z" fill="#36A1EA"/>
    <path opacity="0.982" fill-rule="evenodd" clip-rule="evenodd" d="M20.7177 20.2297C20.563 20.3844 20.4084 20.5391 20.2537 20.6938C19.8223 20.9091 19.397 20.8925 18.9776 20.6441C13.7406 15.4513 8.52565 10.2363 3.33286 4.99932C3.08437 4.57993 3.06778 4.15459 3.28314 3.72321C3.43783 3.56852 3.59249 3.41386 3.74718 3.25917C4.17856 3.04381 4.6039 3.06041 5.02328 3.30889C10.2603 8.50168 15.4752 13.7166 20.668 18.9536C20.9165 19.373 20.9331 19.7983 20.7177 20.2297Z" fill="#36A1EA"/>
  </svg>`,
  profile: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_215_15598)">
    <path opacity="0.936" fill-rule="evenodd" clip-rule="evenodd" d="M29.1875 -0.0625C31.0209 -0.0625 32.8541 -0.0625 34.6875 -0.0625C47.2207 1.5051 56.1582 8.0051 61.5 19.4375C62.787 22.5855 63.5995 25.8355 63.9375 29.1875C63.9375 31.0625 63.9375 32.9375 63.9375 34.8125C62.3189 47.284 55.8189 56.1797 44.4375 61.5C41.2897 62.7869 38.0397 63.5994 34.6875 63.9375C32.8541 63.9375 31.0209 63.9375 29.1875 63.9375C17.1379 62.4873 8.36706 56.3623 2.875 45.5625C1.31711 42.1221 0.337941 38.5387 -0.0625 34.8125C-0.0625 32.9375 -0.0625 31.0625 -0.0625 29.1875C1.5051 16.6543 8.0051 7.71675 19.4375 2.375C22.5855 1.08799 25.8355 0.275491 29.1875 -0.0625ZM29.4375 2.1875C42.1077 1.71882 51.6702 6.8855 58.125 17.6875C62.3154 25.8825 62.8154 34.2991 59.625 42.9375C58.7256 45.1119 57.6006 47.1535 56.25 49.0625C53.4826 41.8368 48.5451 36.6909 41.4375 33.625C45.7391 29.2024 46.8849 24.0566 44.875 18.1875C41.8783 11.9032 36.899 9.04906 29.9375 9.625C23.9391 10.7897 20.085 14.3105 18.375 20.1875C17.3603 25.403 18.7145 29.8821 22.4375 33.625C15.3299 36.6909 10.3924 41.8368 7.625 49.0625C1.86931 40.5181 0.660981 31.3515 4 21.5625C7.874 11.9384 14.8115 5.75091 24.8125 3C26.3577 2.6454 27.8994 2.37456 29.4375 2.1875ZM31.6875 11.6875C37.7969 12.0638 41.6926 15.1887 43.375 21.0625C44.1511 26.9064 41.9636 31.2189 36.8125 34C31.244 36.0715 26.5149 34.884 22.625 30.4375C19.9246 26.4666 19.5912 22.2999 21.625 17.9375C23.8876 14.0879 27.2417 12.0045 31.6875 11.6875ZM24.5625 35.3125C29.4375 37.9757 34.3125 37.9757 39.1875 35.3125C46.9067 38.0734 51.9901 43.3651 54.4375 51.1875C54.4101 51.3672 54.3476 51.5339 54.25 51.6875C45.6535 60.6499 35.3203 63.5665 23.25 60.4375C17.8903 58.7794 13.3486 55.8628 9.625 51.6875C9.46233 51.4544 9.42066 51.2044 9.5 50.9375C12.0041 43.2878 17.025 38.0795 24.5625 35.3125Z" fill="#36A1EA"/>
    </g>
    <defs>
    <clipPath id="clip0_215_15598">
    <rect width="64" height="64" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  logout: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_215_15581)">
    <path opacity="0.976" fill-rule="evenodd" clip-rule="evenodd" d="M9.99023 -0.0214844C10.6348 -0.0214844 11.2793 -0.0214844 11.9238 -0.0214844C15.618 0.410287 18.4611 2.18633 20.4531 5.30664C20.674 5.67677 20.8602 6.06349 21.0117 6.4668C21.0671 7.24225 20.709 7.68629 19.9375 7.79883C19.4998 7.77343 19.1704 7.57294 18.9492 7.19727C17.4643 4.28708 15.0938 2.61846 11.8379 2.19141C8.23006 1.99369 5.42996 3.39017 3.4375 6.38086C2.01866 8.83098 1.77518 11.3948 2.70703 14.0723C3.97319 17.1303 6.22905 18.9994 9.47461 19.6797C13.0711 20.1474 15.9715 18.9514 18.1758 16.0918C18.5286 15.5511 18.8651 14.9997 19.1855 14.4375C19.7935 14.0231 20.3449 14.0875 20.8398 14.6309C21.0063 14.8955 21.0636 15.182 21.0117 15.4902C20.3159 17.0606 19.3062 18.3998 17.9824 19.5078C16.2075 20.925 14.188 21.7486 11.9238 21.9785C11.2793 21.9785 10.6348 21.9785 9.99023 21.9785C6.18118 21.5106 3.29514 19.6343 1.33203 16.3496C0.588998 14.9731 0.137826 13.5122 -0.0214844 11.9668C-0.0214844 11.308 -0.0214844 10.6491 -0.0214844 9.99023C0.530514 5.65598 2.79354 2.58372 6.76758 0.773438C7.81258 0.365362 8.8868 0.100387 9.99023 -0.0214844Z" fill="#9E9FA8"/>
    <path opacity="0.979" fill-rule="evenodd" clip-rule="evenodd" d="M11.8855 7.00645C12.1808 6.97957 12.4552 7.03668 12.7086 7.17776C13.6621 8.05969 14.6156 8.94159 15.5691 9.82352C16.1296 10.59 16.1433 11.3641 15.6102 12.1457C14.643 13.0403 13.6758 13.9349 12.7086 14.8295C12.1353 15.1211 11.6345 15.0386 11.2064 14.5821C11.003 14.1941 11.0167 13.8134 11.2475 13.44C11.762 12.9642 12.2765 12.4883 12.7909 12.0125C10.8291 11.9998 8.8673 11.9871 6.90546 11.9744C6.31691 11.8804 6.0151 11.5569 6 11.0036C6.02025 10.5216 6.2809 10.2107 6.78199 10.071C8.79869 10.0583 10.8154 10.0456 12.8321 10.0329C12.2902 9.53165 11.7483 9.03044 11.2064 8.52919C10.8592 7.79778 11.0856 7.29018 11.8855 7.00645Z" fill="#9E9FA8"/>
    </g>
    <defs>
    <clipPath id="clip0_215_15581">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
