import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { convertFile } from "@/shared/utils/file-helpers";

enum Accept {
  image = ".png, .jpeg, .jpg",
  document = ".pdf, .doc, .docx",
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    file: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
    isDocument: {
      type: Boolean,
      default: false,
    },
    isRemove: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    upload: null,
    remove: null,
  },
  computed: {
    displayedIsLink(): boolean {
      return this.isDocument ? false : !!this.file || !!this.link;
    },

    displayedBackgroundImage(): string {
      return this.link && !this.isDocument ? `url(${this.link})` : "";
    },

    displayedIcon(): string {
      if (this.isDocument) {
        return !!this.file || !!this.link ? SVG.file : SVG.plus;
      }

      return !this.file && !this.link ? SVG.plus : "";
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    displayedAccept(): string {
      return this.isDocument ? Accept.document : Accept.image;
    },

    isSuccess(): boolean {
      return this.isDocument && (!!this.file || !!this.link);
    },

    isIconShow(): boolean {
      return this.isDocument ? true : !this.file && !this.link;
    },

    isLabelShow(): boolean {
      return this.isDocument ? true : !this.file && !this.link;
    },

    isRemoveShow(): boolean {
      return (!!this.file || !!this.link) && this.isRemove;
    },

    isDisabled(): boolean {
      return this.isLoading || !!this.file || !!this.link;
    },

    isDisabledRemove(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    async changeFile(event: Event): Promise<void> {
      const { files } = <HTMLInputElement>event.target;
      const uploadFile: File | null = files ? files[0] : null;

      if (uploadFile) {
        try {
          await convertFile(uploadFile).then((response) => {
            this.$emit("upload", response);
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    handleUpload(): void {
      (<HTMLInputElement>this.$refs.file).click();
    },

    handleRemove(): void {
      this.$emit("remove");
    },
  },
});
