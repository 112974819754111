<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('container')">
      <AuthHeader />

      <div :class="b('content')">
        <AuthIntroduction :class="b('introduction')" />

        <router-view />
      </div>
    </div>
  </div>
</template>
