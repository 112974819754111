import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    title: {
      type: String,
      default: "",
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    close: null,
  },
  computed: {
    displayedCloseIcon(): string {
      return SVG.close;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("close");
    },
  },
});
