import { ValidationPassword } from "@/shared/constants/constants";

const PASSWORD_MIN_LENGTH = 8;

export function validationEmail(email: string): boolean {
  //eslint-disable-next-line
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
}

export function validationPassword(
  password: string,
  confirmPassword: string
): ValidationPassword {
  const containNumbers = validationPasswordContainNumbers(password);
  const containSpecialCharacters =
    validationPasswordContainSpecialCharacters(password);
  const charactersLong = validationPasswordCharactersLong(password);
  const match = validationPasswordMatch(password, confirmPassword);

  return {
    containNumbers,
    containSpecialCharacters,
    charactersLong,
    match,
    isValidation:
      containNumbers && containSpecialCharacters && charactersLong && match,
  };
}

export function validationPasswordContainNumbers(password: string): boolean {
  return /\d/.test(password);
}

export function validationPasswordContainSpecialCharacters(
  password: string
): boolean {
  //eslint-disable-next-line
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
}

export function validationPasswordCharactersLong(password: string): boolean {
  return password.length >= PASSWORD_MIN_LENGTH;
}

export function validationPasswordMatch(
  password: string,
  confirmPassword: string
): boolean {
  return password === confirmPassword;
}
