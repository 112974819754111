import { RegistrationRequest } from "@/shared/repository/repo";

export const RegistrationFactory = (
  params: Partial<RegistrationRequest> = {}
): RegistrationRequest => {
  return {
    name: params.name ? params.name : "",
    email: params.email ? params.email : "",
    password: params.password ? params.password : "",
    password_confirmation: params.password_confirmation
      ? params.password_confirmation
      : "",
  };
};
