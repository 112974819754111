import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedDate,
  SharedRadio,
  SharedUpload,
  SharedDownload,
  SharedCheckbox,
  SharedInputDouble,
  SharedCountry,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  KybParticipantRequestFactory,
  KybParticipantResponseFactory,
} from "@/shared/factory/kyb";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedDate,
    SharedRadio,
    SharedUpload,
    SharedDownload,
    SharedCheckbox,
    SharedInputDouble,
    SharedCountry,
    UIInput,
    UIButton,
  },
  props: {
    participantId: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:participantId": null,
    "update:component": null,
  },
  data() {
    return {
      kybParticipant: KybParticipantResponseFactory(),
      loading: false,
    };
  },
  async mounted(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .getKybParticipant(this.participantId)
        .then((kybParticipant) => {
          this.kybParticipant = KybParticipantResponseFactory(kybParticipant);
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    isContinueDisabled(): boolean {
      return (
        !this.kybParticipant.sur_name ||
        !this.kybParticipant.name ||
        !this.kybParticipant.citizenship ||
        !this.kybParticipant.birth_date ||
        !this.kybParticipant.gender ||
        !this.kybParticipant.share_in_org ||
        !this.kybParticipant.address.country ||
        !this.kybParticipant.address.city ||
        !this.kybParticipant.address.state ||
        !this.kybParticipant.address.street ||
        !this.kybParticipant.address.office ||
        !this.kybParticipant.address.zip ||
        this.loading
      );
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:participantId", 0);

      this.$emit("update:component", VerificationKybComponent.kybParticipants);
    },

    async handleContinue(): Promise<void> {
      try {
        this.loading = true;

        if (this.participantId) {
          await this.$projectServices.projectRepository.kybParticipantUpdate(
            this.participantId,
            KybParticipantRequestFactory(this.kybParticipant)
          );
        } else {
          const { id } =
            await this.$projectServices.projectRepository.kybParticipant(
              KybParticipantRequestFactory(this.kybParticipant)
            );

          this.$emit("update:participantId", id);
        }

        this.$emit(
          "update:component",
          VerificationKybComponent.kybParticipantFiles
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
