import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header-group'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('header-title'))
        }, _toDisplayString(_ctx.$t("Почта")) + ": " + _toDisplayString(_ctx.email), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('header-tag'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t(_ctx.displayedType)), 3)
        ], 2)
      ], 2),
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('header-title'))
      }, "ID: " + _toDisplayString(_ctx.id), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('content'))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}