import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationConfirm = _resolveComponent("ApplicationConfirm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Создание заявки")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
        from: _ctx.application.from,
        "onUpdate:from": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.application.from) = $event)),
        to: _ctx.application.to,
        "onUpdate:to": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.application.to) = $event)),
        amount: _ctx.application.amount,
        "onUpdate:amount": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.application.amount) = $event)),
        coin: _ctx.application.crypto.coin,
        "onUpdate:coin": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.application.crypto.coin) = $event)),
        network: _ctx.application.crypto.network,
        "onUpdate:network": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.application.crypto.network) = $event)),
        address: _ctx.application.crypto.address,
        "onUpdate:address": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.application.crypto.address) = $event)),
        onNext: _ctx.handleNext,
        onConfirm: _ctx.handleConfirm,
        onBack: _ctx.handleBack
      }, null, 40, ["from", "to", "amount", "coin", "network", "address", "onNext", "onConfirm", "onBack"]))
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isConfirm)
          ? (_openBlock(), _createBlock(_component_ApplicationConfirm, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              onClose: _ctx.handleCloseConfirm
            }, null, 8, ["class", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}