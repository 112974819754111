import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  AdminCommissionRequisitesControl,
  AdminCommissionRequisitesControlLabel,
} from "./model";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIInput,
    UIButton,
  },
  data() {
    return {
      controlId: "",
      isLoading: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedControls(): AdminCommissionRequisitesControl[] {
      return [
        {
          id: "0",
          label: AdminCommissionRequisitesControlLabel["МБА Москва"],
          isActive: false,
          isAdded: true,
        },
        {
          id: "1",
          label: AdminCommissionRequisitesControlLabel["Банк 131"],
          isActive: false,
          isAdded: false,
        },
        {
          id: "2",
          label: AdminCommissionRequisitesControlLabel["Биткоин банк"],
          isActive: false,
          isAdded: false,
        },
        {
          id: "3",
          label: AdminCommissionRequisitesControlLabel.Сбербанк,
          isActive: false,
          isAdded: false,
        },
        {
          id: "4",
          label: AdminCommissionRequisitesControlLabel.ВТБ,
          isActive: false,
          isAdded: false,
        },
        {
          id: "5",
          label: AdminCommissionRequisitesControlLabel["Raifaisen Bank"],
          isActive: false,
          isAdded: false,
        },
      ].map((control) => ({
        ...control,
        isActive: control.id === this.controlId,
      }));
    },

    isSaveDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleControl({ id }: AdminCommissionRequisitesControl): void {
      this.controlId = id;
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
