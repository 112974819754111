export interface VerificationTab {
  id: number;
  label: VerificationTabLabel;
  labelSmall: VerificationTabLabelSmall;
  value: VerificationTabValue;
  isActive: boolean;
}

export enum VerificationTabLabel {
  kyc = "Физическое лицо (KYC)",
  kyb = "Юридическое лицо (KYB)",
}

export enum VerificationTabLabelSmall {
  kyc = "Физ. лицо (KYC)",
  kyb = "Юр. лицо (KYB)",
}

export enum VerificationTabValue {
  kyc = "kyc",
  kyb = "kyb",
}
