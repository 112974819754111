import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import {
  SharedCard,
  SharedRadio,
  SharedDate,
  SharedCountry,
} from "@/components/shared";

import { VerificationKycComponent } from "@/views/verification/model";
import {
  KycPersonalInfoRequestFactory,
  KycPersonalInfoResponseFactory,
} from "@/shared/factory/kyc";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedCard,
    SharedRadio,
    SharedDate,
    SharedCountry,
  },
  props: {
    component: {
      type: <PropType<VerificationKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      kycPersonalInfo: KycPersonalInfoResponseFactory(),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      await this.$projectServices.projectRepository
        .getKycPersonalInfo(this.displayedUserId)
        .then((kycPersonalInfo) => {
          this.kycPersonalInfo =
            KycPersonalInfoResponseFactory(kycPersonalInfo);
        });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },

    isContinueDisabled(): boolean {
      const {
        sur_name,
        name,
        gender,
        birth_date,
        citizenship,
        passport,
        passport_date,
        passport_issued_org,
        address,
        telegram,
        phone,
      } = this.kycPersonalInfo;

      return (
        !sur_name ||
        !name ||
        !gender ||
        !birth_date ||
        !citizenship ||
        !passport ||
        !passport_date ||
        !passport_issued_org ||
        !address.country ||
        !address.city ||
        !address.state ||
        !address.office ||
        !address.zip ||
        !phone ||
        !telegram ||
        this.loading
      );
    },
  },
  methods: {
    async handleContinue(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .kycPersonalInfo(KycPersonalInfoRequestFactory(this.kycPersonalInfo))
          .then(() => {
            this.$emit(
              "update:component",
              VerificationKycComponent.kycDocuments
            );
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
