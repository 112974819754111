import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  WalletApplicationsColumn,
  WalletApplicationsColumnLabel,
  WalletApplicationsRow,
} from "./model";

import { WalletCard } from "../card";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
  },
  computed: {
    displayedColumns(): WalletApplicationsColumn[] {
      return [
        {
          id: 0,
          label: WalletApplicationsColumnLabel.dateTime,
        },
        {
          id: 0,
          label: WalletApplicationsColumnLabel.operation,
        },
      ];
    },

    displayedRows(): WalletApplicationsRow[] {
      return [
        {
          id: 0,
          dateTime: "07.05.2024 15:02:10",
          operation: {
            from: {
              amount: "0.04500932",
              currency: "BTC",
            },
            to: {
              amount: "1,246.18",
              currency: "EUR",
            },
            icon: this.displayedIcons.waiting,
          },
        },
        {
          id: 1,
          dateTime: "06.05.2024 10:13:04",
          operation: {
            from: {
              amount: "355.12",
              currency: "AED",
            },
            to: {
              amount: "9,694.99",
              currency: "RUB",
            },
            icon: this.displayedIcons.confirm,
          },
        },
        {
          id: 2,
          dateTime: "05.05.2024 21:55:38",
          operation: {
            from: {
              amount: "0.04500932",
              currency: "BTC",
            },
            to: {
              amount: "2,000.12345678",
              currency: "USDT",
            },
            icon: this.displayedIcons.confirm,
          },
        },
        {
          id: 3,
          dateTime: "04.05.2024 16:08:58",
          operation: {
            from: {
              amount: "0.04500932",
              currency: "BTC",
            },
            to: {
              amount: "1,246.18",
              currency: "EUR",
            },
            icon: this.displayedIcons.error,
          },
        },
        {
          id: 4,
          dateTime: "05.05.2024 08:15:14",
          operation: {
            from: {
              amount: "0.04500932",
              currency: "BTC",
            },
            to: {
              amount: "1,246.18",
              currency: "EUR",
            },
            icon: this.displayedIcons.confirm,
          },
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
});
