import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminKycBack } from "../back";

import { SharedAdd } from "@/components/shared";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybParticipant } from "@/shared/repository/repo";
import { KybParticipantFactory } from "@/shared/factory/kyb";

enum KybParticipantsTitle {
  participant1 = "Участник №1",
  participant2 = "Участник №2",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminKycBack,
    SharedAdd,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    participantId: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:participantId": null,
    "update:component": null,
  },
  data() {
    return {
      kybParticipants: <KybParticipant[]>[],
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.projectRepo
        .getParticipants(this.id)
        .then((kybParticipants) => {
          this.kybParticipants = kybParticipants;
        });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    displayedParticipants(): KybParticipant[] {
      return [
        KybParticipantFactory({
          id: this.kybParticipants[0] ? this.kybParticipants[0].id : null,
          title: KybParticipantsTitle.participant1,
          sur_name: this.kybParticipants[0]
            ? this.kybParticipants[0].sur_name
            : "",
          name: this.kybParticipants[0] ? this.kybParticipants[0].name : "",
          patronymic: this.kybParticipants[0]
            ? this.kybParticipants[0].patronymic
            : "",
        }),
        KybParticipantFactory({
          id: this.kybParticipants[1] ? this.kybParticipants[1].id : null,
          title: KybParticipantsTitle.participant2,
          sur_name: this.kybParticipants[1]
            ? this.kybParticipants[1].sur_name
            : "",
          name: this.kybParticipants[1] ? this.kybParticipants[1].name : "",
          patronymic: this.kybParticipants[1]
            ? this.kybParticipants[1].patronymic
            : "",
        }),
      ].filter((kybParticipant) => !!kybParticipant.id);
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybCompany);
    },

    handleParticipant({ id }: KybParticipant): void {
      this.$emit("update:participantId", id);

      this.$emit("update:component", AdminKycComponent.kybParticipant);
    },
  },
});
