import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_UILink = _resolveComponent("UILink")!
  const _component_SharedAuthCard = _resolveComponent("SharedAuthCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAuthCard, {
      headerSvg: "fingerprint",
      headerTitle: "Вход в систему",
      footerTitle: "У вас еще нет учетной записи?"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LoginForm, {
          loading: _ctx.loading,
          message: _ctx.message,
          email: _ctx.user.email,
          "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
          password: _ctx.user.password,
          "onUpdate:password": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.password) = $event)),
          isRemember: _ctx.isRemember,
          "onUpdate:isRemember": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isRemember) = $event)),
          onLogin: _ctx.handleLogin,
          onGoogle: _ctx.handleGoogle
        }, null, 8, ["loading", "message", "email", "password", "isRemember", "onLogin", "onGoogle"])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_UILink, {
          label: "Создать аккаунт",
          view: "main-outline",
          to: "/registration"
        })
      ]),
      _: 1
    })
  ], 2))
}