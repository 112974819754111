import { RoutesTo } from "@/shared/constants/constants";

export interface AdminToolbarLink {
  id: number;
  label: AdminToolbarLinkLabel;
  to: RoutesTo;
  icon: string;
}

export enum AdminToolbarLinkLabel {
  users = "Пользователи",
  kyc = "КУС",
  application = "Заявки",
  commission = "Комиссии",
}
