import { ForgotRequest } from "../repository/repo";

export const ForgotFactory = (
  params: Partial<ForgotRequest> = {}
): ForgotRequest => {
  return {
    email: params.email ? params.email : "",
    code: params.code ? params.code : "",
    password: params.password ? params.password : "",
    password_confirmation: params.password_confirmation
      ? params.password_confirmation
      : "",
  };
};
