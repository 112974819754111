import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { AdminKycComponent } from "./model";

import { SharedAdminHeader } from "@/components/shared";
import {
  AdminKycWrapper,
  AdminKycNext,
  AdminKycStatus,
  AdminKycPersonalInformation,
  AdminKycPersonalDocuments,
  AdminKycKybCompany,
  AdminKycKybBasicInformation,
  AdminKycKybRegistrationInformation,
  AdminKycKybParticipants,
  AdminKycKybParticipant,
  AdminKycKybParticipantDocuments,
  AdminKycKybDocuments,
} from "@/components/admin-kyc";
import { AdminUserAppResponseFactory } from "@/shared/factory/admin-users";

type Component =
  | typeof AdminKycPersonalInformation
  | typeof AdminKycPersonalDocuments
  | typeof AdminKycKybCompany
  | typeof AdminKycKybBasicInformation
  | typeof AdminKycKybRegistrationInformation
  | typeof AdminKycKybParticipants
  | typeof AdminKycKybParticipant
  | typeof AdminKycKybParticipantDocuments
  | typeof AdminKycKybDocuments;
type FooterComponent = typeof AdminKycNext | typeof AdminKycStatus;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    SharedAdminHeader,
    AdminKycWrapper,
    AdminKycNext,
    AdminKycStatus,
    AdminKycPersonalInformation,
    AdminKycPersonalDocuments,
    AdminKycKybCompany,
  },
  data() {
    return {
      email: "",
      id: "",
      participantId: 0,
      component: AdminKycComponent.personalInformation,
      loading: false,
      userApp: AdminUserAppResponseFactory(),
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  async created(): Promise<void> {
    const { id } = this.$route.query;

    if (typeof id === "string" && !isNaN(Number(id))) {
      this.id = id;

      await this.handleSearch();
    }
  },
  computed: {
    displayedComponent(): Component {
      switch (this.component) {
        case AdminKycComponent.personalInformation:
          return AdminKycPersonalInformation;
        case AdminKycComponent.personalDocuments:
          return AdminKycPersonalDocuments;
        case AdminKycComponent.kybCompany:
          return AdminKycKybCompany;
        case AdminKycComponent.kybBasicInformation:
          return AdminKycKybBasicInformation;
        case AdminKycComponent.kybRegistrationInformation:
          return AdminKycKybRegistrationInformation;
        case AdminKycComponent.kybParticipants:
          return AdminKycKybParticipants;
        case AdminKycComponent.kybParticipant:
          return AdminKycKybParticipant;
        case AdminKycComponent.kybParticipantDocuments:
          return AdminKycKybParticipantDocuments;
        case AdminKycComponent.kybDocuments:
          return AdminKycKybDocuments;
        default:
          return AdminKycPersonalInformation;
      }
    },

    displayedFooterComponent(): FooterComponent {
      if (
        this.component === AdminKycComponent.personalDocuments ||
        this.component === AdminKycComponent.kybCompany ||
        this.component === AdminKycComponent.kybDocuments
      ) {
        return AdminKycStatus;
      }

      return AdminKycNext;
    },
  },
  methods: {
    async handleSearch(): Promise<void> {
      try {
        this.loading = true;

        this.userApp = AdminUserAppResponseFactory();

        await this.projectRepo.adminUserApp(Number(this.id)).then((userApp) => {
          this.userApp = AdminUserAppResponseFactory(userApp);

          if (userApp.type === "kyc") {
            this.component = AdminKycComponent.personalInformation;
          } else if (userApp.type === "kyb") {
            this.component = AdminKycComponent.kybCompany;
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
