import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-application-table";

export const SVG: SvgAttribute = {
  sort: `<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61023 4.38179C6.03012 4.38179 6.26301 3.89553 5.99981 3.56837L3.44516 0.392943C3.24502 0.144165 2.86615 0.144166 2.66601 0.392944L0.111364 3.56837C-0.151837 3.89553 0.0810508 4.38179 0.500942 4.38179H5.61023ZM0.500965 6.61827C0.0810745 6.61827 -0.151812 7.10452 0.111389 7.43168L2.66603 10.6071C2.86618 10.8559 3.24505 10.8559 3.44519 10.6071L5.99984 7.43168C6.26304 7.10452 6.03015 6.61827 5.61026 6.61827H0.500965Z" fill="#222327"/>
  </svg>`,
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5" />
    <circle cx="8" cy="8" r="1.5" />
    <circle cx="8" cy="14" r="1.5" />
  </svg>`,
  remove: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.58399 1.09546C9.84706 1.0883 12.1101 1.09546 14.373 1.11694C15.7395 1.12197 17.0286 1.43707 18.2402 2.06226C19.3947 2.80046 20.1467 3.83887 20.4961 5.17749C20.6427 5.74214 20.743 6.31505 20.7969 6.89624C20.8441 8.25629 20.8656 9.61694 20.8613 10.9783C20.8656 12.3396 20.8441 13.7003 20.7969 15.0603C20.7233 16.1856 20.4225 17.2455 19.8945 18.24C19.1563 19.3945 18.1179 20.1464 16.7793 20.4958C16.2146 20.6425 15.6417 20.7427 15.0605 20.7966C13.7005 20.8439 12.3399 20.8653 10.9785 20.8611C9.61718 20.8653 8.25653 20.8439 6.89649 20.7966C5.77105 20.723 4.71114 20.4222 3.7168 19.8943C2.40296 19.0382 1.60804 17.8279 1.33203 16.2634C1.20944 15.639 1.13783 15.0089 1.11719 14.3728C1.08854 12.1098 1.08854 9.84678 1.11719 7.58374C1.13802 6.45603 1.35286 5.3675 1.76172 4.31812C2.4406 2.82336 3.57927 1.87089 5.17774 1.46069C5.97493 1.264 6.77699 1.14225 7.58399 1.09546ZM7.54102 2.51343C9.83271 2.50627 12.1244 2.51343 14.416 2.53491C15.4923 2.53813 16.5092 2.78161 17.4668 3.26538C18.3109 3.79554 18.8623 4.54748 19.1211 5.52124C19.2487 5.98625 19.3347 6.4589 19.3789 6.93921C19.4478 9.41644 19.4621 11.8943 19.4219 14.3728C19.4032 15.258 19.2457 16.1174 18.9492 16.9509C18.4588 18.0712 17.6209 18.7945 16.4355 19.1208C15.9708 19.2485 15.4981 19.3344 15.0176 19.3787C12.526 19.4478 10.0338 19.4621 7.54102 19.4216C6.67056 19.3983 5.82549 19.2407 5.00586 18.949C3.8856 18.4586 3.1623 17.6207 2.83594 16.4353C2.65181 15.7602 2.55155 15.0727 2.53516 14.3728C2.50651 12.1098 2.50651 9.84678 2.53516 7.58374C2.53317 6.40279 2.81962 5.29991 3.39453 4.27515C3.92821 3.54567 4.63719 3.06585 5.52149 2.83569C6.19047 2.66209 6.86366 2.55467 7.54102 2.51343Z" fill="#F86E6E"/>
    <path opacity="0.971" fill-rule="evenodd" clip-rule="evenodd" d="M9.99032 5.17752C10.7794 5.16244 11.5671 5.18392 12.3536 5.24197C12.9003 5.34475 13.2799 5.65271 13.4923 6.1658C13.6034 6.56068 13.6822 6.96171 13.7286 7.36892C14.6911 7.41374 15.6507 7.49254 16.6075 7.60525C17.0188 7.77657 17.1692 8.08448 17.0587 8.52908C16.9269 8.82445 16.6977 8.98197 16.3712 9.00174C16.1772 8.97437 15.9838 8.9457 15.7911 8.9158C15.6495 11.0522 15.4848 13.1863 15.297 15.3181C15.1474 15.9261 14.7822 16.3486 14.2013 16.5857C13.9494 16.6702 13.6916 16.7274 13.4278 16.7576C11.795 16.7863 10.1622 16.7863 8.52938 16.7576C7.57509 16.7221 6.95204 16.2567 6.66024 15.3611C6.58453 15.0215 6.54156 14.6778 6.53133 14.3299C6.42374 12.5223 6.29483 10.7176 6.14461 8.9158C5.71033 9.08673 5.32361 9.0151 4.98446 8.70095C4.7477 8.20909 4.86943 7.84386 5.34969 7.60525C6.30785 7.49276 7.26752 7.39965 8.2286 7.32595C8.239 6.75426 8.38939 6.22428 8.67977 5.73611C9.04956 5.38626 9.48642 5.20008 9.99032 5.17752ZM9.90438 6.59549C9.84646 6.62496 9.79631 6.66793 9.75399 6.72439C9.72271 6.89781 9.68692 7.06969 9.64657 7.24002C10.5198 7.28299 11.3935 7.29729 12.2677 7.28299C12.253 7.06608 12.2243 6.85124 12.1817 6.63845C11.4238 6.59785 10.6647 6.58354 9.90438 6.59549ZM10.2052 8.65799C9.33797 8.69653 8.46424 8.7395 7.58407 8.78689C7.72105 10.7629 7.85713 12.7395 7.99227 14.7166C7.99343 15.1044 8.18679 15.3121 8.57235 15.3396C10.1622 15.3683 11.752 15.3683 13.3419 15.3396C13.6406 15.3449 13.834 15.2088 13.922 14.9314C14.077 13.0066 14.2131 11.0802 14.3302 9.15213C14.3159 9.0447 14.3015 8.93728 14.2872 8.82986C12.9298 8.7257 11.5691 8.66838 10.2052 8.65799Z" fill="#F86E6E"/>
  </svg>`,
  create: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.77421 1.64902C9.46433 1.64226 11.1544 1.64902 12.8444 1.6693C12.9437 1.69869 13.0384 1.73925 13.1283 1.79099C15.4741 4.1368 17.82 6.48262 20.1658 8.82842C20.2175 8.91835 20.2581 9.01302 20.2875 9.11235C20.3145 10.8024 20.3145 12.4925 20.2875 14.1825C20.2827 15.4726 19.9852 16.6894 19.3951 17.8331C18.6983 18.9229 17.718 19.6327 16.4544 19.9626C15.9213 20.101 15.3805 20.1956 14.8319 20.2465C13.5481 20.2911 12.2637 20.3114 10.9786 20.3074C9.69351 20.3114 8.40908 20.2911 7.12523 20.2465C6.06284 20.177 5.06231 19.8931 4.12367 19.3947C2.88344 18.5866 2.13305 17.4441 1.8725 15.9673C1.75678 15.3779 1.68918 14.783 1.6697 14.1825C1.64265 12.0463 1.64265 9.91008 1.6697 7.77382C1.68936 6.70928 1.89216 5.68173 2.27812 4.69114C2.91897 3.28012 3.99385 2.38101 5.50277 1.99379C6.25518 1.80813 7.01235 1.69321 7.77421 1.64902ZM7.73365 2.98755C9.16682 2.98755 10.6 2.98755 12.0332 2.98755C12.0169 4.25937 12.0372 5.53028 12.094 6.80034C12.198 8.43161 13.043 9.42537 14.6291 9.78162C14.9644 9.84498 15.3024 9.88554 15.6432 9.9033C16.7518 9.92358 17.8605 9.93036 18.9692 9.92358C18.976 11.3433 18.9692 12.763 18.9489 14.1825C18.9508 15.2973 18.6804 16.3384 18.1377 17.3058C17.5552 18.0621 16.7913 18.5421 15.846 18.7457C15.4971 18.8174 15.1456 18.8715 14.7914 18.908C12.4394 18.9732 10.0868 18.9867 7.73365 18.9485C6.63322 18.9439 5.60563 18.6734 4.65097 18.1373C3.96236 17.6335 3.50942 16.9643 3.29216 16.1295C3.11835 15.4922 3.02371 14.8433 3.00823 14.1825C2.98119 12.0463 2.98119 9.91008 3.00823 7.77382C3.00867 6.74461 3.23851 5.77113 3.69778 4.85339C4.19823 4.05656 4.90805 3.53602 5.82726 3.29176C6.45876 3.12788 7.09424 3.02648 7.73365 2.98755ZM13.3717 3.96103C14.9184 5.48744 16.4597 7.02201 17.9957 8.56477C17.0619 8.59961 16.129 8.58611 15.197 8.52421C14.227 8.45984 13.6524 7.95282 13.4731 7.00315C13.4327 6.76136 13.4057 6.51799 13.392 6.27304C13.3717 5.50249 13.3649 4.73182 13.3717 3.96103Z" fill="#9E9FA8"/>
    <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M9.92374 11.1813C10.123 11.1636 10.3122 11.1974 10.4916 11.2827C11.1338 11.9249 11.7761 12.5672 12.4183 13.2094C12.5613 13.4739 12.5478 13.7307 12.3777 13.9801C11.749 14.6088 11.1203 15.2375 10.4916 15.8662C9.90861 16.1021 9.55711 15.9128 9.437 15.2983C9.45181 15.1457 9.49914 15.0037 9.57897 14.8724C9.78855 14.6629 9.99809 14.4533 10.2077 14.2437C8.95026 14.2302 7.69286 14.2167 6.43545 14.2032C5.99393 13.9888 5.87902 13.6575 6.09067 13.2094C6.16226 13.0888 6.26367 13.0009 6.39488 12.9458C7.6658 12.9323 8.93676 12.9187 10.2077 12.9052C9.99809 12.6956 9.78855 12.4861 9.57897 12.2765C9.33284 11.7914 9.44779 11.4263 9.92374 11.1813Z" fill="#9E9FA8"/>
  </svg>`,
};
