import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  WalletHistoryColumn,
  WalletHistoryColumnLabel,
  WalletHistoryRow,
} from "./model";

import { WalletCard } from "../card";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
  },
  computed: {
    displayedColumns(): WalletHistoryColumn[] {
      return [
        {
          id: 0,
          label: WalletHistoryColumnLabel.dateTime,
        },
        {
          id: 1,
          label: WalletHistoryColumnLabel.type,
        },
        {
          id: 2,
          label: WalletHistoryColumnLabel.amount,
        },
        {
          id: 3,
          label: WalletHistoryColumnLabel.currency,
        },
      ];
    },

    displayedRows(): WalletHistoryRow[] {
      return [
        {
          id: 0,
          dateTime: "07.05.2024 15:02:10",
          type: {
            icon: this.displayedIcons.conclusion,
            label: "Вывод",
          },
          amount: "500",
          currency: {
            icon: this.displayedIcons.eur,
            label: "EUR",
            iconType: this.displayedIcons.waiting,
          },
        },
        {
          id: 1,
          dateTime: "06.05.2024 10:13:04",
          type: {
            icon: this.displayedIcons.input,
            label: "Ввод",
          },
          amount: "3,000.99",
          currency: {
            icon: this.displayedIcons.aed,
            label: "AED",
            iconType: this.displayedIcons.confirm,
          },
        },
        {
          id: 2,
          dateTime: "05.05.2024 21:55:38",
          type: {
            icon: this.displayedIcons.conclusion,
            label: "Вывод",
          },
          amount: "5,000.12345678",
          currency: {
            icon: this.displayedIcons.usdt,
            label: "USDT",
            iconType: this.displayedIcons.confirm,
          },
        },
        {
          id: 3,
          dateTime: "04.05.2024 16:08:58",
          type: {
            icon: this.displayedIcons.input,
            label: "Ввод",
          },
          amount: "200",
          currency: {
            icon: this.displayedIcons.aed,
            label: "AED",
            iconType: this.displayedIcons.error,
          },
        },
        {
          id: 4,
          dateTime: "05.05.2024 08:15:14",
          type: {
            icon: this.displayedIcons.conclusion,
            label: "Вывод",
          },
          amount: "1,000",
          currency: {
            icon: this.displayedIcons.usdt,
            label: "USDT",
            iconType: this.displayedIcons.confirm,
          },
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
});
