import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-password-validation";

export const SVG: SvgAttribute = {
  success: `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14_8372)">
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M5.56674 0.480495C7.80778 0.407483 9.53044 1.30592 10.7347 3.17581C11.6186 4.76065 11.7358 6.40128 11.0863 8.09768C10.3572 9.74553 9.12678 10.8197 7.39486 11.3203C5.22729 11.8008 3.37964 11.2422 1.85189 9.64456C0.595381 8.12829 0.204757 6.40953 0.680019 4.48831C1.19225 2.79638 2.25866 1.59716 3.87924 0.890651C4.42517 0.672113 4.98767 0.535395 5.56674 0.480495ZM7.88705 4.25393C8.23289 4.2405 8.4282 4.40457 8.47299 4.74612C8.46687 4.8387 8.43952 4.92464 8.39096 5.00393C7.51985 5.90628 6.63314 6.79299 5.7308 7.66409C5.55448 7.74778 5.38259 7.73996 5.21517 7.64065C4.6722 7.09767 4.12925 6.55472 3.58627 6.01174C3.44989 5.76103 3.48504 5.53838 3.69174 5.34378C3.85381 5.22565 4.0257 5.21002 4.20736 5.2969C4.6403 5.70638 5.06607 6.12436 5.48471 6.55081C6.20735 5.82816 6.93003 5.10549 7.65267 4.38284C7.72924 4.33292 7.80736 4.28996 7.88705 4.25393Z" fill="#73D264"/>
    </g>
    <defs>
    <clipPath id="clip0_14_8372">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  error: `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14_8378)">
    <circle cx="6" cy="6" r="4" fill="white"/>
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M5.56674 0.480495C7.80778 0.407483 9.53044 1.30592 10.7347 3.17581C11.6186 4.76065 11.7358 6.40128 11.0863 8.09768C10.3572 9.74553 9.12678 10.8197 7.39486 11.3203C5.22729 11.8008 3.37964 11.2422 1.85189 9.64456C0.595381 8.12829 0.204757 6.40953 0.680019 4.48831C1.19225 2.79638 2.25866 1.59716 3.87924 0.890651C4.42517 0.672113 4.98767 0.535395 5.56674 0.480495ZM4.18392 3.71487C4.31241 3.72774 4.4296 3.77072 4.53549 3.84378C5.01628 4.32849 5.50064 4.80896 5.98861 5.28518C6.49285 4.78484 7.00067 4.28874 7.51205 3.7969C7.83378 3.65874 8.07204 3.7486 8.22689 4.06643C8.26226 4.23739 8.23102 4.39362 8.13314 4.53518C7.64843 5.01598 7.16796 5.50034 6.69174 5.98831C7.19208 6.49254 7.68818 7.00036 8.18002 7.51174C8.31957 7.92019 8.17894 8.1702 7.75814 8.26174C7.64351 8.24175 7.53804 8.19879 7.44174 8.13284C6.96094 7.64813 6.47658 7.16766 5.98861 6.69143C5.50064 7.16766 5.01628 7.64813 4.53549 8.13284C4.21514 8.31352 3.96125 8.2471 3.77377 7.93362C3.70953 7.74443 3.74077 7.57254 3.86752 7.41799C4.3444 6.94503 4.81707 6.46845 5.28549 5.98831C4.80926 5.50034 4.32879 5.01598 3.84408 4.53518C3.68124 4.27407 3.70859 4.03578 3.92611 3.82034C4.00939 3.77063 4.09533 3.73547 4.18392 3.71487Z" fill="#F86E6E"/>
    </g>
    <defs>
    <clipPath id="clip0_14_8378">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
