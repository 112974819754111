import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedCryptoSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: <PropType<SharedCryptoSelectOption[]>>Array,
      default: () => [],
    },
    value: {
      type: <PropType<SharedCryptoSelectOption>>Object,
      required: true,
    },
  },
  emits: {
    create: null,
    "update:value": null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    displayedOptions(): SharedCryptoSelectOption[] {
      return this.options.map((option) => ({
        ...option,
        currency: { ...option.currency, icon: SVG[option.currency.icon] },
      }));
    },

    displayedPlaceholder(): string {
      return this.value.label ? this.value.label : this.placeholder;
    },

    isCurrencyShow(): boolean {
      return !!this.value.currency.icon && !!this.value.currency.coin;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleOption(option: SharedCryptoSelectOption): void {
      this.$emit("update:value", option);
    },

    handleCreate(): void {
      this.$emit("create");
    },
  },
});
