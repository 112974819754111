<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Загрузка документов" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Подтверждающие документы") }}
        </h4>

        <div :class="b('form-fields', { documents: true })">
          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentRegistrationCompany.file"
            :link="documentRegistrationCompany.link"
          >
            <template #label>
              {{ $t("Сертификат о") }} <br />
              {{ $t("регистрации") }} <br />
              {{ $t("компании") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentRegulation.file"
            :link="documentRegulation.link"
          >
            <template #label>
              <br />
              {{ $t("Устав") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentListOfShareholders.file"
            :link="documentListOfShareholders.link"
          >
            <template #label>
              <br />
              {{ $t("Список акционеров") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentLicense.file"
            :link="documentLicense.link"
          >
            <template #label>
              <br />
              {{ $t("Лицензия") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentCheckAddresses.file"
            :link="documentCheckAddresses.link"
          >
            <template #label>
              {{ $t("Подтверждение") }} <br />
              {{ $t("адреса") }} <br />
              {{ $t("регистрации") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentSow.file"
            :link="documentSow.link"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника дохода") }} <br />
              SoW
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentSof.file"
            :link="documentSof.link"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника средств") }} <br />
              SoF
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentInn.file"
            :link="documentInn.link"
          >
            <template #label>
              {{ $t("Свидетельство") }} <br />
              {{ $t("ИНН") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentRecordSheet.file"
            :link="documentRecordSheet.link"
          >
            <template #label>
              {{ $t("Лист Записи") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentCreate.file"
            :link="documentCreate.link"
          >
            <template #label>
              {{ $t("Решение о создании/") }} <br />
              {{ $t("Учредительный") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentUpdate.file"
            :link="documentUpdate.link"
          >
            <template #label>
              {{ $t("Изменения в") }} <br />
              {{ $t("учредительные") }} <br />
              {{ $t("документы") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentPurpose.file"
            :link="documentPurpose.link"
          >
            <template #label>
              {{ $t("О назначении") }} <br />
              {{ $t("единоличного") }} <br />
              {{ $t("исполнительного") }} <br />
              {{ $t("органа") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentFinance.file"
            :link="documentFinance.link"
          >
            <template #label>
              {{ $t("Финансовая") }} <br />
              {{ $t("отчетность") }} <br />
              {{ $t("(за год/за квартал)") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentExample.file"
            :link="documentExample.link"
          >
            <template #label>
              {{ $t("Карточка образцов") }} <br />
              {{ $t("подписей и печатей") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Cоглашения") }}
        </h4>

        <div :class="b('form-fields', { agreements: true })">
          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentKyb.file"
            :link="documentKyb.link"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("юридического лица") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentBeneficiary.file"
            :link="documentBeneficiary.link"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("бенефициарного") }} <br />
              {{ $t("владельца") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentBroker.file"
            :link="documentBroker.link"
          >
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentDeclaration.file"
            :link="documentDeclaration.link"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Документы для верификации") }}
        </h4>

        <div :class="b('form-fields', { verification: true })">
          <SharedUpload
            :is-remove="false"
            :is-document="true"
            :file="documentDirector.file"
            :link="documentDirector.link"
          >
            <template #label>
              {{ $t("Приказ о назначении") }} <br />
              {{ $t("директора") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
