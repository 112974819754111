<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Банковские реквизиты") }}
      </h3>
    </div>

    <div :class="b('form')">
      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Название*") }}
        </h5>

        <UIInput view="light" v-model="requisiteBank.name" />
      </div>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Номер расчетного счета*") }}
        </h5>

        <UIInput view="light" v-model="requisiteBank.account_number" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Номер корреспондентского счета*") }}
        </h5>

        <UIInput view="light" v-model="requisiteBank.correspondent_account" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Банковский идентификационный код (БИК)*") }}
        </h5>

        <UIInput
          view="light"
          v-model="requisiteBank.bank_identification_code"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Полное наименование банка*") }}
        </h5>

        <UIInput view="light" v-model="requisiteBank.bank_name" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Идентификационный номер") }}
          <br />
          {{ $t("налогоплательщика (ИНН)*") }}
        </h5>

        <UIInput view="light" v-model="requisiteBank.inn" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Код причины постановки на учет (КПП)*") }}
        </h5>

        <UIInput
          view="light"
          v-model="requisiteBank.registration_reason_code"
        />
      </div>
    </div>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />

    <transition name="opacity">
      <RequisitesVerification
        :class="b('modal')"
        v-if="isVerification"
        type="bank-requisite"
        @close="handleVerificationClose"
      />
    </transition>
  </SharedCard>
</template>
