import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG, Country } from "./attributes";
import { SharedCountryOption } from "./model";

import { SvgAttribute } from "@/shared/constants/constants";

const TOGGLE_PADDING = 36;
const TOGGLE_CHEVRON_WIDTH = 14;
const TOGGLE_COUNTRY_WIDTH = 27;
const TOGGLE_GAP = 20;
const TOGGLE_DOTS = 5;

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:value": null,
  },
  data() {
    return {
      isVisible: false,
      toggleLabelWidth: 0,
    };
  },
  mounted() {
    this.handleCalculateToggleWidth();

    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    value(): void {
      this.toggleLabelWidth = 0;

      setTimeout(() => {
        this.handleCalculateToggleWidth();
      }, 1);
    },
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedCountry(): string {
      return this.value ? Country[this.value as keyof typeof Country] : "";
    },

    displayedPlaceholder(): string {
      return this.value ? this.value : this.$t(this.placeholder);
    },

    displayedOptions(): SharedCountryOption[] {
      return Object.keys(Country).map((key) => ({
        label: key,
        value: key,
        icon: Country[key as keyof typeof Country],
        isActive: this.value === key,
      }));
    },

    displayedToggleLabelMaxWidth(): number | string {
      return this.toggleLabelWidth ? `${this.toggleLabelWidth}px` : "";
    },
  },
  methods: {
    handleChangeVisibility(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleOption({ value }: SharedCountryOption): void {
      this.$emit("update:value", value);
    },

    handleCalculateToggleWidth() {
      const toggleButton = <HTMLElement>this.$refs.toggle;
      const toggleLabel = <HTMLElement>this.$refs.toggleLabel;

      if (toggleButton && toggleLabel) {
        let toggleWidth =
          toggleButton.offsetWidth - TOGGLE_PADDING - TOGGLE_CHEVRON_WIDTH;
        const toggleLabelWidth = toggleLabel.scrollWidth + TOGGLE_DOTS;

        if (this.value) {
          toggleWidth = toggleWidth - TOGGLE_COUNTRY_WIDTH;
        }

        if (toggleWidth - toggleLabelWidth > TOGGLE_GAP) {
          this.toggleLabelWidth = toggleLabelWidth;
        } else {
          this.toggleLabelWidth = toggleLabelWidth - TOGGLE_GAP;
        }
      }
    },

    handleResize() {
      this.handleCalculateToggleWidth();
    },
  },
});
