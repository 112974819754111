import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminCommissionTabs = _resolveComponent("AdminCommissionTabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Комиссии")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_AdminCommissionTabs, {
        tab: _ctx.tab,
        "onUpdate:tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
      }, null, 8, ["tab"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent)))
    ], 2)
  ], 2))
}