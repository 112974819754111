import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { WalletTabsTabValue } from "../tabs/model";
import { WalletRequisitesRadioValue } from "../requisites/model";

import { SharedWindow } from "@/components/shared";
import { WalletTabs } from "../tabs";
import { WalletRequisites } from "../requisites";
import { WalletConclusionFiatPattern } from "./fiat-pattern";
import { WalletConclusionFiatNew } from "./fiat-new";
import { WalletConclusionCryptoPattern } from "./crypto-pattern";
import { WalletConclusionCryptoNew } from "./crypto-new";

type Component =
  | typeof WalletConclusionFiatPattern
  | typeof WalletConclusionFiatNew
  | typeof WalletConclusionCryptoPattern
  | typeof WalletConclusionCryptoNew;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    WalletTabs,
    WalletRequisites,
    WalletConclusionFiatPattern,
    WalletConclusionFiatNew,
    WalletConclusionCryptoPattern,
    WalletConclusionCryptoNew,
  },
  props: {
    isConclusion: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isConclusion": null,
  },
  data() {
    return {
      tab: WalletTabsTabValue.fiat,
      requisites: WalletRequisitesRadioValue.pattern,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case WalletTabsTabValue.fiat:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletConclusionFiatPattern;
            case WalletRequisitesRadioValue.new:
              return WalletConclusionFiatNew;
            default:
              return WalletConclusionFiatPattern;
          }
        case WalletTabsTabValue.crypto:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletConclusionCryptoPattern;
            case WalletRequisitesRadioValue.new:
              return WalletConclusionCryptoNew;
            default:
              return WalletConclusionCryptoPattern;
          }
        default:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletConclusionFiatPattern;
            case WalletRequisitesRadioValue.new:
              return WalletConclusionFiatNew;
            default:
              return WalletConclusionFiatPattern;
          }
      }
    },

    isBig(): boolean {
      return (
        this.tab === WalletTabsTabValue.fiat &&
        this.requisites === WalletRequisitesRadioValue.new
      );
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isConclusion", !this.isConclusion);
    },
  },
});
