import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdminHeader = _resolveComponent("SharedAdminHeader")!
  const _component_AdminKycWrapper = _resolveComponent("AdminKycWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAdminHeader, {
      title: "KYC",
      loading: _ctx.loading,
      email: _ctx.email,
      "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      id: _ctx.id,
      "onUpdate:id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.id) = $event)),
      onSearch: _ctx.handleSearch
    }, null, 8, ["loading", "email", "id", "onSearch"]),
    (!!_ctx.userApp.id)
      ? (_openBlock(), _createBlock(_component_AdminKycWrapper, {
          key: 0,
          email: "Нужен email",
          id: _ctx.userApp.user_id,
          type: _ctx.userApp.type
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
              id: _ctx.userApp.user_id,
              "participant-id": _ctx.participantId,
              "onUpdate:participantId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.participantId) = $event)),
              component: _ctx.component,
              "onUpdate:component": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.component) = $event))
            }, null, 40, ["id", "participant-id", "component"]))
          ]),
          _: 1
        }, 8, ["id", "type"]))
      : _createCommentVNode("", true),
    (!!_ctx.userApp.id)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedFooterComponent), {
          key: 1,
          id: _ctx.userApp.user_id,
          component: _ctx.component,
          "onUpdate:component": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.component) = $event)),
          loading: _ctx.loading,
          "onUpdate:loading": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.loading) = $event)),
          status: _ctx.userApp.status,
          "onUpdate:status": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userApp.status) = $event))
        }, null, 40, ["id", "component", "loading", "status"]))
      : _createCommentVNode("", true)
  ], 2))
}