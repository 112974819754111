export enum VerificationKycComponent {
  kycPersonalInformation = "kyc-personal-information",
  kycDocuments = "kyc-documents",
}

export enum VerificationKybComponent {
  kybCompany = "kyb-company",
  kybBasicInformation = "kyb-basic-information",
  kybRegistrationInformation = "kyb-registration-information",
  kybParticipants = "kyb-participants",
  kybParticipant = "kyb-participant",
  kybParticipantFiles = "kyb-participant-files",
  kybDocuments = "kyb-documents",
}
