<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <div :class="b('tabs')">
        <button
          :class="b('tab', { active: tab.active })"
          v-for="tab in displayedTabs"
          :key="tab.id"
          @click="handleTab(tab)"
        >
          {{ $t(tab.label) }}
        </button>
      </div>

      <div :class="b('actions')">
        <button :class="b('action-small')" @click="handleConclusion">
          <span
            :class="b('action-icon', { conclusion: true })"
            v-html="displayedIcons.conclusion"
          ></span>
        </button>

        <button :class="b('action-small')" @click="handleInput">
          <span
            :class="b('action-icon', { input: true })"
            v-html="displayedIcons.input"
          ></span>
        </button>

        <SharedButtonIcon
          :class="b('action')"
          view="main-outline"
          @click="handleConclusion"
        >
          <template #icon-left>
            <span
              :class="b('action-icon', { conclusion: true })"
              v-html="displayedIcons.conclusion"
            ></span>
          </template>

          <template #label>
            {{ $t("Вывод") }}
          </template>
        </SharedButtonIcon>

        <SharedButtonIcon :class="b('action')" view="main" @click="handleInput">
          <template #icon-left>
            <span
              :class="b('action-icon', { input: true })"
              v-html="displayedIcons.input"
            ></span>
          </template>

          <template #label>
            {{ $t("Ввод") }}
          </template>
        </SharedButtonIcon>
      </div>
    </div>

    <div :class="b('table')">
      <div :class="b('columns')">
        <div
          :class="b('column')"
          v-for="column in displayedColumns"
          :key="column.id"
        >
          <p :class="b('column-label')">
            {{ $t(column.label) }}
          </p>

          <button
            v-if="column.sort"
            :class="b('column-sort')"
            @click="handleColumnSort(column)"
          >
            <span :class="b('column-icon')" v-html="displayedIcons.sort"></span>
          </button>
        </div>
      </div>

      <div :class="b('rows')">
        <div :class="b('row')" v-for="row in displayedRows" :key="row.id">
          <div :class="b('row-currency')">
            <span
              :class="b('row-currency-icon')"
              v-html="row.currency.icon"
            ></span>

            <div :class="b('row-currency-group')">
              <span :class="b('row-currency-label')">
                {{ row.currency.name }}
              </span>

              <span
                :class="
                  b('row-currency-tag', {
                    bank: row.currency.isBank,
                    crypto: row.currency.isCrypto,
                  })
                "
              >
                {{ row.currency.tag }}
              </span>
            </div>
          </div>

          <div :class="b('row-total')">
            <p :class="b('row-total-label')">
              {{ row.total }}
            </p>
          </div>

          <div :class="b('row-actions')">
            <button
              :class="b('row-actions-dots', { active: row.isActions })"
              @click="handleActions(row)"
            >
              <span
                :class="b('row-action-icon')"
                v-html="displayedIcons.dots"
              ></span>
            </button>

            <div :class="b('row-actions-list', { visible: row.isActions })">
              <button
                :class="b('row-action')"
                @click="handleRowConclusion(row)"
              >
                <span
                  :class="b('row-action-icon')"
                  v-html="displayedIcons.conclusionGray"
                ></span>
              </button>

              <button :class="b('row-action')" @click="handleRowInput(row)">
                <span
                  :class="b('row-action-icon')"
                  v-html="displayedIcons.inputGray"
                ></span>
              </button>

              <button :class="b('row-action')">
                <span
                  :class="b('row-action-icon')"
                  v-html="displayedIcons.swapGray"
                ></span>
              </button>

              <button :class="b('row-action')">
                <span
                  :class="b('row-action-icon')"
                  v-html="displayedIcons.historyGray"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
