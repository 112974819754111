import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  HistoryTableColumn,
  HistoryTableColumnLabel,
  HistoryTableRow,
  HistoryTableRowStatusIcon,
} from "./model";

import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  data() {
    return {
      rowActionsId: <null | number>null,
    };
  },
  computed: {
    displayedColumns(): HistoryTableColumn[] {
      return [
        {
          id: 0,
          label: HistoryTableColumnLabel.dateTime,
        },
        {
          id: 1,
          label: HistoryTableColumnLabel.type,
        },
        {
          id: 2,
          label: HistoryTableColumnLabel.amount,
        },
        {
          id: 3,
          label: HistoryTableColumnLabel.currency,
        },
        {
          id: 4,
          label: HistoryTableColumnLabel.commission,
        },
        {
          id: 5,
          label: HistoryTableColumnLabel.status,
        },
      ];
    },

    displayedSmallColumns(): HistoryTableColumn[] {
      return [
        {
          id: 0,
          label: HistoryTableColumnLabel.amount,
        },
        {
          id: 1,
          label: HistoryTableColumnLabel.currency,
        },
        {
          id: 2,
          label: HistoryTableColumnLabel.date,
        },
        {
          id: 3,
          label: HistoryTableColumnLabel.status,
        },
      ];
    },

    displayedRows(): HistoryTableRow[] {
      return [
        {
          id: 0,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.created],
            label: "Создано",
          },
        },
        {
          id: 1,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "999,999.12345678",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.expectation],
            label: "Ожидание",
          },
        },
        {
          id: 2,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.waitingForConfirmation],
            label: "Ожидание подтверждения",
          },
        },
        {
          id: 3,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.error],
            label: "Ошибка",
          },
        },
        {
          id: 4,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 5,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 6,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 7,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 8,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 9,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 10,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 11,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 12,
          dateTime: "07.05.2024 15:02:10",
          type: "Вывод",
          amount: {
            type: {
              refill: false,
              conclusion: true,
            },
            label: "0.04",
          },
          currency: "BTC",
          commission: "0.0267",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
        {
          id: 13,
          dateTime: "07.05.2024 15:02:10",
          type: "Пополнение",
          amount: {
            type: {
              refill: true,
              conclusion: false,
            },
            label: "9,999",
          },
          currency: "USDT",
          commission: "0.00018",
          status: {
            icon: SVG[HistoryTableRowStatusIcon.done],
            label: "Выполнено",
          },
        },
      ].map((row) => ({ ...row, isActions: row.id === this.rowActionsId }));
    },

    displayedSortIcon(): string {
      return SVG.sort;
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    displayedDotsIcon(): string {
      return SVG.dots;
    },
  },
  methods: {
    handleRowActions({ id }: HistoryTableRow): void {
      this.rowActionsId = this.rowActionsId === id ? null : id;
    },

    handleConfirm(row: HistoryTableRow): void {
      console.log("Handle Confirm: ", row);
    },

    handleRemove(row: HistoryTableRow): void {
      console.log("Handle Remove: ", row);
    },
  },
});
