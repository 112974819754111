<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="b('', { view })"
    :checked="isChecked"
    :disabled="disabled"
    @click="handleRadio"
  ></button>
</template>
