<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAdminHeader
      title="KYC"
      :loading="loading"
      v-model:email="email"
      v-model:id="id"
      @search="handleSearch"
    />

    <AdminKycWrapper
      v-if="!!userApp.id"
      email="Нужен email"
      :id="userApp.user_id"
      :type="userApp.type"
    >
      <component
        :is="displayedComponent"
        :id="userApp.user_id"
        v-model:participant-id="participantId"
        v-model:component="component"
      />
    </AdminKycWrapper>

    <component
      v-if="!!userApp.id"
      :is="displayedFooterComponent"
      :id="userApp.user_id"
      v-model:component="component"
      v-model:loading="loading"
      v-model:status="userApp.status"
    />
  </div>
</template>
