<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <div :class="b('header-group')">
        <SharedBack @click="handleBack" />

        <h3 :class="b('header-title')">
          {{ $t("Участник") }}
        </h3>
      </div>

      <div :class="b('header-checkboxes')">
        <div :class="b('header-checkbox')">
          <SharedCheckbox
            view="22"
            v-model:checked="kybParticipant.is_beneficiary"
          />

          <p :class="b('header-checkbox-label')">
            {{ $t("Является Бенефициаром") }}
          </p>
        </div>

        <div :class="b('header-checkbox')">
          <SharedCheckbox
            view="22"
            v-model:checked="kybParticipant.is_director"
          />

          <p :class="b('header-checkbox-label')">
            {{ $t("Является директором") }}
          </p>
        </div>
      </div>
    </div>

    <div :class="b('form')">
      <div :class="b('form-fields')">
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Фамилия*") }}
          </h5>

          <UIInput view="light" v-model="kybParticipant.sur_name" />
        </div>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Имя*") }}
          </h5>

          <UIInput view="light" v-model="kybParticipant.name" />
        </div>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Отчество") }}
          </h5>

          <UIInput view="light" v-model="kybParticipant.patronymic" />
        </div>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Гражданство*") }}
          </h5>

          <SharedCountry
            placeholder="Выберите страну"
            v-model:value="kybParticipant.citizenship"
          />
        </div>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Дата рождения*") }}
          </h5>

          <SharedDate
            format="yyyy-MM-dd"
            v-model:date="kybParticipant.birth_date"
          />
        </div>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Укажите пол*") }}
          </h5>

          <div :class="b('form-radios')">
            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                checkedValue="male"
                v-model:value="kybParticipant.gender"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Мужчина") }}
              </p>
            </div>

            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                checkedValue="female"
                v-model:value="kybParticipant.gender"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Женщина") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div :class="b('form-address')">
        <h5 :class="b('form-title')">
          {{ $t("Адрес проживания*") }}
        </h5>

        <div :class="b('form-address-fields')">
          <SharedCountry
            :class="b('form-address-country')"
            placeholder="Cтрана"
            v-model:value="kybParticipant.address.country"
          />

          <UIInput
            :class="b('form-address-city')"
            placeholder="Город"
            view="light"
            v-model="kybParticipant.address.city"
          />

          <UIInput
            :class="b('form-address-state')"
            placeholder="Штат / Область"
            view="light"
            v-model="kybParticipant.address.state"
          />

          <UIInput
            :class="b('form-address-street')"
            placeholder="Улица"
            view="light"
            v-model="kybParticipant.address.street"
          />

          <UIInput
            :class="b('form-address-office')"
            placeholder="Номер Офиса"
            view="light"
            v-model="kybParticipant.address.office"
          />

          <UIInput
            :class="b('form-address-index')"
            placeholder="Индекс"
            view="light"
            v-model="kybParticipant.address.zip"
          />
        </div>
      </div>

      <div :class="b('form-fields')">
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Какой долей он владеет в обществе?*") }}
          </h5>

          <SharedInputDouble
            view="light"
            v-model="kybParticipant.share_in_org"
          />
        </div>
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
