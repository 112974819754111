import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminKycBack = _resolveComponent("AdminKycBack")!
  const _component_SharedAdd = _resolveComponent("SharedAdd")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminKycBack, {
      title: "Участники организации",
      onBack: _ctx.handleBack
    }, null, 8, ["onBack"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('cards'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedParticipants, (participant, participantIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('card')),
          key: participantIndex
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('card-title'))
          }, _toDisplayString(_ctx.$t(participant.title)), 3),
          _createVNode(_component_SharedAdd, {
            "is-success": !!participant.id,
            "is-remove": false,
            onClick: ($event: any) => (_ctx.handleParticipant(participant))
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(participant.name) + " ", 1),
              (participant.name)
                ? (_openBlock(), _createElementBlock("br", _hoisted_1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(participant.patronymic) + " ", 1),
              (participant.patronymic)
                ? (_openBlock(), _createElementBlock("br", _hoisted_2))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(participant.sur_name), 1)
            ]),
            _: 2
          }, 1032, ["is-success", "onClick"])
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}