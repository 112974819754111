import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "auth-introduction";

export const SVG: SvgAttribute = {
  logo: `<svg width="488" height="136" viewBox="0 0 488 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.4587 0.319981V29.3468H93.6566L69.6491 53.7567L0 0V128.129H28.9824V59.0136L72.2507 92.4287L115.017 49.0027V84.5663H143.954V0.319981H56.4587Z" fill="white"/>
    <path d="M180.923 0.319981H203.333L235.465 56.728L267.734 0.319981H290.007V84.5663H268.327V36.7521L240.668 84.5663H230.216L202.74 36.7521V84.5663H180.923V0.319981Z" fill="white"/>
    <path d="M305.707 0.319981H378.186V15.6333H328.437V35.1522H365.042V49.8713H328.437V69.2529H378.186V84.5663H305.707V0.319981Z" fill="white"/>
    <path d="M423.737 42.146L386.082 0.319981H412.098L436.745 27.7926L461.528 0.319981H487.544L449.752 42.146L488 84.5663H461.984L436.745 56.5909L411.505 84.5663H385.489L423.737 42.146Z" fill="white"/>
    <path d="M272.224 128V109.98H275.012V113.38C275.284 112.655 275.658 112.02 276.134 111.476C276.61 110.909 277.188 110.467 277.868 110.15C278.57 109.81 279.352 109.64 280.214 109.64C280.667 109.64 281.03 109.663 281.302 109.708C281.596 109.753 281.857 109.81 282.084 109.878V112.666C281.789 112.553 281.494 112.473 281.2 112.428C280.928 112.383 280.542 112.36 280.044 112.36C279.137 112.36 278.321 112.575 277.596 113.006C276.87 113.437 276.292 114.037 275.862 114.808C275.431 115.556 275.216 116.44 275.216 117.46V128H272.224Z" fill="white"/>
    <path d="M259.981 128.34C258.462 128.34 257.034 128.011 255.697 127.354C254.382 126.697 253.317 125.688 252.501 124.328C251.707 122.968 251.311 121.245 251.311 119.16V118.48C251.311 116.485 251.696 114.842 252.467 113.55C253.237 112.235 254.257 111.261 255.527 110.626C256.796 109.969 258.167 109.64 259.641 109.64C262.293 109.64 264.344 110.365 265.795 111.816C267.245 113.267 267.971 115.318 267.971 117.97V119.874H254.303C254.303 121.279 254.586 122.401 255.153 123.24C255.742 124.079 256.467 124.691 257.329 125.076C258.213 125.439 259.097 125.62 259.981 125.62C261.341 125.62 262.383 125.393 263.109 124.94C263.857 124.487 264.389 123.863 264.707 123.07H267.631C267.268 124.793 266.373 126.107 264.945 127.014C263.539 127.898 261.885 128.34 259.981 128.34ZM259.811 112.36C258.881 112.36 257.997 112.541 257.159 112.904C256.32 113.244 255.629 113.788 255.085 114.536C254.563 115.284 254.303 116.247 254.303 117.426H265.149C265.126 116.179 264.865 115.193 264.367 114.468C263.891 113.72 263.256 113.187 262.463 112.87C261.669 112.53 260.785 112.36 259.811 112.36Z" fill="white"/>
    <path d="M233.94 128V104.2H236.932V118.276L245.228 109.98H248.9L241.42 117.426L249.58 128H245.772L239.278 119.5L236.932 121.71V128H233.94Z" fill="white"/>
    <path d="M221.033 128.34C219.515 128.34 218.087 128.011 216.749 127.354C215.435 126.674 214.369 125.654 213.553 124.294C212.76 122.934 212.363 121.223 212.363 119.16V118.48C212.363 116.531 212.76 114.899 213.553 113.584C214.369 112.269 215.435 111.283 216.749 110.626C218.087 109.969 219.515 109.64 221.033 109.64C222.575 109.64 224.003 109.969 225.317 110.626C226.632 111.283 227.686 112.269 228.479 113.584C229.295 114.899 229.703 116.531 229.703 118.48V119.16C229.703 121.223 229.295 122.934 228.479 124.294C227.686 125.654 226.632 126.674 225.317 127.354C224.003 128.011 222.575 128.34 221.033 128.34ZM221.033 125.62C221.963 125.62 222.847 125.416 223.685 125.008C224.547 124.577 225.249 123.886 225.793 122.934C226.36 121.982 226.643 120.724 226.643 119.16V118.48C226.643 117.029 226.36 115.862 225.793 114.978C225.249 114.071 224.547 113.414 223.685 113.006C222.847 112.575 221.963 112.36 221.033 112.36C220.127 112.36 219.243 112.575 218.381 113.006C217.52 113.414 216.806 114.071 216.239 114.978C215.695 115.862 215.423 117.029 215.423 118.48V119.16C215.423 120.724 215.695 121.982 216.239 122.934C216.806 123.886 217.52 124.577 218.381 125.008C219.243 125.416 220.127 125.62 221.033 125.62Z" fill="white"/>
    <path d="M200.638 128V109.98H203.426V113.38C203.698 112.655 204.072 112.02 204.548 111.476C205.024 110.909 205.602 110.467 206.282 110.15C206.984 109.81 207.766 109.64 208.628 109.64C209.081 109.64 209.444 109.663 209.716 109.708C210.01 109.753 210.271 109.81 210.498 109.878V112.666C210.203 112.553 209.908 112.473 209.614 112.428C209.342 112.383 208.956 112.36 208.458 112.36C207.551 112.36 206.735 112.575 206.01 113.006C205.284 113.437 204.706 114.037 204.276 114.808C203.845 115.556 203.63 116.44 203.63 117.46V128H200.638Z" fill="white"/>
    <path d="M188.9 128.34C187.358 128.34 186.021 128 184.888 127.32C183.754 126.617 182.961 125.711 182.508 124.6V128H179.72V104.2H182.712V113.21C183.233 112.099 184.026 111.227 185.092 110.592C186.18 109.957 187.449 109.64 188.9 109.64C191.144 109.64 192.946 110.388 194.306 111.884C195.688 113.357 196.38 115.556 196.38 118.48V119.16C196.38 121.2 196.062 122.9 195.428 124.26C194.816 125.62 193.943 126.64 192.81 127.32C191.699 128 190.396 128.34 188.9 128.34ZM188.22 125.688C189.693 125.688 190.917 125.133 191.892 124.022C192.889 122.911 193.388 121.291 193.388 119.16V118.48C193.388 116.508 192.889 114.989 191.892 113.924C190.917 112.836 189.693 112.292 188.22 112.292C187.132 112.292 186.168 112.519 185.33 112.972C184.514 113.425 183.868 114.049 183.392 114.842C182.938 115.613 182.712 116.485 182.712 117.46V120.18C182.712 121.268 182.938 122.231 183.392 123.07C183.868 123.886 184.514 124.532 185.33 125.008C186.168 125.461 187.132 125.688 188.22 125.688Z" fill="white"/>
  </svg>`,
};
