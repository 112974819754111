import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:email": null,
    "update:id": null,
    search: null,
  },
  computed: {
    isSearchDisabled(): boolean {
      return (!this.id && !Number(this.id)) || this.loading;
    },
  },
  methods: {
    handleChangeEmail(email: string): void {
      this.$emit("update:email", email);
    },

    handleChangeId(id: string): void {
      this.$emit("update:id", id);
    },

    handleSearch(): void {
      this.$emit("search");
    },
  },
});
