import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-header";

export const SVG: SvgAttribute = {
  filter: `<svg width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.966" fill-rule="evenodd" clip-rule="evenodd" d="M1.92487 0.00310858C6.67757 -0.00518076 11.4303 0.00310858 16.1829 0.0279716C17.4875 0.430266 18.0896 1.33362 17.9893 2.73804C17.9205 3.16176 17.7599 3.54296 17.5076 3.88174C15.625 6.09018 13.7785 8.32785 11.9681 10.5948C11.9285 10.7085 11.8964 10.8246 11.8718 10.9428C11.8557 12.7662 11.8397 14.5894 11.8236 16.4127C11.7269 16.9695 11.446 17.3922 10.9807 17.6807C10.2099 18.0785 9.43924 18.4763 8.66854 18.8742C7.59601 19.2111 6.80122 18.8713 6.28417 17.8548C6.24458 17.741 6.21245 17.6249 6.18783 17.5067C6.17179 15.3353 6.1557 13.164 6.13966 10.9926C6.1164 10.7953 6.05219 10.613 5.94699 10.4456C4.19686 8.34053 2.44669 6.23543 0.696562 4.13037C-0.101364 3.18513 -0.213761 2.15744 0.359378 1.04736C0.746754 0.478942 1.26859 0.13086 1.92487 0.00310858ZM2.11755 1.64407C6.7097 1.63576 11.3018 1.64407 15.8939 1.66893C16.3997 1.8923 16.5362 2.26525 16.3033 2.78777C14.505 4.94255 12.7067 7.09738 10.9084 9.25216C10.5587 9.72152 10.35 10.2519 10.2822 10.8434C10.2662 12.617 10.2501 14.3905 10.234 16.1641C9.54025 16.5845 8.82572 16.974 8.09051 17.3326C7.94465 17.3893 7.84027 17.3479 7.77741 17.2083C7.76137 15.0867 7.74528 12.9651 7.72924 10.8434C7.66147 10.2519 7.45275 9.72152 7.10305 9.25216C5.30475 7.09738 3.50641 4.94255 1.70811 2.78777C1.47983 2.25933 1.6163 1.87808 2.11755 1.64407Z"/>
  </svg>`,
  download: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.971" fill-rule="evenodd" clip-rule="evenodd" d="M9.23829 2C9.36536 2 9.49243 2 9.6195 2C9.89022 2.09619 10.0544 2.28679 10.1119 2.57181C10.1225 6.10856 10.1331 6.4767 10.1437 10.0135C11.3676 8.77894 12.6012 7.5559 13.8446 6.34433C14.2279 6.19524 14.5403 6.28524 14.7817 6.61435C14.9011 6.89145 14.88 7.1562 14.7182 7.40853C13.1033 9.02336 11.4885 10.6382 9.87364 12.253C9.57716 12.4437 9.28064 12.4437 8.98416 12.253C7.36933 10.6382 5.75447 9.02336 4.13964 7.40853C3.97782 7.1562 3.95664 6.89145 4.0761 6.61435C4.31748 6.28524 4.62987 6.19524 5.01324 6.34433C6.25658 7.5559 7.4902 8.77894 8.71413 10.0135C8.72471 6.4767 8.73532 6.10856 8.7459 2.57181C8.80343 2.28679 8.96757 2.09619 9.23829 2Z" fill="#9E9FA8"/>
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M14.3846 15.0962C11.0808 15.0962 7.77704 15.0962 4.47324 15.0962C4.00746 14.8585 3.88569 14.4985 4.10791 14.0161C4.20615 13.8755 4.33852 13.7855 4.505 13.7461C7.78762 13.7249 11.0703 13.7249 14.3529 13.7461C14.8485 13.962 14.9809 14.3168 14.75 14.8103C14.6496 14.9376 14.5279 15.0329 14.3846 15.0962Z" fill="#9E9FA8"/>
  </svg>`,
  clear: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8492 3.94975C14.077 4.17755 14.077 4.5469 13.8492 4.77471L9.72437 8.89949L13.8492 13.0243C14.077 13.2521 14.077 13.6214 13.8492 13.8492C13.6214 14.077 13.252 14.077 13.0242 13.8492L8.89941 9.72445L4.77462 13.8492C4.54682 14.077 4.17747 14.077 3.94967 13.8492C3.72186 13.6214 3.72186 13.2521 3.94967 13.0243L8.07446 8.89949L3.94967 4.7747C3.72186 4.5469 3.72186 4.17755 3.94967 3.94975C4.17747 3.72194 4.54682 3.72194 4.77463 3.94975L8.89941 8.07454L13.0242 3.94975C13.252 3.72194 13.6214 3.72194 13.8492 3.94975Z" fill="#F86E6E"/>
  </svg>`,
};
