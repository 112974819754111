import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { AdminUsersSearchType } from "./model";

import {
  AdminUsersFilter,
  AdminUsersTable,
  AdminUsersStatus,
  AdminUsersBalance,
} from "@/components/admin-users";
import { SharedButtonIcon } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminUserResponse } from "@/shared/repository/repo";
import { AdminUserResponseFactory } from "@/shared/factory/admin-users";
import { SortFactory } from "@/shared/factory/sort";

const PAGE_SIZE = 10;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminUsersFilter,
    AdminUsersTable,
    AdminUsersStatus,
    AdminUsersBalance,
    SharedButtonIcon,
  },
  data() {
    return {
      search: "",
      searchType: AdminUsersSearchType.all,
      userId: 0,
      page: 1,
      users: <AdminUserResponse[]>[],
      sort: SortFactory(),
      isBalance: false,
      isStatus: false,
    };
  },
  async created(): Promise<void> {
    try {
      await this.$projectServices.projectRepository
        .adminUsers()
        .then((users) => {
          this.users = users.map((user) => AdminUserResponseFactory(user));
        });
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedUsers(): AdminUserResponse[] {
      const users = this.handleFilterUsers();

      return users.slice(0, this.page * PAGE_SIZE);
    },

    isMoreShow(): boolean {
      return this.page * PAGE_SIZE < this.users.length;
    },

    isStatusShow(): boolean {
      return !!this.userId && this.isStatus;
    },

    isBalanceShow(): boolean {
      return !!this.userId && this.isBalance;
    },
  },
  methods: {
    handleFilterUsers(): AdminUserResponse[] {
      let users = this.users.slice();

      const search = this.search.toLowerCase().trim();
      const { key, isUp, isDown } = this.sort;

      if (search) {
        users = users.filter((user) => {
          switch (this.searchType) {
            case AdminUsersSearchType.id:
              return user.id.toString().toLowerCase().includes(search);
            case AdminUsersSearchType.email:
              return user.email.toString().toLowerCase().includes(search);
            case AdminUsersSearchType.fio:
              return user.name.toString().toLowerCase().includes(search);
            default:
              return Object.values(user)
                .join(" ")
                .toLowerCase()
                .includes(search);
          }
        });
      }

      if (key) {
        users.sort((a, b) => {
          // eslint-disable-next-line
          // @ts-ignore
          const valA = a[key];
          // eslint-disable-next-line
          // @ts-ignore
          const valB = b[key];

          if (isUp) {
            if (valA < valB) return -1;
            if (valA > valB) return 1;

            return 0;
          } else if (isDown) {
            if (valA < valB) return 1;
            if (valA > valB) return -1;

            return 0;
          }

          return 0;
        });
      }

      return users;
    },

    handleMore(): void {
      this.page++;
    },
  },
});
