import { LoginRequest } from "@/shared/repository/repo";

export const LoginFactory = ({
  email,
  password,
}: Partial<LoginRequest> = {}): LoginRequest => {
  return {
    email: email ? email : "",
    password: password ? password : "",
  };
};
