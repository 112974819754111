import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedVerification = _resolveComponent("SharedVerification")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedVerification, {
      title: "Email Verification",
      code: _ctx.code,
      "onUpdate:code": _ctx.changeCode
    }, null, 8, ["code", "onUpdate:code"]),
    _createVNode(_component_UIButton, {
      label: "Create Account",
      view: "main",
      disabled: _ctx.isVerificationDisabled,
      onClick: _ctx.handleVerification
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}