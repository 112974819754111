import {
  RequisiteBankRequest,
  RequisiteBankResponse,
  RequisiteCryptoRequest,
  RequisiteCryptoResponse,
} from "@/shared/repository/repo";

export const RequisiteBankRequestFactory = (
  params: Partial<RequisiteBankRequest> = {}
): RequisiteBankRequest => {
  return {
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? params.name : "",
    account_number: params.account_number ? params.account_number : "",
    correspondent_account: params.correspondent_account
      ? params.correspondent_account
      : "",
    bank_identification_code: params.bank_identification_code
      ? params.bank_identification_code
      : "",
    bank_name: params.bank_name ? params.bank_name : "",
    inn: params.inn ? params.inn : "",
    registration_reason_code: params.registration_reason_code
      ? params.registration_reason_code
      : "",
  };
};

export const RequisiteBankResponseFactory = (
  params: Partial<RequisiteBankResponse> = {}
): RequisiteBankResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? params.name : "",
    account_number: params.account_number ? params.account_number : "",
    correspondent_account: params.correspondent_account
      ? params.correspondent_account
      : "",
    bank_identification_code: params.bank_identification_code
      ? params.bank_identification_code
      : "",
    bank_name: params.bank_name ? params.bank_name : "",
    inn: params.inn ? params.inn : "",
    registration_reason_code: params.registration_reason_code
      ? params.registration_reason_code
      : "",
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};

export const RequisiteCryptoRequestFactory = (
  params: Partial<RequisiteCryptoRequest> = {}
): RequisiteCryptoRequest => {
  return {
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? params.name : "",
    account_number: params.account_number ? params.account_number : "",
    coin: params.coin ? params.coin : "",
    network: params.network ? params.network : "",
    address: params.address ? params.address : "",
  };
};

export const RequisiteCryptoResponseFactory = (
  params: Partial<RequisiteCryptoResponse> = {}
): RequisiteCryptoResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? params.name : "",
    account_number: params.account_number ? params.account_number : "",
    coin: params.coin ? params.coin : "",
    network: params.network ? params.network : "",
    address: params.address ? params.address : "",
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};
