import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-download";

export const SVG: SvgAttribute = {
  download: `<svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.971" fill-rule="evenodd" clip-rule="evenodd" d="M24.397 12.8333C24.6088 12.8333 24.8206 12.8333 25.0323 12.8333C25.4835 12.9936 25.7571 13.3113 25.853 13.7863C25.8706 19.6809 25.8883 20.2945 25.9059 26.1891C27.9458 24.1315 30.0019 22.0931 32.0741 20.0739C32.713 19.8254 33.2337 19.9754 33.636 20.5239C33.8351 20.9857 33.7998 21.427 33.5301 21.8475C30.8387 24.5389 28.1473 27.2303 25.4559 29.9217C24.9618 30.2394 24.4676 30.2394 23.9734 29.9217C21.282 27.2303 18.5906 24.5389 15.8992 21.8475C15.6295 21.427 15.5942 20.9857 15.7933 20.5239C16.1956 19.9754 16.7163 19.8254 17.3552 20.0739C19.4275 22.0931 21.4835 24.1315 23.5234 26.1891C23.541 20.2945 23.5587 19.6809 23.5763 13.7863C23.6722 13.3113 23.9458 12.9936 24.397 12.8333Z" fill="#9E9FA8"/>
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M32.9743 34.6603C27.468 34.6603 21.9617 34.6603 16.4553 34.6603C15.679 34.2641 15.4761 33.6641 15.8464 32.8602C16.0102 32.6257 16.2308 32.4758 16.5083 32.4102C21.9793 32.3748 27.4504 32.3748 32.9214 32.4102C33.7474 32.77 33.968 33.3613 33.5832 34.1838C33.416 34.396 33.213 34.5548 32.9743 34.6603Z" fill="#9E9FA8"/>
  </svg>`,
};
