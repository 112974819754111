import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('toggle', { view: _ctx.view })),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeIsVisible && _ctx.changeIsVisible(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-label'))
      }, _toDisplayString(_ctx.displayedPlaceholder), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-icon', { active: _ctx.isVisible })),
        innerHTML: _ctx.displayedIcon
      }, null, 10, _hoisted_1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { view: _ctx.view, visible: _ctx.isVisible }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass([_ctx.b('option', { view: _ctx.view }), _ctx.displayedOptionActiveClass(option)]),
          key: option.id,
          onClick: ($event: any) => (_ctx.changeOption(option))
        }, _toDisplayString(option.label), 11, _hoisted_2))
      }), 128))
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}