<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('wrapper', { big: isBig })" v-click-outside="handleClose">
      <h2 :class="b('title', { center: isCenter })" v-if="title">
        {{ $t(title) }}
      </h2>

      <slot />

      <button :class="b('close')" @click="handleClose">
        <span :class="b('close-icon')" v-html="displayedCloseIcon"></span>
      </button>
    </div>
  </div>
</template>
