import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdd = _resolveComponent("SharedAdd")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedCard, {
      class: _normalizeClass(_ctx.b('content'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('content-title'))
        }, _toDisplayString(_ctx.$t("Банковские реквизиты")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('content-block'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('content-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('content-group-title'))
            }, _toDisplayString(_ctx.$t("Банковский счет №1")), 3),
            _createVNode(_component_SharedAdd, {
              "is-confirmed": !!_ctx.requisiteBank.id,
              "is-remove": false,
              onClick: _ctx.handleRequisiteBank
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Подтверждено")), 1)
              ]),
              label: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Добавить")), 1)
              ]),
              _: 1
            }, 8, ["is-confirmed", "onClick"])
          ], 2)
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_SharedCard, {
      class: _normalizeClass(_ctx.b('content', { crypto: true }))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('content-title'))
        }, _toDisplayString(_ctx.$t("Крипто реквизиты")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('content-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('content-group-title'))
          }, _toDisplayString(_ctx.$t("Крипто счет №1")), 3),
          _createVNode(_component_SharedAdd, {
            "is-confirmed": !!_ctx.requisiteCrypto.id,
            "is-remove": false,
            onClick: _ctx.handleRequisiteCrypto
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Подтверждено")), 1)
            ]),
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Добавить")), 1)
            ]),
            _: 1
          }, 8, ["is-confirmed", "onClick"])
        ], 2)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}