import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  props: {
    isRemove: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isRemove": null,
  },
  computed: {
    displayedCloseIcon(): string {
      return SVG.close;
    },

    displayedIcon(): string {
      return SVG.remove;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isRemove", !this.isRemove);
    },

    handleRemove(): void {
      console.log("Handle Remove");
    },
  },
});
