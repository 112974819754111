import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { DefaultHeader } from "../header";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    DefaultHeader,
  },
});
