import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybParticipantUploadFileResponseFactory } from "@/shared/factory/kyb";
import {
  FileType,
  KybParticipantDocumentsFileName,
} from "@/shared/constants/constants";
import { KybParticipantUploadFileResponse } from "@/shared/repository/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    participantId: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      passportSelfie: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ],
        type: FileType.image,
      }),
      passportOrganization: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ],
        type: FileType.image,
      }),
      passportAddress: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ],
        type: FileType.image,
      }),
      selfie: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ],
        type: FileType.image,
      }),
      questionnaire: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ],
        type: FileType.document,
      }),
      personalData: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ],
        type: FileType.document,
      }),
      director: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ],
        type: FileType.document,
      }),
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.projectRepo
        .getKybParticipantFiles(this.participantId)
        .then((kybParticipantFiles) => {
          kybParticipantFiles.forEach((kybParticipantFile) => {
            this.handleCurrentFileResponse(kybParticipantFile);
          });
        });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybParticipant);
    },

    handleCurrentFileResponse(params: KybParticipantUploadFileResponse): void {
      switch (extractFileName(params.name)) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          this.passportSelfie = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          this.passportOrganization =
            KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          this.passportAddress =
            KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          this.selfie = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          this.questionnaire = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          this.personalData = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          this.director = KybParticipantUploadFileResponseFactory(params);

          break;
        default:
          break;
      }
    },
  },
});
