import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
      component: _ctx.component,
      "onUpdate:component": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.component) = $event))
    }, null, 40, ["component"]))
  ], 2))
}