import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Верификация информации о компании")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('list'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedList, (item) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(_ctx.b('item', { padding: item.isPadding })),
              key: item.id,
              onClick: ($event: any) => (_ctx.handleContinue(item))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('item-icon')),
                innerHTML: item.icon
              }, null, 10, _hoisted_2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('item-info'))
              }, [
                _createElementVNode("h4", {
                  class: _normalizeClass(_ctx.b('item-title'))
                }, _toDisplayString(_ctx.$t(item.title)), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('item-label'))
                }, _toDisplayString(_ctx.$t(item.label)), 3)
              ], 2),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('item-chevron')),
                innerHTML: _ctx.displayedChevron
              }, null, 10, _hoisted_3)
            ], 10, _hoisted_1))
          }), 128))
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Отправить на проверку",
          view: "main",
          disabled: _ctx.isVerificationDisabled,
          onClick: _ctx.handleVerification
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}