<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <span :class="b('icon')" v-html="displayedIcon"></span>

    <div :class="b('info')">
      <h4 :class="b('title')">
        {{ displayedTitle }}
      </h4>

      <p :class="b('label')">
        {{ displayedLabel }}
      </p>
    </div>
  </div>
</template>
