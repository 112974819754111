export interface HistoryDetails {
  dateTime: HistoryDetailsParams;
  type: HistoryDetailsParams;
  amountCurrency: HistoryDetailsParams;
  commission: HistoryDetailsParams;
  status: HistoryDetailsParams;
  address: HistoryDetailsAddress;
}

export interface HistoryDetailsParams {
  title: HistoryDetailsTitle;
  label: string;
}

export enum HistoryDetailsTitle {
  dateTime = "Дата и время",
  type = "Тип",
  amountCurrency = "Сумма и валюта",
  commission = "Комиссия",
  status = "Статус",
  address = "Адрес перевода",
  rs = "Р/С",
  ks = "К/С",
  bik = "БИК",
  name = "Полное наименование банка",
  inn = "ИНН",
  kpp = "КПП",
}

export interface HistoryDetailsAddress {
  title: string;
  label: string;
  info?: {
    rs?: HistoryDetailsParams;
    ks?: HistoryDetailsParams;
    bik?: HistoryDetailsParams;
    name?: HistoryDetailsParams;
    inn?: HistoryDetailsParams;
    kpp?: HistoryDetailsParams;
  };
}
