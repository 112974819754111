import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedVerification,
    UIButton,
  },
  props: {
    type: {
      type: String,
      default: "crypto-requisite",
    },
  },
  emits: {
    close: null,
  },
  data() {
    return {
      code: "",
      loading: false,
    };
  },
  computed: {
    isConfirmDisabled(): boolean {
      return this.loading || this.code.length !== CODE_MAX_LENGTH;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("close");
    },

    async handleVerification(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .requisiteVerification(this.code, this.type)
          .then(() => {
            this.$emit("close");
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
