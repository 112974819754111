<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <h3 :class="b('title')">
      {{ $t("Персональная информация") }}
    </h3>

    <div :class="b('form')">
      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Фамилия*") }}
        </h5>

        <UIInput view="light" v-model="kycPersonalInfo.sur_name" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Имя*") }}
        </h5>

        <UIInput view="light" v-model="kycPersonalInfo.name" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Отчество") }}
        </h5>

        <UIInput view="light" v-model="kycPersonalInfo.patronymic" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Укажите ваш пол*") }}
        </h5>

        <div :class="b('form-radios')">
          <div :class="b('form-radio')">
            <SharedRadio
              view="light"
              checked-value="male"
              v-model:value="kycPersonalInfo.gender"
            />

            <p :class="b('form-radio-label')">
              {{ $t("Мужчина") }}
            </p>
          </div>

          <div :class="b('form-radio')">
            <SharedRadio
              view="light"
              checked-value="female"
              v-model:value="kycPersonalInfo.gender"
            />

            <p :class="b('form-radio-label')">
              {{ $t("Женщина") }}
            </p>
          </div>
        </div>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Дата рождения*") }}
        </h5>

        <SharedDate
          v-model:date="kycPersonalInfo.birth_date"
          format="yyyy-MM-dd"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Гражданство*") }}
        </h5>

        <SharedCountry
          placeholder="Выберите страну"
          v-model:value="kycPersonalInfo.citizenship"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Серия и номер паспорта*") }}
        </h5>

        <UIInput
          view="light"
          maska="##########"
          v-model="kycPersonalInfo.passport"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Дата выдачи паспорта*") }}
        </h5>

        <UIInput
          view="light"
          maska="####-##-##"
          v-model="kycPersonalInfo.passport_date"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Наименование органа выдавшего документ*") }}
        </h5>

        <UIInput view="light" v-model="kycPersonalInfo.passport_issued_org" />
      </div>
    </div>

    <div :class="b('form', { address: true })">
      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Адрес организации*") }}
        </h5>

        <div :class="b('form-block', { address: true })">
          <SharedCountry
            :class="b('form-country')"
            placeholder="Cтрана"
            v-model:value="kycPersonalInfo.address.country"
          />

          <UIInput
            :class="b('form-city')"
            placeholder="Город"
            view="light"
            v-model="kycPersonalInfo.address.city"
          />

          <UIInput
            :class="b('form-state')"
            placeholder="Штат / Область"
            view="light"
            v-model="kycPersonalInfo.address.state"
          />

          <UIInput
            :class="b('form-street')"
            placeholder="Улица"
            view="light"
            v-model="kycPersonalInfo.address.street"
          />

          <UIInput
            :class="b('form-office')"
            placeholder="Номер Офиса"
            view="light"
            v-model="kycPersonalInfo.address.office"
          />

          <UIInput
            :class="b('form-index')"
            placeholder="Индекс"
            view="light"
            v-model="kycPersonalInfo.address.zip"
          />
        </div>
      </div>
    </div>

    <div :class="b('form', { last: true })">
      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Номер телефона*") }}
        </h5>

        <UIInput view="light" v-model="kycPersonalInfo.phone" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Телеграм для связи*") }}
        </h5>

        <UIInput
          view="light"
          :is-telegram="true"
          v-model="kycPersonalInfo.telegram"
        />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
