<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAdminHeader
      title="Заявки"
      v-model:email="email"
      v-model:id="id"
      @search="handleSearch"
    />

    <div :class="b('wrapper')">
      <SharedSearch v-model:search="search" />

      <AdminApplicationTable :class="b('table')" />

      <SharedButtonIcon
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedIcons.more"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>
  </div>
</template>
