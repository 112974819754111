<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <button
      :class="b('tab', { active: tab.isActive })"
      v-for="tab in displayedTabs"
      :key="tab.id"
      @click="handleTab(tab)"
    >
      {{ $t(tab.label) }}
    </button>
  </div>
</template>
