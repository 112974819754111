import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('back')),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBack && _ctx.handleBack(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon')),
        innerHTML: _ctx.displayedIcons.chevron
      }, null, 10, _hoisted_1)
    ], 2),
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t(_ctx.title)), 3)
  ], 2))
}