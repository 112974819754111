import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('tabs'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(_ctx.b('tab', { active: tab.active })),
            key: tab.id,
            onClick: ($event: any) => (_ctx.handleTab(tab))
          }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_1))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('actions'))
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('action-small')),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleConclusion && _ctx.handleConclusion(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('action-icon', { conclusion: true })),
            innerHTML: _ctx.displayedIcons.conclusion
          }, null, 10, _hoisted_2)
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('action-small')),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('action-icon', { input: true })),
            innerHTML: _ctx.displayedIcons.input
          }, null, 10, _hoisted_3)
        ], 2),
        _createVNode(_component_SharedButtonIcon, {
          class: _normalizeClass(_ctx.b('action')),
          view: "main-outline",
          onClick: _ctx.handleConclusion
        }, {
          "icon-left": _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('action-icon', { conclusion: true })),
              innerHTML: _ctx.displayedIcons.conclusion
            }, null, 10, _hoisted_4)
          ]),
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Вывод")), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_component_SharedButtonIcon, {
          class: _normalizeClass(_ctx.b('action')),
          view: "main",
          onClick: _ctx.handleInput
        }, {
          "icon-left": _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('action-icon', { input: true })),
              innerHTML: _ctx.displayedIcons.input
            }, null, 10, _hoisted_5)
          ]),
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Ввод")), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('table'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('columns'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.b('column')),
            key: column.id
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('column-label'))
            }, _toDisplayString(_ctx.$t(column.label)), 3),
            (column.sort)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: _normalizeClass(_ctx.b('column-sort')),
                  onClick: ($event: any) => (_ctx.handleColumnSort(column))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('column-icon')),
                    innerHTML: _ctx.displayedIcons.sort
                  }, null, 10, _hoisted_7)
                ], 10, _hoisted_6))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('rows'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRows, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.b('row')),
            key: row.id
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-currency'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-currency-icon')),
                innerHTML: row.currency.icon
              }, null, 10, _hoisted_8),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-currency-group'))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-currency-label'))
                }, _toDisplayString(row.currency.name), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(
                  _ctx.b('row-currency-tag', {
                    bank: row.currency.isBank,
                    crypto: row.currency.isCrypto,
                  })
                )
                }, _toDisplayString(row.currency.tag), 3)
              ], 2)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-total'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('row-total-label'))
              }, _toDisplayString(row.total), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('row-actions'))
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.b('row-actions-dots', { active: row.isActions })),
                onClick: ($event: any) => (_ctx.handleActions(row))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-action-icon')),
                  innerHTML: _ctx.displayedIcons.dots
                }, null, 10, _hoisted_10)
              ], 10, _hoisted_9),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-actions-list', { visible: row.isActions }))
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('row-action')),
                  onClick: ($event: any) => (_ctx.handleRowConclusion(row))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('row-action-icon')),
                    innerHTML: _ctx.displayedIcons.conclusionGray
                  }, null, 10, _hoisted_12)
                ], 10, _hoisted_11),
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('row-action')),
                  onClick: ($event: any) => (_ctx.handleRowInput(row))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('row-action-icon')),
                    innerHTML: _ctx.displayedIcons.inputGray
                  }, null, 10, _hoisted_14)
                ], 10, _hoisted_13),
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('row-action'))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('row-action-icon')),
                    innerHTML: _ctx.displayedIcons.swapGray
                  }, null, 10, _hoisted_15)
                ], 2),
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('row-action'))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('row-action-icon')),
                    innerHTML: _ctx.displayedIcons.historyGray
                  }, null, 10, _hoisted_16)
                ], 2)
              ], 2)
            ], 2)
          ], 2))
        }), 128))
      ], 2)
    ], 2)
  ], 2))
}