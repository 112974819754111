import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";

import { AdminKycComponent } from "@/views/admin-kyc/model";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  props: {
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  methods: {
    handleNext(): void {
      switch (this.component) {
        case AdminKycComponent.personalInformation:
          this.$emit("update:component", AdminKycComponent.personalDocuments);

          break;
        case AdminKycComponent.kybBasicInformation:
          this.$emit(
            "update:component",
            AdminKycComponent.kybRegistrationInformation
          );

          break;
        case AdminKycComponent.kybParticipant:
          this.$emit(
            "update:component",
            AdminKycComponent.kybParticipantDocuments
          );

          break;
        default:
          this.$emit("update:component", AdminKycComponent.kybCompany);

          break;
      }
    },
  },
});
