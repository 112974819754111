<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('group')">
      <router-link :class="b('logo')" to="/">
        <span :class="b('logo-icon')" v-html="displayedIcons.logo"></span>
      </router-link>

      <span :class="b('tag')">
        {{ $t("Админ") }}
      </span>
    </div>

    <div :class="b('links')">
      <router-link
        :class="b('link')"
        v-for="link in displayedLinks"
        :key="link.id"
        :to="link.to"
      >
        <span :class="b('link-icon')" v-html="link.icon"></span>

        {{ $t(link.label) }}
      </router-link>
    </div>
  </div>
</template>
