<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b('', { empty: !isIconShow })">
    <SharedVerification
      title="Проверка безопасности"
      :code="code"
      :isError="isVerificationError"
      @update:code="changeCode"
    />

    <span :class="b('icon')" v-if="isIconShow" v-html="displayedIcon"></span>
  </div>
</template>
