<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header', { view: headerView })">
      <div :class="b('header-image')" v-html="displayedSvg"></div>

      <h3 :class="b('header-title')">
        {{ $t(headerTitle) }}
      </h3>
    </div>

    <slot></slot>

    <div :class="b('footer')">
      <p :class="b('footer-title')">
        {{ $t(footerTitle) }}
      </p>

      <slot name="footer"></slot>

      <p v-if="isTerms" :class="b('footer-label')">
        {{ $t("Условия обслуживания") }}
      </p>
    </div>
  </div>
</template>
