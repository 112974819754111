<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button :class="b()" @click="handleGoogle">
    <span :class="b('icon')" v-html="displayedIcon"></span>

    {{ label }}
  </button>
</template>
