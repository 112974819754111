import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    isExpected: {
      type: Boolean,
      default: false,
    },
    isRemove: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    remove: null,
  },
  computed: {
    displayedIcon(): string {
      if (this.isConfirmed) {
        return SVG.confirmed;
      } else if (this.isExpected) {
        return SVG.expected;
      }

      return SVG.plus;
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    isIconShow(): boolean {
      return !this.isSuccess;
    },

    isTitleShow(): boolean {
      return this.isSuccess || this.isConfirmed;
    },

    isLabelShow(): boolean {
      return !this.isSuccess && !this.isConfirmed;
    },

    isRemoveShow(): boolean {
      return (
        (this.isSuccess || this.isConfirmed || this.isExpected) && this.isRemove
      );
    },

    isDisabled(): boolean {
      return this.isConfirmed;
    },
  },
  methods: {
    handleRemove(): void {
      this.$emit("remove");
    },
  },
});
