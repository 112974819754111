import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";
import { HistoryTab } from "./model";

import {
  HistoryHeader,
  HistoryTable,
  HistoryDetails,
  HistoryApplicationsDetails,
  HistoryFilters,
  HistoryRemove,
} from "@/components/history";
import { SharedButtonIcon } from "@/components/shared";

import { FilterFactory } from "@/shared/factory/history";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    HistoryHeader,
    HistoryTable,
    HistoryDetails,
    HistoryApplicationsDetails,
    HistoryFilters,
    HistoryRemove,
    SharedButtonIcon,
  },
  data() {
    return {
      tab: HistoryTab.conclusion,
      isFilter: false,
      isDetails: false,
      isApplicationDetails: false,
      isRemove: false,
      filter: FilterFactory(),
    };
  },
  computed: {
    displayedMoreIcon(): string {
      return SVG.more;
    },
  },
  methods: {
    handleMore(): void {
      console.log("Handle More!");
    },
  },
});
