import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedInputPassword = _resolveComponent("SharedInputPassword")!
  const _component_SharedError = _resolveComponent("SharedError")!
  const _component_SharedCheckbox = _resolveComponent("SharedCheckbox")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_UILink = _resolveComponent("UILink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createVNode(_component_UIInput, {
        placeholder: "Адрес электронной почты",
        view: "light",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _ctx.changeEmail
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createVNode(_component_SharedInputPassword, {
          placeholder: "Пароль",
          view: "light",
          modelValue: _ctx.password,
          "is-error": _ctx.isPasswordError,
          "onUpdate:modelValue": _ctx.changePassword
        }, null, 8, ["modelValue", "is-error", "onUpdate:modelValue"]),
        (_ctx.message)
          ? (_openBlock(), _createBlock(_component_SharedError, {
              key: 0,
              message: "Неправильный пароль!"
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('checkbox'))
    }, [
      _createVNode(_component_SharedCheckbox, {
        view: "28",
        checked: _ctx.isRemember,
        "onUpdate:checked": _ctx.changeIsRemember
      }, null, 8, ["checked", "onUpdate:checked"]),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('checkbox-label'))
      }, _toDisplayString(_ctx.$t("Запомнить меня")), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('actions'))
    }, [
      _createVNode(_component_UIButton, {
        label: "Вход в систему",
        view: "main",
        disabled: _ctx.isLoginDisabled,
        onClick: _ctx.handleLogin
      }, null, 8, ["disabled", "onClick"]),
      _createVNode(_component_UILink, {
        label: "Забыли пароль?",
        to: "/forgot",
        view: "main-text"
      })
    ], 2)
  ], 2))
}