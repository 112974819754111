import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  FileImageParams,
  FileType,
  KybParticipantDocumentsFileName,
} from "@/shared/constants/constants";
import {
  KybParticipantUploadFileRequestFactory,
  KybParticipantUploadFileResponseFactory,
} from "@/shared/factory/kyb";
import {
  KybParticipantUploadFileRequest,
  KybParticipantUploadFileResponse,
} from "@/shared/repository/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    UIInput,
    UIButton,
  },
  props: {
    participantId: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:participantId": null,
    "update:component": null,
  },
  data() {
    return {
      passportSelfie: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ],
        type: FileType.image,
      }),
      passportOrganization: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ],
        type: FileType.image,
      }),
      passportAddress: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ],
        type: FileType.image,
      }),
      selfie: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ],
        type: FileType.image,
      }),
      questionnaire: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ],
        type: FileType.document,
      }),
      personalData: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ],
        type: FileType.document,
      }),
      director: KybParticipantUploadFileResponseFactory({
        name: KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ],
        type: FileType.document,
      }),
      questionnaireFile: KybParticipantUploadFileResponseFactory(),
      personalDataFile: KybParticipantUploadFileResponseFactory(),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      if (this.participantId) {
        await this.$projectServices.projectRepository
          .getKybParticipantFiles(this.participantId)
          .then((kybParticipantFiles) => {
            kybParticipantFiles.forEach((kybParticipantFile) => {
              this.handleCurrentFileResponse(kybParticipantFile);
            });
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    isContinueDisabled(): boolean {
      return (
        !this.passportSelfie.id ||
        !this.passportOrganization.id ||
        !this.passportAddress.id ||
        !this.selfie.id ||
        !this.questionnaire.id ||
        !this.personalData.id ||
        !this.director.id ||
        this.loading
      );
    },
  },
  methods: {
    async handleUploadFile(
      params: FileImageParams,
      name: string
    ): Promise<void> {
      try {
        this.loading = true;

        this.handleCurrentFileUpdate(name, params);

        await this.$projectServices.projectRepository
          .kybParticipantUploadFile({
            ...this.handleCurrentFileRequest(name),
            kyb_participant_id: this.participantId,
          })
          .then((uploadFile) => {
            this.handleCurrentFileResponse(uploadFile);
          });
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveFile(name: string): Promise<void> {
      try {
        this.loading = true;

        const { id } = this.handleCurrentFile(name);

        if (typeof id === "number") {
          await this.$projectServices.projectRepository
            .kybParticipantDeleteFile(id, this.participantId)
            .then(() => {
              this.handleCurrentFileClear(name);
            });
        }
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    handleCurrentFile(name: string): KybParticipantUploadFileResponse {
      switch (name) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          return this.passportSelfie;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          return this.passportOrganization;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          return this.passportAddress;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          return this.selfie;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          return this.questionnaire;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          return this.personalData;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          return this.director;
        default:
          return KybParticipantUploadFileResponseFactory();
      }
    },

    handleCurrentFileUpdate(
      name: string,
      { file, link }: FileImageParams
    ): void {
      switch (name) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          this.passportSelfie.file = file;
          this.passportSelfie.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          this.passportOrganization.file = file;
          this.passportOrganization.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          this.passportAddress.file = file;
          this.passportAddress.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          this.selfie.file = file;
          this.selfie.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          this.questionnaire.file = file;
          this.questionnaire.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          this.personalData.file = file;
          this.personalData.link = link;

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          this.director.file = file;
          this.director.link = link;

          break;
        default:
          break;
      }
    },

    handleCurrentFileClear(name: string): void {
      switch (name) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          this.passportSelfie = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-passport-selfie"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          this.passportOrganization = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-passport-organization"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          this.passportAddress = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-passport-address"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          this.selfie = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-selfie"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          this.questionnaire = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-questionnaire"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          this.personalData = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-personal-data"
            ],
            type: FileType.image,
          });

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          this.director = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              "kyb-participant-documents-director"
            ],
            type: FileType.image,
          });

          break;
        default:
          break;
      }
    },

    handleCurrentFileRequest(name: string): KybParticipantUploadFileRequest {
      switch (name) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          return KybParticipantUploadFileRequestFactory(this.passportSelfie);
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          return KybParticipantUploadFileRequestFactory(
            this.passportOrganization
          );
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          return KybParticipantUploadFileRequestFactory(this.passportAddress);
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          return KybParticipantUploadFileRequestFactory(this.selfie);
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          return KybParticipantUploadFileRequestFactory(this.questionnaire);
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          return KybParticipantUploadFileRequestFactory(this.personalData);
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          return KybParticipantUploadFileRequestFactory(this.director);
        default:
          return KybParticipantUploadFileRequestFactory();
      }
    },

    handleCurrentFileResponse(params: KybParticipantUploadFileResponse): void {
      switch (extractFileName(params.name)) {
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-selfie"
        ]:
          this.passportSelfie = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-organization"
        ]:
          this.passportOrganization =
            KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-passport-address"
        ]:
          this.passportAddress =
            KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-selfie"
        ]:
          this.selfie = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-questionnaire"
        ]:
          this.questionnaire = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-personal-data"
        ]:
          this.personalData = KybParticipantUploadFileResponseFactory(params);

          break;
        case KybParticipantDocumentsFileName[
          "kyb-participant-documents-director"
        ]:
          this.director = KybParticipantUploadFileResponseFactory(params);

          break;
        default:
          break;
      }
    },

    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybParticipant);
    },

    handleContinue(): void {
      this.$emit("update:participantId", 0);

      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },
  },
});
