<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Крипто реквизиты") }}
      </h3>
    </div>

    <div :class="b('form')">
      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Название*") }}
        </h5>

        <UIInput view="light" v-model="requisiteCrypto.name" />
      </div>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Монета*") }}
        </h5>

        <UIInput view="light" v-model="requisiteCrypto.coin" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Сеть*") }}
        </h5>

        <UIInput view="light" v-model="requisiteCrypto.network" />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Адрес*") }}
        </h5>

        <UIInput view="light" v-model="requisiteCrypto.address" />
      </div>
    </div>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />

    <transition name="opacity">
      <RequisitesVerification
        :class="b('modal')"
        v-if="isVerification"
        @close="handleVerificationClose"
      />
    </transition>
  </SharedCard>
</template>
