<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Настройки") }}
    </h1>

    <SettingsTabs :class="b('tabs')" v-model:tab="tab" />

    <component :is="displayedComponent" />
  </div>
</template>
