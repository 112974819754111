import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { AdminToolbarLink, AdminToolbarLinkLabel } from "./model";

import { SharedLanguage } from "@/components/shared";

import { RoutesTo, SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedLinks(): AdminToolbarLink[] {
      return [
        {
          id: 0,
          label: AdminToolbarLinkLabel.users,
          to: RoutesTo.adminUsers,
          icon: SVG.users,
        },
        {
          id: 1,
          label: AdminToolbarLinkLabel.kyc,
          to: RoutesTo.adminKyc,
          icon: SVG.kyc,
        },
        {
          id: 2,
          label: AdminToolbarLinkLabel.application,
          to: RoutesTo.adminApplication,
          icon: SVG.application,
        },
        {
          id: 3,
          label: AdminToolbarLinkLabel.commission,
          to: RoutesTo.adminCommission,
          icon: SVG.commission,
        },
      ];
    },
  },
});
