import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('list'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('item'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('item-title'))
        }, _toDisplayString(_ctx.$t("Со счета")), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item-label'))
        }, _toDisplayString(_ctx.$t("Банковский счет")) + " №2", 3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('item', { to: true }))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('item-group'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('item-title'))
          }, _toDisplayString(_ctx.$t("На счет")), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('item-label'))
          }, _toDisplayString(_ctx.$t("Крипто счет")) + " №8 (USDT)", 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('item-copy'))
        }, [
          _createTextVNode(" 12erGfIdfdfEBDdfgDFG7pokmFwe3Z "),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('item-copy-action')),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCopy && _ctx.handleCopy(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('item-icon')),
              innerHTML: _ctx.displayedCopyIcon
            }, null, 10, _hoisted_1)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('item'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('item-title'))
        }, _toDisplayString(_ctx.$t("Текущий курс")), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item-label'))
        }, "1.1230987 USDT", 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('item', { operation: true }))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('item-title'))
        }, _toDisplayString(_ctx.$t("Операция")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('item-operation'))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('item-icon')),
            innerHTML: _ctx.displayedChevronIcon
          }, null, 10, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item-operations'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "100 EUR", 2),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, "~ 111.30987 USDT", 2)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('item'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('item-title'))
        }, _toDisplayString(_ctx.$t("Комиссия")), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item-label'))
        }, "~ 0.108734 USDT", 2)
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Создать заявку",
      view: "main",
      onClick: _ctx.handleCreate
    }, null, 8, ["onClick"])
  ], 2))
}