<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Участник" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('form-group')">
        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Паспорт или ВУ") }}
          </h4>

          <div :class="b('form-fields', { passport: true })">
            <SharedUpload
              :is-remove="false"
              :file="passportSelfie.file"
              :link="passportSelfie.link"
            />

            <SharedUpload
              :is-remove="false"
              :file="passportOrganization.file"
              :link="passportOrganization.link"
            />

            <SharedUpload
              :is-remove="false"
              :file="passportAddress.file"
              :link="passportAddress.link"
            />
          </div>
        </div>

        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Селфи") }}
          </h4>

          <div :class="b('form-fields', { selfie: true })">
            <SharedUpload
              :is-remove="false"
              :file="selfie.file"
              :link="selfie.link"
            />
          </div>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Соглашения") }}
        </h4>

        <div :class="b('form-fields', { agreements: true })">
          <SharedUpload
            :is-document="true"
            :is-remove="false"
            :file="questionnaire.file"
            :link="questionnaire.link"
          >
            <template #label>
              {{ $t("Анкета физического лица") }} <br />
              {{ $t("(подписанная учредителем)") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :is-remove="false"
            :file="personalData.file"
            :link="personalData.link"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Документы для подтверждения верификации") }}
        </h4>

        <div :class="b('form-fields', { verification: true })">
          <SharedUpload
            :is-document="true"
            :is-remove="false"
            :file="director.file"
            :link="director.link"
          >
            <template #label>
              {{ $t("Приказ о назначении") }} <br />
              {{ $t("директора") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
