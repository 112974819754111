<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack :class="b('header-back')" @click="handleBack" />

      <div :class="b('header-group')">
        <h3 :class="b('header-title')">
          {{ $t("Регистрационная информация") }}
        </h3>

        <p :class="b('header-label')">
          {{
            $t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )
          }}
        </p>
      </div>
    </div>

    <div :class="b('form')">
      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Организационно-правовая форма вашей организации*") }}
        </h5>

        <SharedSelect
          placeholder="Выберите организационно-правовую форму"
          view="light"
          :options="displayedOrganizationalAndLegalFormOptions"
          v-model:value="kybRegistrationInfo.organisation_legal_form"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("К какой сфере относится ваш бизнес?*") }}
        </h5>

        <UIInput view="light" v-model="kybRegistrationInfo.business_area" />
      </div>

      <div :class="b('form-group', { address: true })">
        <h5 :class="b('form-title')">
          {{ $t("Адрес организации*") }}
        </h5>

        <div :class="b('form-block')">
          <SharedSelect
            :class="b('form-country')"
            placeholder="Cтрана"
            view="light"
            :options="displayedCountryOptions"
            v-model:value="kybRegistrationInfo.address.country"
          />

          <SharedSelect
            :class="b('form-city')"
            placeholder="Город"
            view="light"
            :options="displayedCityOptions"
            v-model:value="kybRegistrationInfo.address.city"
          />

          <UIInput
            :class="b('form-state')"
            placeholder="Штат / Область"
            view="light"
            v-model="kybRegistrationInfo.address.state"
          />

          <UIInput
            :class="b('form-street')"
            placeholder="Улица"
            view="light"
            v-model="kybRegistrationInfo.address.street"
          />

          <UIInput
            :class="b('form-office')"
            placeholder="Номер Офиса"
            view="light"
            v-model="kybRegistrationInfo.address.office"
          />

          <UIInput
            :class="b('form-index')"
            placeholder="Индекс"
            view="light"
            v-model="kybRegistrationInfo.address.zip"
          />
        </div>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Фактический адрес совпадает с адресом регистрации?*") }}
        </h5>

        <div :class="b('form-checkboxes')">
          <div :class="b('form-checkbox')">
            <SharedRadio
              view="light"
              :checkedValue="true"
              v-model:value="kybRegistrationInfo.address.isActual"
            />

            <p :class="b('form-checkbox-label')">
              {{ $t("Да") }}
            </p>
          </div>

          <div :class="b('form-checkbox')">
            <SharedRadio
              view="light"
              :checkedValue="false"
              v-model:value="kybRegistrationInfo.address.isActual"
            />

            <p :class="b('form-checkbox-label')">
              {{ $t("Нет") }}
            </p>
          </div>
        </div>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Контактный номер*") }}
        </h5>

        <UIInput
          view="light"
          maska="+7 ### ###-##-##"
          v-model="kybRegistrationInfo.phone"
        />
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Сайт") }}
        </h5>

        <UIInput view="light" v-model="kybRegistrationInfo.site" />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
