<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t(title) }}
    </h1>

    <div :class="b('form')">
      <UIInput
        placeholder="Почта"
        view="light"
        :modelValue="email"
        @update:modelValue="handleChangeEmail"
      />

      <UIInput
        placeholder="ID"
        view="light"
        :modelValue="id"
        @update:modelValue="handleChangeId"
      />

      <UIButton
        label="Поиск"
        view="main"
        :disabled="isSearchDisabled"
        @click="handleSearch"
      />
    </div>
  </div>
</template>
