import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { VerificationKybComponent, VerificationKycComponent } from "./model";

import { VerificationTabs } from "@/components/verification";

import { KycPersonalInformation, KycDocuments } from "@/components/kyc";
import {
  KybCompany,
  KybBasicInformation,
  KybRegistrationInformation,
  KybParticipants,
  KybParticipant,
  KybParticipantFiles,
  KybDocuments,
} from "@/components/kyb";
import {
  RequisitesMain,
  RequisitesBank,
  RequisitesCrypto,
} from "@/components/requisites";

import {
  VerificationTab,
  VerificationTabValue,
} from "@/components/verification/tabs/model";

type KycComponentType = typeof KycPersonalInformation | typeof KycDocuments;

type KybComponentType =
  | typeof KybCompany
  | typeof KybBasicInformation
  | typeof KybRegistrationInformation
  | typeof KybParticipants
  | typeof KybParticipant
  | typeof KybParticipantFiles
  | typeof KybDocuments;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    VerificationTabs,
    KycPersonalInformation,
    KycDocuments,
    KybCompany,
    KybBasicInformation,
    KybRegistrationInformation,
    KybParticipants,
    KybParticipant,
    KybParticipantFiles,
    KybDocuments,
    RequisitesMain,
    RequisitesBank,
    RequisitesCrypto,
  },
  data() {
    return {
      tab: VerificationTabValue.kyc,
      kycComponent: VerificationKycComponent.kycPersonalInformation,
      kybComponent: VerificationKybComponent.kybCompany,
      participantId: 0,
    };
  },
  computed: {
    displayedKycComponent(): KycComponentType {
      switch (this.kycComponent) {
        case VerificationKycComponent.kycPersonalInformation:
          return KycPersonalInformation;
        case VerificationKycComponent.kycDocuments:
          return KycDocuments;
        default:
          return KycPersonalInformation;
      }
    },

    displayedKybComponent(): KybComponentType {
      switch (this.kybComponent) {
        case VerificationKybComponent.kybCompany:
          return KybCompany;
        case VerificationKybComponent.kybBasicInformation:
          return KybBasicInformation;
        case VerificationKybComponent.kybRegistrationInformation:
          return KybRegistrationInformation;
        case VerificationKybComponent.kybParticipants:
          return KybParticipants;
        case VerificationKybComponent.kybParticipant:
          return KybParticipant;
        case VerificationKybComponent.kybParticipantFiles:
          return KybParticipantFiles;
        case VerificationKybComponent.kybDocuments:
          return KybDocuments;
        default:
          return KybCompany;
      }
    },

    isKycShow(): boolean {
      return this.tab === VerificationTabValue.kyc;
    },

    isKybShow(): boolean {
      return this.tab === VerificationTabValue.kyb;
    },
  },
  methods: {
    changeTab({ value }: VerificationTab): void {
      this.tab = value;

      switch (value) {
        case VerificationTabValue.kyc:
          this.kycComponent = VerificationKycComponent.kycPersonalInformation;

          break;
        case VerificationTabValue.kyb:
          this.kybComponent = VerificationKybComponent.kybCompany;

          break;
        default:
          break;
      }
    },
  },
});
