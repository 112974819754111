import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminUsersFilter = _resolveComponent("AdminUsersFilter")!
  const _component_AdminUsersTable = _resolveComponent("AdminUsersTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_AdminUsersStatus = _resolveComponent("AdminUsersStatus")!
  const _component_AdminUsersBalance = _resolveComponent("AdminUsersBalance")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Пользователи")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_AdminUsersFilter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        "search-type": _ctx.searchType,
        "onUpdate:searchType": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchType) = $event))
      }, null, 8, ["search", "search-type"]),
      _createVNode(_component_AdminUsersTable, {
        class: _normalizeClass(_ctx.b('table')),
        users: _ctx.displayedUsers,
        "user-id": _ctx.userId,
        "onUpdate:userId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userId) = $event)),
        "sort-key": _ctx.sort.key,
        "onUpdate:sortKey": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sort.key) = $event)),
        "sort-is-up": _ctx.sort.isUp,
        "onUpdate:sortIsUp": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort.isUp) = $event)),
        "sort-is-down": _ctx.sort.isDown,
        "onUpdate:sortIsDown": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sort.isDown) = $event)),
        "is-status": _ctx.isStatus,
        "onUpdate:isStatus": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isStatus) = $event)),
        "is-balance": _ctx.isBalance,
        "onUpdate:isBalance": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isBalance) = $event))
      }, null, 8, ["class", "users", "user-id", "sort-key", "sort-is-up", "sort-is-down", "is-status", "is-balance"]),
      (_ctx.isMoreShow)
        ? (_openBlock(), _createBlock(_component_SharedButtonIcon, {
            key: 0,
            class: _normalizeClass(_ctx.b('more')),
            view: "gray-outline",
            onClick: _ctx.handleMore
          }, {
            "icon-left": _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('more-icon')),
                innerHTML: _ctx.displayedIcons.more
              }, null, 10, _hoisted_1)
            ]),
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isStatusShow)
          ? (_openBlock(), _createBlock(_component_AdminUsersStatus, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "user-id": _ctx.userId,
              "onUpdate:userId": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.userId) = $event)),
              isStatus: _ctx.isStatus,
              "onUpdate:isStatus": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isStatus) = $event))
            }, null, 8, ["class", "user-id", "isStatus"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isBalanceShow)
          ? (_openBlock(), _createBlock(_component_AdminUsersBalance, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "user-id": _ctx.userId,
              "onUpdate:userId": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.userId) = $event)),
              isBalance: _ctx.isBalance,
              "onUpdate:isBalance": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isBalance) = $event))
            }, null, 8, ["class", "user-id", "isBalance"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}