import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { VerificationKycComponent } from "@/views/verification/model";
import {
  KycSystemFileResponseFactory,
  KycUploadFileRequestFactory,
  KycUploadFileResponseFactory,
} from "@/shared/factory/kyc";
import {
  KycUploadFileRequest,
  KycUploadFileResponse,
} from "@/shared/repository/repo";
import {
  FileImageParams,
  FileType,
  ModalIcon,
  ModalLabel,
  ModalTitle,
  KycPersonalDocumentsFileName,
} from "@/shared/constants/constants";
import { ModalFactory } from "@/shared/factory/modal";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      passportSelfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ],
        type: FileType.image,
      }),
      passportOrganization: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ],
        type: FileType.image,
      }),
      passportAddress: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ],
        type: FileType.image,
      }),
      selfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-selfie"],
        type: FileType.image,
      }),
      questionnaire: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ],
        type: FileType.document,
      }),
      personalData: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ],
        type: FileType.document,
      }),
      declaration: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-declaration"
        ],
        type: FileType.document,
      }),
      broker: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-broker"],
        type: FileType.document,
      }),
      originOfFunds: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ],
        type: FileType.document,
      }),
      confirmingRegistration: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ],
        type: FileType.document,
      }),
      questionnaireFile: KycSystemFileResponseFactory(),
      personalDataFile: KycSystemFileResponseFactory(),
      declarationFile: KycSystemFileResponseFactory(),
      brokerFile: KycSystemFileResponseFactory(),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .kycUserFiles(this.displayedUserId)
        .then((userFiles) => {
          userFiles.forEach((userFile) =>
            this.handleCurrentFileResponse(userFile)
          );
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    displayedUserId(): number {
      return this.$projectServices.userRepo.userInfo.id;
    },

    isVerificationDisabled(): boolean {
      return (
        !this.passportSelfie.id ||
        !this.passportOrganization.id ||
        !this.passportAddress.id ||
        !this.selfie.id ||
        !this.questionnaire.id ||
        !this.personalData.id ||
        !this.declaration.id ||
        !this.broker.id ||
        !this.originOfFunds.id ||
        !this.confirmingRegistration.id ||
        this.loading
      );
    },
  },
  methods: {
    async handleUploadFile(
      params: FileImageParams,
      name: string
    ): Promise<void> {
      try {
        this.loading = true;

        this.handleCurrentFileUpdate(name, params);

        await this.$projectServices.projectRepository
          .kycUploadFile(this.handleCurrentFileRequest(name))
          .then((uploadFile) => {
            this.handleCurrentFileResponse(uploadFile);
          });
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveFile(name: string): Promise<void> {
      try {
        this.loading = true;

        const { id } = this.handleCurrentFile(name);

        if (id) {
          await this.$projectServices.projectRepository
            .kycDeleteFile(id)
            .then(() => {
              this.handleCurrentFileClear(name);
            });
        }
      } catch (e) {
        this.handleCurrentFileClear(name);
      } finally {
        this.loading = false;
      }
    },

    handleCurrentFile(name: string): KycUploadFileResponse {
      switch (name) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          return this.passportSelfie;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          return this.passportOrganization;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          return this.passportAddress;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          return this.selfie;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          return this.questionnaire;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          return this.personalData;
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          return this.declaration;
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          return this.broker;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          return this.originOfFunds;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          return this.confirmingRegistration;
        default:
          return KycUploadFileResponseFactory();
      }
    },

    handleCurrentFileUpdate(
      name: string,
      { file, link }: FileImageParams
    ): void {
      switch (name) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          this.passportSelfie.file = file;
          this.passportSelfie.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          this.passportOrganization.file = file;
          this.passportOrganization.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          this.passportAddress.file = file;
          this.passportAddress.link = link;

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          this.selfie.file = file;
          this.selfie.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          this.questionnaire.file = file;
          this.questionnaire.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          this.personalData.file = file;
          this.personalData.link = link;

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          this.declaration.file = file;
          this.declaration.link = link;

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          this.broker.file = file;
          this.broker.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          this.originOfFunds.file = file;
          this.originOfFunds.link = link;

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          this.confirmingRegistration.file = file;
          this.confirmingRegistration.link = link;

          break;
        default:
          break;
      }
    },

    handleCurrentFileClear(name: string): void {
      switch (name) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          this.passportSelfie = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-passport-selfie"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          this.passportOrganization = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-passport-organization"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          this.passportAddress = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-passport-address"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          this.selfie = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName["kyc-personal-documents-selfie"],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          this.questionnaire = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-questionnaire"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          this.personalData = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-personal-data"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          this.declaration = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-declaration"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          this.broker = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName["kyc-personal-documents-broker"],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          this.originOfFunds = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-origin-of-funds"
            ],
            type: FileType.image,
          });

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          this.confirmingRegistration = KycUploadFileResponseFactory({
            name: KycPersonalDocumentsFileName[
              "kyc-personal-documents-confirming-registration"
            ],
            type: FileType.image,
          });

          break;
        default:
          break;
      }
    },

    handleCurrentFileRequest(name: string): KycUploadFileRequest {
      switch (name) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          return KycUploadFileRequestFactory(this.passportSelfie);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          return KycUploadFileRequestFactory(this.passportOrganization);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          return KycUploadFileRequestFactory(this.passportAddress);
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          return KycUploadFileRequestFactory(this.selfie);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          return KycUploadFileRequestFactory(this.questionnaire);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          return KycUploadFileRequestFactory(this.personalData);
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          return KycUploadFileRequestFactory(this.declaration);
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          return KycUploadFileRequestFactory(this.broker);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          return KycUploadFileRequestFactory(this.originOfFunds);
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          return KycUploadFileRequestFactory(this.confirmingRegistration);
        default:
          return KycUploadFileRequestFactory();
      }
    },

    handleCurrentFileResponse(params: KycUploadFileResponse): void {
      switch (extractFileName(params.name)) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          this.passportSelfie = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          this.passportOrganization = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          this.passportAddress = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          this.selfie = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          this.questionnaire = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          this.personalData = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          this.declaration = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          this.broker = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          this.originOfFunds = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          this.confirmingRegistration = KycUploadFileResponseFactory(params);

          break;
        default:
          break;
      }
    },

    handleBack(): void {
      this.$emit(
        "update:component",
        VerificationKycComponent.kycPersonalInformation
      );
    },

    async handleVerification(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .kycVerification()
          .then(() => {
            this.$projectServices.modalRepo.UPDATE_MODAL(
              ModalFactory({
                title: ModalTitle.application,
                label: ModalLabel.application,
                icon: ModalIcon.application,
                callback: () => {},
              })
            );
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
