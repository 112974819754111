<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('list')">
      <div :class="b('item')">
        <h4 :class="b('item-title')">
          {{ $t("Со счета") }}
        </h4>

        <p :class="b('item-label')">{{ $t("Банковский счет") }} №2</p>
      </div>

      <div :class="b('item', { to: true })">
        <div :class="b('item-group')">
          <h4 :class="b('item-title')">
            {{ $t("На счет") }}
          </h4>

          <p :class="b('item-label')">{{ $t("Крипто счет") }} №8 (USDT)</p>
        </div>

        <div :class="b('item-copy')">
          12erGfIdfdfEBDdfgDFG7pokmFwe3Z

          <button :class="b('item-copy-action')" @click="handleCopy">
            <span :class="b('item-icon')" v-html="displayedCopyIcon"></span>
          </button>
        </div>
      </div>

      <div :class="b('item')">
        <h4 :class="b('item-title')">
          {{ $t("Текущий курс") }}
        </h4>

        <p :class="b('item-label')">1.1230987 USDT</p>
      </div>

      <div :class="b('item', { operation: true })">
        <h4 :class="b('item-title')">
          {{ $t("Операция") }}
        </h4>

        <div :class="b('item-operation')">
          <span :class="b('item-icon')" v-html="displayedChevronIcon"></span>

          <div :class="b('item-operations')">
            <p :class="b('item-label')">100 EUR</p>

            <p :class="b('item-label')">~ 111.30987 USDT</p>
          </div>
        </div>
      </div>

      <div :class="b('item')">
        <h4 :class="b('item-title')">
          {{ $t("Комиссия") }}
        </h4>

        <p :class="b('item-label')">~ 0.108734 USDT</p>
      </div>
    </div>

    <UIButton label="Создать заявку" view="main" @click="handleCreate" />
  </div>
</template>
