import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b()),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGoogle && _ctx.handleGoogle(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('icon')),
      innerHTML: _ctx.displayedIcon
    }, null, 10, _hoisted_1),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ], 2))
}