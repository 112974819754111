import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  WalletMainColumn,
  WalletMainColumnLabel,
  WalletMainRow,
  WalletMainTab,
  WalletMainTabLabel,
  WalletMainTabValue,
} from "./model";

import { SharedButtonIcon } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedButtonIcon,
  },
  props: {
    isConclusion: {
      type: Boolean,
      default: false,
    },
    isInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isConclusion": null,
    "update:isInput": null,
  },
  data() {
    return {
      tab: WalletMainTabValue.all,
      actionsRowId: <null | number>null,
    };
  },
  computed: {
    displayedTabs(): WalletMainTab[] {
      return [
        {
          id: 0,
          label: WalletMainTabLabel.all,
          value: WalletMainTabValue.all,
          active: this.tab === WalletMainTabValue.all,
        },
        {
          id: 1,
          label: WalletMainTabLabel.fiat,
          value: WalletMainTabValue.fiat,
          active: this.tab === WalletMainTabValue.fiat,
        },
        {
          id: 2,
          label: WalletMainTabLabel.crypto,
          value: WalletMainTabValue.crypto,
          active: this.tab === WalletMainTabValue.crypto,
        },
      ];
    },

    displayedColumns(): WalletMainColumn[] {
      return [
        {
          id: 0,
          label: WalletMainColumnLabel.currency,
          sort: true,
        },
        {
          id: 1,
          label: WalletMainColumnLabel.total,
          sort: true,
        },
        {
          id: 2,
          label: WalletMainColumnLabel.action,
          sort: false,
        },
      ];
    },

    displayedRows(): WalletMainRow[] {
      return [
        {
          id: 0,
          currency: {
            name: "USD",
            icon: this.displayedIcons.usd,
            tag: "Bank Account",
            isBank: true,
            isCrypto: false,
          },
          total: "8,800",
        },
        {
          id: 1,
          currency: {
            name: "EUR",
            icon: this.displayedIcons.eur,
            tag: "Bank Account",
            isBank: true,
            isCrypto: false,
          },
          total: "1,099",
        },
        {
          id: 2,
          currency: {
            name: "RUB",
            icon: this.displayedIcons.rub,
            tag: "Bank Account",
            isBank: true,
            isCrypto: false,
          },
          total: "20,000.05",
        },
        {
          id: 3,
          currency: {
            name: "AED",
            icon: this.displayedIcons.aed,
            tag: "Bank Account",
            isBank: true,
            isCrypto: false,
          },
          total: "350",
        },
        {
          id: 4,
          currency: {
            name: "USDT",
            icon: this.displayedIcons.usdt,
            tag: "Crypto Account",
            isBank: false,
            isCrypto: true,
          },
          total: "200.12345678",
        },
        {
          id: 5,
          currency: {
            name: "BTC",
            icon: this.displayedIcons.btc,
            tag: "Crypto Account",
            isBank: false,
            isCrypto: true,
          },
          total: "0.03502",
        },
      ].map((row) => ({ ...row, isActions: row.id === this.actionsRowId }));
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleTab({ value }: WalletMainTab): void {
      this.tab = value;
    },

    handleConclusion(): void {
      this.$emit("update:isConclusion", !this.isConclusion);
    },

    handleInput(): void {
      this.$emit("update:isInput", !this.isInput);
    },

    handleRowConclusion(row: WalletMainRow): void {
      this.$emit("update:isConclusion", !this.isConclusion);
      console.log(row);
    },

    handleRowInput(row: WalletMainRow): void {
      this.$emit("update:isInput", !this.isInput);
      console.log(row);
    },

    handleActions({ id }: WalletMainRow): void {
      this.actionsRowId = this.actionsRowId === id ? null : id;
    },

    handleColumnSort(column: WalletMainColumn): void {
      console.log(column);
    },
  },
});
