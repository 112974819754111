<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button
      :class="[b('toggle', { view }), displayedActiveClass]"
      @click="handleToggle"
    >
      {{ displayedLanguage }}

      <span
        v-if="isChevron"
        :class="b('toggle-icon', { active: isVisible })"
        v-html="displayedIcons.chevron"
      ></span>
    </button>

    <div :class="b('options', { view, visible: isVisible })">
      <button
        :class="b('option', { view, active: option.isActive })"
        v-for="option in displayedLanguages"
        :key="option.id"
        @click="handleOption(option)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>
