import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('delimiter'))
    }, null, 2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, "or", 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('delimiter'))
    }, null, 2)
  ], 2))
}