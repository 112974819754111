<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">КУС/КУВ</h2>

    <div :class="b('wrapper')">
      <div :class="b('block')">
        <div :class="b('group')">
          <h4 :class="b('group-title')">
            {{ $t("Паспорт или ВУ") }}
          </h4>

          <div :class="b('fields', { passport: true })">
            <SharedUpload
              :file="passportSelfie.file"
              :link="passportSelfie.link"
              :is-remove="false"
            />

            <SharedUpload
              :file="passportOrganization.file"
              :link="passportOrganization.link"
              :is-remove="false"
            />

            <SharedUpload
              :file="passportAddress.file"
              :link="passportAddress.link"
              :is-remove="false"
            />
          </div>
        </div>

        <div :class="b('group')">
          <h4 :class="b('group-title')">
            {{ $t("Селфи") }}
          </h4>

          <div :class="b('fields', { selfie: true })">
            <SharedUpload
              :file="selfie.file"
              :link="selfie.link"
              :is-remove="false"
            />
          </div>
        </div>
      </div>

      <div :class="b('group')">
        <h4 :class="b('group-title')">
          {{ $t("Соглашения") }}
        </h4>

        <div :class="b('fields', { agreements: true })">
          <SharedUpload
            :is-document="true"
            :file="documentBroker.file"
            :link="documentBroker.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("На") }} <br />
              {{ $t("обслуживание") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="documentBroker.file"
            :link="documentBroker.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("На создание") }} <br />
              {{ $t("брокерских счетов") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="declaration.file"
            :link="declaration.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("О рисках") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="broker.file"
            :link="broker.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("На доверительное") }} <br />
              {{ $t("управление") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
