import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value", "type"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('field', { view: _ctx.view, error: _ctx.isError })),
      placeholder: _ctx.$t(_ctx.placeholder),
      value: _ctx.modelValue,
      type: _ctx.visibility,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeModelValue && _ctx.changeModelValue(...args)))
    }, null, 42, _hoisted_1),
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('eye')),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeVisibility && _ctx.changeVisibility(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('eye-icon')),
        innerHTML: _ctx.displayedIcon
      }, null, 10, _hoisted_2)
    ], 2)
  ], 2))
}