import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('toggle', { active: _ctx.isVisible })),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-placeholder'))
      }, _toDisplayString(_ctx.displayedPlaceholder), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-group'))
      }, [
        (_ctx.isCurrencyShow)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.b('toggle-currency'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('toggle-icon')),
                innerHTML: _ctx.value.currency.icon
              }, null, 10, _hoisted_1),
              _createTextVNode(" " + _toDisplayString(_ctx.value.currency.coin), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-icon', { active: _ctx.isVisible })),
          innerHTML: _ctx.displayedChevronIcon
        }, null, 10, _hoisted_2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { visible: _ctx.isVisible }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('option', { active: option.isActive })),
          key: option.id,
          onClick: ($event: any) => (_ctx.handleOption(option))
        }, [
          _createTextVNode(_toDisplayString(option.label) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('option-currency'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('option-icon')),
              innerHTML: option.currency.icon
            }, null, 10, _hoisted_4),
            _createTextVNode(" " + _toDisplayString(option.currency.coin), 1)
          ], 2)
        ], 10, _hoisted_3))
      }), 128)),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('option', { create: true })),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCreate && _ctx.handleCreate(...args)))
      }, " Создать новый ", 2)
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}