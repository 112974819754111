<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Комиссии") }}
    </h2>

    <div :class="b('wrapper')">
      <AdminCommissionTabs v-model:tab="tab" />

      <component :is="displayedComponent" />
    </div>
  </div>
</template>
