export interface AdminApplicationTableColumn {
  id: number;
  label: AdminApplicationTableColumnLabel;
}

export enum AdminApplicationTableColumnLabel {
  date = "<span>Дата</span>",
  id = "<span>ID <br /> польз.</span>",
  email = "<span>почта</span>",
  type = "<span>тип</span>",
  amount = "<span>сумма</span>",
  currency = "<span>Валюта</span>",
  price = "<span>Цена <br /> исполнения</span>",
  commission = "<span>Комиссия</span>",
  number = "<span>№</span>",
  status = "<span>Статус</span>",
  stage = "<span>Этап</span>",
}

export interface AdminApplicationTableRow {
  date: string;
  id: string;
  email: string;
  type: string;
  amount: string;
  currency: string;
  price: string;
  commission: string;
  number: string;
  status: {
    label: string;
  };
  stage: {
    label: string;
  };
}
