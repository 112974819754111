<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Документы" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('form-group')">
        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Паспорт или ВУ") }}
          </h4>

          <div :class="b('form-fields', { passport: true })">
            <SharedUpload
              :file="passportSelfie.file"
              :link="passportSelfie.link"
              :is-remove="false"
            />

            <SharedUpload
              :file="passportOrganization.file"
              :link="passportOrganization.link"
              :is-remove="false"
            />

            <SharedUpload
              :file="passportAddress.file"
              :link="passportAddress.link"
              :is-remove="false"
            />
          </div>
        </div>

        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Селфи") }}
          </h4>

          <div :class="b('form-fields', { selfie: true })">
            <SharedUpload
              :file="selfie.file"
              :link="selfie.link"
              :is-remove="false"
            />
          </div>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Соглашения") }}
        </h4>

        <div :class="b('form-fields', { default: true })">
          <SharedUpload
            :is-document="true"
            :file="questionnaire.file"
            :link="questionnaire.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("физического лица") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="personalData.file"
            :link="personalData.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="declaration.file"
            :link="declaration.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="broker.file"
            :link="broker.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Документы для подтверждения верификации") }}
        </h4>

        <div :class="b('form-fields', { verification: true })">
          <SharedUpload
            :is-document="true"
            :file="originOfFunds.file"
            :link="originOfFunds.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Документ о происхождении") }} <br />
              {{ $t("денежных средств") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-document="true"
            :file="confirmingRegistration.file"
            :link="confirmingRegistration.link"
            :is-remove="false"
          >
            <template #label>
              {{ $t("Документ подтверждающий") }} <br />
              {{ $t("постановку на учет в налого...") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
