<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <span :class="b('logo')" v-html="displayedLogo"></span>

    <SharedLanguage view="main" />
  </div>
</template>
