import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  AdminKycKybCompanyCard,
  AdminKycKybCompanyCardLabel,
  AdminKycKybCompanyCardTitle,
} from "./model";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminKycComponent } from "@/views/admin-kyc/model";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedCards(): AdminKycKybCompanyCard[] {
      return [
        {
          id: 0,
          title: AdminKycKybCompanyCardTitle.basic,
          label: AdminKycKybCompanyCardLabel.basic,
          component: AdminKycComponent.kybBasicInformation,
        },
        {
          id: 1,
          title: AdminKycKybCompanyCardTitle.participants,
          label: AdminKycKybCompanyCardLabel.participants,
          component: AdminKycComponent.kybParticipants,
        },
        {
          id: 2,
          title: AdminKycKybCompanyCardTitle.documents,
          label: AdminKycKybCompanyCardLabel.documents,
          component: AdminKycComponent.kybDocuments,
        },
      ];
    },
  },
  methods: {
    handleCard({ component }: AdminKycKybCompanyCard): void {
      this.$emit("update:component", component);
    },
  },
});
