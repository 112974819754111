import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  DefaultToolbarLink,
  DefaultToolbarLinkIcon,
  DefaultToolbarLinkLabel,
} from "./model";

import { SharedLanguage } from "@/components/shared";

import {
  RoutesName,
  RoutesTo,
  SvgAttribute,
} from "@/shared/constants/constants";
import { UserInfoResponse } from "@/shared/repository/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  data() {
    return {
      isMenu: false,
      loading: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedLinks(): DefaultToolbarLink[] {
      return [
        {
          id: 0,
          label: DefaultToolbarLinkLabel.wallet,
          to: RoutesTo.wallet,
          icon: SVG[DefaultToolbarLinkIcon.wallet],
        },
        {
          id: 1,
          label: DefaultToolbarLinkLabel.application,
          to: RoutesTo.application,
          icon: SVG[DefaultToolbarLinkIcon.application],
        },
        {
          id: 2,
          label: DefaultToolbarLinkLabel.history,
          to: RoutesTo.history,
          icon: SVG[DefaultToolbarLinkIcon.history],
        },
        {
          id: 3,
          label: DefaultToolbarLinkLabel.settings,
          to: RoutesTo.settings,
          icon: SVG[DefaultToolbarLinkIcon.settings],
        },
      ];
    },

    displayedUserInfo(): UserInfoResponse {
      return this.$projectServices.userRepo.userInfo;
    },
  },
  methods: {
    handleBurger(): void {
      this.isMenu = !this.isMenu;
    },

    handleClose(): void {
      this.isMenu = !this.isMenu;
    },

    async handleLogout(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository.logout();

        this.$router.push({ name: RoutesName.login });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
