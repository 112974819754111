export interface HistoryTableColumn {
  id: number;
  label: HistoryTableColumnLabel;
}

export enum HistoryTableColumnLabel {
  date = "Дата",
  dateTime = "Дата и время",
  type = "Тип",
  amount = "Сумма",
  currency = "Валюта",
  commission = "Комиссия",
  status = "Статус",
}

export interface HistoryTableRow {
  id: number;
  dateTime: string;
  type: string;
  amount: {
    type: {
      refill: boolean;
      conclusion: boolean;
    };
    label: string;
  };
  currency: string;
  commission: string;
  status: {
    icon: string;
    label: string;
  };
  isActions?: boolean;
}

export enum HistoryTableRowStatusIcon {
  created = "created",
  expectation = "expectation",
  waitingForConfirmation = "waitingForConfirmation",
  error = "error",
  atWork = "atWork",
  done = "done",
}
