import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KycUploadFileResponseFactory } from "@/shared/factory/kyc";
import {
  FileType,
  KycPersonalDocumentsFileName,
} from "@/shared/constants/constants";
import { KycUploadFileResponse } from "@/shared/repository/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  data() {
    return {
      passportSelfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ],
        type: FileType.image,
      }),
      passportOrganization: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ],
        type: FileType.image,
      }),
      passportAddress: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ],
        type: FileType.image,
      }),
      selfie: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-selfie"],
        type: FileType.image,
      }),
      questionnaire: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ],
        type: FileType.document,
      }),
      personalData: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ],
        type: FileType.document,
      }),
      declaration: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-declaration"
        ],
        type: FileType.document,
      }),
      broker: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName["kyc-personal-documents-broker"],
        type: FileType.document,
      }),
      originOfFunds: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ],
        type: FileType.document,
      }),
      confirmingRegistration: KycUploadFileResponseFactory({
        name: KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ],
        type: FileType.document,
      }),
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.projectRepo.kycUserFiles(this.id).then((userFiles) => {
        userFiles.forEach((userFile) =>
          this.handleCurrentFileResponse(userFile)
        );
      });
    } catch (e) {
      console.log(e);
    }
  },
  emits: {
    "update:component": null,
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.personalInformation);
    },

    handleCurrentFileResponse(params: KycUploadFileResponse): void {
      switch (extractFileName(params.name)) {
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-selfie"
        ]:
          this.passportSelfie = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-organization"
        ]:
          this.passportOrganization = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-passport-address"
        ]:
          this.passportAddress = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-selfie"]:
          this.selfie = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-questionnaire"
        ]:
          this.questionnaire = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-personal-data"
        ]:
          this.personalData = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-declaration"]:
          this.declaration = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName["kyc-personal-documents-broker"]:
          this.broker = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-origin-of-funds"
        ]:
          this.originOfFunds = KycUploadFileResponseFactory(params);

          break;
        case KycPersonalDocumentsFileName[
          "kyc-personal-documents-confirming-registration"
        ]:
          this.confirmingRegistration = KycUploadFileResponseFactory(params);

          break;
        default:
          break;
      }
    },
  },
});
