import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryHeader = _resolveComponent("HistoryHeader")!
  const _component_HistoryTable = _resolveComponent("HistoryTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_HistoryRemove = _resolveComponent("HistoryRemove")!
  const _component_HistoryDetails = _resolveComponent("HistoryDetails")!
  const _component_HistoryApplicationsDetails = _resolveComponent("HistoryApplicationsDetails")!
  const _component_HistoryFilters = _resolveComponent("HistoryFilters")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("История")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_HistoryHeader, {
        tab: _ctx.tab,
        "onUpdate:tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
        isFilter: _ctx.isFilter,
        "onUpdate:isFilter": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFilter) = $event)),
        filter: _ctx.filter,
        "onUpdate:filter": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event))
      }, null, 8, ["tab", "isFilter", "filter"]),
      _createVNode(_component_HistoryTable, {
        class: _normalizeClass(_ctx.b('table'))
      }, null, 8, ["class"]),
      _createVNode(_component_SharedButtonIcon, {
        class: _normalizeClass(_ctx.b('more')),
        view: "gray-outline",
        onClick: _ctx.handleMore
      }, {
        "icon-left": _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('more-icon')),
            innerHTML: _ctx.displayedMoreIcon
          }, null, 10, _hoisted_1)
        ]),
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
        ]),
        _: 1
      }, 8, ["class", "onClick"])
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isRemove)
          ? (_openBlock(), _createBlock(_component_HistoryRemove, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isRemove: _ctx.isRemove,
              "onUpdate:isRemove": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isRemove) = $event))
            }, null, 8, ["class", "isRemove"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isDetails)
          ? (_openBlock(), _createBlock(_component_HistoryDetails, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isDetails: _ctx.isDetails,
              "onUpdate:isDetails": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDetails) = $event))
            }, null, 8, ["class", "isDetails"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isApplicationDetails)
          ? (_openBlock(), _createBlock(_component_HistoryApplicationsDetails, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isApplicationDetails: _ctx.isApplicationDetails,
              "onUpdate:isApplicationDetails": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isApplicationDetails) = $event))
            }, null, 8, ["class", "isApplicationDetails"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isFilter)
          ? (_openBlock(), _createBlock(_component_HistoryFilters, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              isFilter: _ctx.isFilter,
              "onUpdate:isFilter": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isFilter) = $event)),
              currency: _ctx.filter.currency,
              "onUpdate:currency": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filter.currency) = $event)),
              date: _ctx.filter.date,
              "onUpdate:date": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filter.date) = $event)),
              volumeFrom: _ctx.filter.volume.from,
              "onUpdate:volumeFrom": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filter.volume.from) = $event)),
              volumeTo: _ctx.filter.volume.to,
              "onUpdate:volumeTo": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filter.volume.to) = $event)),
              status: _ctx.filter.status,
              "onUpdate:status": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filter.status) = $event)),
              type: _ctx.filter.type,
              "onUpdate:type": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filter.type) = $event))
            }, null, 8, ["class", "isFilter", "currency", "date", "volumeFrom", "volumeTo", "status", "type"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}