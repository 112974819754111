<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <input
      :class="b('field', { view, error: isError })"
      :placeholder="$t(placeholder)"
      :value="modelValue"
      :type="visibility"
      @input="changeModelValue"
    />

    <button :class="b('eye')" @click="changeVisibility">
      <span :class="b('eye-icon')" v-html="displayedIcon"></span>
    </button>
  </div>
</template>
