import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b('', { view: _ctx.view })),
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDownload && _ctx.handleDownload(...args)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('icon')),
      innerHTML: _ctx.displayedIcon
    }, null, 10, _hoisted_2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, [
      _renderSlot(_ctx.$slots, "label")
    ], 2)
  ], 10, _hoisted_1))
}