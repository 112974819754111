<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="b('', { view })"
    :disabled="isDisabled"
    @click="handleDownload"
  >
    <span :class="b('icon')" v-html="displayedIcon"></span>

    <p :class="b('label')">
      <slot name="label"></slot>
    </p>
  </button>
</template>
