import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      ref: "toggle",
      class: _normalizeClass(_ctx.b('toggle', { view: _ctx.view })),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChangeVisibility && _ctx.handleChangeVisibility(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-group'))
      }, [
        (_ctx.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['fi', `fi-${_ctx.displayedCountry}`])
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-label')),
          ref: "toggleLabel",
          style: _normalizeStyle({ maxWidth: _ctx.displayedToggleLabelMaxWidth })
        }, _toDisplayString(_ctx.displayedPlaceholder), 7)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-icon', { active: _ctx.isVisible })),
        innerHTML: _ctx.displayedIcons.chevron
      }, null, 10, _hoisted_1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { view: _ctx.view, visible: _ctx.isVisible }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('option', { view: _ctx.view, active: option.isActive })),
          key: option.value,
          onClick: ($event: any) => (_ctx.handleOption(option))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fi', `fi-${option.icon}`])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(option.label), 1)
        ], 10, _hoisted_2))
      }), 128))
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}