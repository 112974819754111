<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('wrapper')" v-click-outside="handleClose">
      <button :class="b('close')" @click="handleClose">
        <span :class="b('close-icon')" v-html="displayedCloseIcon"></span>
      </button>

      <span :class="b('icon')" v-html="displayedIcon"></span>

      <div :class="b('info')">
        <h3 :class="b('info-title')">
          {{ $t("Удаления заявки") }}
        </h3>

        <p :class="b('info-label')">
          {{ $t("Вы действительно хотите удалить заявку?") }}
        </p>
      </div>

      <div :class="b('footer')">
        <UIButton label="Оставить" view="main" @click="handleClose" />

        <UIButton label="Удалить" view="main" @click="handleRemove" />
      </div>
    </div>
  </div>
</template>
