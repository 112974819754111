import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { WalletTabsTabValue } from "../tabs/model";
import { WalletRequisitesRadioValue } from "../requisites/model";

import { SharedWindow } from "@/components/shared";
import { WalletTabs } from "../tabs";
import { WalletRequisites } from "../requisites";
import { WalletInputFiatPattern } from "./fiat-pattern";
import { WalletInputFiatNew } from "./fiat-new";
import { WalletInputCryptoPattern } from "./crypto-pattern";
import { WalletInputCryptoNew } from "./crypto-new";
import { WalletInputFiatRequisites } from "./fiat-requisites";
import { WalletInputCryptoRequisites } from "./crypto-requisites";

type Component =
  | typeof WalletInputFiatPattern
  | typeof WalletInputFiatNew
  | typeof WalletInputCryptoPattern
  | typeof WalletInputCryptoNew;

type RequisitesComponent =
  | typeof WalletInputFiatRequisites
  | typeof WalletInputCryptoRequisites
  | null;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    WalletTabs,
    WalletRequisites,
    WalletInputFiatPattern,
    WalletInputFiatNew,
    WalletInputCryptoPattern,
    WalletInputCryptoNew,
    WalletInputFiatRequisites,
    WalletInputCryptoRequisites,
  },
  props: {
    isInput: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isInput": null,
  },
  data() {
    return {
      tab: WalletTabsTabValue.fiat,
      requisites: WalletRequisitesRadioValue.pattern,
      isFiatRequisites: false,
      isCryptoRequisites: false,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case WalletTabsTabValue.fiat:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletInputFiatPattern;
            case WalletRequisitesRadioValue.new:
              return WalletInputFiatNew;
            default:
              return WalletInputFiatPattern;
          }
        case WalletTabsTabValue.crypto:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletInputCryptoPattern;
            case WalletRequisitesRadioValue.new:
              return WalletInputCryptoNew;
            default:
              return WalletInputCryptoPattern;
          }
        default:
          switch (this.requisites) {
            case WalletRequisitesRadioValue.pattern:
              return WalletInputFiatPattern;
            case WalletRequisitesRadioValue.new:
              return WalletInputFiatNew;
            default:
              return WalletInputFiatPattern;
          }
      }
    },

    displayedRequisites(): RequisitesComponent {
      if (this.isFiatRequisites) {
        return WalletInputFiatRequisites;
      }

      if (this.isCryptoRequisites) {
        return WalletInputCryptoRequisites;
      }

      return null;
    },

    isHeaderShow(): boolean {
      return this.isFiatRequisites || this.isCryptoRequisites;
    },

    isRequisitesShow(): boolean {
      return this.isFiatRequisites || this.isCryptoRequisites;
    },

    isBig(): boolean {
      return (
        (this.tab === WalletTabsTabValue.fiat &&
          this.requisites === WalletRequisitesRadioValue.new) ||
        this.isFiatRequisites
      );
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isInput", !this.isInput);
    },
  },
});
