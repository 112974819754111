/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { Language } from "@/shared/constants/constants";

import { getCookie, setCookie } from "@/shared/utils/cookie-helpers";

enum LanguageMutation {
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
}

interface State {
  language?: Language;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "language",
})
export default class LanguageModuleState extends VuexModule<State> {
  language = Language["ru-RU"];

  @Mutation
  [LanguageMutation.UPDATE_LANGUAGE](language: Language): void {
    this.language = language;
  }

  @Action
  setLanguage(): void {
    const language = getCookie("language");

    switch (language) {
      case Language["ru-RU"]:
        this.UPDATE_LANGUAGE(language);

        break;
      case Language["en-US"]:
        this.UPDATE_LANGUAGE(language);

        break;
      default:
        setCookie("language", Language["ru-RU"]);

        this.UPDATE_LANGUAGE(Language["ru-RU"]);

        break;
    }
  }

  @Action
  updateLanguage(language: Language): void {
    this.UPDATE_LANGUAGE(language);

    setCookie("language", language);
  }
}
