import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Отправьте 1,000 USDT по этим реквизитам:")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Сеть")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Адрес")), 3),
        _createVNode(_component_UIInput, { view: "light" })
      ], 2)
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t(
          "Нажимая кнопку, вы подтверждаете факт отправки средств на указанный адрес."
        )) + " ", 1),
      _createElementVNode("br", {
        class: _normalizeClass(_ctx.b('label-delimeter'))
      }, null, 2),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("Ваш баланс обновится после зачисления средств на ваш счет")), 1)
    ], 2),
    _createVNode(_component_UIButton, {
      class: _normalizeClass(_ctx.b('confirm')),
      label: "Подтвердить отправление",
      view: "main"
    }, null, 8, ["class"])
  ], 2))
}