import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SharedLanguage = _resolveComponent("SharedLanguage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(_ctx.b('wallet')),
      to: "/"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('icon', { wallet: true })),
          innerHTML: _ctx.displayedIcons.wallet
        }, null, 10, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('wallet-group'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label'))
          }, "9,999.12", 2),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('wallet-currency'))
          }, "USD", 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('wallet-divider'))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('wallet-group'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label'))
          }, "2,456.12345678", 2),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('wallet-currency'))
          }, "USDT", 2)
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('group'))
    }, [
      _createVNode(_component_SharedLanguage, {
        class: _normalizeClass(_ctx.b('language')),
        view: "light"
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('profile'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.displayedUserInfo.email), 3),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('profile-logout')),
          disabled: _ctx.isLogoutDisabled,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedIcons.logout
          }, null, 10, _hoisted_3)
        ], 10, _hoisted_2)
      ], 2)
    ], 2)
  ], 2))
}