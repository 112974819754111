import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { AdminUserAppStatusRequestFactory } from "@/shared/factory/admin-users";
import { SelectOptionFactory } from "@/shared/factory/select";
import { SharedSelectOption } from "@/components/shared/select/model";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedSelect,
    UIInput,
    UIButton,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:userId": null,
    "update:isStatus": null,
  },
  data() {
    return {
      status: AdminUserAppStatusRequestFactory({
        id: this.userId,
      }),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .adminUserApp(this.userId)
        .then((userApp) => {
          this.status.status = userApp.status;
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "pending",
            value: "pending",
          },
          this.status.status
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "waiting",
            value: "waiting",
          },
          this.status.status
        ),
        SelectOptionFactory(
          {
            id: 2,
            label: "completed",
            value: "completed",
          },
          this.status.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "reject",
            value: "reject",
          },
          this.status.status
        ),
      ];
    },

    isUpdateDisabled(): boolean {
      return (
        !this.status.id ||
        !this.status.status ||
        !this.status.message ||
        this.loading
      );
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:userId", 0);
      this.$emit("update:isStatus", false);
    },

    async handleChangeStatus(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository.adminUserAppStatus(
          this.status
        );

        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
