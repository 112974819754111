import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-notification";

export const SVG: SvgAttribute = {
  password: `<svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_21680)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.761719 -0.046875C14.168 -0.046875 27.5742 -0.046875 40.9805 -0.046875C41.0388 0.0587401 41.1169 0.15249 41.2148 0.234375C41.2617 3.23422 41.2774 6.23422 41.2617 9.23438C41.2617 10.2344 41.2617 11.2343 41.2617 12.2344C40.793 12.2344 40.3242 12.2344 39.8555 12.2344C39.8555 8.60937 39.8555 4.98438 39.8555 1.35938C28.043 1.35938 16.2305 1.35938 4.41797 1.35938C3.57422 1.35938 2.73047 1.35938 1.88672 1.35938C1.88672 9.10938 1.88672 16.8593 1.88672 24.6094C1.88672 25.3906 1.88672 26.1719 1.88672 26.9531C1.41797 26.9531 0.949219 26.9531 0.480469 26.9531C0.480469 18.0469 0.480469 9.14062 0.480469 0.234375C0.605469 0.171875 0.699218 0.078125 0.761719 -0.046875Z" fill="#003178"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41797 1.35938C4.41797 9.07812 4.41797 16.7969 4.41797 24.5156C14.043 24.5156 23.6679 24.5156 33.293 24.5156C33.293 24.5468 33.293 24.5782 33.293 24.6094C23.6367 24.6094 13.9805 24.6094 4.32422 24.6094C3.51172 24.6094 2.69922 24.6094 1.88672 24.6094C1.88672 16.8593 1.88672 9.10938 1.88672 1.35938C2.73047 1.35938 3.57422 1.35938 4.41797 1.35938Z" fill="#98E5FD"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41797 1.35938C16.2305 1.35938 28.043 1.35938 39.8555 1.35938C39.8555 4.98438 39.8555 8.60937 39.8555 12.2344C38.6557 12.1664 37.6244 12.5414 36.7617 13.3594C36.4329 13.7072 36.1517 14.0822 35.918 14.4844C35.9336 11.0154 35.918 7.54673 35.8711 4.07812C35.8242 4.03125 35.7773 3.98438 35.7305 3.9375C25.8242 3.875 15.918 3.875 6.01172 3.9375C5.96484 3.98438 5.91797 4.03125 5.87109 4.07812C5.80859 9.85941 5.80859 15.6406 5.87109 21.4219C5.94922 21.5 6.02735 21.5782 6.10547 21.6562C15.1679 21.7031 24.2304 21.7188 33.293 21.7031C33.293 22.6406 33.293 23.5781 33.293 24.5156C23.6679 24.5156 14.043 24.5156 4.41797 24.5156C4.41797 16.7969 4.41797 9.07812 4.41797 1.35938Z" fill="#C9EFFE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.918 14.4844C35.7735 14.9404 35.711 15.4091 35.7305 15.8906C35.5142 16.0291 35.3423 16.2166 35.2148 16.4531C35.1523 17.3281 35.1523 18.2032 35.2148 19.0781C35.2902 19.3262 35.4308 19.5137 35.6367 19.6406C35.6367 19.9219 35.6367 20.2031 35.6367 20.4844C35.1669 20.4688 34.6982 20.4845 34.2305 20.5313C33.6897 20.7434 33.3773 21.1341 33.293 21.7031C24.2304 21.7188 15.1679 21.7031 6.10547 21.6563C6.02735 21.5782 5.94922 21.5 5.8711 21.4219C5.80859 15.6406 5.80859 9.85941 5.8711 4.07813C5.91797 4.03125 5.96485 3.98438 6.01172 3.9375C15.918 3.875 25.8242 3.875 35.7305 3.9375C35.7773 3.98438 35.8242 4.03125 35.8711 4.07813C35.918 7.54674 35.9336 11.0154 35.918 14.4844Z" fill="#FEF4F4"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6988 6.70311C12.8199 7.25552 12.6169 8.00552 13.0894 8.95311C13.2354 9.12738 13.4073 9.268 13.605 9.37498C13.0747 9.42176 12.5434 9.43742 12.0113 9.42186C11.5287 9.3306 11.2006 9.04935 11.0269 8.57811C10.7959 7.74393 11.0615 7.13455 11.8238 6.74998C12.4479 6.70314 13.073 6.68751 13.6988 6.70311Z" fill="#66DCFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6992 6.70313C19.0743 6.6875 24.4493 6.70313 29.8242 6.75C30.7937 7.23357 31.0281 7.96794 30.5273 8.95313C30.2812 9.20914 29.9842 9.381 29.6367 9.46875C23.7459 9.54675 17.871 9.5311 12.0117 9.42188C12.5438 9.43744 13.0751 9.42178 13.6055 9.375C13.4078 9.26802 13.2359 9.1274 13.0898 8.95313C12.6173 8.00554 12.8204 7.25554 13.6992 6.70313Z" fill="#9AE7FC"/>
    <path opacity="0.956" fill-rule="evenodd" clip-rule="evenodd" d="M41.2617 9.23438C42.2187 10.2427 43.0625 11.3364 43.793 12.5156C42.9677 12.2783 42.1239 12.1846 41.2617 12.2344C41.2617 11.2343 41.2617 10.2344 41.2617 9.23438Z" fill="#00A9F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6988 11.3906C13.4753 11.5204 13.3191 11.7079 13.23 11.9531C12.2502 11.7608 11.8283 12.167 11.9644 13.1718C12.2407 13.6308 12.6313 13.787 13.1363 13.6406C13.2503 13.8235 13.4064 13.9641 13.605 14.0625C13.0747 14.1093 12.5434 14.1249 12.0113 14.1093C11.5287 14.0181 11.2006 13.7369 11.0269 13.2656C10.7959 12.4314 11.0615 11.822 11.8238 11.4375C12.4479 11.3906 13.073 11.375 13.6988 11.3906Z" fill="#65DAFA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6992 11.3906C19.0743 11.375 24.4493 11.3906 29.8242 11.4375C30.7937 11.9211 31.0281 12.6554 30.5273 13.6406C30.2812 13.8967 29.9842 14.0685 29.6367 14.1563C23.7459 14.2343 17.871 14.2186 12.0117 14.1094C12.5438 14.125 13.0751 14.1093 13.6055 14.0625C13.4069 13.9642 13.2507 13.8235 13.1367 13.6406C13.6659 13.3629 13.8378 12.9253 13.6523 12.3281C13.5238 12.1837 13.3832 12.0586 13.2305 11.9531C13.3195 11.708 13.4758 11.5205 13.6992 11.3906Z" fill="#9AE6FC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2309 11.9531C13.3836 12.0585 13.5243 12.1836 13.6528 12.3281C13.8382 12.9253 13.6664 13.3628 13.1372 13.6406C12.6322 13.787 12.2416 13.6307 11.9653 13.1718C11.8293 12.167 12.2511 11.7608 13.2309 11.9531Z" fill="#03357A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1992 11.8594C16.1875 11.7693 16.6093 12.2068 16.4649 13.1719C15.9024 13.9219 15.3399 13.9219 14.7774 13.1719C14.7148 12.8907 14.7148 12.6094 14.7774 12.3282C14.9374 12.1841 15.078 12.0278 15.1992 11.8594Z" fill="#05357A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0117 11.8594C19 11.7693 19.4218 12.2068 19.2774 13.1719C18.7149 13.9219 18.1524 13.9219 17.5899 13.1719C17.5273 12.8907 17.5273 12.6094 17.5899 12.3282C17.7499 12.1841 17.8905 12.0278 18.0117 11.8594Z" fill="#05357A"/>
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M39.855 12.2344C40.3238 12.2344 40.7925 12.2344 41.2613 12.2344C42.1235 12.1846 42.9673 12.2784 43.7925 12.5156C45.0507 13.0404 45.8163 13.9779 46.0894 15.3281C46.1363 15.9211 46.1519 16.5148 46.1363 17.1094C46.043 18.1864 46.0118 19.2802 46.0425 20.3906C45.605 20.3906 45.1676 20.3906 44.73 20.3906C44.7457 18.7966 44.73 17.2029 44.6832 15.6094C44.4183 14.5945 43.7777 13.9539 42.7613 13.6875C42.2623 13.6407 41.7623 13.6251 41.2613 13.6406C40.7925 13.6406 40.3238 13.6406 39.855 13.6406C38.2747 13.6297 37.3059 14.3797 36.9488 15.8906C36.5426 15.8906 36.1363 15.8906 35.73 15.8906C35.7105 15.4091 35.7731 14.9404 35.9175 14.4844C36.1513 14.0822 36.4325 13.7072 36.7613 13.3594C37.624 12.5414 38.6552 12.1664 39.855 12.2344Z" fill="#0356CA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8555 13.6407C39.8555 15.8907 39.8555 18.1407 39.8555 20.3907C39.8555 20.422 39.8555 20.4533 39.8555 20.4845C38.918 20.4845 37.9805 20.4845 37.043 20.4845C37.043 20.4533 37.043 20.422 37.043 20.3907C37.043 20.1407 37.043 19.8908 37.043 19.6407C37.2281 19.5333 37.3688 19.377 37.4648 19.172C37.5274 18.2345 37.5274 17.297 37.4648 16.3595C37.3611 16.1145 37.1892 15.9582 36.9492 15.8907C37.3063 14.3798 38.2751 13.6298 39.8555 13.6407Z" fill="#C8EEFC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8555 13.6406C40.3242 13.6406 40.793 13.6406 41.2617 13.6406C41.2617 15.8906 41.2617 18.1406 41.2617 20.3906C40.793 20.3906 40.3242 20.3906 39.8555 20.3906C39.8555 18.1406 39.8555 15.8906 39.8555 13.6406Z" fill="#003278"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.2617 13.6406C41.7627 13.625 42.2627 13.6407 42.7617 13.6875C43.7782 13.9539 44.4187 14.5945 44.6836 15.6093C44.7305 17.2028 44.7461 18.7966 44.7305 20.3906C45.168 20.3906 45.6054 20.3906 46.043 20.3906C46.3868 20.3906 46.7304 20.3906 47.0742 20.3906C44.6841 20.4841 42.2779 20.5154 39.8555 20.4843C39.8555 20.4531 39.8555 20.4218 39.8555 20.3906C40.3242 20.3906 40.793 20.3906 41.2617 20.3906C41.2617 18.1406 41.2617 15.8906 41.2617 13.6406Z" fill="#09A8EF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.7305 15.8906C36.1367 15.8906 36.543 15.8906 36.9492 15.8906C37.1892 15.9581 37.3611 16.1144 37.4649 16.3594C37.5274 17.2969 37.5274 18.2344 37.4649 19.1719C37.3688 19.3769 37.2281 19.5332 37.043 19.6406C36.6026 19.7604 36.165 19.7604 35.7305 19.6406C35.6993 19.6406 35.668 19.6406 35.6367 19.6406C35.4309 19.5137 35.2902 19.3262 35.2149 19.0781C35.1523 18.2032 35.1523 17.3281 35.2149 16.4531C35.3424 16.2166 35.5142 16.0291 35.7305 15.8906Z" fill="#FDAA0A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8555 16.0781C17.9767 16.6305 17.7736 17.3805 18.2461 18.3281C18.4965 18.5533 18.7622 18.7408 19.043 18.8906C18.1915 18.9294 17.3478 18.8825 16.5117 18.75C15.3641 17.8321 15.3953 16.9571 16.6055 16.125C17.3548 16.0781 18.1048 16.0625 18.8555 16.0781Z" fill="#0FBA74"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8553 16.0781C20.9181 16.0625 22.9806 16.0781 25.0428 16.125C26.0466 16.6056 26.281 17.3399 25.7459 18.3281C25.4997 18.5842 25.2028 18.756 24.8553 18.8438C22.918 18.8906 20.9805 18.9063 19.0428 18.8906C18.762 18.7408 18.4963 18.5533 18.2459 18.3281C17.7734 17.3805 17.9765 16.6305 18.8553 16.0781Z" fill="#07D683"/>
    <path opacity="0.921" fill-rule="evenodd" clip-rule="evenodd" d="M46.1364 17.1094C46.4931 18.1925 46.8056 19.2863 47.0739 20.3906C46.7302 20.3906 46.3865 20.3906 46.0427 20.3906C46.0119 19.2802 46.0432 18.1864 46.1364 17.1094Z" fill="#03A3EF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.7305 19.6406C36.165 19.7604 36.6025 19.7604 37.043 19.6406C37.043 19.8907 37.043 20.1406 37.043 20.3906C36.6054 20.3906 36.168 20.3906 35.7305 20.3906C35.7305 20.1406 35.7305 19.8907 35.7305 19.6406Z" fill="#0355C9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6367 19.6406C35.6679 19.6406 35.6992 19.6406 35.7305 19.6406C35.7305 19.8907 35.7305 20.1406 35.7305 20.3906C36.168 20.3906 36.6054 20.3906 37.043 20.3906C37.043 20.4218 37.043 20.4532 37.043 20.4844C36.918 20.4844 36.7929 20.4844 36.668 20.4844C36.3242 20.4844 35.9805 20.4844 35.6367 20.4844C35.6367 20.2031 35.6367 19.9219 35.6367 19.6406Z" fill="#577BA6"/>
    <path opacity="0.999" fill-rule="evenodd" clip-rule="evenodd" d="M47.0742 20.3906C47.7247 20.4489 48.1934 20.7615 48.4805 21.3281C48.4805 25.7969 48.4805 30.2656 48.4805 34.7344C48.2815 35.1361 47.9691 35.433 47.543 35.625C46.6372 35.6719 45.731 35.6874 44.8242 35.6719C41.3082 35.703 37.8082 35.6717 34.3242 35.5781C35.0749 35.5937 35.8249 35.5781 36.5742 35.5312C36.1274 35.3035 35.8617 34.9441 35.7773 34.4531C35.7148 30.1406 35.7148 25.8281 35.7773 21.5156C35.9273 21.0381 36.2242 20.6943 36.668 20.4844C36.7929 20.4844 36.918 20.4844 37.043 20.4844C37.9805 20.4844 38.918 20.4844 39.8555 20.4844C42.2779 20.5154 44.6841 20.4842 47.0742 20.3906Z" fill="#FEC043"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.6367 20.4843C35.9805 20.4843 36.3242 20.4843 36.668 20.4843C36.2242 20.6942 35.9273 21.038 35.7774 21.5156C35.7148 25.8281 35.7148 30.1406 35.7774 34.4531C35.8617 34.9441 36.1274 35.3035 36.5742 35.5312C35.8249 35.5781 35.0749 35.5936 34.3242 35.5781C33.8228 35.4046 33.4946 35.061 33.3399 34.5468C33.293 33.4848 33.2773 32.4223 33.293 31.3593C33.293 29.9219 33.293 28.4843 33.293 27.0468C33.293 26.2343 33.293 25.4219 33.293 24.6093C33.293 24.5781 33.293 24.5468 33.293 24.5156C33.293 23.5781 33.293 22.6406 33.293 21.7031C33.3773 21.134 33.6897 20.7434 34.2305 20.5312C34.6982 20.4844 35.167 20.4688 35.6367 20.4843Z" fill="#FEAA00"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9492 23.297C40.645 23.2603 41.3324 23.3072 42.0117 23.4376C42.3692 23.6367 42.6036 23.9336 42.7149 24.3282C42.7774 25.0157 42.7774 25.7033 42.7149 26.3907C42.6136 26.6247 42.4886 26.8435 42.3399 27.047C42.3086 28.6407 42.2773 30.2345 42.2461 31.8282C41.7946 32.7806 41.0915 33.0462 40.1367 32.6251C39.8618 32.4129 39.6586 32.1472 39.5274 31.8282C39.4961 30.2345 39.4648 28.6407 39.4336 27.047C39.2849 26.8435 39.1599 26.6247 39.0586 26.3907C38.9961 25.7033 38.9961 25.0157 39.0586 24.3282C39.2085 23.8507 39.5054 23.5069 39.9492 23.297Z" fill="#033277"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.88672 24.6094C2.69922 24.6094 3.51172 24.6094 4.32422 24.6094C4.32422 25.3906 4.32422 26.1719 4.32422 26.9531C3.51172 26.9531 2.69922 26.9531 1.88672 26.9531C1.88672 26.1719 1.88672 25.3906 1.88672 24.6094Z" fill="#0076F0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.32422 24.6094C13.9805 24.6094 23.6367 24.6094 33.293 24.6094C33.293 25.4219 33.293 26.2343 33.293 27.0469C23.6679 27.0469 14.043 27.0469 4.41797 27.0469C3.10547 27.0469 1.79297 27.0469 0.480469 27.0469C0.480469 27.0157 0.480469 26.9843 0.480469 26.9531C0.949219 26.9531 1.41797 26.9531 1.88672 26.9531C2.69922 26.9531 3.51172 26.9531 4.32422 26.9531C4.32422 26.1719 4.32422 25.3906 4.32422 24.6094Z" fill="#0E97FC"/>
    <path opacity="0.994" fill-rule="evenodd" clip-rule="evenodd" d="M0.480469 27.0469C1.79297 27.0469 3.10547 27.0469 4.41797 27.0469C4.40236 28.1723 4.41798 29.2973 4.46484 30.4219C4.65201 30.8433 4.94887 31.1557 5.35547 31.3594C4.33862 31.3286 3.33862 31.3599 2.35547 31.4531C1.51687 31.5338 0.891866 31.2214 0.480469 30.5156C0.480469 29.3594 0.480469 28.2031 0.480469 27.0469Z" fill="#C9F0FE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41796 27.0469C14.043 27.0469 23.6679 27.0469 33.293 27.0469C33.293 28.4843 33.293 29.9219 33.293 31.3594C31.5117 31.3594 29.7305 31.3594 27.9492 31.3594C24.1055 31.3594 20.2617 31.3594 16.418 31.3594C15.543 31.3594 14.6679 31.3594 13.793 31.3594C10.9805 31.3594 8.16796 31.3594 5.35546 31.3594C4.94887 31.1557 4.652 30.8433 4.46484 30.4219C4.41797 29.2973 4.40235 28.1723 4.41796 27.0469Z" fill="#FEF4F5"/>
    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M5.35547 31.3593C8.16797 31.3593 10.9805 31.3593 13.793 31.3593C13.793 32.8281 13.793 34.2968 13.793 35.7656C13.4492 35.7656 13.1055 35.7656 12.7617 35.7656C12.0423 35.75 11.3235 35.7656 10.6055 35.8125C9.66848 36.2968 9.32472 37.0623 9.57422 38.1093C10.2617 38.1093 10.9493 38.1093 11.6367 38.1093C18.4805 38.1093 25.3242 38.1093 32.168 38.1093C32.3416 37.2711 32.1229 36.568 31.5117 36C31.3629 35.9191 31.2067 35.8566 31.043 35.8125C30.0122 35.7656 28.9809 35.7499 27.9492 35.7656C27.9492 34.2968 27.9492 32.8281 27.9492 31.3593C29.7305 31.3593 31.5117 31.3593 33.293 31.3593C33.2773 32.4223 33.293 33.4848 33.3398 34.5468C33.4946 35.061 33.8228 35.4046 34.3242 35.5781C37.8083 35.6716 41.3082 35.703 44.8242 35.6718C44.0157 37.3837 42.9533 38.9149 41.6367 40.2656C35.1367 40.2656 28.6367 40.2656 22.1367 40.2656C21.1987 40.25 20.2612 40.2656 19.3242 40.3125C18.9296 40.4237 18.6327 40.6581 18.4336 41.0156C18.2961 43.0406 18.2492 45.0719 18.293 47.1093C10.2592 44.6693 4.94662 39.4505 2.35547 31.4531C3.33862 31.3599 4.33862 31.3286 5.35547 31.3593Z" fill="#02429C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.793 31.3594C14.6679 31.3594 15.543 31.3594 16.418 31.3594C16.418 32.8282 16.418 34.2968 16.418 35.7656C15.543 35.7656 14.6679 35.7656 13.793 35.7656C13.793 34.2968 13.793 32.8282 13.793 31.3594Z" fill="#64DAFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.418 31.3594C20.2617 31.3594 24.1055 31.3594 27.9492 31.3594C27.9492 32.8282 27.9492 34.2968 27.9492 35.7656C24.1055 35.7656 20.2617 35.7656 16.418 35.7656C16.418 34.2968 16.418 32.8282 16.418 31.3594Z" fill="#9AE6FC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7613 35.7656C11.7817 36.2273 11.4067 37.0085 11.6363 38.1093C10.9488 38.1093 10.2613 38.1093 9.57381 38.1093C9.32432 37.0623 9.66807 36.2968 10.6051 35.8125C11.3231 35.7656 12.0419 35.75 12.7613 35.7656Z" fill="#97E4FB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7617 35.7656C13.1055 35.7656 13.4491 35.7656 13.7929 35.7656C14.6679 35.7656 15.543 35.7656 16.4179 35.7656C20.2617 35.7656 24.1054 35.7656 27.9492 35.7656C28.9809 35.75 30.0122 35.7656 31.0429 35.8125C31.2066 35.8567 31.3629 35.9192 31.5117 36C32.1228 36.568 32.3416 37.2712 32.1679 38.1094C25.3242 38.1094 18.4804 38.1094 11.6367 38.1094C11.4071 37.0086 11.7821 36.2274 12.7617 35.7656Z" fill="#C8EFFE"/>
    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M22.137 40.2656C21.7995 40.4591 21.5027 40.7091 21.2463 41.0156C21.1276 41.3808 21.0808 41.7558 21.1057 42.1406C20.3235 42.2481 20.011 42.7012 20.1682 43.5C20.0487 44.1032 20.2362 44.5719 20.7307 44.9062C20.8519 44.9522 20.977 44.9678 21.1057 44.9531C21.1057 45.2656 21.1057 45.5781 21.1057 45.8906C20.7752 45.8762 20.5095 46.0011 20.3088 46.2656C20.1795 46.6908 20.1327 47.1284 20.1682 47.5781C19.5151 47.4918 18.8901 47.3355 18.2932 47.1093C18.2494 45.0719 18.2963 43.0406 18.4338 41.0156C18.633 40.6581 18.9299 40.4238 19.3245 40.3125C20.2614 40.2656 21.1989 40.25 22.137 40.2656Z" fill="#63DBFB"/>
    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M22.1364 40.2656C28.6363 40.2656 35.1364 40.2656 41.6364 40.2656C41.0427 41.0468 40.3551 41.7344 39.5739 42.3281C39.4827 42.2825 39.3889 42.2356 39.2926 42.1875C38.2302 42.1718 37.1676 42.1563 36.1051 42.1406C34.9715 42.1268 33.8465 42.1737 32.7301 42.2812C32.4038 42.6329 32.2788 43.0548 32.3551 43.5469C32.2788 44.039 32.4038 44.4608 32.7301 44.8125C33.783 44.949 34.8455 44.9959 35.9176 44.9531C35.3224 45.3132 34.6974 45.6258 34.0426 45.8906C31.7924 45.875 29.5424 45.8906 27.2926 45.9375C27.0583 46.0469 26.8864 46.2188 26.777 46.4531C26.7302 46.9208 26.7146 47.3896 26.7301 47.8594C26.6371 47.8432 26.5747 47.8746 26.5426 47.9531C25.6677 47.9531 24.7926 47.9531 23.9176 47.9531C23.9332 47.4521 23.9175 46.9522 23.8708 46.4531C23.7613 46.2188 23.5895 46.0469 23.3551 45.9375C22.6058 45.8906 21.8558 45.8751 21.1051 45.8906C21.1051 45.5782 21.1051 45.2656 21.1051 44.9531C22.1368 44.9688 23.1681 44.9531 24.1989 44.9062C24.7976 44.6515 25.0164 44.1983 24.8551 43.5469C24.9876 42.9473 24.8001 42.4942 24.2926 42.1875C23.2306 42.1406 22.1681 42.125 21.1051 42.1406C21.0802 41.7558 21.1271 41.3808 21.2458 41.0156C21.5022 40.7092 21.799 40.4591 22.1364 40.2656Z" fill="#99E6FD"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1059 42.1406C22.1688 42.125 23.2314 42.1406 24.2934 42.1875C24.8008 42.4942 24.9883 42.9474 24.8559 43.5469C25.0171 44.1984 24.7983 44.6515 24.1996 44.9063C23.1688 44.9531 22.1376 44.9688 21.1059 44.9531C20.9771 44.9679 20.8521 44.9522 20.7309 44.9063C20.2363 44.572 20.0488 44.1032 20.1684 43.5C20.0111 42.7013 20.3237 42.2482 21.1059 42.1406Z" fill="#02D1FC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.293 42.1406C28.4809 42.125 29.6684 42.1406 30.8555 42.1875C31.0899 42.2969 31.2617 42.4688 31.3711 42.7031C31.4336 43.2656 31.4336 43.8281 31.3711 44.3906C31.2538 44.6607 31.0508 44.8326 30.7617 44.9063C29.5341 44.9964 28.3153 44.9651 27.1055 44.8125C26.9848 44.6803 26.8754 44.5397 26.7774 44.3906C26.7148 43.8281 26.7148 43.2656 26.7774 42.7031C26.9049 42.4666 27.0767 42.2791 27.293 42.1406Z" fill="#03D1FC"/>
    <path opacity="0.989" fill-rule="evenodd" clip-rule="evenodd" d="M39.5744 42.328C38.4625 43.3454 37.2437 44.2204 35.9182 44.953C34.8461 44.9958 33.7836 44.9489 32.7307 44.8124C32.4043 44.4608 32.2794 44.0389 32.3557 43.5468C32.2794 43.0547 32.4043 42.6328 32.7307 42.2812C33.8471 42.1736 34.9721 42.1268 36.1057 42.1405C37.1682 42.1562 38.2307 42.1718 39.2932 42.1874C39.3895 42.2355 39.4832 42.2824 39.5744 42.328Z" fill="#02D1FC"/>
    <path opacity="0.981" fill-rule="evenodd" clip-rule="evenodd" d="M21.1052 45.8906C21.8559 45.875 22.6059 45.8906 23.3552 45.9375C23.5896 46.0469 23.7615 46.2187 23.8709 46.4531C23.9176 46.9521 23.9333 47.4521 23.9177 47.9531C23.4178 47.9531 22.9177 47.9531 22.4177 47.9531C21.6777 47.8382 20.9277 47.7132 20.1677 47.5781C20.1322 47.1284 20.1791 46.6908 20.3084 46.2656C20.5091 46.0011 20.7748 45.8762 21.1052 45.8906Z" fill="#02D1FC"/>
    <path opacity="0.972" fill-rule="evenodd" clip-rule="evenodd" d="M34.0429 45.8906C31.7378 47.0022 29.3003 47.6585 26.7304 47.8594C26.7149 47.3896 26.7305 46.9209 26.7773 46.4531C26.8867 46.2188 27.0586 46.0469 27.2929 45.9375C29.5427 45.8906 31.7927 45.875 34.0429 45.8906Z" fill="#02D1FC"/>
    </g>
    <defs>
    <clipPath id="clip0_1_21680">
    <rect width="48" height="48" fill="white" transform="translate(0.527344)"/>
    </clipPath>
    </defs>
  </svg>`,
};
