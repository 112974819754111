export interface WalletHistoryColumn {
  id: number;
  label: WalletHistoryColumnLabel;
}

export enum WalletHistoryColumnLabel {
  dateTime = "Дата и время",
  type = "Тип операции",
  amount = "Сумма",
  currency = "Валюта",
}

export interface WalletHistoryRow {
  id: number;
  dateTime: string;
  type: {
    icon: string;
    label: string;
  };
  amount: string;
  currency: {
    icon: string;
    label: string;
    iconType: string;
  };
}
