import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('header-back')),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBack && _ctx.handleBack(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('header-icon')),
          innerHTML: _ctx.displayedChevronIcon
        }, null, 10, _hoisted_1)
      ], 2),
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.b('header-title'))
      }, _toDisplayString(_ctx.$t("Крипто реквизиты")), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form-field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('form-label'))
        }, _toDisplayString(_ctx.$t("Монета")) + "*", 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "model-value": _ctx.coin,
          "onUpdate:modelValue": _ctx.changeCoin
        }, null, 8, ["model-value", "onUpdate:modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form-field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('form-label'))
        }, _toDisplayString(_ctx.$t("Сеть")) + "*", 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "model-value": _ctx.network,
          "onUpdate:modelValue": _ctx.changeNetwork
        }, null, 8, ["model-value", "onUpdate:modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form-field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('form-label'))
        }, _toDisplayString(_ctx.$t("Адрес")) + "*", 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "model-value": _ctx.address,
          "onUpdate:modelValue": _ctx.changeAddress
        }, null, 8, ["model-value", "onUpdate:modelValue"])
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      class: _normalizeClass(_ctx.b('confirm')),
      label: "Подтвердить",
      view: "main",
      disabled: _ctx.isConfirmDisabled,
      onClick: _ctx.handleConfirm
    }, null, 8, ["class", "disabled", "onClick"])
  ], 2))
}