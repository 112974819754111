import { HistoryTab } from "@/views/history/model";

export interface HistoryHeaderTab {
  id: number;
  label: HistoryHeaderTabLabel;
  value: HistoryTab;
  acitve: boolean;
}

export enum HistoryHeaderTabLabel {
  conclusion = "Пополнение/Вывод",
  applications = "Заявки",
}
