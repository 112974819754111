<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('content')">
      <p :class="b('label')">
        {{ $t("На вашу почту отправлена ссылка для подтверждения e-mail!") }}
      </p>
    </div>
  </div>
</template>
