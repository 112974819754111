<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" @close="handleClose">
    <div :class="b('wrapper')">
      <h2 :class="b('title')">
        {{ $t("Изменение баланса") }}
      </h2>

      <div :class="b('form')">
        <div :class="b('group')">
          <div :class="b('field')">
            <p :class="b('field-label')">
              {{ $t("Кошелек") }}
            </p>

            <SharedSelect view="light" />
          </div>

          <div :class="b('field')">
            <p :class="b('field-label')">
              {{ $t("Сумма") }}
            </p>

            <UIInput view="light" />
          </div>
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Комментарий") }}
          </p>

          <UIInput
            view="light"
            placeholder-view="light"
            placeholder="Добавьте комментарий при необходимости"
          />
        </div>
      </div>

      <UIButton
        view="main"
        label="Изменить баланс пользователя"
        :disabled="isChangeBalanceDisabled"
        @click="handleChangeBalance"
      />
    </div>
  </SharedWindow>
</template>
