import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedSelect,
  SharedRadio,
} from "@/components/shared";
import { UIButton, UIInput } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import { SharedSelectOption } from "@/components/shared/select/model";
import {
  KybRegistrationInfoRequestFactory,
  KybRegistrationInfoResponseFactory,
} from "@/shared/factory/kyb";
import { SelectOptionFactory } from "@/shared/factory/select";
import {
  SharedSelectOptionLabel,
  SharedSelectOptionValue,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedSelect,
    SharedRadio,
    UIButton,
    UIInput,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      kybRegistrationInfo: KybRegistrationInfoResponseFactory(),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .getKybRegistrationInfo(this.$projectServices.userRepo.userInfo.id)
        .then((kybRegistrationInfo) => {
          this.kybRegistrationInfo =
            KybRegistrationInfoResponseFactory(kybRegistrationInfo);
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    displayedOrganizationalAndLegalFormOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: SharedSelectOptionLabel.russia,
            value: SharedSelectOptionValue.russia,
          },
          this.kybRegistrationInfo.organisation_legal_form
        ),
      ];
    },

    displayedCountryOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: SharedSelectOptionLabel.RF,
            value: SharedSelectOptionValue.RF,
          },
          this.kybRegistrationInfo.address.country
        ),
      ];
    },

    displayedCityOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: SharedSelectOptionLabel.moscow,
            value: SharedSelectOptionValue.moscow,
          },
          this.kybRegistrationInfo.address.city
        ),
      ];
    },

    isContinueDisabled(): boolean {
      return (
        !this.kybRegistrationInfo.organisation_legal_form ||
        !this.kybRegistrationInfo.business_area ||
        !this.kybRegistrationInfo.phone ||
        !this.kybRegistrationInfo.address.country ||
        !this.kybRegistrationInfo.address.city ||
        !this.kybRegistrationInfo.address.state ||
        !this.kybRegistrationInfo.address.office ||
        !this.kybRegistrationInfo.address.zip ||
        this.loading
      );
    },
  },
  methods: {
    handleBack(): void {
      this.$emit(
        "update:component",
        VerificationKybComponent.kybBasicInformation
      );
    },

    async handleContinue(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .kybRegistrationInfo(
            KybRegistrationInfoRequestFactory(this.kybRegistrationInfo)
          )
          .then(() => {
            this.$emit("update:component", VerificationKybComponent.kybCompany);
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
