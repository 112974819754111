<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Загрузка документов") }}
      </h3>
    </div>

    <div :class="b('passport-photo')">
      <div :class="b('passport')">
        <h5 :class="b('title')">
          {{ $t("Загрузка паспорта или ВУ*") }}
        </h5>

        <div :class="b('passport-uploads')">
          <SharedUpload
            :is-loading="loading"
            :file="passportSelfie.file"
            :link="passportSelfie.link"
            @upload="handleUploadFile($event, passportSelfie.name)"
            @remove="handleRemoveFile(passportSelfie.name)"
          >
            <template #label>
              {{ $t("Страница") }} <br />
              {{ $t("с фото") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-loading="loading"
            :file="passportOrganization.file"
            :link="passportOrganization.link"
            @upload="handleUploadFile($event, passportOrganization.name)"
            @remove="handleRemoveFile(passportOrganization.name)"
          >
            <template #label>
              {{ $t("Страница") }} <br />
              {{ $t("кем выдан") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :is-loading="loading"
            :file="passportAddress.file"
            :link="passportAddress.link"
            @upload="handleUploadFile($event, passportAddress.name)"
            @remove="handleRemoveFile(passportAddress.name)"
          >
            <template #label>
              {{ $t("Страница") }} <br />
              {{ $t("с пропиской") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('photo')">
        <h5 :class="b('title')">
          {{ $t("Загрузка селфи*") }}
        </h5>

        <SharedUpload
          :class="b('photo-upload')"
          :is-loading="loading"
          :isCenter="true"
          :file="selfie.file"
          :link="selfie.link"
          @upload="handleUploadFile($event, selfie.name)"
          @remove="handleRemoveFile(selfie.name)"
        />
      </div>
    </div>

    <div :class="b('agreements')">
      <h5 :class="b('title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('agreements-block')">
        <div :class="b('agreements-group', { kyc: true })">
          <SharedDownload
            :file="questionnaireFile.file"
            :name="questionnaireFile.name"
          >
            <template #label>
              {{ $t("Анкета") }} <br :class="b('agreements-maintenance')" />
              {{ $t("физического лица") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="questionnaire.file"
            :link="questionnaire.link"
            @upload="handleUploadFile($event, questionnaire.name)"
            @remove="handleRemoveFile(questionnaire.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('agreements-group', { personal: true })">
          <SharedDownload
            :file="personalDataFile.file"
            :name="personalDataFile.name"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="personalData.file"
            :link="personalData.link"
            @upload="handleUploadFile($event, personalData.name)"
            @remove="handleRemoveFile(personalData.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('agreements-group')">
          <SharedDownload
            :file="declarationFile.file"
            :name="declarationFile.name"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="declaration.file"
            :link="declaration.link"
            @upload="handleUploadFile($event, declaration.name)"
            @remove="handleRemoveFile(declaration.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('agreements-group')">
          <SharedDownload :file="brokerFile.file" :name="brokerFile.name">
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="broker.file"
            :link="broker.link"
            @upload="handleUploadFile($event, broker.name)"
            @remove="handleRemoveFile(broker.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>

    <div :class="b('other')">
      <h4 :class="b('other-title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h4>

      <p :class="b('other-label')">
        {{
          $t(
            "Например: Документ о происхождении денежных средств, Документ подтверждающий постановку на учет в налоговом органе и другие"
          )
        }}
      </p>

      <div :class="b('other-documents')">
        <SharedUpload
          :class="b('other-download')"
          :is-document="true"
          :is-loading="loading"
          :file="originOfFunds.file"
          :link="originOfFunds.link"
          @upload="handleUploadFile($event, originOfFunds.name)"
          @remove="handleRemoveFile(originOfFunds.name)"
        >
          <template #label>
            {{ $t("Документ о происхождении") }} <br />
            {{ $t("денежных средств") }}
          </template>
        </SharedUpload>

        <SharedUpload
          :class="b('other-download')"
          :is-document="true"
          :is-loading="loading"
          :file="confirmingRegistration.file"
          :link="confirmingRegistration.link"
          @upload="handleUploadFile($event, confirmingRegistration.name)"
          @remove="handleRemoveFile(confirmingRegistration.name)"
        >
          <template #label>
            {{ $t("Документ подтверждающий") }} <br />
            {{ $t("постановку на учет в налого...") }}
          </template>
        </SharedUpload>
      </div>
    </div>

    <UIButton
      :class="b('verification')"
      label="Отправить на проверку"
      view="main"
      :disabled="isVerificationDisabled"
      @click="handleVerification"
    />
  </SharedCard>
</template>
