import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { WalletTabsTab, WalletTabsTabLabel, WalletTabsTabValue } from "./model";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    tab: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:tab": null,
  },
  computed: {
    displayedTabs(): WalletTabsTab[] {
      return [
        {
          id: 0,
          label: WalletTabsTabLabel.fiat,
          value: WalletTabsTabValue.fiat,
          isActive: WalletTabsTabValue.fiat === this.tab,
        },
        {
          id: 1,
          label: WalletTabsTabLabel.crypto,
          value: WalletTabsTabValue.crypto,
          isActive: WalletTabsTabValue.crypto === this.tab,
        },
      ];
    },
  },
  methods: {
    handleTab({ value }: WalletTabsTab): void {
      this.$emit("update:tab", value);
    },
  },
});
