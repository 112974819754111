<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <div :class="b('header-group')">
        <h4 :class="b('header-title')">{{ $t("Почта") }}: {{ email }}</h4>

        <span :class="b('header-tag')">
          <h4 :class="b('header-title')">
            {{ $t(displayedType) }}
          </h4>
        </span>
      </div>

      <h4 :class="b('header-title')">ID: {{ id }}</h4>
    </div>

    <div :class="b('content')">
      <slot />
    </div>
  </div>
</template>
