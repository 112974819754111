import { createStore, Store } from "vuex";

import { ProjectStore } from "@/store/model";

import { Profile, Language, Notification, Modal, User } from "@/store/modules";

const store = (): Store<ProjectStore> => <Store<ProjectStore>>createStore({
    modules: {
      profile: Profile,
      language: Language,
      notification: Notification,
      modal: Modal,
      user: User,
    },
  });

export default store;
