import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    isApplicationDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isApplicationDetails": null,
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isApplicationDetails", !this.isApplicationDetails);
    },
  },
});
