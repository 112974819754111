<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <DefaultToolbar :class="b('toolbar')" />

    <DefaultContent />

    <DefaultNavbar :class="b('navbar')" />

    <transition name="opacity">
      <DefaultModal :class="b('modal')" v-if="isModal" />
    </transition>
  </div>
</template>
