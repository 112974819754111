<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <UIInput
        placeholder="Адрес электронной почты"
        view="light"
        :modelValue="email"
        @update:modelValue="changeEmail"
      />

      <div :class="b('group')">
        <SharedInputPassword
          placeholder="Пароль"
          view="light"
          :modelValue="password"
          :is-error="isPasswordError"
          @update:modelValue="changePassword"
        />

        <SharedError v-if="message" message="Неправильный пароль!" />
      </div>
    </div>

    <div :class="b('checkbox')">
      <SharedCheckbox
        view="28"
        :checked="isRemember"
        @update:checked="changeIsRemember"
      />

      <p :class="b('checkbox-label')">
        {{ $t("Запомнить меня") }}
      </p>
    </div>

    <div :class="b('actions')">
      <UIButton
        label="Вход в систему"
        view="main"
        :disabled="isLoginDisabled"
        @click="handleLogin"
      />

      <UILink label="Забыли пароль?" to="/forgot" view="main-text" />
    </div>
  </div>
</template>
