<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Участник" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Фамилия") }}
          </h4>

          <UIInput view="light" v-model="kybParticipant.sur_name" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Имя") }}
          </h4>

          <UIInput view="light" v-model="kybParticipant.name" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Отчество") }}
          </h4>

          <UIInput view="light" v-model="kybParticipant.patronymic" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Гражданство") }}
          </h4>

          <UIInput view="light" v-model="kybParticipant.citizenship" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Дата рождения") }}
          </h4>

          <SharedDate
            format="yyyy-MM-dd"
            v-model:date="kybParticipant.birth_date"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Пол") }}
          </h4>

          <div :class="b('field-radios')">
            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                checkedValue="male"
                v-model:value="kybParticipant.gender"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Мужчина") }}
              </p>
            </div>

            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                checkedValue="female"
                v-model:value="kybParticipant.gender"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Женщина") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div :class="b('address')">
        <h4 :class="b('field-label')">
          {{ $t("Адрес организации") }}
        </h4>

        <div :class="b('fields', { address: true })">
          <UIInput
            view="light"
            placeholder="Cтрана"
            v-model="kybParticipant.address.country"
          />

          <UIInput view="light" v-model="kybParticipant.address.city" />

          <UIInput
            view="light"
            placeholder="Штат / Область"
            v-model="kybParticipant.address.state"
          />

          <UIInput
            view="light"
            placeholder="Улица"
            v-model="kybParticipant.address.street"
          />

          <UIInput
            view="light"
            placeholder="Номер Офиса"
            v-model="kybParticipant.address.office"
          />

          <UIInput
            view="light"
            placeholder="Индекс"
            v-model="kybParticipant.address.zip"
          />
        </div>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Доля владения в обществе") }}
          </h4>

          <SharedInputDouble
            view="light"
            v-model="kybParticipant.share_in_org"
          />
        </div>

        <div :class="b('field')">
          <div :class="b('field-checkboxes')">
            <div :class="b('field-checkbox')">
              <SharedCheckbox
                view="22"
                v-model:checked="kybParticipant.is_beneficiary"
              />

              <p :class="b('field-checkbox-label')">
                {{ $t("Является Бенефициаром") }}
              </p>
            </div>

            <div :class="b('field-checkbox')">
              <SharedCheckbox
                view="22"
                v-model:checked="kybParticipant.is_director"
              />

              <p :class="b('field-checkbox-label')">
                {{ $t("Является директором") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
