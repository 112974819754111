import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton, UILink } from "@/components/ui";
import {
  SharedInputPassword,
  SharedOr,
  SharedGoogle,
  SharedPasswordValidation,
  SharedError,
} from "@/components/shared";

import { ValidationPassword } from "@/shared/constants/constants";
import {
  validationEmail,
  validationPassword,
  validationPasswordMatch,
} from "@/shared/utils/validation-helpers";

const EMAIL_MESSAGE = "Такое значение поля email адрес уже существует.";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    UILink,
    SharedInputPassword,
    SharedOr,
    SharedGoogle,
    SharedPasswordValidation,
    SharedError,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    password_confirmation: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:email": null,
    "update:password": null,
    "update:password_confirmation": null,
    registration: null,
    google: null,
  },
  computed: {
    displayedValidationPassword(): ValidationPassword {
      return validationPassword(this.password, this.password_confirmation);
    },

    isPasswordValidationShow(): boolean {
      return !!this.password && !this.displayedValidationPassword.isValidation;
    },

    isConfirmPasswordValidationShow(): boolean {
      return (
        !!this.password_confirmation && !this.displayedValidationPassword.match
      );
    },

    isPasswordError(): boolean {
      return !!this.password && !this.displayedValidationPassword.isValidation;
    },

    isConfirmPasswordError(): boolean {
      return (
        !!this.password_confirmation &&
        !validationPasswordMatch(this.password, this.password_confirmation)
      );
    },

    isRegistrationDisabled(): boolean {
      return (
        !validationEmail(this.email) ||
        !this.displayedValidationPassword.isValidation ||
        this.loading
      );
    },

    isErrorDisplayed(): boolean {
      return this.message === EMAIL_MESSAGE;
    },
  },
  methods: {
    changeEmail(email: string): void {
      this.$emit("update:email", email);
    },

    changePassword(password: string): void {
      this.$emit("update:password", password);
    },

    changeConfirmPassword(confirmPassword: boolean): void {
      this.$emit("update:password_confirmation", confirmPassword);
    },

    handleRegistration(): void {
      this.$emit("registration");
    },

    handleGoogle(): void {
      this.$emit("google");
    },
  },
});
