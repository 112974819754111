export interface AdminUsersTableColumn {
  id: number;
  label: AdminUsersTableColumnLabel;
  sort?: {
    key: string;
    isActive: boolean;
  };
}

export enum AdminUsersTableColumnLabel {
  id = "<span>ID</span>",
  email = "<span>почта</span>",
  kyc = "<span>статус KYC</span>",
  fio = "<span>ФИО</span>",
  balance = "<span>Баланс</span>",
  application = "<span>Кол-во <br /> акт. заявок</span>",
}

export enum AdminUsersTableColumnSortKey {
  id = "id",
  email = "email",
  kyc = "kyc",
  name = "name",
  balance = "balance",
  application = "application",
}

export interface AdminUsersTableOption {
  id: number;
  label: AdminUsersTableOptionLabel;
  value: AdminUsersTableOptionValue;
}

export enum AdminUsersTableOptionLabel {
  kyc = "Посмотреть КУС",
  status = "Изменить статус КУС",
  admin = "Сделать администратором",
  balance = "Изменить баланс",
  remove = "Удалить аккаунт",
}

export enum AdminUsersTableOptionValue {
  kyc = "kyc",
  status = "status",
  admin = "admin",
  balance = "balance",
  remove = "remove",
}

export interface AdminUsersTableRowStatus {
  icon: string;
  label: string;
}
