import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { SharedLanguageOption, SharedLanguageOptionLabel } from "./model";

import { Language, SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    view: {
      type: String,
      default: "",
    },
    isChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      languageRepo: this.$projectServices.languageRepo,
    };
  },
  computed: {
    displayedActiveClass(): string {
      return this.isVisible
        ? `${COMPONENT_NAME}__toggle--${this.view}-active`
        : "";
    },

    displayedLanguage(): SharedLanguageOptionLabel {
      switch (this.languageRepo.language) {
        case Language["ru-RU"]:
          return SharedLanguageOptionLabel["ru-RU"];
        case Language["en-US"]:
          return SharedLanguageOptionLabel["en-EN"];
        default:
          return SharedLanguageOptionLabel["ru-RU"];
      }
    },

    displayedLanguages(): SharedLanguageOption[] {
      return [
        {
          id: 0,
          label: SharedLanguageOptionLabel["ru-RU"],
          value: Language["ru-RU"],
          isActive: this.languageRepo.language === Language["ru-RU"],
        },
        {
          id: 1,
          label: SharedLanguageOptionLabel["en-EN"],
          value: Language["en-US"],
          isActive: this.languageRepo.language === Language["en-US"],
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleOption({ value }: SharedLanguageOption): void {
      this.languageRepo.updateLanguage(value);

      this.$i18n.locale = value;
    },
  },
});
