<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Участник") }}
      </h3>
    </div>

    <div :class="b('form')">
      <div :class="b('form-upload')">
        <div :class="b('form-passport')">
          <h5 :class="b('form-title')">
            {{ $t("Загрузка паспорта или водительских прав*") }}
          </h5>

          <div :class="b('form-uploads')">
            <SharedUpload
              :is-loading="loading"
              :file="passportSelfie.file"
              :link="passportSelfie.link"
              @upload="handleUploadFile($event, passportSelfie.name)"
              @remove="handleRemoveFile(passportSelfie.name)"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с фото") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :is-loading="loading"
              :file="passportOrganization.file"
              :link="passportOrganization.link"
              @upload="handleUploadFile($event, passportOrganization.name)"
              @remove="handleRemoveFile(passportOrganization.name)"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("кем выдан") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :is-loading="loading"
              :file="passportAddress.file"
              :link="passportAddress.link"
              @upload="handleUploadFile($event, passportAddress.name)"
              @remove="handleRemoveFile(passportAddress.name)"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с пропиской") }}
              </template>
            </SharedUpload>
          </div>
        </div>

        <div :class="b('form-photo')">
          <h5 :class="b('form-title')">
            {{ $t("Загрузка селфи*") }}
          </h5>

          <SharedUpload
            :class="b('form-photo-upload')"
            :is-loading="loading"
            :isCenter="true"
            :file="selfie.file"
            :link="selfie.link"
            @upload="handleUploadFile($event, selfie.name)"
            @remove="handleRemoveFile(selfie.name)"
          />
        </div>
      </div>
    </div>

    <div :class="b('uploads')">
      <h5 :class="b('form-title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('uploads-groups')">
        <div :class="b('uploads-group')">
          <SharedDownload
            :file="questionnaireFile.file"
            :name="questionnaireFile.name"
          >
            <template #label>
              {{ $t("Анкета физического лица") }} <br />
              {{ $t("(подписанная учредителем)") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="questionnaire.file"
            :link="questionnaire.link"
            @upload="handleUploadFile($event, questionnaire.name)"
            @remove="handleRemoveFile(questionnaire.name)"
          >
            <template #label>
              {{ $t("Подписанный") }} <br />
              {{ $t("документ") }}
            </template>
          </SharedUpload>
        </div>

        <div :class="b('uploads-group')">
          <SharedDownload
            :file="personalDataFile.file"
            :name="personalDataFile.name"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedDownload>

          <SharedUpload
            :is-document="true"
            :is-loading="loading"
            :file="personalData.file"
            :link="personalData.link"
            @upload="handleUploadFile($event, personalData.name)"
            @remove="handleRemoveFile(personalData.name)"
          >
            <template #label>
              {{ $t("Подписанное") }} <br />
              {{ $t("соглашение") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>

    <div :class="b('other')">
      <h5 :class="b('form-title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h5>

      <p :class="b('form-label')">
        {{ $t("Например: Приказ о назначении директора") }}
      </p>

      <SharedUpload
        :class="b('other-upload')"
        :is-document="true"
        :is-loading="loading"
        :file="director.file"
        :link="director.link"
        @upload="handleUploadFile($event, director.name)"
        @remove="handleRemoveFile(director.name)"
      >
        <template #label>
          {{ $t("Приказ о назначении") }} <br />
          {{ $t("директора") }}
        </template>
      </SharedUpload>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
