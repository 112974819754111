<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("История") }}
    </h1>

    <div :class="b('wrapper')">
      <HistoryHeader
        v-model:tab="tab"
        v-model:isFilter="isFilter"
        v-model:filter="filter"
      />

      <HistoryTable :class="b('table')" />

      <SharedButtonIcon
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedMoreIcon"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <HistoryRemove
        :class="b('modal')"
        v-if="isRemove"
        v-model:isRemove="isRemove"
      />
    </transition>

    <transition name="opacity">
      <HistoryDetails
        :class="b('modal')"
        v-if="isDetails"
        v-model:isDetails="isDetails"
      />
    </transition>

    <transition name="opacity">
      <HistoryApplicationsDetails
        :class="b('modal')"
        v-if="isApplicationDetails"
        v-model:isApplicationDetails="isApplicationDetails"
      />
    </transition>

    <transition name="opacity">
      <HistoryFilters
        :class="b('modal')"
        v-if="isFilter"
        v-model:isFilter="isFilter"
        v-model:currency="filter.currency"
        v-model:date="filter.date"
        v-model:volumeFrom="filter.volume.from"
        v-model:volumeTo="filter.volume.to"
        v-model:status="filter.status"
        v-model:type="filter.type"
      />
    </transition>
  </div>
</template>
