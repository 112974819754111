import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";

import { validationEmail } from "@/shared/utils/validation-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:email": null,
    next: null,
  },
  computed: {
    isNextDisabled(): boolean {
      return !validationEmail(this.email) || this.loading;
    },
  },
  methods: {
    changeEmail(email: string): void {
      this.$emit("update:email", email);
    },

    handleNext(): void {
      this.$emit("next");
    },
  },
});
