import {
  KycPersonalInfoRequest,
  KycPersonalInfoResponse,
  KycSystemFileResponse,
  KycUploadFileRequest,
  KycUploadFileResponse,
} from "@/shared/repository/repo";
import { FileType } from "@/shared/constants/constants";
import {
  extractFileName,
  uniqueFileName,
} from "@/shared/utils/file-name-helpers";

export const KycPersonalInfoRequestFactory = (
  params: Partial<KycPersonalInfoResponse> = {}
): KycPersonalInfoRequest => {
  return {
    sur_name: params.sur_name ? params.sur_name : "",
    name: params.name ? params.name : "",
    patronymic: params.patronymic ? params.patronymic : "",
    gender: params.gender ? params.gender : "",
    birth_date: params.birth_date ? params.birth_date : "",
    citizenship: params.citizenship ? params.citizenship : "",
    passport: params.passport ? params.passport : "",
    passport_date: params.passport_date ? params.passport_date : "",
    passport_issued_org: params.passport_issued_org
      ? params.passport_issued_org
      : "",
    telegram: params.telegram ? params.telegram : "",
    phone: params.phone ? params.phone : "",
    country: params.address ? params.address.country : "",
    city: params.address ? params.address.city : "",
    state: params.address ? params.address.state : "",
    street: params.address ? params.address.street : "",
    office: params.address ? params.address.office : "",
    zip: params.address ? params.address.zip : "",
  };
};

export const KycPersonalInfoResponseFactory = (
  params: Partial<KycPersonalInfoResponse> = {}
): KycPersonalInfoResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    sur_name: params.sur_name ? params.sur_name : "",
    name: params.name ? params.name : "",
    patronymic: params.patronymic ? params.patronymic : "",
    gender: params.gender ? params.gender : "",
    birth_date: params.birth_date ? params.birth_date : "",
    citizenship: params.citizenship ? params.citizenship : "",
    passport: params.passport ? params.passport : "",
    passport_date: params.passport_date ? params.passport_date : "",
    passport_issued_org: params.passport_issued_org
      ? params.passport_issued_org
      : "",
    telegram: params.telegram ? params.telegram : "",
    status: params.status ? params.status : "",
    phone: params.phone ? params.phone : "",
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
    deleted_at: params.deleted_at ? params.deleted_at : null,
    address: {
      id: params.address ? params.address.id : null,
      user_app_id: params.address ? params.address.user_app_id : null,
      country: params.address ? params.address.country : "",
      city: params.address ? params.address.city : "",
      state: params.address ? params.address.state : "",
      street: params.address ? params.address.street : "",
      office: params.address ? params.address.office : "",
      zip: params.address ? params.address.zip : "",
      country_actual: params.address ? params.address.country_actual : null,
      city_actual: params.address ? params.address.city_actual : null,
      state_actual: params.address ? params.address.state_actual : null,
      street_actual: params.address ? params.address.street_actual : null,
      office_actual: params.address ? params.address.office_actual : null,
      zip_actual: params.address ? params.address.zip_actual : null,
      creatd_at: params.address ? params.address.creatd_at : "",
      updated_at: params.address ? params.address.updated_at : "",
    },
  };
};

export const KycUploadFileRequestFactory = (
  params: Partial<KycUploadFileResponse> = {}
): KycUploadFileRequest => {
  return {
    file: params.file ? params.file : "",
    name: params.name ? `${uniqueFileName(params.name)}` : "",
    type: params.type ? params.type : FileType.image,
  };
};

export const KycUploadFileResponseFactory = (
  params: Partial<KycUploadFileResponse> = {}
): KycUploadFileResponse => {
  return {
    id: params.id ? params.id : null,
    user_id: params.user_id ? params.user_id : null,
    name: params.name ? extractFileName(params.name) : "",
    generated_name: params.generated_name ? params.generated_name : "",
    file: params.file ? params.file : "",
    link: params.link ? params.link : "",
    type: params.type ? params.type : FileType.image,
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};

export const KycSystemFileResponseFactory = (
  params: Partial<KycSystemFileResponse> = {}
): KycSystemFileResponse => {
  return {
    id: params.id ? params.id : null,
    file: params.file ? params.file : "",
    name: params.name ? params.name : "",
    type: params.type ? params.type : "",
    creatd_at: params.creatd_at ? params.creatd_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
  };
};
