import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
  },
  props: {
    coin: {
      type: String,
      default: "",
    },
    network: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:coin": null,
    "update:network": null,
    "update:address": null,
    back: null,
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    isConfirmDisabled(): boolean {
      return !this.coin || !this.network || !this.address;
    },
  },
  methods: {
    changeCoin(coin: string): void {
      this.$emit("update:coin", coin);
    },

    changeNetwork(network: string): void {
      this.$emit("update:network", network);
    },

    changeAddress(address: string): void {
      this.$emit("update:address", address);
    },

    handleBack(): void {
      this.$emit("back");
    },

    handleConfirm(): void {
      console.log("Confirm");
    },
  },
});
