<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Выбрерите шаблон реквизитов") }}
        </p>

        <SharedSelect view="light" />
      </div>

      <div :class="b('fields-group')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Монета") }}
          </p>

          <SharedSelect view="light" />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Сумма") }}
          </p>

          <UIInput view="light" />
        </div>
      </div>
    </div>

    <UIButton label="Создать заявку" view="main" />
  </div>
</template>
