import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    view: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    checkedValue: {
      type: [String, Boolean],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:value": null,
  },
  computed: {
    isChecked(): boolean {
      return this.value === this.checkedValue;
    },
  },
  methods: {
    handleRadio(): void {
      this.$emit("update:value", this.checkedValue);
    },
  },
});
