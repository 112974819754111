import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(_ctx.b('tab', { active: tab.isActive })),
        key: tab.id,
        onClick: ($event: any) => (_ctx.changeTab(tab))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('tab-label'))
        }, _toDisplayString(_ctx.$t(tab.label)), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('tab-label', { small: true }))
        }, _toDisplayString(_ctx.$t(tab.labelSmall)), 3)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}