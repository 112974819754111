import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-input-password";

export const SVG: SvgAttribute = {
  password: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M8.52563 2.19725C10.4444 2.13964 12.167 2.68456 13.6936 3.83201C14.7795 4.67147 15.676 5.67343 16.3831 6.83787C16.8778 7.83114 17.0185 8.87409 16.8049 9.96678C16.6501 10.6747 16.3454 11.3075 15.8909 11.8652C14.8574 13.3092 13.5391 14.4049 11.9358 15.1523C8.91984 16.2741 6.18936 15.7936 3.74438 13.7109C2.76702 12.8513 1.976 11.8494 1.37133 10.7051C0.78658 9.05792 1.00924 7.53449 2.0393 6.13475C2.99046 4.7967 4.19164 3.74785 5.64282 2.98826C6.55994 2.54814 7.5209 2.28447 8.52563 2.19725ZM8.56079 3.3574C10.1258 3.30689 11.5438 3.73463 12.8147 4.64061C13.9257 5.45243 14.8163 6.45439 15.4866 7.64646C15.7648 8.40658 15.8059 9.18002 15.6096 9.96678C15.549 10.1465 15.4787 10.3223 15.3987 10.4941C14.4508 12.0632 13.1559 13.2526 11.5139 14.0625C9.25882 14.9678 7.1377 14.7217 5.15063 13.3242C4.05228 12.5192 3.16751 11.5289 2.49633 10.3535C2.23294 9.68314 2.16262 8.99172 2.2854 8.27928C2.34422 7.99732 2.43798 7.72778 2.56665 7.47068C3.51454 5.90162 4.80948 4.71218 6.45141 3.90232C7.13095 3.61081 7.83407 3.42917 8.56079 3.3574Z" fill="#9E9FA8"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M8.70116 5.71289C10.4203 5.69285 11.5628 6.48974 12.1289 8.10352C12.4694 9.68464 11.9713 10.921 10.6348 11.8125C9.29246 12.4884 8.02683 12.3712 6.83788 11.4609C5.82317 10.4903 5.50089 9.32428 5.87109 7.96289C6.37502 6.65585 7.3184 5.90583 8.70116 5.71289ZM8.77148 6.87305C7.61136 7.0959 6.97854 7.79903 6.87304 8.98242C7.00196 10.2598 7.70508 10.9629 8.98241 11.0918C10.2597 10.9629 10.9629 10.2598 11.0918 8.98242C10.9331 7.605 10.1596 6.90188 8.77148 6.87305Z" fill="#9E9FA8"/>
  </svg>`,
  text: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_10991)">
    <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M16.3653 0.896652C16.7844 0.883008 17.0187 1.08223 17.0684 1.49431C17.0519 1.61997 17.0109 1.73715 16.9453 1.84587C11.9121 6.87909 6.87895 11.9123 1.84573 16.9455C1.46997 17.156 1.17114 17.0798 0.94925 16.717C0.870433 16.5068 0.893872 16.3076 1.01956 16.1193C1.87552 15.2692 2.72513 14.4137 3.56839 13.5529C2.84426 12.8817 2.21731 12.1317 1.68753 11.3029C1.10417 10.2696 0.928384 9.16807 1.16019 7.99821C1.25805 7.52139 1.43383 7.07607 1.68753 6.66228C2.59895 5.25866 3.76495 4.11609 5.18558 3.23454C7.74604 1.84303 10.3007 1.85475 12.8496 3.2697C13.0547 3.40446 13.2598 3.53924 13.4649 3.674C14.3497 2.78923 15.2344 1.90447 16.1192 1.0197C16.2044 0.977068 16.2865 0.936052 16.3653 0.896652ZM8.56058 3.35759C10.0398 3.29612 11.3875 3.68282 12.6035 4.51775C12.0357 5.1032 11.4615 5.68328 10.8809 6.25798C10.8457 6.28143 10.8106 6.28143 10.7754 6.25798C9.64947 5.57391 8.50102 5.53875 7.33011 6.15251C6.10171 6.95137 5.58024 8.08224 5.76566 9.54509C5.84043 9.99231 6.00451 10.4024 6.25784 10.7756C6.28129 10.8107 6.28129 10.8459 6.25784 10.881C5.64799 11.4968 5.03275 12.1061 4.41214 12.7092C3.58467 11.9933 2.92256 11.1495 2.42581 10.1779C2.02934 8.97999 2.20513 7.87844 2.95316 6.87321C3.72997 5.79156 4.68506 4.90678 5.81839 4.21892C6.67814 3.7428 7.5922 3.45568 8.56058 3.35759ZM8.77152 6.87321C9.17666 6.8587 9.56337 6.93488 9.93167 7.10173C9.96511 7.12697 9.97094 7.15626 9.94925 7.18962C9.02932 8.10955 8.10942 9.02945 7.18948 9.94939C7.16603 9.97284 7.14262 9.97284 7.11917 9.94939C6.7333 9.1178 6.82119 8.33849 7.38284 7.6115C7.76671 7.21159 8.22962 6.9655 8.77152 6.87321Z" fill="#9E9FA8"/>
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M14.8184 5.36129C15.1374 5.3069 15.3893 5.41237 15.5742 5.6777C15.8086 6.00581 16.043 6.33396 16.2774 6.66207C16.8607 7.69535 17.0365 8.7969 16.8047 9.96676C16.7068 10.4436 16.5311 10.8889 16.2774 11.3027C15.2735 12.8692 13.9552 14.0938 12.3223 14.9765C10.2218 15.9755 8.10073 16.0223 5.95901 15.1171C5.64629 14.8109 5.63455 14.4945 5.92385 14.1679C6.07464 14.0668 6.23871 14.0316 6.41604 14.0625C8.5829 14.9376 10.6454 14.7383 12.6035 13.4648C13.7054 12.6911 14.6137 11.736 15.3281 10.5996C15.7692 9.73195 15.8512 8.82963 15.5742 7.89254C15.3137 7.31257 14.9739 6.78522 14.5547 6.31051C14.3423 5.90579 14.4302 5.58938 14.8184 5.36129Z" fill="#9E9FA8"/>
    <path opacity="0.955" fill-rule="evenodd" clip-rule="evenodd" d="M11.4434 8.94734C12.0979 8.94158 12.3264 9.25798 12.1289 9.89656C11.7996 10.9251 11.1375 11.6458 10.1426 12.0587C9.83186 12.2079 9.51545 12.2314 9.19335 12.129C8.83423 11.8115 8.82249 11.4833 9.1582 11.1446C10.2417 10.9048 10.9038 10.2427 11.1445 9.15828C11.2262 9.0565 11.3258 8.98619 11.4434 8.94734Z" fill="#9E9FA8"/>
    </g>
    <defs>
    <clipPath id="clip0_1_10991">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
