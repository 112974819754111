import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedMultiSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    values: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
    options: {
      type: <PropType<SharedMultiSelectOption[]>>Array,
      default: () => [],
    },
  },
  emits: {
    "update:values": null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    displayedValues(): SharedMultiSelectOption[] {
      return this.values.map((value) => ({
        ...value,
        icon: SVG[value.icon ? value.icon : ""],
      }));
    },

    displayedCounter(): number {
      return this.values.length;
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    isLabelShow(): boolean {
      return !this.values.length;
    },

    isCounterShow(): boolean {
      return !!this.values.length;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleRemoveValue(value: SharedMultiSelectOption): void {
      this.handleRemoveOption(value);
    },

    handleRemoveOption(option: SharedMultiSelectOption): void {
      this.$emit(
        "update:values",
        this.values.filter((value) => value.id !== option.id)
      );
    },

    handleOption(option: SharedMultiSelectOption): void {
      const hasOption = this.values.find((value) => value.id === option.id);

      if (hasOption) {
        this.handleRemoveOption(option);
      } else {
        this.$emit("update:values", [...this.values, option]);
      }
    },
  },
});
