import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminCommissionTabValue } from "./model";

import {
  AdminCommissionTabs,
  AdminCommissionMain,
  AdminCommissionRequisites,
} from "@/components/admin-commission";

type Component = typeof AdminCommissionMain | typeof AdminCommissionRequisites;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminCommissionTabs,
    AdminCommissionMain,
    AdminCommissionRequisites,
  },
  data() {
    return {
      tab: AdminCommissionTabValue.main,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case AdminCommissionTabValue.main:
          return AdminCommissionMain;
        case AdminCommissionTabValue.requisites:
          return AdminCommissionRequisites;
        default:
          return AdminCommissionMain;
      }
    },
  },
});
