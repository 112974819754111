import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_RequisitesVerification = _resolveComponent("RequisitesVerification")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, { onClick: _ctx.handleBack }, null, 8, ["onClick"]),
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('header-title'))
        }, _toDisplayString(_ctx.$t("Крипто реквизиты")), 3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Название*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.requisiteCrypto.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requisiteCrypto.name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Монета*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.requisiteCrypto.coin,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.requisiteCrypto.coin) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Сеть*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.requisiteCrypto.network,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.requisiteCrypto.network) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Адрес*")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.requisiteCrypto.address,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.requisiteCrypto.address) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('confirm')),
        label: "Подтвердить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"]),
      _createVNode(_Transition, { name: "opacity" }, {
        default: _withCtx(() => [
          (_ctx.isVerification)
            ? (_openBlock(), _createBlock(_component_RequisitesVerification, {
                key: 0,
                class: _normalizeClass(_ctx.b('modal')),
                onClose: _ctx.handleVerificationClose
              }, null, 8, ["class", "onClose"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}