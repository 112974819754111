import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdminHeader = _resolveComponent("SharedAdminHeader")!
  const _component_SharedSearch = _resolveComponent("SharedSearch")!
  const _component_AdminApplicationTable = _resolveComponent("AdminApplicationTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAdminHeader, {
      title: "Заявки",
      email: _ctx.email,
      "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      id: _ctx.id,
      "onUpdate:id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.id) = $event)),
      onSearch: _ctx.handleSearch
    }, null, 8, ["email", "id", "onSearch"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_SharedSearch, {
        search: _ctx.search,
        "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["search"]),
      _createVNode(_component_AdminApplicationTable, {
        class: _normalizeClass(_ctx.b('table'))
      }, null, 8, ["class"]),
      _createVNode(_component_SharedButtonIcon, {
        class: _normalizeClass(_ctx.b('more')),
        view: "gray-outline",
        onClick: _ctx.handleMore
      }, {
        "icon-left": _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('more-icon')),
            innerHTML: _ctx.displayedIcons.more
          }, null, 10, _hoisted_1)
        ]),
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
        ]),
        _: 1
      }, 8, ["class", "onClick"])
    ], 2)
  ], 2))
}