import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { HistoryHeaderTab, HistoryHeaderTabLabel } from "./model";

import { SharedSearch } from "@/components/shared";

import { HistoryTab } from "@/views/history/model";

import { HistoryFilter, SvgAttribute } from "@/shared/constants/constants";
import { FilterFactory } from "@/shared/factory/history";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSearch,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: <PropType<HistoryFilter>>Object,
      required: true,
    },
  },
  emits: {
    "update:tab": null,
    "update:isFilter": null,
    "update:filter": null,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    displayedTabs(): HistoryHeaderTab[] {
      return [
        {
          id: 0,
          label: HistoryHeaderTabLabel.conclusion,
          value: HistoryTab.conclusion,
          acitve: this.tab === HistoryTab.conclusion,
        },
        {
          id: 1,
          label: HistoryHeaderTabLabel.applications,
          value: HistoryTab.applications,
          acitve: this.tab === HistoryTab.applications,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    hasFilter(): boolean {
      return Object.values(this.filter).reduce((accumulator, value) => {
        if (!accumulator && (value.length || value.from || value.to)) {
          accumulator = true;
        }

        return accumulator;
      }, false);
    },

    isFilterActive(): boolean {
      return this.isFilter || this.hasFilter;
    },
  },
  methods: {
    handleTab({ value }: HistoryHeaderTab): void {
      this.$emit("update:tab", value);
    },

    handleFilter(): void {
      this.$emit("update:isFilter", !this.isFilter);
    },

    handleClearFilter(): void {
      this.$emit("update:filter", FilterFactory());
    },

    handleDownload(): void {
      console.log("Handle Download");
    },
  },
});
