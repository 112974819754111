import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  emits: {
    next: null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleNext(): void {
      this.$emit("next");
    },
  },
});
