import { UserInfoResponse } from "../repository/repo";

export const UserInfoFactory = (
  params: Partial<UserInfoResponse> = {}
): UserInfoResponse => {
  return {
    id: params.id ? params.id : 0,
    name: params.name ? params.name : "",
    email: params.email ? params.email : "",
    email_verified_at: params.email_verified_at ? params.email_verified_at : "",
    two_factor_secret: params.two_factor_secret ? params.two_factor_secret : "",
    two_factor_recovery_codes: params.two_factor_recovery_codes
      ? params.two_factor_recovery_codes
      : "",
    created_at: params.created_at ? params.created_at : "",
    updated_at: params.updated_at ? params.updated_at : "",
    user_info: {
      id: params.user_info ? params.user_info.id : 0,
      user_id: params.user_info ? params.user_info.user_id : 0,
      lang: params.user_info ? params.user_info.lang : "",
      application_type: params.user_info
        ? params.user_info.application_type
        : "",
      created_at: params.user_info ? params.user_info.created_at : "",
      updated_at: params.user_info ? params.user_info.updated_at : "",
    },
  };
};
