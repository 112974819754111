import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { ApplicationComponent } from "./model";

import {
  ApplicationForm,
  ApplicationCrypto,
  ApplicationConfirm,
} from "@/components/application";

import { ApplicationRequestFactory } from "@/shared/factory/application";

type Component =
  | typeof ApplicationForm
  | typeof ApplicationCrypto
  | typeof ApplicationConfirm;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    ApplicationForm,
    ApplicationCrypto,
    ApplicationConfirm,
  },
  data() {
    return {
      application: ApplicationRequestFactory(),
      component: ApplicationComponent.form,
      isConfirm: false,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.component) {
        case ApplicationComponent.form:
          return ApplicationForm;
        case ApplicationComponent.crypto:
          return ApplicationCrypto;
        default:
          return ApplicationForm;
      }
    },
  },
  methods: {
    handleNext(): void {
      console.log("Handle Next");
    },

    handleCloseConfirm(): void {
      this.isConfirm = false;
    },

    handleConfirm(): void {
      this.component = ApplicationComponent.form;
    },

    handleBack(): void {
      this.component = ApplicationComponent.form;
    },
  },
});
