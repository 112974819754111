import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

enum Visibility {
  hide = "password",
  visible = "text",
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:modelValue": null,
  },
  data() {
    return {
      visibility: Visibility.hide,
    };
  },
  computed: {
    displayedIcon(): string {
      return SVG[this.visibility];
    },
  },
  methods: {
    changeModelValue(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      this.$emit("update:modelValue", value);
    },

    changeVisibility(): void {
      switch (this.visibility) {
        case Visibility.hide:
          this.visibility = Visibility.visible;

          break;
        case Visibility.visible:
          this.visibility = Visibility.hide;

          break;
        default:
          break;
      }
    },
  },
});
