import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedDate,
  SharedRadio,
  SharedCountry,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import {
  KybBasicInfoRequestFactory,
  KybBasicInfoResponseFactory,
} from "@/shared/factory/kyb";
import { VerificationKybComponent } from "@/views/verification/model";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedDate,
    SharedRadio,
    SharedCountry,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      kybBasicInfo: KybBasicInfoResponseFactory(),
      loading: false,
    };
  },
  async created(): Promise<void> {
    try {
      this.loading = true;

      await this.$projectServices.projectRepository
        .getKybBasicInfo(this.$projectServices.userRepo.userInfo.id)
        .then((kybBasicInfo) => {
          this.kybBasicInfo = KybBasicInfoResponseFactory(kybBasicInfo);
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    isContinueDisabled(): boolean {
      const {
        country,
        organisation,
        regional_number,
        registration_date,
        inn,
        telegram,
      } = this.kybBasicInfo;

      return (
        !country ||
        !organisation ||
        !regional_number ||
        !registration_date ||
        !inn ||
        !telegram ||
        this.loading
      );
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    async handleContinue(): Promise<void> {
      try {
        this.loading = true;

        await this.$projectServices.projectRepository
          .kybBasicInfo(KybBasicInfoRequestFactory(this.kybBasicInfo))
          .then(() => {
            this.$emit(
              "update:component",
              VerificationKybComponent.kybRegistrationInformation
            );
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
