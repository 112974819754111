import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-date";

export enum SharedDateFormat {
  date = "dd.MM.yyyy",
  dateReverse = "yyyy-MM-dd",
}

export const SVG: SvgAttribute = {
  icon: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" stroke="none">
    <path opacity="0.984" fill-rule="evenodd" clip-rule="evenodd" d="M12.48 1.45897C12.0623 1.42453 11.7752 1.60031 11.6186 1.98632C11.6011 2.31423 11.5952 2.64236 11.6011 2.97069C10.1011 2.97069 8.60108 2.97069 7.10107 2.97069C7.10691 2.64236 7.10107 2.31423 7.08349 1.98632C6.88482 1.545 6.55084 1.38679 6.08154 1.51171C5.83798 1.60289 5.67977 1.77281 5.60693 2.02147C5.58935 2.33766 5.58352 2.65407 5.58935 2.97069C3.96896 3.07341 2.97872 3.91717 2.61865 5.50194C2.5952 8.31444 2.5952 11.1269 2.61865 13.9394C2.92605 15.4304 3.83424 16.28 5.34326 16.4883C8.01513 16.5117 10.687 16.5117 13.3589 16.4883C14.8679 16.28 15.7761 15.4304 16.0835 13.9394C16.1069 11.1269 16.1069 8.31444 16.0835 5.50194C15.7234 3.91717 14.7332 3.07341 13.1128 2.97069C13.1277 2.61633 13.1101 2.26477 13.0601 1.91601C12.9402 1.66616 12.7468 1.51382 12.48 1.45897ZM11.6011 4.48241C10.1011 4.48241 8.60108 4.48241 7.10107 4.48241C7.11605 4.84846 7.09847 5.21176 7.04834 5.57226C6.78688 5.97968 6.42945 6.09099 5.97607 5.90624C5.78703 5.79926 5.66399 5.64106 5.60693 5.43163C5.58935 5.12718 5.58352 4.82248 5.58935 4.51757C4.76648 4.57283 4.28013 5.00642 4.13037 5.81835C4.11279 6.369 4.10692 6.91979 4.11279 7.47069C7.60497 7.47069 11.0972 7.47069 14.5894 7.47069C14.5952 6.91979 14.5894 6.369 14.5718 5.81835C14.422 5.00642 13.9357 4.57283 13.1128 4.51757C13.1186 4.82248 13.1128 5.12718 13.0952 5.43163C12.8563 5.9801 12.4637 6.1266 11.9175 5.87108C11.7784 5.76695 11.6788 5.63216 11.6186 5.46679C11.6011 5.13888 11.5952 4.81074 11.6011 4.48241ZM14.5894 8.98241C11.0972 8.98241 7.60497 8.98241 4.11279 8.98241C4.10692 10.5411 4.11279 12.0996 4.13037 13.6582C4.26044 14.3467 4.66474 14.7744 5.34326 14.9414C8.01513 14.9648 10.687 14.9648 13.3589 14.9414C14.0374 14.7744 14.4417 14.3467 14.5718 13.6582C14.5894 12.0996 14.5952 10.5411 14.5894 8.98241Z"/>
  </svg>`,
};
