import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { ApplicationConfirmComponent, ApplicationConfirmTitle } from "./model";

import { SharedWindow } from "@/components/shared";
import { ApplicationConfirmResult } from "./result";
import { ApplicationConfirmEmail } from "./email";

type Component =
  | typeof ApplicationConfirmResult
  | typeof ApplicationConfirmEmail;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    ApplicationConfirmResult,
    ApplicationConfirmEmail,
  },
  emits: {
    close: null,
  },
  data() {
    return {
      component: ApplicationConfirmComponent.email,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.component) {
        case ApplicationConfirmComponent.result:
          return ApplicationConfirmResult;
        case ApplicationConfirmComponent.email:
          return ApplicationConfirmEmail;
        default:
          return ApplicationConfirmEmail;
      }
    },

    displayedTitle(): ApplicationConfirmTitle {
      switch (this.component) {
        case ApplicationConfirmComponent.result:
          return ApplicationConfirmTitle.result;
        case ApplicationConfirmComponent.email:
          return ApplicationConfirmTitle.email;
        default:
          return ApplicationConfirmTitle.result;
      }
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("close");
    },

    handleCreate(): void {
      this.component = ApplicationConfirmComponent.email;
    },
  },
});
