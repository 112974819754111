import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedVerification = _resolveComponent("SharedVerification")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.$t("Код-подтверждение отправлен на email")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createVNode(_component_SharedVerification, {
          class: _normalizeClass(_ctx.b('verification')),
          code: _ctx.code,
          "onUpdate:code": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event))
        }, null, 8, ["class", "code"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('repeat'))
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('repeat-action')),
            disabled: _ctx.isRepeatDisabled,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRepeat && _ctx.handleRepeat(...args)))
          }, _toDisplayString(_ctx.$t("Отправить повторно")), 11, _hoisted_1),
          (_ctx.seconds)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.b('repeat-label'))
              }, _toDisplayString(_ctx.displayedRepeatLabel), 3))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        label: "Создать заявку",
        view: "main",
        disabled: _ctx.isCreateDisabled,
        onClick: _ctx.handleCreate
      }, null, 8, ["disabled", "onClick"])
    ], 2)
  ], 2))
}