import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  DefaultToolbar,
  DefaultContent,
  DefaultNavbar,
  DefaultModal,
} from "@/components/default";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    DefaultToolbar,
    DefaultContent,
    DefaultNavbar,
    DefaultModal,
  },
  data() {
    return {
      isModal: false,
    };
  },
  async created() {
    try {
      await this.$projectServices.projectRepository
        .userInfo()
        .then((userInfo) => {
          this.$projectServices.userRepo.UPDATE_USER_INFO(userInfo);
        });
    } catch (e) {
      console.log(e);
    }
  },
});
