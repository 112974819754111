import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "default-modal";

export const SVG: SvgAttribute = {
  logo: `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1305_39494)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.9844 -0.078125C39.6094 -0.078125 40.2344 -0.078125 40.8594 -0.078125C42.0797 0.593616 42.5484 1.63528 42.2656 3.04688C40.7031 3.04688 39.1406 3.04688 37.5781 3.04688C37.2953 1.63528 37.764 0.593616 38.9844 -0.078125Z" fill="#D3DCFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5781 3.04683C39.1406 3.04683 40.7031 3.04683 42.2656 3.04683C43.6199 3.04683 44.9739 3.04683 46.3281 3.04683C45.55 3.38253 45.0031 3.95544 44.6875 4.76558C44.5833 9.81766 44.5833 14.8698 44.6875 19.9218C44.9366 20.821 45.4835 21.446 46.3281 21.7968C44.9739 21.7968 43.6199 21.7968 42.2656 21.7968C40.7031 21.7968 39.1406 21.7968 37.5781 21.7968C36.433 21.7326 35.6777 21.1596 35.3125 20.0781C35.2344 16.1721 35.2083 12.2659 35.2344 8.35933C35.2085 7.16028 35.2344 5.96236 35.3125 4.76558C35.4278 4.27362 35.6622 3.85695 36.0156 3.51558C36.4577 3.12833 36.9785 2.97208 37.5781 3.04683Z" fill="#E70054"/>
    <path opacity="0.998" fill-rule="evenodd" clip-rule="evenodd" d="M46.3281 3.04688C52.6825 3.02083 59.0367 3.04688 65.3906 3.125C66.1606 3.42606 66.6553 3.97294 66.875 4.76563C66.9531 7.31717 66.9792 9.86927 66.9531 12.4219C66.8233 14.9471 66.7451 17.4992 66.7187 20.0781C66.392 21.2117 65.6369 21.7847 64.4531 21.7969C60.5469 21.7969 56.6406 21.7969 52.7344 21.7969C50.5989 21.7969 48.4636 21.7969 46.3281 21.7969C45.4834 21.4461 44.9365 20.8211 44.6875 19.9219C44.5833 14.8698 44.5833 9.81771 44.6875 4.76563C45.0031 3.95549 45.55 3.38258 46.3281 3.04688Z" fill="#FE4055"/>
    <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M36.0156 3.51562C35.6622 3.857 35.4278 4.27367 35.3125 4.76562C35.2344 5.96241 35.2084 7.16033 35.2344 8.35938C22.4105 10.8444 13.9469 18.2923 9.84376 30.7031C7.08327 41.5856 9.27077 51.3252 16.4063 59.9219C19.75 63.5039 23.6303 66.3164 28.0469 68.3594C27.0174 68.6856 26.3142 69.3627 25.9375 70.3906C25.8594 72.6817 25.8333 74.9734 25.8594 77.2656C15.4982 73.0762 8.2326 65.7847 4.06251 55.3906C-0.617097 41.7916 1.30999 29.2395 9.84376 17.7344C16.6092 9.4675 25.3331 4.72792 36.0156 3.51562Z" fill="#72C2F9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2344 8.35938C35.2083 12.266 35.2344 16.1722 35.3125 20.0781C35.6777 21.1597 36.433 21.7327 37.5782 21.7969C37.5782 25.4948 37.5782 29.1927 37.5782 32.8906C37.5782 40.7552 37.5782 48.6198 37.5782 56.4844C36.4655 56.4813 35.7103 57.002 35.3125 58.0469C35.2344 59.8691 35.2083 61.692 35.2344 63.5156C32.1433 63.5366 30.8413 65.0991 31.3282 68.2031C30.2207 68.157 29.1269 68.2091 28.0469 68.3594C23.6303 66.3164 19.75 63.5039 16.4063 59.9219C9.27079 51.3252 7.08329 41.5856 9.84378 30.7031C13.9469 18.2923 22.4105 10.8444 35.2344 8.35938Z" fill="#4FABF6"/>
    <path opacity="0.996" fill-rule="evenodd" clip-rule="evenodd" d="M66.9531 12.4219C70.2348 12.3958 73.5161 12.4219 76.7969 12.5C78.1117 13.1793 78.5544 14.247 78.125 15.7031C76.893 17.6464 75.8253 19.6777 74.9219 21.7969C75.8253 23.9161 76.893 25.9473 78.125 27.8906C78.5645 29.3516 78.1219 30.3931 76.7969 31.0156C74.5648 31.1666 72.3253 31.2187 70.0781 31.1719C64.8695 31.198 59.6612 31.1719 54.4531 31.0937C54.2084 30.9987 54 30.8684 53.8281 30.7031C53.8147 30.5475 53.8669 30.4173 53.9844 30.3125C57.5422 27.5458 61.0319 24.7073 64.4531 21.7969C65.6369 21.7847 66.392 21.2117 66.7187 20.0781C66.7451 17.4992 66.8233 14.9471 66.9531 12.4219Z" fill="#E80054"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5781 21.7969C39.1406 21.7969 40.7031 21.7969 42.2656 21.7969C42.2656 25.4948 42.2656 29.1927 42.2656 32.8906C41.3002 31.0378 39.9459 30.6992 38.2031 31.875C37.9492 32.2023 37.7409 32.5408 37.5781 32.8906C37.5781 29.1927 37.5781 25.4948 37.5781 21.7969Z" fill="#D3DCFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.2656 21.7969C43.6198 21.7969 44.9739 21.7969 46.3281 21.7969C48.4636 21.7969 50.5989 21.7969 52.7344 21.7969C52.7083 24.2973 52.7344 26.7973 52.8125 29.2969C52.9437 29.9219 53.2822 30.3906 53.8281 30.7031C54 30.8684 54.2084 30.9987 54.4531 31.0938C59.6612 31.1719 64.8695 31.198 70.0781 31.1719C72.8916 43.5405 69.7145 54.0873 60.5469 62.8125C59.1847 63.9144 57.7784 64.9561 56.3281 65.9375C54.8031 66.7516 53.2927 67.5589 51.7969 68.3594C50.7169 68.2091 49.6231 68.157 48.5156 68.2031C48.9656 65.1331 47.6634 63.5706 44.6094 63.5156C44.6355 61.7441 44.6094 59.9731 44.5312 58.2031C44.1661 57.1216 43.4108 56.5486 42.2656 56.4844C42.2656 48.6198 42.2656 40.7552 42.2656 32.8906C42.2656 29.1927 42.2656 25.4948 42.2656 21.7969Z" fill="#4FABF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.7344 21.7969C56.6406 21.7969 60.5469 21.7969 64.4531 21.7969C61.0319 24.7073 57.5422 27.5458 53.9844 30.3125C53.8669 30.4173 53.8147 30.5475 53.8281 30.7031C53.2822 30.3906 52.9438 29.9219 52.8125 29.2969C52.7344 26.7973 52.7083 24.2973 52.7344 21.7969Z" fill="#C80152"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.2656 32.8906C42.2656 40.7551 42.2656 48.6198 42.2656 56.4844C42.0573 56.4844 41.8489 56.4844 41.6406 56.4844C40.2864 56.4844 38.9323 56.4844 37.5781 56.4844C37.5781 48.6198 37.5781 40.7551 37.5781 32.8906C37.7409 32.5408 37.9492 32.2023 38.2031 31.875C39.9459 30.6992 41.3002 31.0378 42.2656 32.8906Z" fill="#E8EEFE"/>
    <path opacity="0.994" fill-rule="evenodd" clip-rule="evenodd" d="M76.7969 31.0156C80.5044 46.1112 76.8586 59.1059 65.8594 70C62.3623 73.1548 58.4041 75.5767 53.9844 77.2656C54.0105 75.0255 53.9844 72.7858 53.9062 70.5469C53.5958 69.4292 52.8927 68.7 51.7969 68.3594C53.2927 67.5589 54.8031 66.7516 56.3281 65.9375C57.7784 64.9561 59.1847 63.9144 60.5469 62.8125C69.7145 54.0873 72.8916 43.5405 70.0781 31.1719C72.3253 31.2188 74.5648 31.1666 76.7969 31.0156Z" fill="#72C2F8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5781 56.4844C38.9324 56.4844 40.2864 56.4844 41.6406 56.4844C40.81 56.7685 40.2631 57.3413 40 58.2031C39.9219 59.9731 39.8958 61.7441 39.9219 63.5156C39.6614 63.5156 39.4011 63.5156 39.1406 63.5156C37.8386 63.5156 36.5364 63.5156 35.2344 63.5156C35.2083 61.692 35.2344 59.8691 35.3125 58.0469C35.7103 57.002 36.4655 56.4813 37.5781 56.4844Z" fill="#1C2E7A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.6406 56.4844C41.8489 56.4844 42.0574 56.4844 42.2656 56.4844C43.4108 56.5486 44.1661 57.1216 44.5313 58.2031C44.6094 59.9731 44.6355 61.7441 44.6094 63.5156C43.0469 63.5156 41.4844 63.5156 39.9219 63.5156C39.8958 61.7441 39.9219 59.9731 40 58.2031C40.2631 57.3413 40.81 56.7684 41.6406 56.4844Z" fill="#2A428C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2344 63.5156C36.5364 63.5156 37.8386 63.5156 39.1406 63.5156C37.1302 64.4111 36.3489 65.9736 36.7969 68.2031C36.4844 68.2031 36.1719 68.2031 35.8594 68.2031C34.3489 68.2031 32.8386 68.2031 31.3281 68.2031C30.8413 65.0991 32.1433 63.5366 35.2344 63.5156Z" fill="#D2DBFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.1406 63.5156C39.4011 63.5156 39.6614 63.5156 39.9219 63.5156C41.4844 63.5156 43.0469 63.5156 44.6094 63.5156C47.6634 63.5706 48.9656 65.1331 48.5156 68.2031C44.6094 68.2031 40.7031 68.2031 36.7969 68.2031C36.3489 65.9736 37.1302 64.4111 39.1406 63.5156Z" fill="#E8EEFE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.3281 68.2031C32.8386 68.2031 34.3489 68.2031 35.8594 68.2031C34.873 68.6428 34.1699 69.372 33.75 70.3906C33.6719 73.4109 33.6458 76.4319 33.6719 79.4531C30.9553 79.0677 28.3511 78.3384 25.8594 77.2656C25.8333 74.9734 25.8594 72.6817 25.9375 70.3906C26.3142 69.3627 27.0174 68.6856 28.0469 68.3594C29.1269 68.2091 30.2206 68.157 31.3281 68.2031Z" fill="#1C2E7A"/>
    <path opacity="0.995" fill-rule="evenodd" clip-rule="evenodd" d="M35.8594 68.2031C36.1719 68.2031 36.4844 68.2031 36.7969 68.2031C40.7031 68.2031 44.6094 68.2031 48.5156 68.2031C49.6231 68.157 50.7169 68.2091 51.7969 68.3594C52.8927 68.7 53.5958 69.4292 53.9063 70.5469C53.9844 72.7858 54.0105 75.0255 53.9844 77.2656C50.5697 78.6672 47.028 79.5527 43.3594 79.9219C41.0677 79.9219 38.7761 79.9219 36.4844 79.9219C35.566 79.7667 34.6285 79.6105 33.6719 79.4531C33.6458 76.4319 33.6719 73.4109 33.75 70.3906C34.1699 69.372 34.873 68.6428 35.8594 68.2031Z" fill="#2A428C"/>
    </g>
    <defs>
    <clipPath id="clip0_1305_39494">
    <rect width="80" height="80" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  logoSmall: `<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1305_39961)">
    <path opacity="0.991" fill-rule="evenodd" clip-rule="evenodd" d="M44.6211 5.68355C42.3368 5.8004 40.032 5.83945 37.707 5.80074C36.7128 5.68437 35.6971 5.6453 34.6602 5.68355C34.547 3.9084 33.6486 3.30294 31.9648 3.86714C31.3196 4.29714 31.0461 4.90261 31.1445 5.68355C30.4561 5.6693 29.9873 5.98179 29.7383 6.62105C20.8774 6.02311 13.7484 9.22623 8.35156 16.2304C3.25372 23.8508 2.57013 31.8585 6.30078 40.2539C5.89632 40.6011 5.60335 41.0308 5.42188 41.5429C5.36329 43.0659 5.34376 44.5893 5.36328 46.1132C2.58519 41.998 0.944558 37.4668 0.441406 32.5195C0.441406 30.8007 0.441406 29.082 0.441406 27.3632C1.58812 17.8051 6.1975 10.4418 14.2695 5.27339C22.3224 0.701634 30.7209 -0.040553 39.4648 3.04683C41.2709 3.77402 42.9897 4.65293 44.6211 5.68355Z" fill="#73C2F8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6602 5.6836C33.4883 5.6836 32.3164 5.6836 31.1445 5.6836C31.0461 4.90267 31.3196 4.29719 31.9648 3.8672C33.6486 3.303 34.547 3.90846 34.6602 5.6836Z" fill="#2A438D"/>
    <path opacity="0.997" fill-rule="evenodd" clip-rule="evenodd" d="M44.6211 5.68364C46.3483 5.63093 48.067 5.68952 49.7773 5.85942C49.9589 6.00169 50.0955 6.17747 50.1875 6.38676C50.2461 7.71431 50.2657 9.04244 50.2461 10.3711C50.2657 11.6218 50.2461 12.8718 50.1875 14.1211C50.127 14.4181 49.9903 14.6719 49.7773 14.8829C47.6008 15.0556 45.4133 15.1142 43.2148 15.0586C41.3789 15.0586 39.543 15.0586 37.707 15.0586C37.248 14.971 36.955 14.6975 36.8281 14.2383C36.75 11.6602 36.75 9.08208 36.8281 6.50395C37.0016 6.11522 37.2945 5.88084 37.707 5.80083C40.032 5.83955 42.3368 5.80049 44.6211 5.68364Z" fill="#FE4054"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1445 5.68363C32.3164 5.68363 33.4883 5.68363 34.6602 5.68363C35.6972 5.64538 36.7128 5.68445 37.707 5.80081C37.2945 5.88083 37.0016 6.1152 36.8281 6.50394C36.75 9.08206 36.75 11.6602 36.8281 14.2383C36.955 14.6975 37.248 14.971 37.707 15.0586C36.7128 15.175 35.6972 15.214 34.6602 15.1758C33.4883 15.1758 32.3164 15.1758 31.1445 15.1758C30.5034 15.2161 30.0541 14.9427 29.7969 14.3555C29.7383 11.7777 29.7187 9.19954 29.7383 6.62113C29.9873 5.98187 30.4561 5.66938 31.1445 5.68363Z" fill="#E70254"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7383 6.62108C29.7187 9.1995 29.7383 11.7777 29.7969 14.3555C30.0541 14.9427 30.5034 15.2161 31.1445 15.1758C31.1445 20.293 31.1445 25.4101 31.1445 30.5273C28.6054 30.5273 26.0664 30.5273 23.5273 30.5273C21.1442 30.5078 18.7614 30.5273 16.3789 30.5859C15.6172 30.8789 15.0898 31.4062 14.7969 32.168C14.7383 34.7457 14.7187 37.3239 14.7383 39.9023C14.5429 39.9023 14.3477 39.9023 14.1523 39.9023C11.7692 39.8828 9.3864 39.9023 7.0039 39.9609C6.78045 40.0924 6.54608 40.19 6.30078 40.2539C2.57013 31.8586 3.25372 23.8508 8.35156 16.2305C13.7484 9.22627 20.8774 6.02314 29.7383 6.62108Z" fill="#4FABF6"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M50.2461 10.3711C53.4104 10.3516 56.5745 10.3711 59.7383 10.4297C60.0339 10.589 60.2683 10.8039 60.4414 11.0742C60.4414 11.4258 60.4414 11.7773 60.4414 12.1289C59.8507 13.0951 59.3037 14.0912 58.8008 15.1172C59.3037 16.1432 59.8507 17.1393 60.4414 18.1055C60.4414 18.457 60.4414 18.8086 60.4414 19.1602C60.2575 19.4798 59.9841 19.6948 59.6211 19.8047C58.4499 19.8633 57.278 19.8827 56.1055 19.8633C56.0664 19.8633 56.0273 19.8633 55.9883 19.8633C51.9847 19.7658 47.9612 19.7072 43.918 19.6875C43.8106 19.6549 43.7326 19.5963 43.6836 19.5117C45.4332 18.0881 47.191 16.6624 48.957 15.2344C47.0434 15.1758 45.1293 15.1562 43.2148 15.1758C43.2148 15.1368 43.2148 15.0976 43.2148 15.0586C45.4133 15.1141 47.6008 15.0555 49.7773 14.8828C49.9903 14.6719 50.127 14.418 50.1875 14.1211C50.2461 12.8718 50.2657 11.6217 50.2461 10.3711Z" fill="#E70054"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1445 15.1758C32.3164 15.1758 33.4883 15.1758 34.6602 15.1758C34.6602 20.293 34.6602 25.4101 34.6602 30.5273C33.4883 30.5273 32.3164 30.5273 31.1445 30.5273C31.1445 25.4101 31.1445 20.293 31.1445 15.1758Z" fill="#2A428C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.707 15.0586C39.543 15.0586 41.3789 15.0586 43.2148 15.0586C43.2148 15.0976 43.2148 15.1368 43.2148 15.1758C43.1953 16.4654 43.2148 17.7545 43.2734 19.043C43.3508 19.2567 43.4875 19.4129 43.6836 19.5117C43.7326 19.5963 43.8106 19.6549 43.918 19.6875C47.9612 19.7072 51.9847 19.7658 55.9883 19.8633C53.5261 19.8243 51.0651 19.8633 48.6055 19.9805C50.1706 23.3167 50.9128 26.8323 50.832 30.5273C45.4414 30.5273 40.0508 30.5273 34.6602 30.5273C34.6602 25.4101 34.6602 20.293 34.6602 15.1758C35.6971 15.214 36.7128 15.175 37.707 15.0586Z" fill="#50AAF6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.2148 15.1758C45.1293 15.1562 47.0434 15.1758 48.957 15.2344C47.191 16.6624 45.4332 18.0881 43.6836 19.5117C43.4875 19.4129 43.3508 19.2567 43.2734 19.043C43.2148 17.7545 43.1953 16.4654 43.2148 15.1758Z" fill="#C90052"/>
    <path opacity="0.996" fill-rule="evenodd" clip-rule="evenodd" d="M55.9883 19.8633C56.0273 19.8633 56.0664 19.8633 56.1055 19.8633C57.413 23.2944 57.999 26.8492 57.8633 30.5273C56.1836 30.5273 54.5039 30.5273 52.8242 30.5273C52.1601 30.5273 51.4961 30.5273 50.832 30.5273C50.9128 26.8323 50.1706 23.3167 48.6055 19.9805C51.0651 19.8633 53.5261 19.8242 55.9883 19.8633Z" fill="#72C2F9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5273 30.5274C22.67 30.8185 22.1036 31.4044 21.8281 32.2852C21.7695 34.8239 21.75 37.363 21.7695 39.9024C19.4258 39.9024 17.082 39.9024 14.7383 39.9024C14.7187 37.3239 14.7383 34.7458 14.7969 32.168C15.0898 31.4063 15.6172 30.8789 16.3789 30.586C18.7614 30.5274 21.1442 30.5078 23.5273 30.5274Z" fill="#BDC8F7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5273 30.5273C26.0664 30.5273 28.6054 30.5273 31.1445 30.5273C32.3164 30.5273 33.4883 30.5273 34.6602 30.5273C40.0508 30.5273 45.4414 30.5273 50.832 30.5273C51.4961 30.5273 52.1601 30.5273 52.8242 30.5273C51.9669 30.8184 51.4005 31.4044 51.125 32.2852C51.086 34.2383 51.0468 36.1914 51.0078 38.1445C50.7539 39.0234 50.1875 39.5898 49.3086 39.8437C47.3559 39.9023 45.4027 39.9219 43.4492 39.9023C36.2226 39.9023 28.9961 39.9023 21.7695 39.9023C21.75 37.363 21.7695 34.8239 21.8281 32.2852C22.1036 31.4044 22.67 30.8184 23.5273 30.5273Z" fill="#D3DCFB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.8242 30.5273C54.5039 30.5273 56.1836 30.5273 57.8633 30.5273C59.0773 30.4553 59.9367 30.9631 60.4414 32.0508C60.4414 40.4101 60.4414 48.7696 60.4414 57.1289C60.1505 57.8297 59.6428 58.318 58.918 58.5938C50.6368 58.6523 42.3555 58.6719 34.0742 58.6523C33.3535 58.4163 32.8262 57.9476 32.4922 57.2461C32.3619 56.1395 32.3033 55.0262 32.3164 53.9063C32.3016 52.8237 32.3602 51.7495 32.4922 50.6836C32.8262 49.9821 33.3535 49.5134 34.0742 49.2773C36.0277 49.2969 37.9809 49.2773 39.9336 49.2188C40.8125 48.9648 41.3789 48.3984 41.6328 47.5195C41.6718 45.5664 41.711 43.6133 41.75 41.6602C42.0255 40.7794 42.5919 40.1934 43.4492 39.9023C45.4027 39.9219 47.3559 39.9023 49.3086 39.8438C50.1875 39.5898 50.7539 39.0234 51.0078 38.1445C51.0468 36.1914 51.086 34.2383 51.125 32.2852C51.4005 31.4044 51.9669 30.8184 52.8242 30.5273Z" fill="#E8EEFE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1523 39.9024C13.295 40.1935 12.7286 40.7794 12.4531 41.6602C12.3945 44.1989 12.375 46.738 12.3945 49.2774C10.8893 49.2388 9.40496 49.2779 7.94141 49.3945C6.97029 48.3905 6.11092 47.2967 5.36328 46.1133C5.34376 44.5894 5.3633 43.066 5.42188 41.543C5.60335 41.0309 5.89632 40.6011 6.30078 40.2539C6.54608 40.1901 6.78046 40.0924 7.00391 39.961C9.3864 39.9024 11.7692 39.8828 14.1523 39.9024Z" fill="#E8EFFE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1523 39.9023C14.3477 39.9023 14.5429 39.9023 14.7383 39.9023C17.082 39.9023 19.4258 39.9023 21.7695 39.9023C28.9961 39.9023 36.2226 39.9023 43.4492 39.9023C42.5919 40.1934 42.0255 40.7794 41.75 41.6602C41.711 43.6133 41.6718 45.5664 41.6328 47.5195C41.3789 48.3984 40.8125 48.9648 39.9336 49.2187C37.9809 49.2773 36.0277 49.2969 34.0742 49.2773C26.8476 49.2773 19.6211 49.2773 12.3945 49.2773C12.375 46.738 12.3945 44.1989 12.4531 41.6602C12.7286 40.7794 13.295 40.1934 14.1523 39.9023Z" fill="#FEFEFF"/>
    <path opacity="0.995" fill-rule="evenodd" clip-rule="evenodd" d="M12.3945 49.2773C19.6211 49.2773 26.8476 49.2773 34.0742 49.2773C33.3535 49.5133 32.8262 49.9821 32.4922 50.6835C32.3602 51.7495 32.3016 52.8236 32.3164 53.9062C32.3033 55.0262 32.3619 56.1394 32.4922 57.246C32.8262 57.9475 33.3535 58.4163 34.0742 58.6523C24.8304 59.6833 16.7054 57.2029 9.69922 51.2109C9.08948 50.6208 8.50354 50.0153 7.94141 49.3945C9.40496 49.2779 10.8893 49.2387 12.3945 49.2773Z" fill="#D3DCFB"/>
    </g>
    <defs>
    <clipPath id="clip0_1305_39961">
    <rect width="60" height="60" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
  </svg>`,
};
