<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('group')">
        <UIInput
          placeholder="Адрес электронной почты*"
          view="light"
          :modelValue="email"
          :is-error="isErrorDisplayed"
          @update:modelValue="changeEmail"
        />

        <SharedError
          v-if="isErrorDisplayed"
          message="Такой email уже существует, авторизуйтесь"
        />
      </div>

      <div :class="b('fields-validation')">
        <SharedInputPassword
          placeholder="Пароль*"
          view="light"
          :modelValue="password"
          :isError="isPasswordError"
          @update:modelValue="changePassword"
        />

        <SharedPasswordValidation
          v-if="isPasswordValidationShow"
          :validation="displayedValidationPassword"
        />
      </div>

      <div :class="b('fields-validation')">
        <SharedInputPassword
          placeholder="Подтвердите пароль*"
          view="light"
          :modelValue="password_confirmation"
          :isError="isConfirmPasswordError"
          @update:modelValue="changeConfirmPassword"
        />

        <p v-if="isConfirmPasswordValidationShow" :class="b('fields-label')">
          {{ $t("Пароль не совпадает") }}
        </p>
      </div>
    </div>

    <div :class="b('actions')">
      <UIButton
        label="Создать аккаунт"
        view="main"
        :disabled="isRegistrationDisabled"
        @click="handleRegistration"
      />
    </div>

    <p :class="b('label')">
      {{ $t("Нажав “Создать учетную запись“, я принимаю") }}

      <br :class="b('label-br')" />
      {{ $t("the") }}

      <UILink label="Условия обслуживания" view="main-text-12" to="/terms" />

      {{ $t("и") }}

      <UILink
        label="Политика конфиденциальности"
        view="main-text-12"
        to="/privacy"
      />
    </p>
  </div>
</template>
