import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletTabs = _resolveComponent("WalletTabs")!
  const _component_WalletRequisites = _resolveComponent("WalletRequisites")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    title: "Ввод",
    "is-big": _ctx.isBig,
    "is-center": true,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        (!_ctx.isHeaderShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('header'))
            }, [
              _createVNode(_component_WalletTabs, {
                class: _normalizeClass(_ctx.b('tabs')),
                tab: _ctx.tab,
                "onUpdate:tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event))
              }, null, 8, ["class", "tab"]),
              _createVNode(_component_WalletRequisites, {
                requisites: _ctx.requisites,
                "onUpdate:requisites": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.requisites) = $event))
              }, null, 8, ["requisites"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.isRequisitesShow)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedRequisites), { key: 1 }))
          : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), { key: 2 }))
      ], 2)
    ]),
    _: 1
  }, 8, ["is-big", "onClose"]))
}