import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminToolbar = _resolveComponent("AdminToolbar")!
  const _component_AdminContent = _resolveComponent("AdminContent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminToolbar, {
      class: _normalizeClass(_ctx.b('toolbar'))
    }, null, 8, ["class"]),
    _createVNode(_component_AdminContent)
  ], 2))
}