<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedCard :class="b('content')">
      <h3 :class="b('content-title')">
        {{ $t("Банковские реквизиты") }}
      </h3>

      <div :class="b('content-block')">
        <div :class="b('content-group')">
          <h5 :class="b('content-group-title')">
            {{ $t("Банковский счет №1") }}
          </h5>

          <SharedAdd
            :is-confirmed="!!requisiteBank.id"
            :is-remove="false"
            @click="handleRequisiteBank"
          >
            <template #title>
              {{ $t("Подтверждено") }}
            </template>

            <template #label>
              {{ $t("Добавить") }}
            </template>
          </SharedAdd>
        </div>
      </div>
    </SharedCard>

    <SharedCard :class="b('content', { crypto: true })">
      <h3 :class="b('content-title')">
        {{ $t("Крипто реквизиты") }}
      </h3>

      <div :class="b('content-group')">
        <h5 :class="b('content-group-title')">
          {{ $t("Крипто счет №1") }}
        </h5>

        <SharedAdd
          :is-confirmed="!!requisiteCrypto.id"
          :is-remove="false"
          @click="handleRequisiteCrypto"
        >
          <template #title>
            {{ $t("Подтверждено") }}
          </template>

          <template #label>
            {{ $t("Добавить") }}
          </template>
        </SharedAdd>
      </div>
    </SharedCard>
  </div>
</template>
