<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <WalletCard :class="b()" title="Пополнение/Вывод">
    <div :class="b('table')">
      <div :class="b('columns')">
        <div
          :class="b('column')"
          v-for="column in displayedColumns"
          :key="column.id"
        >
          <p :class="b('column-label')">
            {{ $t(column.label) }}
          </p>
        </div>
      </div>

      <div :class="b('rows')">
        <div :class="b('row')" v-for="row in displayedRows" :key="row.id">
          <div :class="b('row-date-time')">
            <p :class="b('row-label')">
              {{ row.dateTime }}
            </p>
          </div>

          <div :class="b('row-type')">
            <span :class="b('row-icon')" v-html="row.type.icon"></span>

            <p :class="b('row-label', { type: true })">
              {{ row.type.label }}
            </p>

            <p :class="b('row-label', { amount: true })">
              {{ row.amount }}
            </p>
          </div>

          <div :class="b('row-amount')">
            <p :class="b('row-label')">
              {{ row.amount }}
            </p>
          </div>

          <div :class="b('row-currency')">
            <div :class="b('row-currency-group')">
              <span :class="b('row-icon')" v-html="row.currency.icon"></span>

              <p :class="b('row-label', { currency: true })">
                {{ row.currency.label }}
              </p>
            </div>

            <span :class="b('row-icon')" v-html="row.currency.iconType"></span>
          </div>
        </div>
      </div>
    </div>
  </WalletCard>
</template>
