import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:search": null,
  },
  data() {
    return {
      isSearch: false,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isClearShow(): boolean {
      return !!this.search;
    },
  },
  methods: {
    handleSearch(): void {
      this.isSearch = !this.isSearch;
    },

    handleChangeSearch(event: Event): void {
      this.$emit("update:search", (<HTMLInputElement>event.target).value);
    },
  },
});
