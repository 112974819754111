<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button :class="b('', { view })">
    <slot name="icon-left"></slot>

    <span :class="b('label')">
      <slot name="label"></slot>
    </span>
  </button>
</template>
