import { AdminCommissionTabValue } from "@/views/admin-commission/model";

export interface AdminCommissionTabsTab {
  id: number;
  label: AdminCommissionTabsTabLabel;
  value: AdminCommissionTabValue;
  isActive: boolean;
}

export enum AdminCommissionTabsTabLabel {
  main = "Комиссии",
  requisites = "Реквизиты",
}
