import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-google";

export const SVG: SvgAttribute = {
  google: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clip-path="url(#clip0_468_1843)">
      <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M11.4453 -0.0234375C12.1484 -0.0234375 12.8516 -0.0234375 13.5547 -0.0234375C16.0193 0.238001 18.2146 1.14425 20.1406 2.69531C20.1719 2.72656 20.1719 2.75781 20.1406 2.78906C18.8432 3.83658 17.5464 4.88344 16.25 5.92969C13.4953 4.40387 10.8156 4.52106 8.21094 6.28125C7.06498 7.18453 6.23689 8.31736 5.72656 9.67969C4.41592 8.60208 3.10342 7.52395 1.78906 6.44531C3.90262 2.65319 7.12138 0.496941 11.4453 -0.0234375Z" fill="#F14335"/>
      <path opacity="0.994" fill-rule="evenodd" clip-rule="evenodd" d="M1.78906 6.44531C3.10342 7.52395 4.41592 8.60208 5.72656 9.67969C5.20517 11.2858 5.23644 12.8795 5.82031 14.4609C4.52139 15.5412 3.20889 16.6037 1.88281 17.6484C1.10185 16.2119 0.6331 14.6806 0.476562 13.0547C0.476562 12.336 0.476562 11.6172 0.476562 10.8984C0.643993 9.33877 1.08149 7.85442 1.78906 6.44531Z" fill="#FABA00"/>
      <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M24.4766 10.8984C24.4766 11.6172 24.4766 12.336 24.4766 13.0547C24.1027 16.4409 22.6027 19.2066 19.9766 21.3516C18.6883 20.2623 17.3915 19.1842 16.0859 18.1172C17.4945 17.2553 18.4945 16.0522 19.0859 14.5078C16.961 14.5078 14.8359 14.5078 12.7109 14.5078C12.7109 12.9141 12.7109 11.3203 12.7109 9.72656C16.5703 9.72656 20.4297 9.72656 24.2891 9.72656C24.3523 10.1233 24.4148 10.5139 24.4766 10.8984Z" fill="#518EF7"/>
      <path opacity="0.994" fill-rule="evenodd" clip-rule="evenodd" d="M5.82031 14.4609C6.60983 16.477 8.01608 17.8755 10.0391 18.6562C12.1359 19.363 14.1515 19.1834 16.0859 18.1172C17.3915 19.1842 18.6883 20.2623 19.9766 21.3516C18.1014 22.8589 15.9608 23.7339 13.5547 23.9766C12.8516 23.9766 12.1484 23.9766 11.4453 23.9766C7.81006 23.5772 4.89599 21.921 2.70312 19.0078C2.3992 18.5715 2.12576 18.1184 1.88281 17.6484C3.20889 16.6037 4.52139 15.5412 5.82031 14.4609Z" fill="#28B346"/>
    </g>
    <defs>
      <clipPath id="clip0_468_1843">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>`,
};
