import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedMultiSelect = _resolveComponent("SharedMultiSelect")!
  const _component_SharedCurrency = _resolveComponent("SharedCurrency")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Фильтр",
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Валюта")), 3),
            _createVNode(_component_SharedMultiSelect, {
              placeholder: _ctx.$t('Все'),
              options: _ctx.displayedCurrencyOptions,
              values: _ctx.currency,
              "onUpdate:values": _ctx.changeCurrency
            }, null, 8, ["placeholder", "options", "values", "onUpdate:values"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('block'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("Объем от")), 3),
              _createVNode(_component_SharedCurrency, {
                label: "USD",
                value: _ctx.volumeFrom,
                "onUpdate:value": _ctx.changeVolumeFrom
              }, null, 8, ["value", "onUpdate:value"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("до")), 3),
              _createVNode(_component_SharedCurrency, {
                label: "USD",
                value: _ctx.volumeTo,
                "onUpdate:value": _ctx.changeVolumeTo
              }, null, 8, ["value", "onUpdate:value"])
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Дата/Период")), 3),
            _createVNode(_component_SharedDate, {
              placeholder: "За все время",
              range: true,
              "multi-calendars": true,
              "onUpdate:date": _ctx.changeDate
            }, null, 8, ["onUpdate:date"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Статус")), 3),
            _createVNode(_component_SharedMultiSelect, {
              placeholder: _ctx.$t('Все'),
              options: _ctx.displayedStatusOptions,
              values: _ctx.status,
              "onUpdate:values": _ctx.changeStatus
            }, null, 8, ["placeholder", "options", "values", "onUpdate:values"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Тип")), 3),
            _createVNode(_component_SharedMultiSelect, {
              placeholder: _ctx.$t('Все'),
              options: _ctx.displayedTypeOptions,
              values: _ctx.type,
              "onUpdate:values": _ctx.changeType
            }, null, 8, ["placeholder", "options", "values", "onUpdate:values"])
          ], 2)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Применить фильтр",
          view: "main",
          onClick: _ctx.handleApply
        }, null, 8, ["onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}