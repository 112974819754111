import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  AdminUsersTableColumn,
  AdminUsersTableColumnLabel,
  AdminUsersTableColumnSortKey,
  AdminUsersTableOption,
  AdminUsersTableOptionLabel,
  AdminUsersTableOptionValue,
  AdminUsersTableRowStatus,
} from "./model";

import { RoutesName, SvgAttribute } from "@/shared/constants/constants";
import { AdminUserResponse } from "@/shared/repository/repo";

const NA = "N/A";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    users: {
      type: <PropType<AdminUserResponse[]>>Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: 0,
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortIsUp: {
      type: Boolean,
      default: false,
    },
    sortIsDown: {
      type: Boolean,
      default: false,
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
    isBalance: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:userId": null,
    "update:sortKey": null,
    "update:sortIsUp": null,
    "update:sortIsDown": null,
    "update:isStatus": null,
    "update:isBalance": null,
  },
  data() {
    return {
      id: <number | null>null,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): AdminUsersTableColumn[] {
      return [
        {
          id: 0,
          label: AdminUsersTableColumnLabel.id,
          sort: {
            key: AdminUsersTableColumnSortKey.id,
            isActive: this.sortKey === AdminUsersTableColumnSortKey.id,
          },
        },
        {
          id: 1,
          label: AdminUsersTableColumnLabel.email,
          sort: {
            key: AdminUsersTableColumnSortKey.email,
            isActive: this.sortKey === AdminUsersTableColumnSortKey.email,
          },
        },
        {
          id: 2,
          label: AdminUsersTableColumnLabel.kyc,
        },
        {
          id: 3,
          label: AdminUsersTableColumnLabel.fio,
          sort: {
            key: AdminUsersTableColumnSortKey.name,
            isActive: this.sortKey === AdminUsersTableColumnSortKey.name,
          },
        },
        {
          id: 4,
          label: AdminUsersTableColumnLabel.balance,
        },
        {
          id: 5,
          label: AdminUsersTableColumnLabel.application,
        },
      ];
    },

    displayedOptions(): AdminUsersTableOption[] {
      return [
        {
          id: 0,
          label: AdminUsersTableOptionLabel.kyc,
          value: AdminUsersTableOptionValue.kyc,
        },
        {
          id: 1,
          label: AdminUsersTableOptionLabel.status,
          value: AdminUsersTableOptionValue.status,
        },
        {
          id: 2,
          label: AdminUsersTableOptionLabel.admin,
          value: AdminUsersTableOptionValue.admin,
        },
        {
          id: 3,
          label: AdminUsersTableOptionLabel.balance,
          value: AdminUsersTableOptionValue.balance,
        },
        {
          id: 4,
          label: AdminUsersTableOptionLabel.remove,
          value: AdminUsersTableOptionValue.remove,
        },
      ];
    },
  },
  methods: {
    handleColumnSort(column: AdminUsersTableColumn): void {
      if (column.sort) {
        const { key } = column.sort;

        if (this.sortKey !== key) {
          this.$emit("update:sortKey", key);

          this.$emit("update:sortIsUp", true);
          this.$emit("update:sortIsDown", false);
        } else {
          if (!this.sortIsUp && !this.sortIsDown) {
            this.$emit("update:sortIsUp", true);
            this.$emit("update:sortIsDown", false);
          } else if (this.sortIsUp) {
            this.$emit("update:sortIsUp", false);
            this.$emit("update:sortIsDown", true);
          } else {
            this.$emit("update:sortKey", "");
            this.$emit("update:sortIsDown", false);
          }
        }
      }
    },

    handleRowToggle({ id }: AdminUserResponse): void {
      this.id = this.id === id ? null : id;
    },

    handleClearActiveUserId(): void {
      this.id = null;
    },

    handleIsRowOptionDisabled(
      { value }: AdminUsersTableOption,
      { user_info: { application_type } }: AdminUserResponse
    ): boolean {
      switch (value) {
        case AdminUsersTableOptionValue.kyc:
        case AdminUsersTableOptionValue.status:
        case AdminUsersTableOptionValue.balance:
          return !application_type;
        default:
          return false;
      }
    },

    handleRowOption(
      { value }: AdminUsersTableOption,
      { user_info: { user_id } }: AdminUserResponse
    ): void {
      switch (value) {
        case AdminUsersTableOptionValue.kyc:
          this.$router.replace({
            name: RoutesName.adminKyc,
            query: { id: user_id },
          });

          break;
        case AdminUsersTableOptionValue.status:
          this.$emit("update:userId", user_id);
          this.$emit("update:isStatus", true);

          break;
        case AdminUsersTableOptionValue.balance:
          this.$emit("update:userId", user_id);
          this.$emit("update:isBalance", true);

          break;
        default:
          break;
      }

      this.handleClearActiveUserId();
    },

    handleDisplayedRowStatus({
      user_info: { application_type },
    }: AdminUserResponse): AdminUsersTableRowStatus {
      return {
        icon: "",
        label: application_type ?? NA,
      };
    },

    handleDisplayedRowFio({
      user_info: { application_type },
    }: AdminUserResponse): string {
      return application_type ?? NA;
    },
  },
});
