import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { FileType, KybDocumentsFileName } from "@/shared/constants/constants";
import { KybUserFileResponseFactory } from "@/shared/factory/kyb";
import { KybUserFileResponse } from "@/shared/repository/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      documentRegistrationCompany: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-registration-company"],
        type: FileType.document,
      }),
      documentRegulation: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-regulation"],
        type: FileType.document,
      }),
      documentListOfShareholders: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-list-of-shareholders"],
        type: FileType.document,
      }),
      documentLicense: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-license"],
        type: FileType.document,
      }),
      documentCheckAddresses: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-check-addresses"],
        type: FileType.document,
      }),
      documentSow: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-sow"],
        type: FileType.document,
      }),
      documentSof: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-sof"],
        type: FileType.document,
      }),
      documentInn: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-inn"],
        type: FileType.document,
      }),
      documentRecordSheet: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-record-sheet"],
        type: FileType.document,
      }),
      documentCreate: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-create"],
        type: FileType.document,
      }),
      documentUpdate: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-update"],
        type: FileType.document,
      }),
      documentPurpose: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-purpose"],
        type: FileType.document,
      }),
      documentFinance: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-finance"],
        type: FileType.document,
      }),
      documentExample: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-example"],
        type: FileType.document,
      }),
      documentKyb: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-document"],
        type: FileType.document,
      }),
      documentBeneficiary: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-beneficiary"],
        type: FileType.document,
      }),
      documentBroker: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-broker"],
        type: FileType.document,
      }),
      documentDeclaration: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-declaration"],
        type: FileType.document,
      }),
      documentDirector: KybUserFileResponseFactory({
        name: KybDocumentsFileName["kyb-documents-director"],
        type: FileType.document,
      }),
      projectRepo: this.$projectServices.projectRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.projectRepo.getKybUserFiles(this.id).then((userFiles) => {
        userFiles.forEach((userFile) => {
          this.handleCurrentFileResponse(userFile);
        });
      });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybCompany);
    },

    handleCurrentFileResponse(params: KybUserFileResponse): void {
      switch (extractFileName(params.name)) {
        case KybDocumentsFileName["kyb-documents-registration-company"]:
          this.documentRegistrationCompany = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-regulation"]:
          this.documentRegulation = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-list-of-shareholders"]:
          this.documentListOfShareholders = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-license"]:
          this.documentLicense = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-check-addresses"]:
          this.documentCheckAddresses = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-sow"]:
          this.documentSow = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-sof"]:
          this.documentSof = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-inn"]:
          this.documentInn = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-record-sheet"]:
          this.documentRecordSheet = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-create"]:
          this.documentCreate = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-update"]:
          this.documentUpdate = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-purpose"]:
          this.documentPurpose = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-finance"]:
          this.documentFinance = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-example"]:
          this.documentExample = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-document"]:
          this.documentKyb = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-beneficiary"]:
          this.documentBeneficiary = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-broker"]:
          this.documentBroker = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-declaration"]:
          this.documentDeclaration = KybUserFileResponseFactory(params);

          break;
        case KybDocumentsFileName["kyb-documents-director"]:
          this.documentDirector = KybUserFileResponseFactory(params);

          break;
        default:
          break;
      }
    },
  },
});
