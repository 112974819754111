import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label')),
            innerHTML: _ctx.$t(column.label)
          }, null, 10, _hoisted_1),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('column-sort')),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleColumnSort && _ctx.handleColumnSort(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('column-icon')),
              innerHTML: _ctx.displayedIcons.sort
            }, null, 10, _hoisted_2)
          ], 2)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRows, (row) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('row')),
          key: row.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-date'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.date), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-id'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.id), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-email'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.email), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-type'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.type), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-amount'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label', { success: true }))
            }, _toDisplayString(row.amount), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-currency'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.currency), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-price'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.price), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-commission'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.commission), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-number'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.number), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-status'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('row-icon')),
              innerHTML: _ctx.displayedIcons.create
            }, null, 10, _hoisted_3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.status.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-stage'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(row.stage.label), 3),
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.b('row-action'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-icon')),
                innerHTML: _ctx.displayedIcons.remove
              }, null, 10, _hoisted_4)
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-action'))
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(_ctx.b('row-toggle'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('row-icon')),
                innerHTML: _ctx.displayedIcons.dots
              }, null, 10, _hoisted_5)
            ], 2)
          ], 2)
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}