<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Кошелек") }}
    </h2>

    <div :class="b('wrapper')">
      <WalletMain
        v-model:isConclusion="isConclusion"
        v-model:isInput="isInput"
      />

      <div :class="b('group')">
        <WalletHistory />

        <WalletApplications />
      </div>
    </div>

    <transition name="opacity">
      <WalletInput
        v-if="isInput"
        :class="b('modal')"
        v-model:isInput="isInput"
      />
    </transition>

    <transition name="opacity">
      <WalletConclusion
        v-if="isConclusion"
        :class="b('modal')"
        v-model:isConclusion="isConclusion"
      />
    </transition>
  </div>
</template>
