import { ApplicationRequest } from "../constants/constants";

export const ApplicationRequestFactory = (
  params: Partial<ApplicationRequest> = {}
): ApplicationRequest => {
  return {
    from: params.from
      ? params.from
      : {
          id: -1,
          label: "",
          currency: {
            icon: "",
            coin: "",
          },
        },
    to: params.to
      ? params.to
      : {
          id: -1,
          label: "",
          currency: {
            icon: "",
            coin: "",
          },
        },
    amount: params.amount ? params.amount : "",
    crypto: {
      coin: params.crypto ? params.crypto.coin : "",
      network: params.crypto ? params.crypto.network : "",
      address: params.crypto ? params.crypto.address : "",
    },
  };
};
