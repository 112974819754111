import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  emits: {
    create: null,
  },
  computed: {
    displayedCopyIcon(): string {
      return SVG.copy;
    },

    displayedChevronIcon(): string {
      return SVG.chevron;
    },
  },
  methods: {
    handleCopy(): void {
      console.log("Handle Copy");
    },

    handleCreate(): void {
      this.$emit("create");
    },
  },
});
